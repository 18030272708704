import React from 'react';
import { Image, StyleSheet } from 'react-native';

import mainStyles from '../../styles';

const PublicProfilePicture = ({ presence, small, url }) => {
  const pictureStyle = [styles.picture, small ? styles.small : styles.large];

  // eslint-disable-next-line default-case
  switch (presence) {
    case 'online':
      pictureStyle.push(styles.online);
      break;
    case 'offline':
      pictureStyle.push(styles.offline);
      break;
    case 'streaming':
      pictureStyle.push(styles.streaming);
      break;
  }

  const splitData = url === undefined ? [] : url.split('/');
  return (
    <Image
      defaultSource={require('../../assets/icons/userProfile/user.png')}
      source={splitData[splitData.length - 1] === 'undefined' ? require('../../assets/icons/userProfile/user.png') : { uri: url }}
      style={pictureStyle}
    />
  );
};

export default PublicProfilePicture;

const styles = StyleSheet.create({
  large: {
    width: 100,
    height: 100,
    borderRadius: 50,
  },
  offline: {},
  online: {
    borderWidth: 2,
    borderColor: mainStyles.colours.green,
  },
  streaming: {
    borderWidth: 2,
    borderColor: mainStyles.colours.orange,
  },
  picture: {
    borderWidth: 0,
    backgroundColor: mainStyles.colours.purpleDark,
  },
  small: {
    width: 50,
    height: 50,
    borderRadius: 25,
  },
});
