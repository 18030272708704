import React, { Component } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';

import selectors from '../../store/selectors';
import mainStyles from '../../styles';
import { withRouter } from '../../utils/routing/Routing.web';
import ModalRez from '../app/ModalRez';

class ProfilePicture extends Component {
  state = { showModal: null };

  handleFile(event) {
    this.props.history.push('/profile-crop', { file: event.target.files[0] });
  }

  showModal(modalName) {
    this.setState({ showModal: modalName });
  }

  openFilePicker() {
    this.fileRef.click();
  }

  render() {
    const splitData = this.props.profilePicture.split('/');

    return (
      <>
        <TouchableOpacity onPress={() => this.showModal('openFile')} testID="change-picture-button">
          <Image
            defaultSource={require('../../assets/icons/userProfile/user.png')}
            source={
              splitData[splitData.length - 1] === 'undefined'
                ? require('../../assets/icons/userProfile/user.png')
                : { uri: this.props.profilePicture }
            }
            style={styles.profilePicture}
          />
        </TouchableOpacity>

        <input
          ref={ref => {
            this.fileRef = ref;
          }}
          accept="image/*"
          id="pictureFile"
          onChange={event => {
            this.handleFile(event);
          }}
          style={{ display: 'none' }}
          type="file"
        />
        <ModalRez
          confirmButtonAction={() => this.openFilePicker()}
          confirmButtonLabel="Confirm"
          isVisible={this.state.showModal === 'openFile'}
          onBackdropPress={() => this.setState({ showModal: null })}
          testID="open-file-modal"
          title="Confirm Change Picture"
        >
          <Text>Do you want to change your profile picture?</Text>
        </ModalRez>
      </>
    );
  }
}

const styles = StyleSheet.create({
  profilePicture: {
    width: 108,
    height: 108,
    borderRadius: 54,
    backgroundColor: mainStyles.colours.dark,
    borderWidth: 2,
    borderColor: '#FE6B0C',
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    profilePicture: selectors.user.getProfilePicture(state),
  };
};

export default connect(mapStateToProps)(withRouter(ProfilePicture));
