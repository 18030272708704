import React from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { withRouter } from 'react-router-dom';

import mainStyles from '../../../styles';

const NavBarLeftButton = ({ history, backButton }) => {
  if (!history.index || !backButton) {
    return <View style={styles.container} />;
  }
  return (
    <TouchableOpacity onPress={() => history.goBack()} style={styles.container} testID="navbarback-button">
      <Image resizeMode="contain" source={require('../../../assets/icons/arrow/arrow_left.png')} style={styles.backArrow} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    width: mainStyles.TOPBAR_HEIGHT,
    height: mainStyles.TOPBAR_HEIGHT,
    flexBasis: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  backArrow: {
    width: 18,
    height: 18,
  },
});

export default withRouter(NavBarLeftButton);
