import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import createSagaMiddleware from 'redux-saga';
import { call } from 'redux-saga/effects';

import appReducers from './reducers/app';
import mediaReducers from './reducers/media';
import podcastReducers from './reducers/podcast';
import providerReducers from './reducers/providers';
import tuneinReducers from './reducers/tunein';
import userReducers from './reducers/user';
import rootSaga from './sagas';
import storage from './storage';
import types from './types';

export function safe(sagaFn) {
  return function* innerSafe(action) {
    try {
      return yield call(sagaFn, action);
    } catch (e) {
      console.warn('[global-saga-recoverable-handler]', e);
    }
  };
}

const effectMiddleware = next => effect => {
  if (effect.type === 'FORK' && effect.payload.args.length === 2) {
    effect.payload.args[1] = safe(effect.payload.args[1]);
  }
  return next(effect);
};

const sagaMiddleware = createSagaMiddleware({
  onError: e => console.error('[global-saga-fatal-handler]', e),
  effectMiddlewares: [effectMiddleware],
});

const rootPersistConfig = {
  key: 'rezzonation',
  storage,
  blacklist: ['app', 'media', 'players', 'tunein'],
  stateReconciler: autoMergeLevel2,
};

const mediaPersistConfig = {
  key: 'media',
  storage,
  blacklist: ['music', 'stream', 'player', 'playlists', 'seekbar', 'queue'],
  stateReconciler: autoMergeLevel2,
};

const appReducer = combineReducers({
  app: appReducers,
  media: persistReducer(mediaPersistConfig, mediaReducers),
  providers: providerReducers,
  user: userReducers,
  podcast: podcastReducers,
  tunein: tuneinReducers,
});

const rootReducer = (state, action) => {
  if (action.type === types.user.LOGOUT) {
    storage.removeItem('persist:root');
    state = undefined;
  }

  return appReducer(state, action);
};

export const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const composes = [applyMiddleware(sagaMiddleware)];
if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  composes.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}

export const store = createStore(persistedReducer, compose(...composes));

export const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
