import axios from 'axios';

import { config } from '../../config';

export const searchTrackOnId = async (id, token) =>
  axios({
    method: 'GET',
    url: 'https://api.music.apple.com/v1/catalog/NL/songs',
    params: {
      ids: id,
    },
    headers: {
      Authorization: `Bearer ${config.APPLE_DEV_TOKEN}`,
      'music-user-token': token,
    },
  }).then(res => res.data.data[0]);
