import '../assets/fonts/roboto.css';
import '../assets/fonts/cabo.css';

import * as consts from './consts';
import mainStyles from './mainStyles';

export default {
  ...mainStyles,
  ...consts,
};
