import React from 'react';
import { Image, Platform, StyleSheet, Text, TouchableHighlight, TouchableOpacity, View } from 'react-native';
import TextTicker from 'react-native-text-ticker';
import { connect } from 'react-redux';

import Seekbar from '../../components/media/seekbar/Seekbar';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import mainStyles from '../../styles';
import { withRouter } from '../../utils/routing/Routing';

function MusicMiniPlayer(props) {
  const { track, type, statuses, origin } = props.player;
  const { album, artistName, name, provider } = track;
  const imageSrc = album && album.images && Array.isArray(album.images) && album.images.length > 0 ? album.images[0].url : null;

  const togglePlayer = () => {
    props.toggleProviderStatus({ provider, status: props.player.statuses[provider] === 'playing' ? 'paused' : 'playing' });
  };

  const navigateOriginScreen = () => {
    switch (origin) {
      case 'music':
        const trackName = track.name.replace('/', ' ');
        props.history.push(`/track/${trackName}/${track.id}`, track);
        break;
      case 'video':
        props.history.push(`/video/${track.id}`);
        break;
      case 'radio':
        props.history.push(`/radio/${track.id}`, track);
        break;
      case 'podcast':
        props.history.push('/podcast/channel/play');
        break;
      default:
        console.warn('navigateOriginScreen: unknown type', type);
        break;
    }
  };

  const isProviderPlaying = () => Object.keys(statuses).find(prov => statuses[prov] === 'playing');

  return (
    <TouchableHighlight onPress={navigateOriginScreen} testID="mmp-openplayscreen-button">
      <View style={styles.miniPlayerContainer}>
        {origin !== 'radio' && (
          <View style={styles.seekbarContainer}>
            <Seekbar />
          </View>
        )}
        <Image
          defaultSource={require('../../assets/icons/radio/radio_mini.png')}
          resizeMode="cover"
          source={imageSrc === '' ? require('../../assets/icons/radio/radio_mini.png') : { uri: imageSrc }}
          style={styles.coverArt}
        />
        <View style={styles.titleContainer}>
          {Platform.OS !== 'web' && (
            <TextTicker
              duration={10000}
              loop
              marqueeDelay={1000}
              repeatSpacer={50}
              style={[styles.title, mainStyles.typography.p]}
              useNativeDriver={Platform.OS !== 'web'}
            >
              {name}
              {artistName ? ` - ${artistName}` : ''}
            </TextTicker>
          )}
          {Platform.OS === 'web' && (
            <Text numberOfLines={1} style={[styles.title, mainStyles.typography.p]} testID="mmp-track-title">
              {name}
              {artistName ? ` - ${artistName}` : ''}
            </Text>
          )}
        </View>
        <View style={styles.playerControlContainer}>
          <TouchableOpacity onPress={() => togglePlayer()} testID="mmp-playpause-button">
            {isProviderPlaying() ? (
              <Image source={require('../../assets/icons/pause/pause.png')} style={styles.playerControlIcon} testID="mmp-status-playing" />
            ) : (
              <Image source={require('../../assets/icons/play/play.png')} style={styles.playerControlIcon} testID="mmp-status-paused" />
            )}
          </TouchableOpacity>
        </View>
      </View>
    </TouchableHighlight>
  );
}

const styles = StyleSheet.create({
  coverArt: {
    width: 40,
    height: 40,
  },
  coverArtPlaceholder: {
    width: 40,
    height: 40,
    backgroundColor: mainStyles.IMAGE_PLACEHOLDER_COLOR,
    borderRadius: mainStyles.IMAGE_BORDER_RADIUS,
  },
  miniPlayerContainer: {
    backgroundColor: mainStyles.colours.controlBar,
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: mainStyles.MINI_PLAYER_HEIGHT,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: mainStyles.APP_PADDING,
    paddingRight: mainStyles.APP_PADDING,
  },
  playerControlContainer: {
    width: 32,
    height: 32,
  },
  playerControlIcon: {
    width: 32,
    height: 32,
  },
  titleContainer: {
    flex: 1,
    paddingLeft: 8,
    paddingRight: 8,
  },
  title: {
    color: mainStyles.TEXT_COLOR,
    textAlign: 'center',
    lineHeight: mainStyles.MINI_PLAYER_HEIGHT,
    flexGrow: 1,
  },
  seekbarContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
  },
});

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    player: selectors.media.player.getPlayer(state),
  }),
  {
    toggleProviderStatus: actions.media.player.toggleProviderStatus,
  },
)(withRouter(MusicMiniPlayer));
