import types from '../../types';

export const addGroupchatMessage = ({ message }) => ({
  type: types.tunein.ADD_NEW_GROUPCHAT_MESSAGE,
  payload: {
    message,
  },
});

export const clearGroupchatHistory = ({ chat }) => ({
  type: types.tunein.CLEAR_GROUPCHAT_HISTORY,
  payload: {
    chat,
  },
});

export const setUserOnline = ({ user }) => ({
  type: types.tunein.SET_USER_ONLINE,
  payload: {
    user,
  },
});

export const setUserOffline = ({ user }) => ({
  type: types.tunein.SET_USER_OFFLINE,
  payload: {
    user,
  },
});

export const setUserStreaming = ({ user }) => ({
  type: types.tunein.SET_USER_STREAMING,
  payload: {
    user,
  },
});

export const newTuneInTrackReceived = ({ payload }) => ({
  type: types.tunein.NEW_TRACK_RECEIVED,
  payload,
});

export const enableTuneIn = ({ user }) => ({
  type: types.tunein.ENABLE_TUNE_IN,
  payload: { user },
});

export const disableTuneIn = () => ({
  type: types.tunein.DISABLE_TUNE_IN,
});

export const clearPlayingStatus = ({ user }) => ({
  type: types.tunein.CLEAR_PLAYING_STATUS,
  payload: { user },
});

export const setHostUsersCount = data => ({
  type: types.tunein.SET_TUNE_IN_NUMBER,
  payload: data,
});

export const setTunedOutNumber = data => ({
  type: types.tunein.SET_TUNE_OUT_NUMBER,
  payload: data,
});

export const setResetTuneInNumber = () => ({
  type: types.tunein.RESET_TUNE_IN_NUMBER,
});
