export const getUserId = store => store.user.userId;
export const getJwt = store => store.user.jwt;
export const validInvite = store => store.user.hasValidInvite;
export const getProfile = store => store.user.profile;
export const getProfilePicture = store => store.user.profile.profilePictureUrl;
export const getXmppPassword = store => store.user.xmppPassword;
export const getFollowing = store => store.user.profile.following;
export const getUsername = store => store.user.profile.username;
export const getDisplayname = store => store.user.profile.displayName;
export const getFcmToken = store => store.user.fcmToken;
export const getCmData = store => store.user.cmData;
