export const CREATE_NEW_PLAYLIST = 'PLAYLIST_CREATE_NEW_PLAYLIST';
export const PLAYLIST_CREATED = 'PLAYLIST_CREATED';
export const UPDATE_PLAYLIST = 'PLAYLIST_UPDATE_PLAYLIST';
export const ADD_PLAYLIST = 'PLAYLIST_ADD_PLAYLIST';
export const DELETE_PLAYLIST = 'PLAYLIST_DELETE_PLAYLIST';
export const ADD_TRACK = 'PLAYLIST_ADD_TRACK';
export const TRACK_ADDED = 'PLAYLIST_TRACK_ADDED';

export const GET_PLAYLISTS = 'PLAYLIST_GET_PLAYLISTS';
export const PLAYLISTS_RETRIEVED = 'PLAYLIST_PLAYLISTS_RETRIEVED';

export const FOLLOW_PLAYLIST = 'PLAYLIST_FOLLOW_PLAYLIST';
export const FOLLOWING_PLAYLIST = 'PLAYLIST_FOLLOWING_PLAYLIST';
export const UNFOLLOW_PLAYLIST = 'PLAYLIST_UNFOLLOW_PLAYLIST';
export const UNFOLLOWED_PLAYLIST = 'PLAYLIST_UNFOLLOWED_PLAYLIST';
export const SET_PLAYLISTS = 'PLAYLISTS_SET_PLAYLISTS';
export const SET_IMAGE_PLAYLIST = 'PLAYLIST_SET_IMAGE';
export const GET_IMAGE_PLAYLIST = 'PLAYLIST_GET_IMAGE';
