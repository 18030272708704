import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity } from 'react-native';

import mainStyles from '../../styles';

const ModalButton = ({ children, onPress, label, iconSrc, testID }) => {
  return (
    <TouchableOpacity onPress={onPress} style={[styles.button, mainStyles.centerFlex]} testID={testID}>
      {iconSrc && <Image source={iconSrc} style={[styles.icon, mainStyles.margin.right.sm]} />}
      {label && <Text style={[mainStyles.typography.h3, styles.text]}>{label}</Text>}
      {children}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    flex: 1,
    flexDirection: 'row',
    padding: mainStyles.distance.sm,
  },
  text: {
    color: 'rgba(23,23,38,1)',
  },
  icon: {
    width: 20,
    height: 20,
  },
});

export default ModalButton;
