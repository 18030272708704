import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';

import actions from '../../../store/actions';
import mainStyles from '../../../styles';
import { withRouter } from '../../../utils/routing/Routing';

function PodcastListItem(props) {
  const navigateToPodcastChannel = channel => {
    props.setChannel({ channel });
    props.history.push('/podcast/channel/episodes');
  };

  return (
    <TouchableOpacity
      onPress={() => navigateToPodcastChannel(props.item)}
      style={[styles.podcastContainer, mainStyles.margin.bottom.xs, mainStyles.margin.top.xs]}
      testID={`podcast_${props.item.trackId}`}
    >
      <Image source={{ uri: props.item.artworkUrl60 }} style={[styles.searchImage, mainStyles.margin.right.sm]} />

      <View style={styles.podcastDetailsContainer}>
        <Text numberOfLines={1} style={mainStyles.typography.h2}>
          {props.item.trackName}
        </Text>
        <Text numberOfLines={2} style={mainStyles.typography.h2Light}>
          {props.item.artistName}
        </Text>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  podcastContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  podcastDetailsContainer: {
    flex: 1,
    paddingRight: mainStyles.distance.sm,
  },
  searchImage: {
    width: mainStyles.SMALL_IMAGE_SIZE,
    height: mainStyles.SMALL_IMAGE_SIZE,
    borderRadius: mainStyles.IMAGE_BORDER_RADIUS,
  },
});

export default connect(
  state => ({
    ...state,
  }),
  {
    setChannel: actions.podcast.search.setChannel,
  },
)(withRouter(PodcastListItem));
