import React, { Component } from 'react';
import ReactPlayer from 'react-player';

export default class VideoPlayer extends Component {
  setRefPlayer = player => {
    global.youtubePlayer = player;
  };

  generateVideoUrl({ id, provider }) {
    let url;
    switch (provider) {
      case 'youtube':
        url = `https://youtube.com/watch?v=${id}`;
        break;
      default:
        console.warn('generateVideoUrl: unknown provider', provider);
    }
    return url;
  }

  render() {
    const { height, id, provider, setDuration, status, width, onEnded, onPause, onPlay } = this.props;
    const { origin } = window.location;

    const url = this.generateVideoUrl({ id, provider });

    return (
      <ReactPlayer
        ref={this.setRefPlayer}
        config={{
          youtube: {
            playerVars: {
              modestbranding: 1,
              color: 'red',
              controls: 0,
              iv_load_policy: 3,
              playsinline: 1,
              origin,
            },
          },
        }}
        height={height}
        onDuration={setDuration}
        onEnded={onEnded}
        onPause={onPause}
        onPlay={onPlay}
        playing={status === 'playing'}
        url={url}
        width={width}
      />
    );
  }
}
