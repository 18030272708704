import types from '../../types';

export const setAuthToken = ({ token, expires }) => ({
  type: types.providers.deezer.SET_AUTH_TOKEN,
  payload: {
    token,
    expires,
  },
});

export const initDeezerPlayer = () => ({
  type: types.providers.deezer.INIT_PLAYER,
});

export const replayTrack = () => ({
  type: types.providers.deezer.REPLAY_TRACK,
});

export const playDeezerTrack = ({ track }) => ({
  type: types.providers.deezer.PLAY_TRACK,
  payload: {
    track,
  },
});
