import types from '../../types';

const initialState = {
  stations: [],
  radioStation: false,
};

export default (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case types.media.stations.SET_STATIONS:
      return {
        ...state,
        stations: payload,
      };
    case types.media.stations.SET_FAVORITE_STATION:
      return {
        ...state,
        stations: state.stations.map(station => (station.key === payload.key ? { ...station, favorite: payload.favorite } : station)),
      };
    case types.user.LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    case types.media.stations.SET_RADIOSTATION_ISFAV:
      return {
        ...state,
        radioStation: payload,
      };

    default:
      return state;
  }
};
