import types from '../../types';

export const addSubscription = payload => ({
  type: types.podcast.subscriptions.ADD_SUBSCRIPTION,
  payload: {
    ...payload,
  },
});

export const setSubscriptions = payload => ({
  type: types.podcast.subscriptions.SET_SUBSCRIPTIONS,
  payload: {
    ...payload,
  },
});

export const removeSubscription = payload => ({
  type: types.podcast.subscriptions.REMOVE_SUBSCRIPTION,
  payload: {
    ...payload,
  },
});
