import qs from 'qs';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import actions from '../../store/actions';
import selectors from '../../store/selectors';
import { updateProviderImportedPlaylists } from '../../utils/api/api';
import { Redirect, withRouter } from '../../utils/routing/Routing';

class DeezerAuth extends Component {
  componentDidMount() {
    const { location, setAuthToken, jwt } = this.props;
    const { token } = qs.parse(location.search.substr(1));

    if (token) {
      updateProviderImportedPlaylists(jwt, token, 'deezer');
      setAuthToken({ token });
    }
  }

  render() {
    const { originUrl, setOriginUrl } = this.props;
    const redirectTo = originUrl && originUrl !== null ? originUrl : '/music';
    setOriginUrl({ origin: null });

    return <Redirect to={redirectTo} />;
  }
}

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    originUrl: selectors.app.getOriginUrl(state),
    jwt: selectors.user.getJwt(state),
  }),
  {
    setOriginUrl: actions.app.setOriginUrl,
    setAuthToken: actions.providers.deezer.setAuthToken,
  },
)(withRouter(DeezerAuth));
