import React from 'react';
import { Image, Platform, StyleSheet, Text, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Modal from 'react-native-modal';

import mainStyles from '../../styles';
import PopupButton from './PopupButton';

const PopupRez = ({
  iconSrc,
  children,
  childrenTwo,
  title,
  isVisible,
  onBackdropPress,
  confirmButtonLabel,
  confirmButtonAction,
  cancelButtonLabel,
  testID,
  confirmButtonLabelForFriends,
  easySharing,
  easySharingAction,
  cancelButtonAction,
  appStoreImage,
  playStoreImage,
}) => {
  return (
    <Modal isVisible={isVisible} onBackdropPress={onBackdropPress} style={styles.modalContainer}>
      <LinearGradient colors={['rgba(60,33,91,1)', 'rgba(30,18,84,1)']} style={styles.container} testID={testID}>
        {title && (
          <View style={[styles.header, mainStyles.margin.bottom.sm]}>
            <Text style={[mainStyles.typography.h1, styles.title]}>{title}</Text>
            {iconSrc && <Image source={iconSrc} style={[styles.icon]} />}
          </View>
        )}
        {children && (
          <View style={mainStyles.margin.bottom.lg}>
            <Text style={styles.paragraphText}>{children}</Text>
          </View>
        )}
        {childrenTwo && (
          <View style={[mainStyles.margin.bottom.md]}>
            <Text style={styles.paragraphText}>{childrenTwo}</Text>
          </View>
        )}
        {appStoreImage && (
          <View style={[styles.imageContainer, mainStyles.margin.bottom.sm]}>
            <Image source={appStoreImage} style={[styles.image]} />
            <Image source={playStoreImage} style={[styles.image]} />
          </View>
        )}
        {confirmButtonAction && (
          <View style={styles.buttonContainer}>
            <PopupButton
              backgroundColor={mainStyles.colours.orange}
              fontSize
              label={confirmButtonLabel}
              labelFriend={confirmButtonLabelForFriends}
              onPress={confirmButtonAction}
              testID={`${testID}-confirmbutton`}
            />
            {easySharing && <PopupButton labelEasysharing={easySharing} onPress={easySharingAction} testID={`${testID}-easysharingbutton`} />}
            {cancelButtonLabel && <PopupButton labelCancel={cancelButtonLabel} onPress={cancelButtonAction} testID={`${testID}-cancelbutton`} />}
          </View>
        )}
      </LinearGradient>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    justifyContent: 'center',
    alignItems: Platform.OS === 'web' ? 'center ' : null,
  },
  container: {
    borderWidth: 1,
    borderColor: mainStyles.colours.orange,
    paddingHorizontal: 20,
    paddingVertical: 14,
    borderRadius: 10,
    minWidth: 280,
    maxWidth: 500,
  },
  buttonContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: '5%',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: Platform.OS === 'web' ? 8 : 10,
  },
  title: {
    color: mainStyles.colours.orangeLight,
    fontWeight: 'bold',
    paddingTop: '2%',
    fontFamily: 'Roboto-Medium',
  },
  icon: {
    width: 20,
    height: 15,
    padding: '5%',
    marginLeft: '2%',
  },
  paragraphText: {
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'Roboto-Medium',
    color: 'white',
  },
  imageContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingBottom: Platform.OS === 'web' ? 8 : 10,
  },
  image: {
    width: '45%',
    height: 35,
    borderRadius: 6,
    padding: '5%',
  },
});

export default PopupRez;
