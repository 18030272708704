/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
import { taffy } from 'taffydb';

import storage from '../store/storage.web';

let db;
storage.getItem('podcastdb').then(
  json_str => {
    try {
      const dbData = json_str ? JSON.parse(json_str) : [];
      db = new taffy(dbData);
      console.log('Database initialized: ', db().get());
    } catch (error) {
      console.log('Error while parsing the DB: ', error);
    }
  },
  error => {
    console.log('Error while fetching the DB from storage: ', error);
  },
);

let popupStatedb;
storage.getItem('popupStateDb').then(
  json_str => {
    try {
      const dbData = json_str ? JSON.parse(json_str) : [];
      popupStatedb = new taffy(dbData);
      console.log('Database initialized: ', popupStatedb().get());
    } catch (error) {
      console.log('Error while parsing the DB: ', error);
    }
  },
  error => {
    console.log('Error while fetching the DB from storage: ', error);
  },
);

export const databaseInit = () => {
  return new Promise((resolve, reject) => {
    // No need to initialize the Taffy db, its already been opened and ready to use
    resolve();
  });
};

export const insertPopupState = (isPopupshowing, id) => {
  return new Promise((resolve, reject) => {
    const data = {
      isPopupshowing,
      id,
    };
    const result = popupStatedb.insert(data, true);
    console.log('--result insert--', result.get());
    savepopupStatedb();
    popupStatedb.settings({
      onInsert() {
        console.log('New data inserted:', this);
        savepopupStatedb();
        resolve(this);
      },
    });
  });
};

export const updatePopupState = (id, isPopupshowing) => {
  return new Promise((resolve, reject) => {
    popupStatedb({ id }).update({ isPopupshowing });
    savepopupStatedb();
    popupStatedb.settings({
      onUpdate() {
        console.log('Data Updated:', this);
        resolve(this);
      },
    });
  });
};

export const fetchTitleById = id => {
  return new Promise((resolve, reject) => {
    const data = popupStatedb({ id }).get();
    resolve(data);
  });
};

export const savePopupDataLocally = async () => {
  try {
    const stateData = await fetchTitleById('1');
    if (stateData.length !== 0) {
      await updatePopupState('1', 'true');
    } else {
      await insertPopupState('true', '1');
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const savepopupStatedb = () => {
  // Only available for web client
  try {
    const data = popupStatedb().get();
    if (typeof data === 'object' && Array.isArray(data)) {
      const json_str = JSON.stringify(data);
      storage.setItem('popupStateDb', json_str).then(
        () => {
          console.log('DB has been saved!');
        },
        error => {
          console.log('Error while saving the DB into storage: ', error);
          return false;
        },
      );
    }
  } catch (error) {
    console.log('Error while saving the DB: ', error);
    return false;
  }
};

export const insertPodcast = (title, podcastId, position, duration, userId) => {
  return new Promise((resolve, reject) => {
    const data = {
      title,
      podcastId: podcastId || '',
      position,
      duration,
      userId,
    };
    const result = db.insert(data, true);
    console.log('--result insert--', result.get());
    saveDB();
    db.settings({
      onInsert() {
        console.log('New data inserted:', this);
        saveDB();
        resolve(this);
      },
    });
  });
};

export const fetchPodcastsByUserId = userId => {
  return new Promise((resolve, reject) => {
    const data = db({ userId }).get();
    console.log('Podcast data (fetchPodcastsByUserId): ', data);
    resolve(data);
  });
};

export const deletePodcasts = () => {
  return new Promise((resolve, reject) => {
    db().remove();
    console.log('All Podcast Deleted');
    saveDB();
    resolve();
  });
};

export const deletePodcastById = (userId, podcastId, title) => {
  return new Promise((resolve, reject) => {
    db({ userId, podcastId, title }).remove();
    console.log('Podcast Deleted');
    saveDB();
    resolve();
  });
};

export const fetchPodcastsById = (userId, podcastId, title) => {
  return new Promise((resolve, reject) => {
    const data = db({ userId, podcastId: podcastId || '', title }).get();
    console.log('Podcast data (fetchPodcastsById): ', data);
    resolve(data);
  });
};

export const updatePodcastPosition = (userId, podcastId, position, title) => {
  return new Promise((resolve, reject) => {
    db({ userId, podcastId: podcastId || '', title }).update({ position });
    saveDB();
    db.settings({
      onUpdate() {
        console.log('Data Updated:', this);
        resolve(this);
      },
    });
  });
};

export const saveDB = () => {
  // Only available for web client
  try {
    const data = db().get();
    if (typeof data === 'object' && Array.isArray(data)) {
      const json_str = JSON.stringify(data);
      storage.setItem('podcastdb', json_str).then(
        () => {
          console.log('DB has been saved!');
        },
        error => {
          console.log('Error while saving the DB into storage: ', error);
          return false;
        },
      );
    }
  } catch (error) {
    console.log('Error while saving the DB: ', error);
    return false;
  }
};

const initDB = () => {
  // Only available for web client
  storage.getItem('podcastdb').then(
    json_str => {
      try {
        let dbData;
        if (json_str) {
          dbData = JSON.parse(json_str);
          console.log('DB data found: ', dbData);
        } else {
          console.log('No DB data is saved: ', json_str);
          dbData = [];
        }
        return new taffy(dbData);
      } catch (error) {
        console.log('Error while parsing the DB: ', error);
        return false;
      }
    },
    error => {
      console.log('Error while fetching the DB: ', error);
      return false;
    },
  );
};
