/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { Platform, Share, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import ImagePicker from 'react-native-image-crop-picker';
import { connect } from 'react-redux';

import ListButton from '../../../components/app/ListButton';
import ModalRez from '../../../components/app/ModalRez';
import ListScreenHeader from '../../../containers/app/ListScreenHeader';
import ShareModal from '../../../containers/app/ShareModal';
import FullScreenLayout from '../../../layouts/fullscreen';
import actions from '../../../store/actions';
import selectors from '../../../store/selectors';
import mainStyles from '../../../styles';
import { ACTIVITY_CM_KEY } from '../../../styles/consts';
import { deletePlaylist, editPlaylist, sendDetailsToCM, uploadPlaylisPicture } from '../../../utils/api/api';
import { config } from '../../../utils/config';
import { darkMode } from '../../../utils/DarkMode';
import analytics from '../../../utils/routing/analytics';
import { withRouter } from '../../../utils/routing/Routing';

class PlaylistOptionsScreen extends Component {
  state = {
    showModal: null,
    editedPlaylistName: '',
    croppedImage: '',
    loading: false,
  };

  errorTexts = {
    VALIDATION_ERROR: 'Something is off, please check your input and try again',
    default: 'Ooops, something went wrong. Please try again later.',
  };

  shareTexts = {
    message: `Open this playlist in Rezzonation ${config.APP_URL}/playlists/${this.props.location.state.shortId || ''}`,
    title: 'Share playlist',
    url: `${config.APP_URL}/playlists/${this.props.location.state.shortId || ''}`,
  };

  componentDidMount() {
    const { location } = this.props;
    if (!location.state.shortId) {
      this.goToPlaylist();
    }
  }

  handleFile(event) {
    this.props.history.push(`/playlist-crop/${this.props.playlist.playlistId}`, { file: event.target.files[0] });
  }

  onDeletePlaylist() {
    const { jwt, storeDeletePlaylist, playlist } = this.props;

    deletePlaylist({ jwt, playlist: playlist.playlistId })
      .then(() => {
        this.setState({ showModal: null });
        storeDeletePlaylist(playlist.playlistId);
        this.props.setAlert(null);
        this.props.history.go(-2);
      })
      .catch(() => {
        this.props.setAlert({ message: `Couldn't delete playlist ${playlist.name}`, type: 'error' });
      });
  }

  renamePlaylist() {
    const { jwt, playlist } = this.props;
    const { editedPlaylistName } = this.state;

    const data = { name: editedPlaylistName };

    editPlaylist({ jwt, data, playlistId: playlist.playlistId })
      .then(({ name }) => {
        this.setState({ showModal: null });
        this.props.updatePlaylist({
          ...playlist,
          name,
        });
        this.props.setAlert(null);
      })
      .catch(err => {
        this.props.setAlert({
          message: this.errorTexts[err.response.error] || `Couldn't rename playlist ${playlist.name} to ${editedPlaylistName}`,
          type: 'error',
        });
      });
  }

  sharePlaylist() {
    // const id = this.props.playlist.shortId;  --> for new share screen
    // const title = this.props.playlist.name;

    // this.props.history.push('/share', { type: 'playlist', id, title }); --> for new share screen

    analytics().logEvent('share_playlist');
    const { url, title, message } = this.shareTexts;
    const cmActivity = {
      created: new Date(),
      userId: this.props.userId,
      playlistShare: this.props.playlist.playlistId,
    };
    sendDetailsToCM(cmActivity, ACTIVITY_CM_KEY);
    if (Platform.OS === 'web') {
      this.showModal('share');
    } else {
      Share.share(
        {
          title,
          url,
          message: Platform.OS === 'android' ? `${message} ${url}` : message,
        },
        {
          dialogTitle: title,
          subject: title,
        },
      );
    }
  }

  followPlaylist() {
    const { followPlaylist, unfollowPlaylist, userId, playlist } = this.props;

    if (!playlist.isFollower) {
      analytics().logEvent('follow_playlist');
      followPlaylist({
        playlistId: playlist.playlistId,
        userId,
        shortId: playlist.shortId,
        isOwner: playlist.isOwner,
      });
    } else {
      analytics().logEvent('unfollow_playlist');
      unfollowPlaylist({
        playlistId: playlist.playlistId,
        shortId: playlist.shortId,
      });
    }
  }

  addImagePlaylist() {
    if (Platform.OS === 'web') {
      this.fileRef.click();
    } else {
      this.setState({
        loading: true,
      });

      ImagePicker.openPicker({
        width: 1024,
        height: 1024,
        cropping: true,
        includeBase64: true,
        multiple: false,
        mediaType: 'photo',
        showCropGuidelines: false,
        cropperCircleOverlay: true,
      })
        .then(image => {
          this.setState({
            croppedImage: image.data,
          });
          this.uploadFile();
        })
        .catch(err => {
          this.setState({
            loading: false,
          });
          this.props.setAlert({ message: "We can't open that file. Is it a photo?", type: 'error' });
        });
    }
  }

  uploadFile() {
    const { jwt, playlist } = this.props;

    uploadPlaylisPicture({ playlistId: playlist.playlistId, picture: this.state.croppedImage, jwt })
      .then(data => {
        if (data.url) {
          this.props.setImagePlaylist({
            data,
          });
          this.props.setAlert({ message: 'Your playlist picture has been updated.', type: 'success' });
        } else {
          this.props.setAlert({ message: 'Something went wrong updating your playlist picture.', type: 'error' });
        }
        this.setState({
          croppedImage: '',
          loading: false,
        });
      })
      .catch(error => {
        this.props.setAlert({ message: 'Something went wrong updating your playlist picture.', type: 'error' });
        this.setState({
          croppedImage: '',
          loading: false,
        });
      });
  }

  publicisePlaylist() {
    const { jwt, playlist } = this.props;

    const data = { isPublic: !playlist.isPublic };

    editPlaylist({ jwt, data, playlistId: playlist.playlistId })
      .then(({ isPublic }) => {
        this.props.updatePlaylist({
          ...playlist,
          isPublic,
        });
        this.props.setAlert(null);
      })
      .catch(err => {
        this.props.setAlert({
          message: this.errorTexts[err.response.error] || `Couldn't publicise/privatise playlist ${playlist.name}`,
          type: 'error',
        });
      });
  }

  goToPlaylist() {
    this.props.setAlert(null);
    this.props.history.push(`/playlists/${this.props.playlist.shortId}`);
  }

  showModal(modalName) {
    this.setState({ showModal: modalName });
  }

  render() {
    if (!this.props.playlist) this.props.getPlaylists();
    const playlist = this.props.playlist || { tracks: [] };
    // const imageSrc = playlist && playlist.source && playlist.source.image ? playlist.source.image : undefined;
    const { playlistImageData } = this.props;
    let imageSrc = '';
    if (playlistImageData && playlistImageData.data && playlistImageData.data.url && playlistImageData.data.playlistId === playlist.playlistId) {
      imageSrc = this.props.playlistImageData.data.url;
    } else if (playlist && playlist.customImage) {
      imageSrc = playlist.customImage;
    } else {
      imageSrc = playlist && playlist.source && playlist.source.image ? playlist.source.image : undefined;
    }

    return (
      <FullScreenLayout>
        <ListScreenHeader image={imageSrc} title={playlist && playlist.name} />

        <View style={styles.listButtonContainer}>
          <ListButton icon={require('../../../assets/icons/share/share.png')} onPress={() => this.sharePlaylist()}>
            Share playlist
          </ListButton>
          {playlist.isOwner && (
            <>
              <ListButton
                icon={require('../../../assets/icons/edit/edit.png')}
                onPress={() => this.showModal('rename')}
                testID="playlistrename-button"
              >
                Rename playlist
              </ListButton>
              <ListButton
                icon={require('../../../assets/icons/trash/trash.png')}
                onPress={() => this.showModal('delete')}
                testID="playlistdelete-button"
              >
                Delete playlist
              </ListButton>
              <ListButton
                icon={require('../../../assets/icons/addplaylist/addtoplaylist.png')}
                onPress={() => this.addImagePlaylist()}
                testID="addimageplaylist-button"
              >
                Add image
              </ListButton>
            </>
          )}
          {Platform.OS === 'web' && (
            <>
              <input
                ref={ref => {
                  this.fileRef = ref;
                }}
                accept="image/*"
                id="pictureFile"
                onChange={event => {
                  this.handleFile(event);
                }}
                style={{ display: 'none' }}
                type="file"
              />
            </>
          )}

          <TouchableOpacity onPress={() => this.goToPlaylist()} style={mainStyles.centerFlex} testID="playlistscreen-button">
            <Text style={[mainStyles.typography.ctaUnderline, mainStyles.margin.bottom.lg, mainStyles.margin.top.md]}>Cancel</Text>
          </TouchableOpacity>
        </View>

        <ModalRez
          confirmButtonAction={() => this.onDeletePlaylist()}
          confirmButtonLabel="Delete"
          isVisible={this.state.showModal === 'delete'}
          onBackdropPress={() => this.setState({ showModal: null })}
          testID="playlistdelete-modal"
          title="Delete playlist"
        >
          <Text>Are you sure you want to delete your playlist?</Text>
        </ModalRez>

        <ModalRez
          confirmButtonAction={() => this.renamePlaylist()}
          confirmLabel="Rename"
          isVisible={this.state.showModal === 'rename'}
          onBackdropPress={() => this.setState({ showModal: null })}
          testID="playlistrename-modal"
          title="Rename playlist"
        >
          <Text>What would you like to rename your playlist to?</Text>
          <TextInput
            defaultValue={playlist.name}
            onChangeText={text => {
              this.setState({ editedPlaylistName: text });
            }}
            style={[mainStyles.margin.top.sm, styles.renameTextInput]}
            testID="playlistrename-input"
          />
        </ModalRez>

        <ShareModal
          isVisible={this.state.showModal === 'share'}
          message={this.shareTexts.message}
          onBackdropPress={() => this.setState({ showModal: null })}
          title={this.shareTexts.title}
          url={this.shareTexts.url}
        />
      </FullScreenLayout>
    );
  }
}

const styles = StyleSheet.create({
  listButtonContainer: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  renameTextInput: {
    backgroundColor: 'white',
    color: darkMode ? mainStyles.colours.dark : 'white',
    padding: mainStyles.distance.sm,
    height: 40,
  },
});

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    jwt: selectors.user.getJwt(state),
    userId: selectors.user.getUserId(state),
    playlist: selectors.media.playlists.getPlaylist(state, ownProps.location.state.shortId),
    playlistImageData: selectors.media.playlists.getImagePlaylist(state),
  }),
  {
    updatePlaylist: actions.media.playlists.updatePlaylist,
    setAlert: actions.app.setAlert,
    storeDeletePlaylist: actions.media.playlists.deletePlaylist,
    followPlaylist: actions.media.playlists.followPlaylist,
    unfollowPlaylist: actions.media.playlists.unfollowPlaylist,
    setImagePlaylist: actions.media.playlists.setImagePlaylist,
    getPlaylists: actions.media.playlists.getPlaylists,
  },
)(withRouter(PlaylistOptionsScreen));
