import React from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import Modal from 'react-native-modal';

import mainStyles from '../../styles';
import ModalButton from './ModalButton';

class ModalRez extends Modal {
  render() {
    const { children, title, isVisible, onBackdropPress, confirmButtonLabel, confirmButtonAction, cancelButtonLabel, testID } = this.props;

    return (
      <Modal isVisible={isVisible} onBackdropPress={onBackdropPress}>
        <View style={styles.container} testID={testID}>
          {title && (
            <View style={[styles.header, mainStyles.margin.bottom.md]}>
              <Text style={[mainStyles.typography.h2, styles.title]}>{title}</Text>
            </View>
          )}
          {children && <View style={mainStyles.margin.bottom.md}>{children}</View>}
          {confirmButtonAction && (
            <View style={styles.buttonContainer}>
              <ModalButton label={confirmButtonLabel || 'Confirm'} onPress={confirmButtonAction} testID={`${testID}-confirmbutton`} />
              <ModalButton label={cancelButtonLabel || 'Cancel'} onPress={onBackdropPress} testID={`${testID}-cancelbutton`} />
            </View>
          )}
        </View>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(205,205,205,1)',
    paddingTop: 14,
    paddingBottom: 14,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 10,
    minWidth: 280,
  },
  buttonContainer: {
    flexDirection: 'row',
  },
  header: {
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(115,106,120,1)',
    paddingBottom: Platform.OS === 'web' ? 8 : 16,
  },
  title: {
    color: 'rgba(23,23,38,1)',
  },
});

export default ModalRez;
