import React from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { withRouter } from 'react-router-dom';

import mainStyles from '../../../styles';

const NavBarRightButton = ({ onPress }) => {
  return (
    <View style={styles.container}>
      <TouchableOpacity hitSlop={{ top: 10, bottom: 20, left: 20, right: 20 }} onPress={onPress} testID="navbaroptions-button">
        <Image resizeMode="contain" source={require('../../../assets/icons/more/more_vertical.png')} style={styles.icon} />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: mainStyles.TOPBAR_HEIGHT,
    height: mainStyles.TOPBAR_HEIGHT,
    flexBasis: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 18,
    height: 18,
  },
  menuButton: {
    justifyContent: 'center',
    width: 32,
    height: 32,
  },
  menuButtonIcon: {
    margin: 'auto',
  },
});

export default withRouter(NavBarRightButton);
