import { client, jid, xml } from '@xmpp/client';
import debug from '@xmpp/debug';
import setupPubSub from '@xmpp-plugins/pubsub';
import EventEmitter from 'events';
import uuid from 'uuid/v4';

import { store } from '../../store/store';
import { TUNED_IN_EMIT, TUNED_IN_TYPE, TUNED_OUT_EMIT, TUNED_OUT_TYPE, XMPP_GROUP_TYPE, XMPP_MESSAGE } from '../../styles/consts';
import { config } from '../config';

export default class xmpp extends EventEmitter {
  constructor({ username, password, following = [] }) {
    super();
    this.client = client({
      service: config.XMPP_SERVICE,
      domain: config.XMPP_DOMAIN,
      username: username.toLowerCase(),
      password,
      reconnect: {
        delay: 5000,
      },
    });
    debug(this.client, true);
    this.pubsub = setupPubSub(this.client);
    this.domain = config.XMPP_DOMAIN;
    this.following = following;
    this.username = username.toLowerCase();
    this.hasInitialized = false;
    this.registered = false;
    this.from = '';
    this.pubsubServiceId = `pubsub.${this.domain}`;
    this.status = this.client.status;
    this.subscribedTo = [];
    this.starting = false;
    // eslint-disable-next-line babel/no-unused-expressions
    this.startingTimer;

    if (!this.hasInitialized) {
      this.registerListeners();
    }
  }

  registerListeners() {
    if (!this.registered) {
      this.client.on('offline', () => {
        this.status = 'offline';
        this.hasInitialized = false;
      });

      this.client.on('online', async address => {
        this.from = address.toString();
        this.status = 'online';
        if (!this.hasInitialized) this.initialize();
      });

      this.client.on('disconnect', () => {
        this.status = 'offline';
        this.hasInitialized = false;
      });

      this.client.on('error', err => {
        console.log('XMPP error', err);
      });

      this.client.on('stanza', stanza => {
        try {
          const stanzaStmp = Object.assign(stanza);
          this.processStanza(stanzaStmp);
        } catch (err) {
          console.log(err);
        }
      });

      this.registered = true;
    }
  }

  getStatus() {
    return this.status;
  }

  initialize() {
    this.sendPresence();

    this.sendPresence(this.username);
    console.log(`FOLLOWING - ${this.following}`);
    this.following.forEach(u => {
      this.emit('ClearGroupChat', { chat: `${u.toLowerCase()}@conference.${this.domain}` });
      this.sendPresence(u.toLowerCase());
      this.subscribePubSubUser({ username: u.toLowerCase() });
    });

    this.pubsub.items(this.pubsubServiceId, this.username).catch(err => {
      if (err && err.message === 'item-not-found') {
        this.pubsub.createNode(this.pubsubServiceId, this.username).catch(e => console.log(e));
      }
    });

    this.pubsub.on(`item-published:${this.pubsubServiceId}`, event => this.processPubsubMessage(event, this));
    this.requestAllSubscriptions();

    this.hasInitialized = true;
    clearInterval(this.startingTimer);
  }

  start() {
    this.startingTimer = setInterval(() => {
      if (!this.starting) {
        this.starting = true;
        this.client
          .start()
          .then(() => {
            this.starting = false;
          })
          .catch(() => {
            this.starting = false;
          });
      }
      store.dispatch({ type: 'APP_SET_TUNEIN_STATUS', payload: false });
    }, 30000);
  }

  logout() {
    this.client.stop().catch(console.error);
  }

  async sendPresence(to, type) {
    try {
      if (to) {
        console.log(`SEND PRESENCE TO: ${to}`);
        await this.client.send(
          xml('presence', {
            from: `${this.username}@${this.domain}`,
            to: `${to}@conference.${this.domain}/${this.username}`,
            type,
          }),
        );
      } else {
        await this.client.send(xml('presence'));
      }
    } catch (err) {
      console.error(err);
    }
  }

  requestAllSubscriptions() {
    try {
      const msg = xml(
        'iq',
        { type: 'get', from: `${this.username}@${this.domain}`, to: this.pubsubServiceId },
        xml('pubsub', { xmlns: 'http://jabber.org/protocol/pubsub' }, xml('subscriptions')),
      );
      this.client.send(msg);
    } catch (err) {
      console.error(err);
    }
  }

  // This function uses for send message to one xmpp user to another user and also tuned in count
  // as xmpp doesn't provide any other xml method to send a value from one xmpp client to antoher xmpp client
  sendMessage({ to, type, message }) {
    let msg;
    if (type === XMPP_GROUP_TYPE) {
      msg = xml(XMPP_MESSAGE, { type: XMPP_GROUP_TYPE, to, id: uuid(), from: this.from }, xml('body', {}, message));
    } else if (type === TUNED_IN_TYPE) {
      msg = xml(XMPP_MESSAGE, { type: XMPP_GROUP_TYPE, to, id: uuid(), from: this.from }, xml(TUNED_IN_TYPE, {}, JSON.stringify(message)));
    } else if (type === TUNED_OUT_TYPE) {
      msg = xml(XMPP_MESSAGE, { type: XMPP_GROUP_TYPE, to, id: uuid(), from: this.from }, xml(TUNED_OUT_TYPE, {}, JSON.stringify(message)));
    }
    this.client.send(msg).catch(() => {});
  }

  processStanza(stanza) {
    try {
      if (stanza.is(XMPP_MESSAGE)) {
        if (stanza.getChild('delay') && stanza.getChild('event')) {
          const event = stanza.getChild('event');
          const items = event.children.find(e => e.name === 'items');
          const item = items.children.find(e => e.name === 'item');
          const entry = item.children.find(i => i.name === 'entry');

          try {
            const data = JSON.parse(entry.children[0]);
            const from = items.attrs.node;
            this.processStreamingMessage({ from, data });
          } catch (err) {
            console.error(err);
          }
        }
        if (stanza.getChild('body') && stanza.getChild('body').text()) {
          if (stanza.attrs.type === XMPP_GROUP_TYPE) {
            this.processGroupChatMessage(stanza);
          }
        }
        if (stanza.getChild(TUNED_IN_TYPE) && stanza.getChild(TUNED_IN_TYPE).text()) {
          this.processTuneIn(stanza);
        }
        if (stanza.getChild(TUNED_OUT_TYPE) && stanza.getChild(TUNED_OUT_TYPE).text()) {
          this.processTuneOut(stanza);
        }
      }

      if (stanza.is('iq')) {
        if (stanza.attrs.from === this.pubsubServiceId) {
          const pubsub = stanza.getChild('pubsub');
          if (pubsub) {
            const subscriptions = pubsub.getChild('subscriptions');
            if (subscriptions) {
              this.subscribedTo = subscriptions.children.map(s => s.attrs.node);
              this.emit('TuneInSubscribersReceived');
            }
          }
        }
      }
    } catch (err) {
      console.log('Error processing stanza', stanza);
      console.error(err);
    }

    if (stanza.is('presence')) {
      const { from, to, type } = stanza.attrs;
      const user = jid(from);
      const toUser = jid(to);
      const domainRegex = new RegExp('.*conference.*');
      if (!user.getDomain().match(domainRegex)) {
        if (type === 'unavailable') {
          this.emit('TuneInUserOffline', { user: user.getLocal() });
        }
        if (!type && toUser.getLocal() !== user.getLocal()) {
          console.log(`####ONLINE FROM - ${from}`);
          this.emit('TuneInUserOnline', { user: user.getLocal() });
        }
      }
    }
  }

  processTuneIn(stanza) {
    try {
      const message = stanza.getChild(TUNED_IN_TYPE).text();
      this.emit(TUNED_IN_EMIT, message);
    } catch (err) {
      console.error(err);
    }
  }

  processTuneOut(stanza) {
    try {
      const message = stanza.getChild(TUNED_OUT_TYPE).text();
      this.emit(TUNED_OUT_EMIT, message);
    } catch (err) {
      console.error(err);
    }
  }

  processGroupChatMessage(stanza) {
    const chat = stanza.attrs.from.split('/')[0];
    const from = stanza.attrs.from.split('/')[1];
    try {
      const message = stanza.getChild('body').text();
      const msgId = stanza.getChild('stanza-id').attrs.id;
      const date = stanza.getChild('delay') ? stanza.getChild('delay').attrs.stamp : new Date();

      const msg = {
        id: msgId,
        chat,
        from,
        message,
        date,
      };

      this.emit('NewGroupChatMessage', { message: msg });
    } catch (err) {
      console.error(err);
    }
  }

  processPubsubMessage(event) {
    if (event.node) {
      try {
        const data = JSON.parse(event.entry.children[0]);
        const from = event.node;
        this.processStreamingMessage({ from, data });
      } catch (e) {
        console.error(e);
      }
    }
  }

  processStreamingMessage({ from, data }) {
    const payload = {
      from,
    };

    if (data.type) {
      switch (data.type) {
        case 'TuneInTrack':
          payload.track = data.payload;
          this.emit('NewTuneInTrackReceived', payload);
          break;
        case 'StartStreaming':
          this.emit('TuneInUserStartedStreaming', payload);
          break;
        case 'StopStreaming':
          this.emit('TuneInUserStoppedStreaming', payload);
          break;
        default:
          break;
      }
    }
  }

  subscribePubSubUser({ username }) {
    const subMsg = xml(
      'iq',
      {
        type: 'set',
        from: `${this.username}@${this.domain}`,
        to: this.pubsubServiceId,
      },
      xml(
        'pubsub',
        { xmlns: 'http://jabber.org/protocol/pubsub' },
        xml('subscribe', {
          node: username,
          jid: `${this.username}@${this.domain}`,
        }),
      ),
    );
    this.client.send(subMsg).catch(err => {
      console.log('Error subscribing to user PubSub');
      console.log(err);
    });
  }

  unsubscribePubSubUser({ username }) {
    const subMsg = xml(
      'iq',
      {
        type: 'set',
        from: `${this.username}@${this.domain}`,
        to: this.pubsubServiceId,
      },
      xml(
        'pubsub',
        { xmlns: 'http://jabber.org/protocol/pubsub' },
        xml('unsubscribe', {
          node: username,
          jid: `${this.username}@${this.domain}`,
        }),
      ),
    );
    this.client.send(subMsg).catch(() => {});
  }

  publishPlayingStatus({ status, position, dateStartedTrack, hostUserTuneInCount }) {
    console.log('Publishing playing status', status);
    status = { trackPosition: position, trackStartTime: dateStartedTrack, tuneInNumber: hostUserTuneInCount, ...status };

    this.pubsub
      .publish(
        this.pubsubServiceId,
        this.username,
        xml(
          'item',
          {},
          xml(
            'entry',
            {},
            JSON.stringify({
              type: 'TuneInTrack',
              payload: status,
            }),
          ),
        ),
      )
      .catch(() => {});
    // This publish will error. Openfire's response is valid but does not include child attributes.
    // The library however does expect one to be there so this will error in .getChild() is undefined.
  }

  publishIsStreaming() {
    this.status = 'streaming';
    this.pubsub
      .publish(
        this.pubsubServiceId,
        this.username,
        xml(
          'item',
          {},
          xml(
            'entry',
            {},
            JSON.stringify({
              type: 'StartStreaming',
            }),
          ),
        ),
      )
      .catch(() => {});
    // This publish will error. Openfire's response is valid but does not include child attributes.
    // The library however does expect one to be there so this will error in .getChild() is undefined.
  }

  publishStoppedStreaming() {
    this.status = 'online';
    this.pubsub
      .publish(
        this.pubsubServiceId,
        this.username,
        xml(
          'item',
          {},
          xml(
            'entry',
            {},
            JSON.stringify({
              type: 'StopStreaming',
            }),
          ),
        ),
      )
      .catch(() => {});
  }
}
