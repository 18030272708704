import axios from 'axios';
import { eventChannel } from 'redux-saga';
import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects';

import { normalizeDeezerTrack } from '../../../utils/providers/deezer/normalize';
import types from '../../types';

function deezerChannel() {
  return eventChannel(emitter => {
    const { deezer } = global;

    deezer.addListener('DEEZER_LOGIN', login => {
      emitter({
        type: 'event',
        event: 'deezer_login',
        payload: {
          token: login.token,
          expires: login.expires,
          userId: login.userId,
        },
      });
    });

    deezer.addListener('DeezerPlay', () => {
      emitter({
        type: 'event',
        event: 'playing',
        payload: {},
      });
    });

    deezer.addListener('DeezerCurrentTrack', event => {
      emitter({
        type: 'event',
        event: 'track',
        payload: event.track,
      });
    });

    deezer.addListener('DeezerPaused', () => {
      emitter({
        type: 'event',
        event: 'paused',
      });
    });

    deezer.addListener('DeezerStopped', () => {
      emitter({
        type: 'event',
        event: 'stopped',
      });
    });

    deezer.addListener('DeezerPlayTrack', event => {
      emitter({
        type: 'event',
        event: 'play_track',
        payload: event,
      });
    });

    deezer.addListener('DeezerTrackEnd', () => {
      emitter({
        type: 'event',
        event: 'trackended',
      });
    });

    deezer.addListener('DeezerPlayNext', () => {
      emitter({
        type: 'event',
        event: 'playnext',
      });
    });

    deezer.addListener('DeezerPlayPrev', () => {
      emitter({
        type: 'event',
        event: 'playprevious',
      });
    });
    // --> add disconnect
    return () => {};
  });
}

function* getTrackInfo(id) {
  const trackInfo = yield call(axios, {
    method: 'GET',
    url: `https://api.deezer.com/track/${id}`,
  });

  if (trackInfo.status === 200) {
    const { data } = trackInfo;
    const track = normalizeDeezerTrack(data);

    yield put({ type: types.media.music.LOAD_NEW_MUSIC_TRACK, payload: { provider: 'deezer', track, origin: 'music' } });
    yield put({ type: types.media.player.SET_PROGRESS, payload: { duration: data.duration } });
    yield put({ type: types.media.player.SET_PROVIDER_STATUS, payload: { status: 'playing', provider: 'deezer' } });
  }
}

function* startDeezer() {
  const deezer = yield call(deezerChannel);

  try {
    while (true) {
      const deezerEvent = yield take(deezer);
      switch (deezerEvent.event) {
        case 'deezer_login':
          yield put({
            type: types.providers.deezer.SET_AUTH_TOKEN,
            payload: { token: deezerEvent.payload.token, expires: deezerEvent.payload.expires, userId: deezerEvent.payload.userId },
          });
          break;
        case 'playing':
          yield put({ type: types.media.player.SET_PROVIDER_STATUS, payload: { status: 'playing', provider: 'deezer' } });
          break;
        case 'paused':
          yield put({ type: types.media.player.SET_PROVIDER_STATUS, payload: { status: 'paused', provider: 'deezer' } });
          break;
        case 'stopped':
          yield put({ type: types.media.player.SET_PROVIDER_STATUS, payload: { status: 'stopped', provider: 'deezer' } });
          break;
        case 'play_track':
          yield fork(getTrackInfo, deezerEvent.payload.id);
          break;
        case 'trackended':
          yield put({ type: types.media.player.SET_PROVIDER_STATUS, payload: { status: 'paused', provider: 'deezer', position: 0 } });
          break;
        case 'playnext':
          yield put({ type: types.media.queue.PLAY_NEXT_TRACK });
          break;
        case 'playprevious':
          yield put({ type: types.media.queue.PLAY_PREVIOUS_TRACK });
          break;
        case 'track':
          const track = deezerEvent.payload;
          const player = yield select(getStorePlayer);
          if (track && player.track.id !== track.id) {
            yield put({
              type: types.media.music.LOAD_NEW_MUSIC_TRACK,
              payload: { provider: 'deezer', track, origin: 'music' },
            });
          }
          break;
        case 'track_ending':
          yield put({ type: types.media.queue.TRACK_ENDING, payload: { trackEnding: true } });
          break;

        default:
          console.log('deezer unknown event', deezerEvent.event);
      }
    }
  } finally {
    deezer.close();
  }
}

export function* watchStartDeezer() {
  yield takeEvery(types.providers.deezer.INIT_PLAYER, startDeezer);
}

const getStorePlayer = store => {
  return store.media.music.player;
};

function* playTrack({ id }, { track }) {
  const player = yield select(getStorePlayer);
  if (player.status === 'playing' && player.currentProvider !== 'deezer') {
    yield put({ type: types.media.music.TOGGLE_PLAYER_STATE, payload: {} });
    global.deezer.play(parseInt(id, 10), track);
  } else {
    global.deezer.play(parseInt(id, 10), track);
  }
}

export function* playDeezerTrack({ payload }) {
  const { track } = payload;
  const { id } = track;

  yield call(playTrack, { id }, { track });
}

export function* watchPlayDeezerTrack() {
  yield takeEvery(types.providers.deezer.PLAY_TRACK, playDeezerTrack);
}
