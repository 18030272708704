import React, { useEffect, useRef, useState } from 'react';
import { FlatList, InteractionManager, Platform, Text, View } from 'react-native';

import mainStyles from '../../../styles';
import { SideScroll } from './SideScroll';

export const VerticalList = ({ items, itemSeparator, listTitle, circle = false, renderItem, keyExtractor }) => {
  // States used for the side scroll indicator
  const [contentOffset, setContentOffset] = useState({ x: 0, y: 0 });
  const [contentSize, setContentSize] = useState(0);
  const [scrollViewWidth, setScrollViewWidth] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const flatListRef = useRef();

  const buttonWidth = circle ? mainStyles.CIRCLE_BUTTON_WIDTH : mainStyles.SQUARE_BUTTON_WIDTH;

  useEffect(() => {
    if (flatListRef.current) {
      InteractionManager.runAfterInteractions(() => {
        flatListRef.current.scrollToOffset({ offset: scrollPosition });
      });
    }
  }, [scrollPosition]);

  return (
    <View style={[mainStyles.margin.bottom.lg]}>
      <Text style={[mainStyles.typography.h1, mainStyles.margin.bottom.sm]}>{listTitle}</Text>
      <FlatList
        ref={flatListRef}
        data={items}
        getItemLayout={(data, index) => ({
          length: buttonWidth,
          offset: buttonWidth * index + 10,
          index,
        })}
        horizontal
        initialNumToRender={5}
        ItemSeparatorComponent={itemSeparator}
        keyExtractor={keyExtractor}
        maxToRenderPerBatch={5}
        onContentSizeChange={width => {
          setContentSize(width);
        }}
        onLayout={e => {
          setScrollViewWidth(e.nativeEvent.layout.width);
        }}
        onScroll={e => {
          setContentOffset(e.nativeEvent.contentOffset);
        }}
        removeClippedSubview
        renderItem={renderItem}
        showsVerticalScrollIndicator={Platform.OS !== 'web'}
      />
      {Platform.OS === 'web' && (
        <SideScroll contentOffset={contentOffset} contentSize={contentSize} scrollViewWidth={scrollViewWidth} setScrollPosition={setScrollPosition} />
      )}
    </View>
  );
};
