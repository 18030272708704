import { Formik } from 'formik';
import qs from 'qs';
import React, { Component } from 'react';
import { ActivityIndicator, Text, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';

import ButtonRez from '../../components/app/ButtonRez';
import TextInputRez from '../../components/app/TextInputRez';
import FullScreenLayout from '../../layouts/fullscreen';
import actions from '../../store/actions';
import mainStyles from '../../styles';
import { setPassword } from '../../utils/api/api';

class ResetPasswordScreen extends Component {
  state = {
    confirmed: false,
    loading: false,
  };

  ResetPasswordSchema = Yup.object().shape({
    password: Yup.string().required('Required').min(8, 'New password must be 8 characters long'),
  });

  changePassword({ password }) {
    const { requestId, userId } = qs.parse(this.props.location.search.substr(1));

    this.setState({ loading: true });

    setPassword({ password, requestId, userId })
      .then(() => {
        this.setState({
          confirmed: true,
          loading: false,
        });
        this.props.setAlert({
          type: 'success',
          message: 'Your password has been changed.',
        });
        this.props.history.push('/signin');
      })
      .catch(err => {
        let msg = '';
        if (err && err.response && err.response.data && err.response.data.error) {
          switch (err.response.data.error) {
            case 'VALIDATION_ERROR':
              msg = 'Please enter a password';
              break;
            case 'REQUEST_UNKNOWN_USED':
              msg = 'Your request is unknown or has already been used';
              break;
            case 'REQUEST_EXPIRED':
              msg = 'Your request has expired. Please request a new password reset';
              break;
            default:
              msg = 'Error changing password';
              break;
          }
        } else {
          msg = 'Your password could not be changed.';
        }

        this.props.setAlert({
          type: 'error',
          message: msg,
        });

        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const { confirmed, loading } = this.state;

    return (
      <FullScreenLayout title="reset password">
        {!confirmed && (
          <Text style={[mainStyles.typography.p, mainStyles.typography.textCenter, mainStyles.margin.top.lg]}>
            To change your password enter it below and click set.
          </Text>
        )}

        {!loading && !confirmed && (
          <Formik
            initialValues={{ password: '' }}
            onSubmit={values => this.changePassword(values)}
            validateOnBlur
            validationSchema={this.ResetPasswordSchema}
          >
            {props => (
              <>
                <View style={mainStyles.margin.top.lg}>
                  <TextInputRez
                    autoCapitalize="none"
                    autoCorrect={false}
                    autoFocus={false}
                    enablesReturnKeyAutomatically
                    icon={require('../../assets/icons/lock/lock.png')}
                    label="Password"
                    name="password"
                    onBlur={props.handleBlur('password')}
                    onChangeText={props.handleChange('password')}
                    onSubmitEditing={() =>
                      props.validateForm().then(() => {
                        if (props.isValid) this.changePassword(props.values);
                      })
                    }
                    placeholder="New password"
                    secureTextEntry
                    validation={{ error: props.errors.password, touched: props.touched.password }}
                    value={props.values.password}
                  />
                </View>
                <View style={mainStyles.margin.top.md}>
                  <ButtonRez
                    disabled={!props.isValid}
                    fontColor="rgba(23,23,38,1)"
                    label="Set"
                    large
                    onPress={props.handleSubmit}
                    startColor="rgba(255,255,255,1)"
                  />
                </View>
              </>
            )}
          </Formik>
        )}

        {loading && (
          <View style={mainStyles.loading}>
            <ActivityIndicator color="rgba(255,142,12,1)" size="large" />
          </View>
        )}
      </FullScreenLayout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        setAlert: actions.app.setAlert,
      },
      dispatch,
    ),
  };
}

export default connect(null, mapDispatchToProps)(ResetPasswordScreen);
