import 'react-image-crop/dist/ReactCrop.css';

import React, { Component } from 'react';
import ReactCrop from 'react-image-crop';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import ActivityIndicator from 'react-native-web/dist/exports/ActivityIndicator';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';

import ButtonRez from '../../../components/app/ButtonRez';
import AppLayout from '../../../layouts/app';
import actions from '../../../store/actions';
import selectors from '../../../store/selectors';
import mainStyles from '../../../styles';
import { uploadPlaylisPicture } from '../../../utils/api/api';
import { withRouter } from '../../../utils/routing/Routing';

class CropPlaylistPicture extends Component {
  state = {
    crop: {
      unit: '%',
      x: 0,
      y: 0,
      width: 100,
      aspect: 1,
    },
    image: null,
    showCrop: false,
    source: '',
    uploading: false,
    playlistID: '',
  };

  componentDidMount() {
    const { location } = this.props;
    const {
      params: { playlistId },
    } = matchPath(location.pathname, {
      path: '/playlist-crop/:playlistId',
    });
    if (playlistId && playlistId !== '') {
      this.setState({ playlistID: playlistId });
    }
    this.openFile();
  }

  async uploadFile({ picture, jwt }) {
    const data = await uploadPlaylisPicture({ playlistId: this.state.playlistID, picture, jwt }).catch(() => {
      this.props.setAlert({ message: 'Something went wrong updating your playlist picture.', type: 'error' });
      this.setState({
        uploading: false,
      });
    });

    if (!data) return;

    this.props.setImagePlaylist({
      data,
    });

    this.props.setAlert({ message: 'Your playlist picture has been updated.', type: 'success' });
    this.goBack();
  }

  processCrop() {
    const { crop, image, source } = this.state;

    this.setState({
      uploading: true,
    });

    const img = document.createElement('img');
    img.addEventListener('load', () => {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = 1024;
      canvas.height = 1024;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(img, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, 1024, 1024);
      const base64CroppedImage = canvas.toDataURL('image/jpeg');
      this.uploadFile({ picture: base64CroppedImage, jwt: this.props.jwt });
    });
    img.src = source;
  }

  openFile() {
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({
        showCrop: true,
        source: reader.result,
      });
    };
    reader.onerror = e => {
      console.log('Error reading file', e);
    };
    reader.readAsDataURL(this.props.location.state.file);
  }

  goBack() {
    this.props.setAlert(null);
    this.props.history.goBack();
  }

  render() {
    return (
      <AppLayout title="Edit picture">
        {this.state.showCrop && this.state.source && (
          <>
            <ReactCrop
              circularCrop
              crop={this.state.crop}
              onChange={crop => this.setState({ crop })}
              onImageLoaded={image => this.setState({ image })}
              src={this.state.source}
              testID="playlist-picture-crop"
            />
            <View style={[mainStyles.margin.top.xl, styles.btnContainer]}>
              {!this.state.uploading && (
                <ButtonRez
                  btnStyle={{ maxWidth: 226, width: 226 }}
                  endColor="#FFFFFF"
                  fontColor="#171726"
                  label="Save"
                  onPress={() => this.processCrop()}
                  startColor="#FFFFFF"
                  testID="playlist-picture-save"
                />
              )}
              {this.state.uploading && <ActivityIndicator color="rgba(255,142,12,1)" size="small" />}
            </View>
            <View style={mainStyles.margin.top.lg}>
              <TouchableOpacity onPress={() => this.goBack()}>
                <Text style={[mainStyles.typography.ctaUnderline, mainStyles.typography.textCenter]} testID="playlist-picture-cancel">
                  Cancel
                </Text>
              </TouchableOpacity>
            </View>
          </>
        )}
        {(!this.state.showCrop || !this.state.source) && <ActivityIndicator color="rgba(255,142,12,1)" size="large" />}
      </AppLayout>
    );
  }
}

const styles = StyleSheet.create({
  btnContainer: {
    alignItems: 'center',
  },
});

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    jwt: selectors.user.getJwt(state),
  }),
  {
    setImagePlaylist: actions.media.playlists.setImagePlaylist,
    setAlert: actions.app.setAlert,
  },
)(withRouter(CropPlaylistPicture));
