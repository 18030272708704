function buildArtworkUrl({ width, height }, url) {
  return url.replace('{w}', width).replace('{h}', height);
}

export const normalizeAppleTrack = track => ({
  id: track.id || track.playParams.id,
  provider: 'apple',
  name: track.attributes.name,
  artistName: track.attributes.artistName,
  album: normalizeAppleAlbum(
    track.album || {
      id: track.attributes.url ? track.attributes.url.split('/').slice(-1)[0].split('?')[0] : undefined,
      attributes: {
        name: track.attributes.albumName,
        artistName: track.attributes.artistName,
        artwork: {
          ...track.attributes.artwork,
        },
      },
      release_date: track.attributes.releaseDate,
    },
  ),
  duration: track.attributes.durationInMillis,
  discNumber: track.attributes.discNumber,
  trackNumber: track.attributes.trackNumber,
  explicit: undefined,
  isrc: track.attributes.isrc,
  type: 'track',
});

export const normalizeAppleAlbum = album => ({
  id: album.id ? album.id.toString() : undefined,
  provider: 'apple',
  name: album.attributes.name,
  artistName: album.attributes.artistName ? album.attributes.artistName : undefined,
  images: [
    {
      url: buildArtworkUrl({ width: 1000, height: 1000 }, album.attributes.artwork.url),
      width: 1000,
      height: 1000,
    },
    {
      url: buildArtworkUrl({ width: 500, height: 500 }, album.attributes.artwork.url),
      width: 500,
      height: 500,
    },
    {
      url: buildArtworkUrl({ width: 250, height: 250 }, album.attributes.artwork.url),
      width: 250,
      height: 250,
    },
    {
      url: buildArtworkUrl({ width: 56, height: 56 }, album.attributes.artwork.url),
      width: 56,
      height: 56,
    },
  ],
  releaseDate: album.release_date,
  type: 'album',
});
