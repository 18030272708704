import 'firebase/analytics';

import * as firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyCzuqF16JD-KxDUuhPKCJLgvGc-0K8YT30',
  authDomain: 'rezzonation-4451c.firebaseapp.com',
  databaseURL: 'https://rezzonation-4451c.firebaseio.com',
  projectId: 'rezzonation-4451c',
  storageBucket: 'rezzonation-4451c.appspot.com',
  messagingSenderId: '53008497361',
  appId: '1:53008497361:web:cd4e38e426bb1c8049a3f0',
  measurementId: 'G-TK3LCG0THT',
};
firebase.initializeApp(firebaseConfig);

const { analytics } = firebase;

export default analytics;
