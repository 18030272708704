import React from 'react';
import { Text, View } from 'react-native';
import Collapsible from 'react-native-collapsible';

import mainStyles from '../../../styles';

export default function ChannelDetailsExpando(props) {
  return (
    <View>
      <Collapsible collapsed={!props.open}>
        {props.meta.language && (
          <View style={mainStyles.margin.bottom.sm}>
            <Text style={mainStyles.typography.h3}>Language:</Text>
            <Text style={mainStyles.typography.p}>{props.meta.language}</Text>
          </View>
        )}
        {props.meta.genres && (
          <View style={mainStyles.margin.bottom.sm}>
            <Text style={mainStyles.typography.h3}>Genres:</Text>
            <Text style={mainStyles.typography.p}>{props.meta.genres.join(' ')}</Text>
          </View>
        )}
        {props.meta.website && (
          <View style={mainStyles.margin.bottom.sm}>
            <Text style={mainStyles.typography.h3}>Website:</Text>
            <Text style={mainStyles.typography.p}>{props.meta.website}</Text>
          </View>
        )}
      </Collapsible>
    </View>
  );
}
