import React from 'react';
import { Image, Platform, StyleSheet, Text, TouchableOpacity } from 'react-native';

import mainStyles from '../../styles';

const PopupButton = ({ onPress, label, iconSrc, testID, backgroundColor, labelCancel, labelFriend, labelEasysharing }) => {
  return (
    <TouchableOpacity onPress={onPress} style={[styles.button]} testID={testID}>
      {iconSrc && <Image source={iconSrc} style={[styles.icon, mainStyles.margin.right.sm]} />}
      {label && (
        <Text
          style={[
            mainStyles.typography.h3,
            {
              backgroundColor,
              fontSize: 18,
              width: Platform.OS === 'web' ? '60%' : 210,
              padding: Platform.OS === 'web' ? '3%' : 10,
              color: 'white',
              textAlign: 'center',
              fontWeight: 'bold',
              height: Platform.OS === 'web' ? 46 : 40,
              borderRadius: 23.5,
              fontFamily: 'Roboto-Medium',
            },
            styles.text,
          ]}
        >
          {label}
        </Text>
      )}
      {labelFriend && Platform.OS !== 'web' && (
        <TouchableOpacity onPress={onPress} style={[{ backgroundColor }, styles.selectFriendsButton]}>
          <Text style={styles.bigButtonText}>{labelFriend}</Text>
        </TouchableOpacity>
      )}
      {labelEasysharing && (
        <TouchableOpacity onPress={onPress} style={[mainStyles.typography.h3, { backgroundColor: 'green' }, styles.bigButton]}>
          <Text style={styles.bigButtonText}>{labelEasysharing}</Text>
        </TouchableOpacity>
      )}
      {labelCancel && <Text style={[mainStyles.typography.h3, styles.cancelLabel]}>{labelCancel}</Text>}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: mainStyles.distance.sm,
    marginBottom: '5%',
  },

  text: {
    borderRadius: 20,
    overflow: 'hidden',
  },

  icon: {
    width: 20,
    height: 20,
  },

  bigButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: mainStyles.distance.sm,
    width: Platform.OS === 'web' ? '100%' : 210,
    borderRadius: 20,
    height: 40,
    marginTop: 10,
  },

  bigButtonText: {
    fontSize: 17,
    padding: '5%',
    color: 'white',
    textAlign: 'center',
    paddingHorizontal: '7%',
    fontWeight: 'bold',
    borderRadius: 20,
    overflow: 'hidden',
    width: '100%',
    height: 40,
    fontFamily: 'Roboto-Medium',
  },

  selectFriendsButton: {
    width: 210,
    borderRadius: 20,
    marginBottom: '6%',
    height: 40,
  },

  bigButtonGreen: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: mainStyles.distance.sm,
    width: '100%',
    borderRadius: 20,
    marginBottom: '6%',
    height: 40,
  },

  cancelLabel: {
    color: 'lightgray',
    textDecorationLine: 'underline',
    fontStyle: 'italic',
    height: 20,
    fontFamily: 'Roboto-Medium',
  },
});

export default PopupButton;
