import React, { Component } from 'react';
import { Image, Platform, Share, StyleSheet, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';

import SeekBar from '../../../components/media/seekbar/Seekbar';
import ShareModal from '../../../containers/app/ShareModal';
import MediaControl from '../../../containers/media/MediaControl';
import PlayScreenHeader from '../../../containers/media/PlayScreenHeader';
import AppLayout from '../../../layouts/app';
import actions from '../../../store/actions';
import selectors from '../../../store/selectors';
import mainStyles from '../../../styles';
import { ACTIVITY_CM_KEY } from '../../../styles/consts';
import { sendDetailsToCM } from '../../../utils/api/api';
import { config } from '../../../utils/config';
import analytics from '../../../utils/routing/analytics';
import { withRouter } from '../../../utils/routing/Routing';

class TrackScreen extends Component {
  state = {
    showModal: null,
  };

  setTrackStatus(status) {
    this.props.toggleProviderStatus({ provider: this.props.player.track.provider, status });
  }

  togglePlayer() {
    const { player } = this.props;
    this.setTrackStatus(player.statuses[player.track.provider] === 'playing' ? 'paused' : 'playing');
  }

  addToPlaylist() {
    const { location } = this.props;
    const { id, provider } = location.state;
    this.props.history.push(`/track/${id}/addToPlaylist/${provider}`);
  }

  playNextTrack() {
    this.setTrackStatus('paused');
    this.props.manualNextTrack(true);
    this.props.playNextTrack();
  }

  playPreviousTrack() {
    this.setTrackStatus('paused');
    this.props.playPreviousTrack();
  }

  shareTrack() {
    const message = `Listen to ${this.props.player.track.name} on Rezzonation`;
    const trackName = encodeURIComponent(this.props.player.track.name);
    const artistName = encodeURIComponent(this.props.player.track.artistName);
    const url = `${config.APP_URL}/track-share/${trackName}/${artistName}/${this.props.player.track.id}/${this.props.player.track.provider}`;

    analytics().logEvent('share_track', {
      name: this.props.player.track.name,
      artist: this.props.player.track.artistName,
      provider: this.props.player.track.provider,
    });
    const cmActivity = {
      created: new Date(),
      userId: this.props.userId,
      songShare: this.props.player.track.id,
    };
    sendDetailsToCM(cmActivity, ACTIVITY_CM_KEY);
    if (Platform.OS === 'web') {
      this.showModal('share');
    } else {
      Share.share(
        {
          title: 'Share track',
          url,
          message: Platform.OS === 'android' ? `${message} ${url}` : message,
        },
        {
          dialogTitle: 'Share track',
          subject: 'Share track',
        },
      );
    }
  }

  showModal(modalName) {
    this.setState({ showModal: modalName });
  }

  render() {
    const { player, queueStore, toggleRepeat, toggleShuffle } = this.props;
    const { statuses, track } = player;
    const { artistName, name, album } = track;

    const imageSrc = album && album.images && Array.isArray(album.images) && album.images.length > 0 ? album.images[0].url : null;
    const isProviderPlaying = () => Object.keys(statuses).find(prov => statuses[prov] === 'playing');
    const hasQueueItems = queueStore && queueStore.queue && queueStore.queue.length > 0;
    const hasPrevTrack = hasQueueItems && queueStore.queueIndexHistory.length > 1;
    const hasNextTrack = hasQueueItems && queueStore.queueIndexHistory.length < queueStore.queue.length;
    const shareTrackName = encodeURIComponent(this.props.player.track.name);

    return (
      <AppLayout hideMiniPlayer>
        <View style={styles.container}>
          <PlayScreenHeader image={{ uri: imageSrc }} moreTracks={album} subtitle={artistName} title={name} />

          <View style={[mainStyles.margin.top.md, styles.actionButtons]}>
            <TouchableOpacity onPress={() => this.addToPlaylist()} testID="addtracktoplaylist-button">
              <Image source={require('../../../assets/icons/addplaylist/addtoplaylist.png')} style={styles.actionButton} />
            </TouchableOpacity>

            <TouchableOpacity onPress={() => this.shareTrack()}>
              <Image source={require('../../../assets/icons/share/share.png')} style={styles.actionButton} />
            </TouchableOpacity>
          </View>

          <View style={mainStyles.margin.top.md}>
            <SeekBar fullSeekbar />
          </View>

          <View style={[mainStyles.margin.top.md, mainStyles.margin.bottom.md]}>
            <MediaControl
              hasNextTrack={hasNextTrack}
              hasPrevTrack={hasPrevTrack}
              isPlaying={isProviderPlaying()}
              isRepeatActive={queueStore.repeat}
              isShuffleActive={queueStore.shuffle}
              onNextPress={() => this.playNextTrack()}
              onPrevPress={() => this.playPreviousTrack()}
              onRepeatPress={toggleRepeat}
              onShufflePress={toggleShuffle}
              onTogglePlay={() => this.togglePlayer()}
            />
          </View>
        </View>

        <ShareModal
          isVisible={this.state.showModal === 'share'}
          message={`Listen to ${this.props.player.track.name} on Rezzonation`}
          onBackdropPress={() => this.setState({ showModal: null })}
          title="Share track"
          url={`${config.APP_URL}/track-share/${shareTrackName}/${this.props.player.track.id}/${this.props.player.track.provider}`}
        />
      </AppLayout>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: mainStyles.APP_PADDING,
  },
  actionButton: {
    width: 20,
    height: 20,
  },
  actionButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    userId: selectors.user.getUserId(state),
    player: selectors.media.player.getPlayer(state),
    queueStore: selectors.media.queue.getQueue(state),
  }),
  {
    toggleProviderStatus: actions.media.player.toggleProviderStatus,
    playNextTrack: actions.media.queue.playNextTrack,
    manualNextTrack: actions.media.queue.manualNextTrack,
    toggleRepeat: actions.media.queue.toggleRepeat,
    toggleShuffle: actions.media.queue.toggleShuffle,
    playPreviousTrack: actions.media.queue.playPreviousTrack,
  },
)(withRouter(TrackScreen));
