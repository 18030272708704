import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import mainStyles from '../../../styles';

const RecentSearchQuery = ({ query, results, images, run, created }) => {
  const image =
    images && Array.isArray(images) && images.length > 0 ? (
      <Image resizeMode="cover" source={images[0].url} style={[styles.image, mainStyles.margin.right.sm]} />
    ) : (
      <View style={styles.placeholder} />
    );

  return (
    <TouchableOpacity onPress={() => run(query)} style={[styles.container, mainStyles.margin.bottom.md]} testID={`podcastrecentsearch_${query}`}>
      {image}
      <View style={styles.resultInfo}>
        <Text style={mainStyles.typography.h2}>{query}</Text>
        {results >= 0 && <Text style={mainStyles.typography.h2Light}>{results} results</Text>}
        {created && <Text style={mainStyles.typography.h2Light}>Created {created}</Text>}
      </View>
    </TouchableOpacity>
  );
};

export default RecentSearchQuery;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  image: {
    width: mainStyles.SMALL_IMAGE_SIZE,
    height: mainStyles.SMALL_IMAGE_SIZE,
  },
  resultInfo: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
});
