import React, { Component } from 'react';
import { Text } from 'react-native';
import { connect } from 'react-redux';

import ModalRez from '../../components/app/ModalRez';
import SeekbarInnerLine from '../../components/media/seekbar/SeekbarInnerLine';
import Track from '../../components/media/tracks/Track';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import { withRouter } from '../../utils/routing/Routing';

class CMSuggestionModal extends Component {
  SUGGESTION_TOTAL_MS = 10000;

  SUGGESTION_TICK_RATE = 100;

  state = {
    previewingCrossMatch: false,
    msPassed: 0,
    progressInterval: null,
  };

  componentDidUpdate = () => {
    if (!this.state.previewingCrossMatch && this.props.crossMatch.suggestions.length > 0 && this.state.progressInterval === null) {
      this.startProgressInterval();
    } else if (this.props.crossMatch.suggestions.length === 0 && this.state.progressInterval !== null) {
      this.stopProgressInterval();
    }
  };

  startProgressInterval = () => {
    this.setState({
      msPassed: 0,
      progressInterval: setInterval(() => this.incrementProgress(), this.SUGGESTION_TICK_RATE),
    });
  };

  stopProgressInterval = () => {
    clearInterval(this.state.progressInterval);
    this.setState({
      progressInterval: null,
    });
  };

  incrementProgress = () => {
    const newState = {
      msPassed: this.state.msPassed + this.SUGGESTION_TICK_RATE,
    };

    if (newState.msPassed >= this.SUGGESTION_TOTAL_MS) {
      this.props.clearCrossMatch([]);
      this.stopProgressInterval();
      newState.msPassed = this.SUGGESTION_TOTAL_MS;
      newState.progressInterval = null;
    }

    this.setState(newState);
  };

  currentWidthStyle = () => ({ width: `${(this.state.msPassed / this.SUGGESTION_TOTAL_MS) * 100}%` });

  trackChosen = () => {
    this.stopProgressInterval();

    this.setState({
      previewingCrossMatch: true,
    });
  };

  closeDialog = () => {
    this.stopProgressInterval();
    this.setState({
      previewingCrossMatch: false,
      msPassed: 0,
      progressInterval: null,
    });

    this.props.clearCrossMatch();
  };

  render = () => (
    <ModalRez
      isVisible={this.props.crossMatch.suggestions.length > 0}
      onBackdropPress={() => this.closeDialog()}
      title="Wrong track? Help us choose the correct one."
    >
      {this.props.crossMatch.suggestions.length > 0 && (
        <>
          <Text>Currently playing:</Text>
          <Track key={`track-${this.props.crossMatch.track.uri}`} alt track={this.props.crossMatch.track} />
          <Text>Alternatives:</Text>
          {this.props.crossMatch.suggestions.map(track => (
            <Track key={`track-${track.id}`} alt onTrackChosen={this.trackChosen} track={track} />
          ))}
        </>
      )}
      <SeekbarInnerLine currentWidthStyle={this.currentWidthStyle} onLayout={() => {}} />
    </ModalRez>
  );
}

export default connect(
  state => ({
    ...state,
    player: selectors.media.player.getPlayer(state),
    crossMatch: selectors.media.music.getCrossMatch(state),
  }),
  {
    clearCrossMatch: actions.media.music.clearCrossMatch,
  },
)(withRouter(CMSuggestionModal));
