import types from '../../types';

export const createUser = () => ({
  type: types.user.CREATE_NEW_USER,
  payload: {},
});

export const setJWT = ({ jwt }) => ({
  type: types.user.SET_JWT,
  payload: { jwt },
});

export const setUserCredentials = ({ userId, jwt }) => ({
  type: types.user.SET_USER_CREDENTIALS,
  payload: { userId, jwt },
});

export const setInvite = ({ code }) => ({
  type: types.user.SET_VALID_INVITE,
  payload: { code },
});

export const logoutUser = () => ({
  type: types.user.LOGOUT,
});

export const setFcmToken = payload => ({
  type: types.user.SET_FCM_TOKEN,
  payload,
});

export const setProfile = ({ profile }) => ({
  type: types.user.SET_PROFILE,
  payload: profile,
});

export const setProfilePicture = ({ url }) => ({
  type: types.user.SET_PROFILE_PICTURE,
  payload: {
    url,
  },
});

export const setUsername = ({ username }) => ({
  type: types.user.SET_USERNAME,
  payload: { username },
});

export const setDisplayname = ({ displayName }) => ({
  type: types.user.SET_DISPLAYNAME,
  payload: { displayName },
});

export const setXmppPassword = ({ password }) => ({
  type: types.user.SET_XMPP_PASSWORD,
  payload: { password },
});

export const setNewFollowing = ({ username, profilePicture }) => ({
  type: types.user.SET_NEW_FOLLOWED_USER,
  payload: { username, profilePicture },
});

export const dropFollowing = ({ username }) => ({
  type: types.user.DROP_FOLLOWED_USER,
  payload: { username },
});

export const setPersonalDetailsCMdata = data => ({
  type: types.user.SET_PERSONAL_DETAILS_CM_DATA,
  payload: data,
});

export const deletePersonalDetailsCMdata = data => ({
  type: types.user.DELETE_PERSONAL_DETAILS_CM_DATA,
  payload: data,
});
