import types from '../../types';

const storedPodcasts = {
  podcastCurrent: [],
};

export default (state = storedPodcasts, action) => {
  const { payload, type } = action;

  switch (type) {
    case types.podcast.storedPodcasts.SET_CURRENT:
      return {
        ...state,
        podcastCurrent: payload,
      };

    case types.podcast.storedPodcasts.REMOVE_CURRENT:
      return {
        ...state,
        podcastCurrent: [],
      };

    case types.user.LOGOUT:
      return {
        ...state,
        podcastCurrent: [],
      };
    default:
      return state;
  }
};
