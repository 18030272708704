import axios from 'axios';
import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';
import { fork, put, select, takeEvery } from 'redux-saga/effects';

import { PERSONAL_DETAILS_CM_KEY } from '../../styles/consts';
import { getUserProfile, sendDetailsToCM } from '../../utils/api/api';
import messaging from '../../utils/firebaseMessaging';
import { createUserId } from '../../utils/userid';
import { getCmData, getJwt } from '../selectors/user';
import types from '../types';

export function* createUser() {
  const userId = createUserId();
  yield put({ type: types.user.NEW_USER_CREATED, payload: { userId } });
}

export function* watchCreateUser() {
  yield takeEvery(types.user.CREATE_NEW_USER, createUser);
}

export function* messageTokenOnLogin() {
  let fcmToken;
  const authStatus = yield messaging().requestPermission();
  if (authStatus) {
    fcmToken = yield messaging().getToken();
  }
  const jwt = yield select(getJwt);
  const uuid = DeviceInfo.getUniqueId();
  const platform = Platform.OS.toUpperCase();
  if (jwt) {
    yield getUserProfile({ jwt, uuid, fcmToken, platform });
  }
}

export function* watchUserLogin() {
  if (Platform.OS !== 'web') {
    yield takeEvery(types.user.SET_USER_CREDENTIALS, messageTokenOnLogin);
  }
}

export function* deleteCMdata() {
  const cmStoredData = yield select(getCmData);
  sendDetailsToCM(cmStoredData, PERSONAL_DETAILS_CM_KEY);
}

export function* watchDeleteUser() {
  yield takeEvery(types.user.DELETE_PERSONAL_DETAILS_CM_DATA, deleteCMdata);
}

export function* sendUserToApi(action) {
  const { payload } = action;
  const { userId } = payload;

  yield axios({
    method: 'POST',
    url: `${process.env.API.URL}/user/`,
    data: {
      userId,
    },
  });
}

export function* watchSendUserToApi() {
  yield takeEvery(types.user.NEW_USER_CREATED, sendUserToApi);
  if (Platform.OS !== 'web') {
    yield takeEvery(types.user.NEW_USER_CREATED, messageTokenOnLogin);
  }
}

export default function* rootSaga() {
  yield fork(watchSendUserToApi);
  yield fork(watchCreateUser);
  yield fork(watchUserLogin);
  yield fork(watchDeleteUser);
}

export const getUserId = state => {
  return state.user.userId;
};

export function* syncTokens() {
  // const userId = yield select(getUserId);
  // const tokens = yield axios({
  //   url: `${process.env.API.URL}/auth/tokens`,
  //   headers: {
  //     userid: userId
  //   }
  // });
  //
  // console.log(tokens);
}
