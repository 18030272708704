import React, { useEffect } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import actions from '../../store/actions';
import selectors from '../../store/selectors';
import mainStyles from '../../styles';

function DetectWebPopup(props) {
  useEffect(() => {
    setTimeout(() => {
      props.setWebDownloadPopup({ visibleDownloadPopup: true, validateDownloadPopup: true });
    }, 20000);
  }, []);

  const insets = useSafeArea();
  const openApp = () => {
    if (props.os === 'Android') {
      props.setWebDownloadPopup({ visibleDownloadPopup: false, validateDownloadPopup: false });
      window.open('https://play.google.com/store/apps/details?id=app.rezzonation.android');
    }
    if (props.os === 'iOS') {
      props.setWebDownloadPopup({ visibleDownloadPopup: false, validateDownloadPopup: false });
      window.open('https://apps.apple.com/nl/app/rezzonation/id1419542792');
    }
  };

  if (props.webPopup.visibleDownloadPopup === true) {
    return (
      <View style={[styles.popup, { marginBottom: insets.bottom }]}>
        <Text style={[mainStyles.margin.top.md, mainStyles.margin.bottom.md, { textAlign: 'center', fontSize: 18, fontWeight: 'bold' }]}>
          See Rezzonation in...
        </Text>
        <View style={styles.optionContainer}>
          <View style={{ flex: 1, flexDirection: 'row ', alignItems: 'center' }}>
            <Image source={require('../../assets/logos/one/logo.png')} style={styles.logoSize} />
            <Text style={[mainStyles.p, mainStyles.margin.top.md, mainStyles.margin.bottom.md, styles.popupText]} testID="alert">
              Rezzonation app
            </Text>
          </View>
          <TouchableOpacity onPress={openApp} testID="openstore-button">
            <View style={{ backgroundColor: '#2f006b', padding: 15, paddingHorizontal: 44, borderRadius: 25 }}>
              <Text style={{ color: 'white', fontWeight: 'bold' }}>Open</Text>
            </View>
          </TouchableOpacity>
        </View>
        <View style={styles.browserOptionContainer}>
          <TouchableOpacity onPress={() => props.setWebDownloadPopup({ visibleDownloadPopup: false })} testID="continueweb-button">
            <View style={{ backgroundColor: '#e3e3e3', padding: 15, paddingHorizontal: 30, borderRadius: 25, borderWidth: 1, borderColor: 'grey' }}>
              <Text style={{ color: 'grey', fontWeight: 'bold' }}>Continue in browser</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
  return null;
}

const styles = StyleSheet.create({
  popup: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    paddingBottom: '4%',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    minHeight: 50,
    backgroundColor: 'white',
    borderTopLeftRadius: 17,
    borderTopRightRadius: 17,
    zIndex: 2,
  },
  optionContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: '5%',
    paddingBottom: '4%',
  },

  browserOptionContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: '5%',
  },

  popupText: {
    flexShrink: 1,
    fontWeight: 'bold',
    fontSize: 16,
  },

  logoSize: {
    borderWidth: 1,
    borderRadius: 12,
    borderColor: '#e3e3e3',
    width: 45,
    height: 45,
    marginRight: '2%',
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    webPopup: selectors.app.getWebDownloadPopup(state),
    jwt: selectors.user.getJwt(state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        setWebDownloadPopup: actions.app.showWebDownloadPopup,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DetectWebPopup);
