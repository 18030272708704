import React, { Component } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';

import actions from '../../../store/actions';
import selectors from '../../../store/selectors';
import mainStyles from '../../../styles';
import analytics from '../../../utils/routing/analytics';
import { withRouter } from '../../../utils/routing/Routing';

class PopularVideoComponent extends Component {
  playVideo(id, provider, name) {
    if (this.props.tunedTo) {
      this.props.disableTuneIn();
      analytics().logEvent('stop_tunein');
    }
    this.props.setTrack({
      track: {
        name,
        id,
        provider: 'youtube',
      },
      origin: 'video',
    });

    this.props.history.push(`/video/${id}`, { provider, name, id });
  }

  render() {
    const { videoId, title } = this.props;

    return (
      <TouchableOpacity onPress={() => this.playVideo(videoId, 'youtube', title)} style={styles.video}>
        <Image source={{ uri: `https://i.ytimg.com/vi/${videoId}/mqdefault.jpg` }} style={styles.videoImage} />
        <Text numberOfLines={2} style={[mainStyles.typography.h3, mainStyles.margin.top.sm]}>
          {title}
        </Text>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  placeholder: {
    width: mainStyles.SQUARE_BUTTON_WIDTH,
    height: 73,
    backgroundColor: 'rgba(255,255,255,0.8)',
    borderRadius: 2,
  },
  video: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: mainStyles.SQUARE_BUTTON_WIDTH,
  },
  videoImage: {
    width: mainStyles.SQUARE_BUTTON_WIDTH,
    height: 73,
    borderRadius: 2,
  },
});

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    userId: selectors.user.getUserId(state),
    tunedTo: selectors.tunein.getTunedTo(state),
  }),
  {
    setTrack: actions.media.player.setTrack,
    disableTuneIn: actions.tunein.disableTuneIn,
  },
)(withRouter(PopularVideoComponent));
