import * as app from './app';
import media from './media';
import podcast from './podcast';
import providers from './providers';
import * as tunein from './tunein';
import * as user from './user';

export default {
  app,
  media,
  providers,
  user,
  podcast,
  tunein,
};
