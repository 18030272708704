/* eslint-disable no-unused-vars */
/* eslint-disable guard-for-in */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable func-names */
export const checkDRMSupportSpotify = () => {
  const config = [
    {
      audioCapabilities: [
        {
          contentType: 'audio/mp4; codecs="mp4a.40.5"',
          robustness: 'HW_SECURE_DECODE',
        },
        {
          contentType: 'audio/mp4; codecs="mp4a.40.2"',
          robustness: 'SW_SECURE_CRYPTO',
        },
        {
          contentType: 'audio/webm; codecs="vorbis"',
          robustness: 'SW_SECURE_CRYPTO',
        },
      ],
    },
  ];

  return navigator
    .requestMediaKeySystemAccess('com.widevine.alpha', config)
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};
// The configWithVideo object should be extended to include the specific codec claims respective to your particular media
let configWithVideo = [
  {
    initDataTypes: ['cenc'],
    audioCapabilities: [
      {
        contentType: 'audio/mp4;codecs="mp4a.40.2"',
      },
    ],
    videoCapabilities: [
      {
        contentType: 'video/mp4;codecs="avc1.42E01E"',
      },
    ],
  },
];

const keySystems = {
  widevine: ['com.widevine.alpha'],
  playready: ['com.microsoft.playready', 'com.youtube.playready'],
  clearkey: ['webkit-org.w3.clearkey', 'org.w3.clearkey'],
  primetime: ['com.adobe.primetime', 'com.adobe.access'],
  fairplay: ['com.apple.fairplay'],
};

let hasEME;
const testVideoElement = document.createElement('video');
const supportedSystems = [];
const unsupportedSystems = [];

// it will get required keysystems information to avoid not supported keysystem warnning for web app
export const supportsEncryptedMediaExtension = function () {
  if (!testVideoElement.mediaKeys) {
    if (window.navigator.requestMediaKeySystemAccess) {
      if (typeof window.navigator.requestMediaKeySystemAccess === 'function') {
        // found default EME
        hasEME = true;
        var isKeySystemSupported = function (keySystem) {
          configWithVideo = [{ initDataTypes: ['cenc'] }];
          if (window.navigator.requestMediaKeySystemAccess) {
            window.navigator
              .requestMediaKeySystemAccess(keySystem, configWithVideo)
              .then(function (keySystemAccess) {
                supportedSystems.push(keySystem);
              })
              .catch(function () {
                unsupportedSystems.push(keySystem);
              });
          }
        };
        var keysys;
        var dummy;
        for (keysys in keySystems) {
          if (keySystems.hasOwnProperty(keysys)) {
            for (dummy in keySystems[keysys]) {
              isKeySystemSupported(keySystems[keysys][dummy]);
            }
          }
        }
      }
    } else if (window.MSMediaKeys) {
      if (typeof window.MSMediaKeys === 'function') {
        // found MS-EME
        hasEME = true;
        var keysy;
        var dum;
        for (keysy in keySystems) {
          if (keySystems.hasOwnProperty(keysy)) {
            for (dum in keySystems[keysy]) {
              if (window.MSMediaKeys.isTypeSupported(keySystems[keysy][dum])) {
                supportedSystems.push(keySystems[keysy][dum]);
              } else {
                unsupportedSystems.push(keySystems[keysy][dum]);
              }
            }
          }
        }
      }
    } else if (testVideoElement.webkitGenerateKeyRequest) {
      if (typeof testVideoElement.webkitGenerateKeyRequest === 'function') {
        // found WebKit EME
        hasEME = true;
        var keys;
        var dumy;
        for (keys in keySystems) {
          if (keySystems.hasOwnProperty(keys)) {
            for (dumy in keySystems[keys]) {
              if (testVideoElement.canPlayType('video/mp4', keySystems[keys][dumy])) {
                supportedSystems.push(keySystems[keys][dumy]);
              } else {
                unsupportedSystems.push(keySystems[keys][dumy]);
              }
            }
          }
        }
      }
    } else {
      // no supported EME implementation found
      hasEME = false;
    }
  }
};
