export const SET_CURRENT_PLAYER = 'MUSIC_SET_CURRENT_PLAYER';
export const LOAD_NEW_MUSIC_TRACK = 'MUSIC_LOAD_NEW_MUSIC_TRACK';
export const TOGGLE_PLAYER_STATE = 'MUSIC_TOGGLE_PLAYER_STATE';

export const SET_ARTIST_RECOMMENDATIONS = 'MUSIC_SET_ARTIST_RECOMMENDATIONS';
export const SET_TRACKS_RECOMMENDATIONS = 'MUSIC_SET_TRACKS_RECOMMENDATIONS';

export const PLAY_TRACK = 'MUSIC_PLAY_TRACK';

export const STORE_RECENT_SEARCH = 'MUSIC_STORE_RECENT_SEARCH';
export const CLEAR_CROSS_MATCH = 'MUSIC_CLEAR_CROSS_MATCH';
export const SET_CROSS_MATCH = 'MUSIC_SET_CROSS_MATCH';
