import React, { useEffect, useState } from 'react';
import { Platform, Share, StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ListButton from '../../components/app/ListButton';
import DeleteButton from '../../components/user/DeleteButton';
import LogoutButton from '../../components/user/LogoutButton';
import ShareModal from '../../containers/app/ShareModal';
import ProfileHeader from '../../containers/user/ProfileHeader';
import AppLayout from '../../layouts/app';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import mainStyles from '../../styles';
import { ACTIVITY_CM_KEY } from '../../styles/consts';
import { getUserProfile, sendDetailsToCM } from '../../utils/api/api';
import { config } from '../../utils/config';
import analytics from '../../utils/routing/analytics';
import { withRouter } from '../../utils/routing/Routing';

const ProfileScreen = ({ profile, jwt, userId, setProfile, setPopup }) => {
  const [showShareModal, setShowShareModal] = useState(false);
  const shareTexts = {
    message: 'Checkout my profile in Rezzonation.',
    title: 'Share profile',
    url: `${config.APP_URL}/profile/${profile.username}`,
  };

  const shareProfile = () => {
    const { url, title, message } = shareTexts;
    analytics().logEvent('share_profile');
    const cmActivity = {
      created: new Date(),
      userId,
      profileShare: userId,
    };
    sendDetailsToCM(cmActivity, ACTIVITY_CM_KEY);
    if (Platform.OS === 'web') {
      setShowShareModal(prev => !prev);
    } else {
      Share.share(
        {
          title,
          url,
          message: Platform.OS === 'android' ? `${message} ${url}` : message,
        },
        {
          dialogTitle: title,
          subject: title,
        },
      );
    }
  };

  const inviteFriends = () => {
    const cmActivity = {
      created: new Date(),
      userId,
      userInvites: userId,
    };
    sendDetailsToCM(cmActivity, ACTIVITY_CM_KEY);
    setPopup({ moreFun: true });
  };

  useEffect(() => {
    getUserProfile({ jwt }).then(prof => {
      setProfile({ profile: prof });
    });
  }, []);

  return (
    <AppLayout style={{ justifyContent: 'space-between' }} title="Profile">
      <View style={styles.profileContainer}>
        <ProfileHeader inviteFriends={inviteFriends} profile={profile} shareProfile={shareProfile} />

        <View>
          <ListButton icon={require('../../assets/icons/connect/connect_white.png')} path="/providers" testID="connect-provider-button">
            Connect streaming service
          </ListButton>
          <ListButton icon={require('../../assets/icons/about/about.png')} path="/about" testID="about-button">
            About
          </ListButton>
          <ListButton icon={require('../../assets/icons/follow-unfollow/follow.png')} path="/follower" testID="following-button">
            Following
          </ListButton>
          <ListButton icon={require('../../assets/icons/faq/faq.png')} path="/FaqScreen" testID="faq-button">
            FAQ
          </ListButton>
          {Platform.OS !== 'web' && (
            <ListButton icon={require('../../assets/icons/settings/settings.png')} path="/SettingScreen">
              Settings
            </ListButton>
          )}
        </View>
      </View>
      <View style={[styles.logoutButtonContainer, mainStyles.margin.bottom.md]}>
        <LogoutButton />
      </View>
      <View style={[styles.deleteButtonContainer, mainStyles.margin.bottom.xl]}>
        <DeleteButton />
      </View>

      <ShareModal
        isVisible={showShareModal}
        message={shareTexts.message}
        onBackdropPress={() => setShowShareModal(prev => !prev)}
        title={shareTexts.title}
        url={shareTexts.url}
      />
    </AppLayout>
  );
};

const styles = StyleSheet.create({
  profileContainer: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  logoutButtonContainer: {
    alignItems: 'center',
  },
  deleteButtonContainer: {
    alignItems: 'center',
  },
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        setAlert: actions.app.setAlert,
        setPopup: actions.app.setPopup,
        setProfile: actions.user.setProfile,
      },
      dispatch,
    ),
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    jwt: selectors.user.getJwt(state),
    profile: selectors.user.getProfile(state),
    userId: selectors.user.getUserId(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileScreen));
