import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';

import selectors from '../../../store/selectors';
import mainStyles from '../../../styles';
import { withRouter } from '../../../utils/routing/Routing';

const PlaylistButton = ({ trackCount, playlist, testID, playlistImageData, onClickPlaylist }) => {
  let imageSrc = '';
  if (playlistImageData?.data?.url && playlistImageData.data.playlistId === playlist.playlistId) {
    imageSrc = playlistImageData.data.url;
  } else if (playlist?.customImage) {
    imageSrc = playlist.customImage;
  } else {
    imageSrc = playlist?.source?.image ? playlist.source.image : undefined;
  }
  return (
    <TouchableOpacity key={`playlist-${playlist.playlistId}`} onPress={onClickPlaylist} style={styles.playlist} testID={testID}>
      {imageSrc ? (
        <Image resizeMode="cover" source={{ uri: imageSrc }} style={styles.image} />
      ) : (
        <Image resizeMode="cover" source={require('../../../assets/logos/one/logo.png')} style={[styles.iconPlaceholder, styles.image]} />
      )}

      <View style={[styles.playlistDetails, mainStyles.margin.left.sm]}>
        <Text numberOfLines={2} style={mainStyles.typography.h2}>
          {playlist.name}
        </Text>
        {trackCount > 0 && (
          <Text style={mainStyles.typography.p}>
            {trackCount} {trackCount > 1 ? 'songs' : 'song'}
          </Text>
        )}
      </View>
      <Image source={require('../../../assets/icons/arrow/arrow_right.png')} style={mainStyles.iconRight} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  image: {
    width: mainStyles.SMALL_IMAGE_SIZE,
    height: mainStyles.SMALL_IMAGE_SIZE,
    borderRadius: mainStyles.IMAGE_BORDER_RADIUS,
    flexGrow: 0,
  },
  iconPlaceholder: {
    width: mainStyles.SMALL_IMAGE_SIZE,
    height: mainStyles.SMALL_IMAGE_SIZE,
    backgroundColor: mainStyles.IMAGE_PLACEHOLDER_COLOR,
    borderRadius: mainStyles.IMAGE_BORDER_RADIUS,
  },
  playlist: {
    flex: 1,
    flexDirection: 'row',
    // justifyContent: 'space-between',
    alignItems: 'center',
  },
  playlistDetails: {
    flex: 1,
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    playlistImageData: selectors.media.playlists.getImagePlaylist(state),
  };
};

export default connect(mapStateToProps)(withRouter(PlaylistButton));

// export default withRouter(PlaylistButton);
