import types from '../../types';

const initialState = {
  hasValidInvite: false,
  inviteCode: null,
  fcmToken: null,
  jwt: null,
  profile: {
    profilePictureUrl: '',
    username: null,
    displayName: null,
    following: [],
    followingCount: 0,
    followers: [],
    followerCount: 0,
    showAdditionalInfoPage: false,
  },
  userId: null,
  xmppPassword: null,
  cmData: {
    userId: null,
    createdAt: null,
    email: null,
    username: null,
    displayName: null,
    countryOrigin: null,
    profilePicture: null,
    role: null,
    deleted: null,
  },
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case types.user.SET_USER_CREDENTIALS:
      return {
        ...state,
        userId: payload.userId,
        jwt: payload.jwt,
      };
    case types.user.SET_VALID_INVITE:
      return {
        ...state,
        hasValidInvite: true,
        inviteCode: payload.code,
      };
    case types.user.LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    case types.user.SET_PROFILE:
      const profile = payload.profile ? payload.profile : payload;
      return {
        ...state,
        profile: {
          ...state.profile,
          profilePictureUrl: profile.profilePictureUrl,
          username: profile.username,
          displayName: profile.displayName,
          following: profile.following,
          followingCount: profile.followingCount,
          followers: profile.followers,
          followerCount: profile.followerCount,
          showAdditionalInfoPage: profile.showAdditionalInfoPage,
        },
        xmppPassword: profile.xmppPassword,
      };
    case types.user.SET_PROFILE_PICTURE:
      return {
        ...state,
        profile: {
          ...state.profile,
          profilePictureUrl: payload.url,
        },
        cmData: {
          ...state.cmData,
          profilePicture: payload.url,
        },
      };
    case types.user.SET_USERNAME:
      return {
        ...state,
        profile: {
          ...state.profile,
          username: payload.username,
        },
      };
    case types.user.SET_DISPLAYNAME:
      return {
        ...state,
        profile: {
          ...state.profile,
          displayName: payload.displayName,
        },
      };
    case types.user.SET_FCM_TOKEN:
      return {
        ...state,
        fcmToken: payload,
      };
    case types.user.SET_XMPP_PASSWORD:
      return {
        ...state,
        xmppPassword: payload.password,
      };
    case types.user.SET_NEW_FOLLOWED_USER:
      if (state.profile.following.find(u => u.username === payload.username)) return state;

      const newFollowing = state.profile.following;
      newFollowing.push({ username: payload.username, profilePictureUrl: payload.profilePicture });

      return {
        ...state,
        profile: {
          ...state.profile,
          following: newFollowing,
        },
      };
    case types.user.DROP_FOLLOWED_USER:
      if (!state.profile.following.find(u => u.username === payload.username)) return state;

      const dropFollowed = state.profile.following;
      dropFollowed.splice(
        state.profile.following.findIndex(u => u.username === payload.username),
        1,
      );

      return {
        ...state,
        profile: {
          ...state.profile,
          following: dropFollowed,
        },
      };
    case types.user.SET_PERSONAL_DETAILS_CM_DATA:
      const userInfo = payload.profile ? payload.profile : payload;
      return {
        ...state,
        cmData: {
          ...state.cmData,
          userId: userInfo.userId,
          createdAt: userInfo.createdAt,
          email: userInfo.email,
          username: userInfo.username,
          profilePicture: userInfo.profilePictureUrl ? userInfo.profilePictureUrl : null,
          displayName: userInfo.displayName,
          countryOrigin: payload.countryOrigin,
          role: payload.role,
        },
      };
    case types.user.DELETE_PERSONAL_DETAILS_CM_DATA:
      return {
        ...state,
        cmData: {
          ...state.cmData,
          deleted: payload.deleted,
        },
      };
    default:
      return state;
  }
};
