import React, { Component } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import Swipeable from 'react-native-swipeable';
import { connect } from 'react-redux';

import actions from '../../../store/actions';
import mainStyles from '../../../styles';
import { withRouter } from '../../../utils/routing/Routing';

class AddToQueueSwiper extends Component {
  state = {
    leftActionActivated: false,
    toggle: false,
  };

  render() {
    const { addToQueue, children, track } = this.props;
    const { leftActionActivated, toggle } = this.state;

    const onLeftActionComplete = () => {
      this.setState({ toggle: !toggle });
      addToQueue({ track, opts: { next: true } });
    };

    const leftSwipeContent = (
      <View style={[styles.leftSwipeItem]}>
        {leftActionActivated ? (
          <Image source={require('../../../assets/icons/check/check_big_green.png')} style={[mainStyles.icon, styles.iconRight]} />
        ) : (
          <Image source={require('../../../assets/icons/check/check_small_dark.png')} style={[mainStyles.icon, styles.iconRight]} />
        )}
      </View>
    );

    return (
      <Swipeable
        leftActionActivationDistance={150}
        leftContent={leftSwipeContent}
        onLeftActionActivate={() => this.setState({ leftActionActivated: true })}
        onLeftActionComplete={onLeftActionComplete}
        onLeftActionDeactivate={() => this.setState({ leftActionActivated: false })}
      >
        {children}
      </Swipeable>
    );
  }
}

const styles = StyleSheet.create({
  leftSwipeItem: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingRight: 50,
  },
});

export default connect(null, {
  addToQueue: actions.media.queue.addToQueue,
})(withRouter(AddToQueueSwiper));
