import types from '../../types';

export const setAuthToken = ({ token, expires, expiresIn, refreshToken }) => {
  return {
    type: types.providers.spotify.SET_AUTH_TOKEN,
    payload: {
      token,
      expires,
      expiresIn,
      refreshToken,
    },
  };
};

export const setDeviceId = ({ id }) => ({
  type: types.providers.spotify.SET_DEVICE_ID,
  payload: {
    deviceId: id,
  },
});

export const initSpotifyPlayer = ({ token }) => ({
  type: types.providers.spotify.INIT_PLAYER,
  payload: { token },
});

export const setSpotifyEnableAuth = () => ({
  type: types.providers.spotify.ENABLE_AUTH,
});

export const playSpotifyTrack = ({ track }) => ({
  type: types.providers.spotify.PLAY_TRACK,
  payload: {
    track,
  },
});

export const resetAuthToken = () => ({
  type: types.providers.spotify.RESET_AUTH_TOKEN,
});
