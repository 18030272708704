import types from '../../types';

const search = {
  queries: [],
  channel: {},
  feed: {},
  episode: {},
};

export default (state = search, action) => {
  const { payload, type } = action;

  switch (type) {
    case types.podcast.search.SET_CHANNEL:
      return {
        ...state,
        channel: payload.channel,
      };
    case types.podcast.search.SET_FEED:
      return {
        ...state,
        feed: payload.feed,
      };
    case types.podcast.search.SET_EPISODE:
      return {
        ...state,
        episode: payload.episode,
      };
    case types.podcast.search.ADD_SEARCH_QUERY:
      const newState = state;
      if (!newState.queries) {
        newState.queries = [];
      }
      const existingSearchIndex = newState.queries.findIndex(e => payload.query.term.includes(e.term));
      if (existingSearchIndex >= 0) {
        newState.queries.splice(existingSearchIndex, 1);
      }

      return {
        ...newState,
        queries: [payload.query, ...newState.queries.splice(0, 4)],
      };
    case types.user.LOGOUT:
      return {
        ...state,
        queries: [],
        channel: {},
        feed: {},
        episode: {},
      };
    default:
      return state;
  }
};
