import types from '../../types';

export const setOriginUrl = payload => ({
  type: types.app.SET_ORIGIN_URL,
  payload,
});

export const appStarted = () => ({
  type: types.app.APP_STARTED,
});

export const setAlert = payload => ({
  type: types.app.SET_ALERT,
  payload,
});

export const setPopup = payload => ({
  type: types.app.SET_POPUP,
  payload,
});

export const showOverlay = () => ({
  type: types.app.SHOW_OVERLAY,
});

export const showWebDownloadPopup = payload => ({
  type: types.app.SET_WEB_DOWNLOAD_POPUP,
  payload,
});

export const hideOverlay = () => ({
  type: types.app.HIDE_OVERLAY,
});

export const pushNotificationsRequested = () => ({
  type: types.app.SET_PUSH_NOTIFICATIONS_REQUESTED,
});
