import { isEqual } from 'lodash';

import types from '../../types';

const player = {
  statuses: {},
  track: {},
  type: null,
  video: {},
  pausedTime: null,
  playTime: null,
  progress: {
    duration: 0,
    position: 0,
  },
  dateStartedTrack: null,
  crossMatched: {
    provider: null,
    originalId: null,
  },
  trackEndingState: null,
  radioCategory: null,
  trackEnd: false,
};

export default (state = player, action) => {
  const { payload, type } = action;

  switch (type) {
    case types.media.player.SET_PROVIDER_STATUS:
      return {
        ...state,
        statuses: {
          ...state.statuses,
          [payload.provider]: payload.status,
          trackEndingState: payload.trackEndingState,
        },
      };
    case types.media.player.SET_CROSS_MATCHED:
      return {
        ...state,
        crossMatched: {
          provider: payload.provider,
          originalId: payload.originalId,
        },
      };
    case types.media.player.SET_TRACK:
      const dateStartedTrack = isEqual(state.track, payload.track) ? state.dateStartedTrack : new Date();
      return {
        ...state,
        origin: payload.origin,
        track: payload.track,
        [payload.origin]: 'playing',
        dateStartedTrack,
      };
    case types.media.player.SET_VIDEO:
      return {
        ...state,
        type: 'video',
        video: payload.video,
      };
    case types.media.player.SET_CATEGORY:
      return {
        ...state,
        type: 'radio',
        radioCategory: payload,
      };
    case types.media.music.PLAY_TRACK:
      return {
        ...state,
        dateStartedTrack: new Date(),
      };
    case types.media.player.SET_TRACKEND:
      return {
        ...state,
        trackEnd: payload,
      };
    case types.media.player.SET_PAUSETIME:
      return {
        ...state,
        pausedTime: payload,
      };
    case types.media.player.SET_PLAYTIME:
      return {
        ...state,
        playTime: payload,
      };
    case types.user.LOGOUT:
      return {
        ...state,
        ...player,
      };
    default:
      return state;
  }
};
