export const getDurationFromProvider = async provider => {
  const TIME_MULITPLIER = 1000;

  if (!provider) {
    return;
  }

  switch (provider) {
    case 'spotify':
      const state = await global.spotify.getSeekbarPositions();
      return { position: state.position, loaded: state.duration, duration: state.duration };
    case 'deezer':
      return {
        position: 0,
        duration: 0,
      };
    case 'apple':
      const { _player } = MusicKit.getInstance();
      return {
        position: _player.currentPlaybackTime * TIME_MULITPLIER,
        loaded: _player.currentBufferedProgress * TIME_MULITPLIER,
        duration: _player.currentPlaybackDuration * TIME_MULITPLIER,
      };
    case 'stream':
      return {
        position: global.streamPlayer.getCurrentTime() * TIME_MULITPLIER || 0,
        loaded: global.streamPlayer.getSecondsLoaded() * TIME_MULITPLIER || 0,
        duration: global.streamPlayer.getDuration() * TIME_MULITPLIER || 0,
      };
    case 'youtube':
      return {
        position: global.youtubePlayer.getCurrentTime() * TIME_MULITPLIER || 0,
        loaded: global.youtubePlayer.getSecondsLoaded() || 0,
        duration: global.youtubePlayer.getDuration() * TIME_MULITPLIER || 0,
      };
    default:
      console.warn('getDurationFromProvider: unknown provider', provider);
      break;
  }
};
