import types from '../../types';

export const emptyPlayQueue = () => ({
  type: types.media.queue.EMPTY_QUEUE,
});

export const startQueue = ({ tracks, index }) => ({
  type: types.media.queue.START_QUEUE,
  payload: { tracks, index },
});

export const playNextTrack = () => ({
  type: types.media.queue.PLAY_NEXT_TRACK,
});

export const manualNextTrack = payload => ({
  type: types.media.queue.MANUAL_NEXT_TRACK,
  payload,
});

export const playPreviousTrack = () => ({
  type: types.media.queue.PLAY_PREVIOUS_TRACK,
});

export const toggleRepeat = () => ({
  type: types.media.queue.TOGGLE_REPEAT,
});

export const toggleShuffle = () => ({
  type: types.media.queue.TOGGLE_SHUFFLE,
});

export const addToQueue = ({ track, opts }) => ({
  type: types.media.queue.ADD_TRACK_TO_QUEUE,
  payload: { track, opts },
});
