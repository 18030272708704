import types from '../../types';

export const setCurrentMusicPlayer = payload => ({
  type: types.media.music.SET_CURRENT_PLAYER,
  payload: {
    ...payload,
  },
});

export const togglePlayerState = () => ({
  type: types.media.music.TOGGLE_PLAYER_STATE,
});

export const playTrack = track => ({
  type: types.media.music.PLAY_TRACK,
  payload: {
    track,
  },
});

export const storeSearch = query => ({
  type: types.media.music.STORE_RECENT_SEARCH,
  payload: {
    query,
  },
});

export const setCrossMatch = crossMatch => ({
  type: types.media.music.SET_CROSS_MATCH,
  payload: crossMatch,
});

export const clearCrossMatch = () => ({
  type: types.media.music.CLEAR_CROSS_MATCH,
});
