import types from '../../types';

const subscriptions = {
  current: [],
};

export default (state = subscriptions, action) => {
  const { payload, type } = action;

  switch (type) {
    case types.podcast.subscriptions.SET_SUBSCRIPTIONS:
      return {
        ...state,
        current: payload.subscriptions,
      };
    case types.podcast.subscriptions.ADD_SUBSCRIPTION:
      return {
        ...state,
        current: [...state.current, payload.subscription],
      };
    case types.podcast.subscriptions.REMOVE_SUBSCRIPTION:
      const newState = state;
      const existingSearchIndex = newState.current.findIndex(e => e.artistId === payload.subscription.artistId);
      if (existingSearchIndex >= 0) {
        newState.current.splice(existingSearchIndex, 1);
      }

      return {
        ...state,
        current: newState.current,
      };
    case types.user.LOGOUT:
      return {
        ...state,
        current: [],
      };
    default:
      return state;
  }
};
