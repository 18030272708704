import React, { useEffect, useState } from 'react';
import { FlatList, Text, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TextInputRez from '../../components/app/TextInputRez';
import CreatePlaylistButton from '../../components/media/playlist/CreatePlaylistButton';
import CreatePlaylistInput from '../../components/media/playlist/CreatePlaylistInput';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import mainStyles from '../../styles';
import history from '../../utils/routing/history';

const Playlists = ({ getPlaylists, userId, playlists, playlistButton, onClickPlaylist }) => {
  const [showCreate, setShowCreate] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [newPlaylistName, setNewPlaylistName] = useState('');

  useEffect(() => {
    getPlaylists({ userId });
  }, []);

  useEffect(() => {
    if (newPlaylistName !== '') {
      const newPlaylist = playlists.filter(playlist => playlist.name === newPlaylistName)[0];
      history.push(`/playlists/${newPlaylist.shortId}`);
    }
  }, [playlists.length]);

  const toggleCreateInput = () => {
    setShowCreate(prev => !prev);
  };

  const PlaylistButton = playlistButton;
  const playlistsToShow = playlists ? playlists.filter(playlist => playlist.name.match(new RegExp(searchQuery, 'i'))) : [];

  return (
    <View>
      <TextInputRez
        defaultValue={searchQuery}
        icon={require('../../assets/icons/search/search.png')}
        onChangeText={setSearchQuery}
        placeholder="Searching playlists..."
        testID="searchplaylists-input"
      />

      <View style={mainStyles.margin.top.md}>
        {!showCreate && <CreatePlaylistButton onCreateClick={() => toggleCreateInput()} />}
        {showCreate && <CreatePlaylistInput setNewPlaylistName={setNewPlaylistName} toggleInput={() => toggleCreateInput()} />}
      </View>

      {playlistsToShow && playlistsToShow.length > 0 && (
        <FlatList
          data={playlistsToShow}
          keyExtractor={p => p.playlistId}
          removeClippedSubview
          renderItem={({ item }) => (
            <View key={`playlist-${item.playlistId}`} style={mainStyles.margin.bottom.md}>
              <PlaylistButton
                onClickPlaylist={() => onClickPlaylist(item)}
                playlist={item}
                testID={`playlist_${item.name}-button`}
                trackCount={item.trackCount || 0}
              />
            </View>
          )}
          style={[mainStyles.margin.top.md, mainStyles.margin.bottom.md]}
        />
      )}

      {playlists && playlists.length === 0 && (
        <View style={mainStyles.margin.top.md}>
          <Text style={mainStyles.typography.p}>You have no playlists yet. Why not create one...</Text>
        </View>
      )}

      {playlists && playlists.length > 0 && playlistsToShow.length === 0 && (
        <View style={mainStyles.margin.top.md}>
          <Text style={[mainStyles.typography.p, mainStyles.typography.textCenter]}>No playlists found...</Text>
        </View>
      )}
    </View>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    playlists: [...selectors.media.playlists.getPlaylists(state)],
    userId: selectors.user.getUserId(state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        getPlaylists: actions.media.playlists.getPlaylists,
        createNewPlaylist: actions.media.playlists.createNewPlaylist,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Playlists);
