import types from '../../types';

const initialState = {
  spotify: null,
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case types.providers.spotify.SET_PLAYER:
      return {
        ...state,
        spotify: payload.player,
      };
    case types.user.LOGOUT:
      return {
        ...state,
        spotify: null,
      };
    default:
      return state;
  }
};
