/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ButtonRez from '../../components/app/ButtonRez';
import PublicProfilePicture from '../../components/user/PublicProfilePicture';
import AppLayout from '../../layouts/app';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import mainStyles from '../../styles';
import { followUser, getPublicUserProfile, unfollowUser } from '../../utils/api/api';
import analytics from '../../utils/routing/analytics';
import { withRouter } from '../../utils/routing/Routing';

class PublicProfileScreen extends Component {
  state = {
    profile: {},
    loading: true,
    test: false,
    isFollowing: false,
  };

  componentDidMount() {
    try {
      const { username } = this.props.match.params.username ? this.props.match.params : this.props.location.state;
      if (username) {
        getPublicUserProfile({ username }).then(profile => {
          this.setState({ profile, loading: false });
        });
      }
      this.setState({
        isFollowing: this.props.following.find(u => u.username === username),
      });
    } catch (err) {
      console.log('Error on mounting Public Profile Screen: ', err);
      // eslint-disable-next-line no-undef
      Sentry.captureException(err);
    }
  }

  submitFollow() {
    const { username } = this.props.match.params.username ? this.props.match.params : this.props.location.state;
    if (this.props.jwt == null) {
      this.props.history.push('/signin');
      return;
    }
    try {
      followUser({
        jwt: this.props.jwt,
        username,
      }).then(() => {
        analytics().logEvent('follow_profile');
        this.props.setFollowing({ username });
        if (global.xmpp && global.xmpp.getStatus() !== 'offline') {
          global.xmpp.sendPresence(username);
          global.xmpp.subscribePubSubUser({ username });
        }
        const oldProfile = this.state.profile;
        // @todo: fix render on prop change so this can be deleted
        this.setState({ test: true, isFollowing: true });
        this.setState({ profile: { ...oldProfile, followerCount: oldProfile.followerCount + 1 } });
      });
    } catch (err) {
      console.log(err);
    }
  }

  submitUnfollow() {
    const { username } = this.props.match.params.username ? this.props.match.params : this.props.location.state;
    if (this.props.jwt == null) {
      this.props.history.push('/signin');
      return;
    }
    try {
      unfollowUser({
        jwt: this.props.jwt,
        username,
      }).then(() => {
        this.props.dropFollowing({ username });
        if (global.xmpp && global.xmpp.getStatus() !== 'offline') {
          global.xmpp.sendPresence(username, 'unavailable');
          global.xmpp.unsubscribePubSubUser({ username });
        }
        const oldProfile = this.state.profile;
        // @todo: fix render on prop change so this can be deleted
        this.setState({ test: true, isFollowing: false });
        this.setState({ profile: { ...oldProfile, followerCount: oldProfile.followerCount - 1 } });
      });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const { isFollowing, loading, profile } = this.state;
    const userProfileName = profile.displayName ? profile.displayName : profile.username;
    return (
      <AppLayout>
        {!loading && (
          <View style={styles.header}>
            <PublicProfilePicture small url={profile.profilePictureUrl} />

            <View style={[styles.user, mainStyles.margin.left.sm, mainStyles.margin.top.xs]}>
              <View style={styles.userDetails}>
                <View style={styles.userNameContainer}>
                  <Text style={[mainStyles.typography.h3]}>{userProfileName}</Text>
                </View>

                <View style={styles.countContainer}>
                  <View style={[styles.count, mainStyles.margin.top.xs]}>
                    <Text style={mainStyles.typography.h3}>{profile.followerCount}</Text>
                    <Text style={mainStyles.typography.h3Light}> Followers</Text>
                  </View>
                  <View style={[styles.count, { justifyContent: 'flex-end' }]}>
                    <Text style={mainStyles.typography.h3}>{profile.followingCount}</Text>
                    <Text style={mainStyles.typography.h3Light}> Following</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        )}

        {!loading && (
          <View style={[styles.buttons, mainStyles.margin.top.md]}>
            {!isFollowing && <ButtonRez inline label="Follow" onPress={() => this.submitFollow()} secondary small />}
            {isFollowing && <ButtonRez inline label="Unfollow" onPress={() => this.submitUnfollow()} secondary small />}
          </View>
        )}

        {loading && (
          <View style={styles.loading}>
            <ActivityIndicator color="rgba(255,142,12,1)" size="large" />
          </View>
        )}
      </AppLayout>
    );
  }
}

const styles = StyleSheet.create({
  btnContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  count: {
    flex: 1,
    flexDirection: 'row',
  },
  countContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  headerContainer: {
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  header: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxHeight: 64,
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  user: {
    flex: 1,
  },
  userDetails: {
    display: 'flex',
  },
  username: {
    marginLeft: 10,
  },
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        dropFollowing: actions.user.dropFollowing,
        setAlert: actions.app.setAlert,
        setProfile: actions.user.setProfile,
        setFollowing: actions.user.setNewFollowing,
      },
      dispatch,
    ),
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    jwt: selectors.user.getJwt(state),
    profile: selectors.user.getProfile(state),
    following: selectors.user.getFollowing(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PublicProfileScreen));
