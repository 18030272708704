import React from 'react';
import { Image, StyleSheet, TouchableOpacity } from 'react-native';

const ControlButton = ({ onPress, disabled, icon, active }) => {
  let iconImage;
  let buttonStyle = styles.controlButton;

  switch (icon) {
    case 'next':
      iconImage = require('../../../assets/icons/next_audio/next.png');
      break;
    case 'pause':
      iconImage = require('../../../assets/icons/pause/pause.png');
      buttonStyle = styles.togglePlayButton;
      break;
    case 'play':
      iconImage = require('../../../assets/icons/play/play.png');
      buttonStyle = styles.togglePlayButton;
      break;
    case 'previous':
      iconImage = require('../../../assets/icons/previous_audio/previous.png');
      break;
    case 'shuffle':
      iconImage = require('../../../assets/icons/shuffle/shuffle.png');
      if (active) iconImage = require('../../../assets/icons/shuffle/shuffle_orange.png');
      break;
    case 'repeat':
      iconImage = require('../../../assets/icons/repeat/repeat.png');
      if (active) iconImage = require('../../../assets/icons/repeat/repeat_orange.png');
      break;
    default:
      break;
  }

  return (
    <TouchableOpacity disabled={disabled} onPress={onPress} style={disabled && { opacity: 0.5 }} testID={`${icon}mediacontrol_button`}>
      <Image resizeMode="contain" source={iconImage} style={buttonStyle} />
    </TouchableOpacity>
  );
};

export default ControlButton;

const styles = StyleSheet.create({
  controlButton: {
    height: 24,
    width: 24,
  },
  togglePlayButton: {
    width: 54,
    height: 54,
  },
});
