import React, { useEffect } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from 'react-native-responsive-screen';
import { connect } from 'react-redux';

import Seekbar from '../../components/media/seekbar/Seekbar';
import AppLayout from '../../layouts/app';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import mainStyles from '../../styles';
import { MEDIA_CONNECT_CM_KEY } from '../../styles/consts';
import { sendDetailsToCM } from '../../utils/api/api';

const VideoPlayerScreen = ({ toggleProviderStatus, player, userId }) => {
  useEffect(() => {
    const cmMedia = {
      userId,
      youtube: new Date(),
    };
    sendDetailsToCM(cmMedia, MEDIA_CONNECT_CM_KEY);
  }, []);
  const togglePlay = () => {
    toggleProviderStatus({ provider: 'youtube', status: player.statuses.youtube === 'playing' ? 'paused' : 'playing' });
  };

  const { track, statuses } = player;
  const hasQueue = false;

  return (
    <AppLayout hideMiniPlayer>
      <View style={styles.details}>
        <Text style={[mainStyles.typography.h2, mainStyles.typography.textCenter]}>{track.name}</Text>
      </View>

      <View style={[styles.seekbarContainer, mainStyles.margin.top.md]}>
        <View style={{ flex: 1, width: wp('75%') }}>
          <Seekbar fullSeekbar />
        </View>
      </View>

      <View
        style={StyleSheet.flatten([{ justifyContent: hasQueue ? 'space-between' : 'center' }, styles.mediaControls, mainStyles.margin.bottom.md])}
      >
        <TouchableOpacity onPress={togglePlay}>
          {statuses.youtube === 'playing' ? (
            <Image source={require('../../assets/icons/pause/pause.png')} style={styles.playButton} />
          ) : (
            <Image source={require('../../assets/icons/play/play.png')} style={styles.playButton} />
          )}
        </TouchableOpacity>
      </View>
    </AppLayout>
  );
};

const styles = StyleSheet.create({
  details: {
    marginTop: ((wp('100%') - 16) / 16) * 9 + 32,
    // flex: 1,
    // justifyContent: 'center',
    // alignItems: 'center'
  },
  seekbarContainer: {
    alignItems: 'center',
  },
  mediaControls: {
    flex: 1,
    height: hp('8%'),
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
  },
  playButton: {
    width: 40,
    height: 40,
  },
  video: {
    flex: 1,
  },
});

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    player: selectors.media.player.getPlayer(state),
    userId: selectors.user.getUserId(state),
  }),
  {
    toggleProviderStatus: actions.media.player.toggleProviderStatus,
  },
)(VideoPlayerScreen);
