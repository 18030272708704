import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import mainStyles from '../../../styles';
import analytics from '../../../utils/routing/analytics';

const AddToPlaylistButton = ({ playlist, trackCount, testID, onClickPlaylist, playlistId }) => {
  const handleClick = () => {
    analytics().logEvent('add_track_playlist');
    onClickPlaylist(playlistId);
  };

  return (
    <TouchableOpacity key={`playlist-${playlist.playlistId}`} onPress={handleClick} style={styles.playlist} testID={testID}>
      {playlist.source && playlist.source.image ? (
        <Image resizeMode="cover" source={{ uri: playlist.source.image }} style={styles.image} />
      ) : (
        <View style={styles.iconPlaceholder} />
      )}
      <View style={[styles.playlistDetails, mainStyles.margin.left.sm]}>
        <Text style={mainStyles.typography.h2}>{playlist.name}</Text>
        {trackCount > 0 && (
          <Text style={mainStyles.typography.p}>
            {trackCount} {trackCount > 1 ? 'songs' : 'song'}
          </Text>
        )}
      </View>
      <View style={styles.addButtonContainer}>
        <Image source={require('../../../assets/icons/plus/plus.png')} style={styles.addIcon} />
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  addButtonContainer: {
    width: 20,
    height: 20,
    borderWidth: 2,
    borderColor: 'rgba(242,242,242,1)',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  addIcon: {
    width: 8,
    height: 8,
  },
  image: {
    width: mainStyles.SMALL_IMAGE_SIZE,
    height: mainStyles.SMALL_IMAGE_SIZE,
    borderRadius: mainStyles.IMAGE_BORDER_RADIUS,
    flexGrow: 0,
  },
  iconPlaceholder: {
    width: mainStyles.SMALL_IMAGE_SIZE,
    height: mainStyles.SMALL_IMAGE_SIZE,
    backgroundColor: mainStyles.IMAGE_PLACEHOLDER_COLOR,
    borderRadius: mainStyles.IMAGE_BORDER_RADIUS,
  },
  playlist: {
    flex: 2,
    flexGrow: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    minHeight: 50,
    maxHeight: 50,
  },
  playlistDetails: {
    flexGrow: 2,
  },
});

export default AddToPlaylistButton;
