import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import actions from '../../store/actions';
import selectors from '../../store/selectors';
import mainStyles from '../../styles';
import { MEDIA_CONNECT_CM_KEY } from '../../styles/consts';
import { sendDetailsToCM } from '../../utils/api/api';
import { savePopupDataLocally } from '../../utils/DatabaseHelper';
import { withRouter } from '../../utils/routing/Routing';
import ProviderButton from './ProviderButton';

const SpotifyButton = ({ userId, enabled }) => {
  const clickHandler = () => {
    savePopupDataLocally();
    const cmMedia = {
      userId,
      spotify: new Date(),
    };
    sendDetailsToCM(cmMedia, MEDIA_CONNECT_CM_KEY);
    global.spotify.login(userId);
  };

  return (
    <View>
      <ProviderButton color="#1DB954" enabled={enabled} handler={clickHandler} icon="spotify" name="Spotify" testID="spotify-provider-button" />
      {!enabled && <Text style={[mainStyles.typography.p, styles.disabledText]}>Spotify is not available on your device.</Text>}
    </View>
  );
};

const styles = StyleSheet.create({
  disabledText: {
    lineHeight: 18,
    textAlign: 'center',
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    userId: selectors.user.getUserId(state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        setOriginUrl: actions.app.setOriginUrl,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SpotifyButton));
