import React, { useState } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';

import actions from '../../store/actions';
import selectors from '../../store/selectors';
import mainStyles from '../../styles';
import { ACTIVITY_CM_KEY } from '../../styles/consts';
import { followUser, sendDetailsToCM, unfollowUser } from '../../utils/api/api';
import { withRouter } from '../../utils/routing/Routing';
import Sentry from '../../utils/Sentry';

const Follow = ({ following, jwt, userId, history, setFollowing, setAlert, followUnfollow, dropFollowing }) => {
  const [profile, setProfile] = useState({});

  const pictureStyle = [styles.picture, styles.small];
  const titleStyles = [mainStyles.typography.h2, mainStyles.margin.bottom.xs];
  const imageSrc = following?.profilePicture ? `https://media.rezzonation.app/${following.profilePicture}` : '';
  const userProfileName = following.displayName ? following.displayName : following.username;

  const userContainerStyles = [styles.userContainer, mainStyles.margin.bottom.sm, mainStyles.margin.top.sm];

  const submitFollow = () => {
    const { username } = following;
    const profilePicture = `https://media.rezzonation.app/${following.profilePicture}`;
    if (jwt == null) {
      history.push('/signin');
      return;
    }
    try {
      followUser({
        jwt,
        username: following.username,
      }).then(() => {
        setFollowing({ username, profilePicture });
        followUnfollow();
        const cmActivity = {
          created: new Date(),
          userId,
          followedUserId: following._id,
        };
        sendDetailsToCM(cmActivity, ACTIVITY_CM_KEY);
        if (global.xmpp && global.xmpp.getStatus() !== 'offline') {
          global.xmpp.sendPresence(username);
          global.xmpp.subscribePubSubUser({ username });
        }
        const newProfile = { profile: { ...profile, followerCount: profile.followerCount + 1 } };
        setProfile(newProfile);
      });
    } catch (err) {
      setAlert({ message: 'Sorry, something failed, please try again.', type: 'error' });
      Sentry.captureException(err);
    }
  };

  const submitUnfollow = () => {
    const { username } = following;
    if (jwt == null) {
      history.push('/signin');
      return;
    }

    try {
      unfollowUser({
        jwt,
        username: following.username,
      }).then(() => {
        dropFollowing({ username });
        followUnfollow();
        if (global.xmpp && global.xmpp.getStatus() !== 'offline') {
          global.xmpp.sendPresence(username, 'unavailable');
          global.xmpp.unsubscribePubSubUser({ username });
        }
        const newProfile = { profile: { ...profile, followerCount: profile.followerCount - 1 } };
        setProfile(newProfile);
      });
    } catch (err) {
      setAlert({ message: 'Sorry, something failed, please try again.', type: 'error' });
      Sentry.captureException(err);
    }
  };

  return (
    <View style={userContainerStyles} testID={`follower-${following.username}`}>
      <Image
        source={following.profilePicture ? { uri: imageSrc } : require('../../assets/icons/userProfile/user.png')}
        style={[pictureStyle, mainStyles.margin.right.sm]}
      />

      <View style={styles.userDetailsContainer}>
        <Text numberOfLines={1} style={titleStyles}>
          {userProfileName}
        </Text>
      </View>
      <TouchableOpacity
        onPress={() => {
          following.isFollowing ? submitUnfollow() : submitFollow();
        }}
        style={styles.followUnfollowBtn}
        testID={following.isFollowing ? `unfollow-${following.username}` : `follow-${following.username}`}
        underlayColor="#fff"
      >
        <Text style={styles.followUnfollowLabel}>{following.isFollowing === true ? 'Unfollow' : 'Follow'}</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  userContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  picture: {
    borderWidth: 0,
    backgroundColor: mainStyles.colours.purpleDark,
  },
  small: {
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  userDetailsContainer: {
    flex: 1,
    paddingRight: mainStyles.distance.sm,
  },
  followUnfollowBtn: {
    marginLeft: 40,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: '#1E6738',
    borderRadius: 5,
    borderWidth: 0.5,
    borderColor: '#fff',
  },
  followUnfollowLabel: {
    color: '#fff',
    textAlign: 'center',
    paddingLeft: 10,
    paddingRight: 10,
  },
});

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    jwt: selectors.user.getJwt(state),
    profile: selectors.user.getProfile(state),
    userId: selectors.user.getUserId(state),
  }),
  {
    setAlert: actions.app.setAlert,
    setProfile: actions.user.setProfile,
    dropFollowing: actions.user.dropFollowing,
    setFollowing: actions.user.setNewFollowing,
  },
)(withRouter(Follow));
