import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import { WebView } from 'react-native-webview';

import FullScreenLayout from '../layouts/fullscreen';
import { withRouter } from '../utils/routing/Routing';

const SpotifyPremiumScreen = ({ history }) => {
  useEffect(() => {
    if (Platform.OS === 'web') {
      window.open('https://www.spotify.com/premium');
      history.goBack();
    }
  }, []);

  return (
    <FullScreenLayout noPadding title="Spotify Premium">
      <WebView source={{ uri: 'https://www.spotify.com/premium' }} />
    </FullScreenLayout>
  );
};

export default withRouter(SpotifyPremiumScreen);
