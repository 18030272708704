/* eslint-disable no-undef */
import { Platform } from 'react-native';

import { registerMessagingToken } from '../api/api';

export const enablePushNotifications = ({ jwt }) => {
  if (Platform.OS === 'web') {
    const messaging = firebase.messaging();
    messaging.usePublicVapidKey('BKk-8s_s4kx-XFqYtCEJFtMpscqdotT54gGTPquHYR1ktHQSV-Wd9-FB3jndkDep7GGdPweVKVZQrLIPlWO1A_g');

    return new Promise((resolve, reject) => {
      requestPushNotificationsPermission()
        .then(async permission => {
          if (permission === 'granted') {
            console.log('Notification permission granted.');
            const token = await getNotificationsTokenFromBrowser();
            console.log('Token received? ', token);
            if (token) {
              console.log('Sending token to server');
              await registerMessagingToken({ token, type: 'web', jwt });
              resolve(true);
            } else {
              resolve(false);
            }
          } else {
            console.log('Unable to get permission to notify.');
            resolve(false);
          }
        })
        .catch(err => {
          console.log('Error requesting notifications permission', err);
          reject();
        });
    });
  }
};

const requestPushNotificationsPermission = () => {
  return Notification.requestPermission();
};

const getNotificationsTokenFromBrowser = () => {
  messaging
    .getToken()
    .then(currentToken => {
      if (currentToken) {
        console.log('Token: ', currentToken);
        return currentToken;
      } else {
        // Show permission request.
        console.log('No Instance ID token available. Request permission to generate one.');
        // Show permission UI.
        return false;
      }
    })
    .catch(err => {
      console.log('An error occurred while retrieving token. ', err);
    });
};
