import types from '../../types';

const initialState = {
  playlists: [],
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case types.media.playlists.PLAYLIST_CREATED:
      state.playlists.unshift(payload);
      return {
        ...state,
      };
    case types.media.playlists.UPDATE_PLAYLIST:
      return {
        ...state,
        playlists: state.playlists.map(playlist => (playlist.playlistId === payload.playlistId ? payload : playlist)),
      };
    case types.media.playlists.DELETE_PLAYLIST:
      return {
        ...state,
        playlists: state.playlists.filter(playlist => playlist.playlistId !== payload),
      };
    case types.media.playlists.PLAYLISTS_RETRIEVED:
      return {
        ...state,
        playlists: payload,
      };
    case types.media.playlists.FOLLOWING_PLAYLIST:
      return {
        ...state,
        playlists: state.playlists.map(playlist => (playlist.playlistId === payload.playlistId ? { ...playlist, isFollower: true } : playlist)),
      };
    case types.media.playlists.UNFOLLOWED_PLAYLIST:
      return {
        ...state,
        playlists: state.playlists.map(playlist => (playlist.playlistId === payload.playlistId ? { ...playlist, isFollower: false } : playlist)),
      };
    case types.media.playlists.ADD_TRACK:
      return {
        ...state,
        playlists: state.playlists.map(playlist =>
          playlist.playlistId === payload.playlistId ? { ...playlist, tracks: [...playlist.tracks, payload.track] } : playlist,
        ),
      };
    case types.media.playlists.SET_IMAGE_PLAYLIST:
      console.log('Add playlist image', payload);
      return {
        ...state,
        playlistImageData: payload,
      };
    case types.media.playlists.SET_PLAYLISTS:
      console.log('Set playlists', payload);
      return {
        ...state,
        playlists: payload,
      };
    case types.user.LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
