import React, { Component } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from 'react-native-responsive-screen';
import { connect } from 'react-redux';

import AppLayout from '../../layouts/app';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import mainStyles from '../../styles';
import { addFavoriteStation, deleteFavoriteStation } from '../../utils/api/api';
import analytics from '../../utils/routing/analytics';
import { withRouter } from '../../utils/routing/Routing';
import Sentry from '../../utils/Sentry';

class StationScreen extends Component {
  togglePlay() {
    this.props.toggleProviderStatus({ provider: 'stream', status: this.props.player.statuses.stream === 'playing' ? 'paused' : 'playing' });
  }

  toggleFavorite() {
    const { setFavoriteStation, player, jwt } = this.props;
    if (player.track.favorite) {
      analytics().logEvent('delete_favorite_station');
      deleteFavoriteStation(jwt, player.track.stationuuid)
        .then(res => {
          if (res.status === 200) {
            player.track.favorite = false;
            this.props.setRadioFav(true);
            setFavoriteStation(player.track.name, false);
          } else {
            throw new Error();
          }
        })
        .catch(err => {
          this.props.setAlert({ message: 'Sorry, something failed, please try again.', type: 'error' });
          Sentry.captureException(err);
        });
    } else {
      analytics().logEvent('add_favorite_station');
      addFavoriteStation(jwt, player.track.stationuuid)
        .then(res => {
          if (res.status === 201) {
            player.track.favorite = true;
            this.props.setRadioFav(true);
            setFavoriteStation(player.track.name, true);
          } else {
            throw new Error();
          }
        })
        .catch(err => {
          this.props.setAlert({ message: 'Sorry, something failed, please try again.', type: 'error' });
          Sentry.captureException(err);
        });
    }
  }

  render() {
    const { player } = this.props;

    return (
      <AppLayout hideMiniPlayer title="radio">
        <View style={styles.stationLogoContainer}>
          <Image
            defaultSource={require('../../assets/icons/radio/radio_mini.png')}
            resizeMode="contain"
            source={player.track.favicon === '' ? require('../../assets/icons/radio/radio_mini.png') : { uri: player.track.favicon }}
            style={styles.stationLogo}
          />
        </View>
        <View style={mainStyles.centerFlex}>
          <Text style={[mainStyles.typography.h2, styles.trackTitle]}>{player.track.name}</Text>
        </View>

        <View style={[styles.controlsContainer]}>
          <TouchableOpacity onPress={() => this.togglePlay()} style={mainStyles.margin.top.md}>
            {this.props.player.statuses.stream === 'playing' ? (
              <Image source={require('../../assets/icons/pause/pause.png')} style={styles.playButton} />
            ) : (
              <Image source={require('../../assets/icons/play/play.png')} style={styles.playButton} />
            )}
          </TouchableOpacity>
        </View>
        <View style={[mainStyles.centerFlex, styles.actionsContainer]}>
          <TouchableOpacity onPress={() => this.toggleFavorite()} style={styles.actionButtonContainer} testID="favouritestation-button">
            {player.track.favorite ? (
              <Image source={require('../../assets/icons/heart/heart_fill.png')} style={mainStyles.icon} testID="station-status-favourite" />
            ) : (
              <Image source={require('../../assets/icons/heart/heart.png')} style={mainStyles.icon} testID="station-status-notfavourite" />
            )}
          </TouchableOpacity>
        </View>
      </AppLayout>
    );
  }
}

const styles = StyleSheet.create({
  stationLogoContainer: {
    alignItems: 'center',
  },
  stationLogo: {
    width: hp('40%'),
    height: wp('75%'),
    maxHeight: 200,
    maxWidth: 200,
  },
  introText: {
    textAlign: 'center',
  },
  controlsContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  playButton: {
    width: 50,
    height: 50,
  },
  actionsContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  actionButtonContainer: {
    padding: mainStyles.distance.lg,
  },
  menuItemName: {
    color: 'rgba(23,23,38,1)',
    height: 20,
  },
});

export default connect(
  (state, ownProps) => ({
    ...state,
    jwt: selectors.user.getJwt(state),
    station: selectors.media.stations.getStation(state, ownProps.location.pathname.split('/').pop()),
    player: selectors.media.player.getPlayer(state),
  }),
  {
    setFavoriteStation: actions.media.stations.setFavoriteStation,
    setRadioFav: actions.media.stations.setRadioFav,
    setAlert: actions.app.setAlert,
    toggleProviderStatus: actions.media.player.toggleProviderStatus,
  },
)(withRouter(StationScreen));
