export const ADD_NEW_GROUPCHAT_MESSAGE = 'TUNEIN_ADD_NEW_GROUPCHAT_MESSAGE';
export const CLEAR_GROUPCHAT_HISTORY = 'TUNEIN_CLEAR_GROUPCHAT_HISTORY';

export const SET_USER_ONLINE = 'TUNEIN_SET_USER_ONLINE';
export const SET_USER_OFFLINE = 'TUNEIN_SET_USER_OFFLINE';
export const SET_USER_STREAMING = 'TUNEIN_SET_USER_STREAMING';

export const NEW_TRACK_RECEIVED = 'TUNEIN_NEW_TRACK_RECEIVED';
export const UPDATE_PLAYING_STATUS = 'TUNEIN_UPDATE_PLAYING_STATUS';
export const CLEAR_PLAYING_STATUS = 'TUNEIN_CLEAR_PLAYING_STATUS';

export const ENABLE_TUNE_IN = 'TUNEIN_ENABLE_TUNEIN';
export const DISABLE_TUNE_IN = 'TUNEIN_DISABLE_TUNEIN';
export const SET_TUNE_IN_NUMBER = 'TUNE_IN_NUMBER';
export const SET_TUNE_OUT_NUMBER = 'TUNE_OUT_NUMBER';
export const RESET_TUNE_IN_NUMBER = 'RESET_TUNE_IN_NUMBER';
