import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Video from './components/media/videos/Video';
import DeezerPlayer from './components/providers/DeezerPlayer';
import AboutScreen from './screens/AboutScreen';
import ApplePremiumScreen from './screens/ApplePremiumScreen';
import AudioSelection from './screens/audio/AudioSelection';
import DeezerAuth from './screens/auth/DeezerAuth';
import SpotifyAuth from './screens/auth/SpotifyAuth';
import ContentSelection from './screens/ContentSelection';
import DeezerPremiumScreen from './screens/DeezerPremiumScreen';
import FaqScreen from './screens/FaqScreen';
import LoadingScreen from './screens/LoadingScreen';
import AlbumScreen from './screens/music/AlbumScreen';
import ArtistScreen from './screens/music/ArtistScreen';
import Music from './screens/music/MainMusicScreen';
import AddToPlaylistScreen from './screens/music/playlist/AddToPlaylistScreen';
import CropPlaylistPicture from './screens/music/playlist/CropPlaylistPicture';
import PlaylistOptionsScreen from './screens/music/playlist/PlaylistOptionsScreen';
import PlaylistScreen from './screens/music/playlist/PlaylistScreen';
import PlaylistsScreen from './screens/music/playlist/PlaylistsScreen';
import ProviderSelection from './screens/music/ProviderSelection';
import SearchScreen from './screens/music/SearchScreen';
import ShareTrackScreen from './screens/music/track/ShareTrackScreen';
import TrackOptionsScreen from './screens/music/track/TrackOptionsScreen';
import TrackScreen from './screens/music/track/TrackScreen';
import VideoPlayerScreen from './screens/music/VideoPlayerScreen';
import PodcastEpisodesScreen from './screens/podcasts/Episodes/EpisodesScreen';
import PodcastPlayScreen from './screens/podcasts/Play/PlayScreen';
import PodcastSearchScreen from './screens/podcasts/Search/SearchScreen';
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen';
import FavouriteRadio from './screens/radio/FavouriteRadio';
import StationCategories from './screens/radio/StationCategories';
import StationScreen from './screens/radio/StationScreen';
import StationsScreen from './screens/radio/StationsScreen';
import SpotifyPremiumScreen from './screens/SpotifyPremiumScreen';
import TermsScreen from './screens/TermsScreen';
import TuneInUserScreen from './screens/tuneIn/TuneInUserScreen';
import CropProfilePicture from './screens/user/CropProfilePicture';
import ExclusiveContentPermission from './screens/user/ExclusiveContentPermission';
import FollowingListScreen from './screens/user/FollowingListScreen';
import ForgotPasswordScreen from './screens/user/ForgotPasswordScreen';
import JoinWaitinglistScreen from './screens/user/JoinWaitinglistScreen';
import ProfileScreen from './screens/user/ProfileScreen';
import PublicProfileScreen from './screens/user/PublicProfileScreen';
import ResetPasswordScreen from './screens/user/ResetPasswordScreen';
import SigninScreen from './screens/user/SigninScreen';
import SignupScreen from './screens/user/SignupScreen';
import ValidateOtpScreen from './screens/user/ValidateOtpScreen';
import WaitinglistScreen from './screens/user/WaitinglistScreen';
import selectors from './store/selectors';
import history from './utils/routing/history';
import PrivateRoute from './utils/routing/privateRoute';
import { Route, Router, Switch } from './utils/routing/Routing';

const RezzonationRoutes = ({ jwt }) => {
  return (
    <Router history={history}>
      <Video />
      <Switch>
        <Route component={props => <LoadingScreen {...props} />} exact path="/" />

        <PrivateRoute component={props => <ContentSelection {...props} />} exact jwt={jwt} path="/content" />

        <PrivateRoute component={AudioSelection} exact jwt={jwt} path="/audio" />
        <PrivateRoute component={props => <Music {...props} />} exact jwt={jwt} path="/music" />
        <PrivateRoute component={SearchScreen} exact jwt={jwt} path="/music/search" />

        <PrivateRoute component={props => <SpotifyAuth {...props} />} exact jwt={jwt} path="/authorize/spotify" />
        <PrivateRoute component={props => <DeezerAuth {...props} />} exact jwt={jwt} path="/authorize/deezer" />
        <PrivateRoute component={props => <ProviderSelection {...props} />} exact jwt={jwt} path="/music/connect" />
        <PrivateRoute component={props => <ProviderSelection {...props} />} exact jwt={jwt} path="/providers" />
        <PrivateRoute component={FaqScreen} exact jwt={jwt} path="/FaqScreen" />
        <PrivateRoute component={SpotifyPremiumScreen} exact jwt={jwt} path="/spotifypremium" />
        <PrivateRoute component={DeezerPremiumScreen} exact jwt={jwt} path="/deezerpremium" />
        <PrivateRoute component={ApplePremiumScreen} exact jwt={jwt} path="/applepremium" />

        <PrivateRoute component={AboutScreen} exact jwt={jwt} path="/about" />
        <PrivateRoute component={FollowingListScreen} exact jwt={jwt} path="/follower" />
        <PrivateRoute component={TermsScreen} exact jwt={jwt} path="/conditions" />
        <PrivateRoute component={PrivacyPolicyScreen} exact jwt={jwt} path="/Privacy Policy" />

        <PrivateRoute component={ArtistScreen} exact jwt={jwt} path="/artist/:provider/:id" />
        <PrivateRoute component={AddToPlaylistScreen} exact jwt={jwt} path="/track/:id/addToPlaylist/:provider" />
        <PrivateRoute component={TrackScreen} exact jwt={jwt} path="/track/:title/:id" />
        <PrivateRoute component={ShareTrackScreen} exact jwt={jwt} path="/track-share/:title/:artist/:id/:provider" />
        <PrivateRoute component={TrackOptionsScreen} exact jwt={jwt} path="/track/:title/:id/:shortId/options" />
        <PrivateRoute component={AlbumScreen} exact jwt={jwt} path="/album/:provider/:id" />
        <PrivateRoute component={props => <PlaylistsScreen {...props} />} exact jwt={jwt} path="/playlists" />
        <PrivateRoute component={PlaylistScreen} exact jwt={jwt} path="/playlists/:shortId" />
        <PrivateRoute component={PlaylistOptionsScreen} exact jwt={jwt} path="/playlists/:shortId/options" />

        <PrivateRoute component={DeezerPlayer} exact jwt={jwt} path="/deezerchannel" />

        <Route exact path="/signup" render={props => <SignupScreen {...props} />} title="Signup" />
        <Route exact path="/waiting" render={props => <WaitinglistScreen {...props} />} title="Waiting List" />
        <Route exact path="/signin" render={props => <SigninScreen {...props} />} title="Signin" />
        <Route exact path="/validate" render={props => <ValidateOtpScreen {...props} />} title="Email Verification" />
        <Route path="/exclusive-content" render={props => <ExclusiveContentPermission {...props} />} />
        <Route exact path="/join" render={props => <JoinWaitinglistScreen {...props} />} title="Join" />
        <Route exact path="/forgot" render={props => <ForgotPasswordScreen {...props} />} title="Forgot password" />
        <Route exact path="/reset-password" render={props => <ResetPasswordScreen {...props} />} title="Reset password" />

        <PrivateRoute component={ProfileScreen} exact jwt={jwt} path="/profile/" />
        <Route component={PublicProfileScreen} exact path="/profile/:username" />
        <PrivateRoute component={CropProfilePicture} exact jwt={jwt} path="/profile-crop" title="Edit photo" />

        <PrivateRoute component={TuneInUserScreen} jwt={jwt} path="/tunein/:username" title="Tune in" />

        <PrivateRoute component={StationCategories} exact jwt={jwt} path="/radioCategory" />
        <PrivateRoute component={FavouriteRadio} exact jwt={jwt} path="/favouriteRadio" />
        <PrivateRoute component={StationsScreen} exact jwt={jwt} path="/radio" title="Radio" />
        <PrivateRoute component={StationScreen} exact jwt={jwt} path="/radio/:radioKey" title="Radio - Play" />

        <PrivateRoute component={PodcastPlayScreen} exact jwt={jwt} path="/podcast/channel/play" />
        <PrivateRoute component={PodcastEpisodesScreen} exact jwt={jwt} path="/podcast/channel/episodes" />
        <PrivateRoute component={PodcastSearchScreen} exact jwt={jwt} path="/podcast/search" />

        <PrivateRoute component={VideoPlayerScreen} exact jwt={jwt} path="/video/:videoId" />
        <PrivateRoute component={CropPlaylistPicture} exact jwt={jwt} path="/playlist-crop/:playlistId" title="Edit photo" />
      </Switch>
    </Router>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    jwt: selectors.user.getJwt(state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({}, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RezzonationRoutes);
