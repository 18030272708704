import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Spotify from '../../assets/logos/spotify/spotify.png';
import selectors from '../../store/selectors';
import ButtonRez from '../app/ButtonRez';

const ProviderButton = ({ availableProviders, handler, icon, enabled, testID }) => {
  let iconImage;
  switch (icon) {
    case 'deezer':
      iconImage = require('../../assets/logos/deezer/deezer.png');
      break;
    case 'apple':
      iconImage = require('../../assets/logos/apple/apple-music-badge.png');
      break;
    case 'soundcloud':
      iconImage = require('../../assets/logos/soundcloud/soundcloud.png');
      break;
    case 'spotify':
      iconImage = Spotify;
      break;
    case 'trackdrip':
      iconImage = require('../../assets/logos/trackdrip/trackdrip.png');
      break;
    default:
      console.log('unknown icon provider');
  }

  const hasProvider = availableProviders[icon];

  return (
    <ButtonRez disabled={!enabled} large onPress={handler} startColor={enabled ? '#ffffff' : 'rgba(255,255,255,0.3)'} testID={testID}>
      <View style={styles.buttonInner}>
        <Image resizeMode="contain" source={iconImage} style={styles.logo} />
      </View>
      <View>
        {hasProvider && <Image resizeMode="contain" source={require('../../assets/icons/check/check_small.png')} style={styles.linkIcon} />}
        {!hasProvider && enabled && <Image resizeMode="contain" source={require('../../assets/icons/connect/connect.png')} style={styles.linkIcon} />}
        {!hasProvider && !enabled && <View style={styles.linkIcon} />}
      </View>
    </ButtonRez>
  );
};

const styles = StyleSheet.create({
  buttonInner: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  linkIcon: {
    width: 20,
    height: 20,
  },
  logo: {
    width: 80,
    height: 24,
    justifyContent: 'flex-start',
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    availableProviders: selectors.providers.getAvailableProviders(state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({}, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderButton);
