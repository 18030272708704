import types from '../../types';

export const setChannel = payload => ({
  type: types.podcast.search.SET_CHANNEL,
  payload: {
    ...payload,
  },
});

export const setFeed = payload => ({
  type: types.podcast.search.SET_FEED,
  payload: {
    ...payload,
  },
});

export const setEpisode = payload => ({
  type: types.podcast.search.SET_EPISODE,
  payload: {
    ...payload,
  },
});

export const addQuery = payload => ({
  type: types.podcast.search.ADD_SEARCH_QUERY,
  payload,
});
