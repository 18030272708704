export const NEW_USER_CREATED = 'NEW_USER_CREATED';
export const CREATE_NEW_USER = 'CREATE_NEW_USER';

export const SET_JWT = 'USER_SET_JWT';
export const SET_FCM_TOKEN = 'USER_SET_FCM_TOKEN';
export const SET_USER_CREDENTIALS = 'USER_SET_USER_CREDENTIALS';
export const SET_VALID_INVITE = 'USER_SET_VALID_INVITE';
export const SET_PERSONAL_DETAILS_CM_DATA = 'USER_SET_PERSONAL_DETAILS_CM_DATA';
export const DELETE_PERSONAL_DETAILS_CM_DATA = 'USER_DELETE_PERSONAL_DETAILS_CM_DATA';

export const LOGOUT = 'USER_LOGOUT';

export const SET_PROFILE = 'USER_SET_PROFILE';
export const SET_PROFILE_PICTURE = 'USER_SET_PROFILE_PICTURE';

export const SET_USERNAME = 'USER_SET_USERNAME';
export const SET_DISPLAYNAME = 'USER_SET_DISPLAYNAME';

export const SET_XMPP_PASSWORD = 'USER_SET_XMPP_PASSWORD';

export const SET_NEW_FOLLOWED_USER = 'USER_SET_NEW_FOLLOWED_USER';
export const DROP_FOLLOWED_USER = 'USER_DROP_FOLLOWED_USER';
