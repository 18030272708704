import types from '../../types';

export const setCurrent = payload => ({
  type: types.podcast.storedPodcasts.SET_CURRENT,
  payload: {
    ...payload,
  },
});

export const getCurrent = payload => ({
  type: types.podcast.storedPodcasts.GET_CURRENT,
  payload: {
    ...payload,
  },
});

export const removeCurrent = payload => ({
  type: types.podcast.storedPodcasts.REMOVE_CURRENT,
  payload: {
    ...payload,
  },
});
