import types from '../../types';

export const setVideo = payload => ({
  type: types.media.player.SET_VIDEO,
  payload,
});

export const setCrossmatched = ({ provider, originalId }) => ({
  type: types.media.player.SET_CROSS_MATCHED,
  payload: {
    provider,
    originalId,
  },
});

/**
 * This function should be called only by the provider api's themselves
 * @param {*} param0
 */
export const setProviderStatus = ({ status, provider, trackEndingState = false }) => ({
  type: types.media.player.SET_PROVIDER_STATUS,
  payload: {
    status,
    provider,
    trackEndingState,
  },
});

/**
 * This function should be called when manually pausing/unpausing a song
 * @param {*} param0
 */
export const toggleProviderStatus = ({ status, provider }) => ({
  type: types.media.player.TOGGLE_PROVIDER_STATUS,
  payload: {
    status,
    provider,
  },
});

export const setTrack = ({ track, origin }) => ({
  type: types.media.player.SET_TRACK,
  payload: {
    track,
    origin,
  },
});

export const setPauseTime = payload => ({
  type: types.media.player.SET_PAUSETIME,
  payload,
});

export const setPlayTime = payload => ({
  type: types.media.player.SET_PLAYTIME,
  payload,
});

export const setCategory = payload => ({
  type: types.media.player.SET_CATEGORY,
  payload,
});

export const setTrackEnded = payload => ({
  type: types.media.player.SET_TRACKEND,
  payload,
});
