import React from 'react';
import { KeyboardAvoidingView, StyleSheet, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import AlertWindow from '../components/app/AlertWindow';
import NavBarRez from '../containers/app/NavBarRez';
import CMSuggestionModal from '../containers/media/CMSuggestionModal';
import mainStyles from '../styles';

export default function ChatLayout({ alert, moreOptionsHandler, title, children, backButton = true }) {
  return (
    <KeyboardAvoidingView style={{ flex: 1 }}>
      <LinearGradient colors={['rgba(60,33,91,1)', 'rgba(30,18,84,1)']} style={styles.overlay}>
        {alert && !!alert.message && <AlertWindow alert={alert} />}
        <NavBarRez backButton={backButton} moreOptionsHandler={moreOptionsHandler} title={title} />
        <View contentContainerStyle={{ flexGrow: 1 }} style={styles.content}>
          {children}
        </View>
        <CMSuggestionModal />
      </LinearGradient>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    paddingTop: mainStyles.distance.sm,
  },
  overlay: {
    flex: 1,
  },
});
