import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

export const SideScroll = ({ contentOffset, contentSize, scrollViewWidth, setScrollPosition }) => {
  // This sets up the scroll indicator's size
  const scrollElementWidthPercent = (scrollViewWidth / contentSize) * 100;
  // This is used to calculate the position of the scroll indicator
  const scrollPerc = (contentOffset.x / (contentSize - scrollViewWidth)) * (100 - scrollElementWidthPercent);

  return (
    scrollViewWidth < contentSize && (
      <View style={styles.sideScrollContainer}>
        <TouchableOpacity
          onPressOut={e => {
            setScrollPosition(e.nativeEvent.locationX);
          }}
          style={styles.sideScrollTouchable}
        >
          <View
            style={[
              styles.sideScroll,
              {
                left: `${Number(scrollPerc).toFixed(0)}%`,
                width: `${scrollElementWidthPercent}%`,
              },
            ]}
          />
        </TouchableOpacity>
      </View>
    )
  );
};

const styles = StyleSheet.create({
  sideScrollContainer: {
    width: '100%',
    height: 10,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sideScrollTouchable: {
    height: 4,
    width: '100%',
  },
  sideScroll: {
    position: 'absolute',
    height: 4,
    borderRadius: 25,
    backgroundColor: 'rgba(255,255,255,0.7)',
  },
});
