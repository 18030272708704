import { formatDistanceToNow, isValid, parseISO } from 'date-fns';
import React, { useRef, useState } from 'react';
import { ActivityIndicator, FlatList, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';

import TextInputRez from '../../../components/app/TextInputRez';
import RecentSearchQuery from '../../../components/media/search/RecentSearchQuery';
import AppLayout from '../../../layouts/app';
import actions from '../../../store/actions';
import selectors from '../../../store/selectors';
import mainStyles from '../../../styles';
import { ACTIVITY_CM_KEY } from '../../../styles/consts';
import { sendDetailsToCM } from '../../../utils/api/api';
import analytics from '../../../utils/routing/analytics';
import { withRouter } from '../../../utils/routing/Routing';
import SubscriptionsList from '../components/SubscriptionsList';
import { formatSearchedData, searchItunesAPI } from '../utils';
import PodcastListItem from './PodcastListItem';

let queryTimeout = null;
let pageNumber = 0;

function SearchScreen(props) {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoadMore, setLoadMore] = useState(false);

  const queryRef = useRef('');

  const onQueryChange = text => {
    pageNumber = 0;
    queryRef.current = text;
    startQueryTimeout();
  };

  const startQueryTimeout = () => {
    if (queryTimeout) {
      clearTimeout(queryTimeout);
      queryTimeout = null;
    }

    queryTimeout = setTimeout(searchQuery, 1500);
  };

  const searchQuery = async () => {
    if (queryRef.current.length >= 3) {
      analytics().logEvent('search_podcast', { query: queryRef.current });
      const cmActivity = {
        created: new Date(),
        userId: props.userId,
        searchPodcast: queryRef.current,
      };
      sendDetailsToCM(cmActivity, ACTIVITY_CM_KEY);
      setLoading(true);
      setLoadMore(false);
      const body = await searchItunesAPI(queryRef.current, pageNumber).catch(() => setLoading(false));
      if (!body) {
        setLoading(false);
        return;
      }

      const formatted = formatSearchedData(body);
      const podcasts = formatted.find(format => format.wrapperType === 'track');
      setLoading(false);

      if (pageNumber === 0) {
        setResults([]);
        setResults(podcasts.data);
      } else {
        const joined = results.concat(podcasts.data);
        setResults(joined);
      }
      if (podcasts.data.length !== 0) {
        setLoadMore(true);
      }
      props.addQuery({
        query: {
          term: queryRef.current,
          created: new Date(),
        },
      });
    }
  };

  const historyItemPressed = query => {
    queryRef.current = query.term;
    searchQuery();
  };

  const renderRecentSearches = () => {
    return props.queries.map(recentSearch => {
      const searchedDate = isValid(recentSearch.created) ? recentSearch.created : parseISO(recentSearch.created);
      return (
        <RecentSearchQuery
          key={Math.random()}
          created={formatDistanceToNow(searchedDate, { addSuffix: true })}
          query={recentSearch.term}
          run={() => historyItemPressed(recentSearch)}
        />
      );
    });
  };

  const LoadMoreRandomData = () => {
    if (isLoadMore) {
      pageNumber += 1;
      searchQuery();
    }
  };

  return (
    <AppLayout title="podcast">
      <TextInputRez
        autoCapitalize="none"
        autoCorrect={false}
        autoFocus={false}
        defaultValue={queryRef.current}
        icon={require('../../../assets/icons/search/search.png')}
        onChangeText={text => onQueryChange(text)}
        placeholder="Searching for..."
        testID="podcastsearch-input"
      />

      {queryRef.current.length < 3 ? (
        <View style={mainStyles.margin.top.md}>
          <SubscriptionsList />
        </View>
      ) : (
        <View />
      )}
      {queryRef.current.length >= 3 && loading && (
        <View style={styles.loading}>
          <ActivityIndicator color="rgba(255,142,12,1)" size="large" />
        </View>
      )}

      {queryRef.current.length >= 3 && (
        <View>
          <FlatList
            data={results}
            keyExtractor={item => item.trackId}
            renderItem={({ item }) => <PodcastListItem key={item.collectionId} item={item} />}
            style={mainStyles.margin.top.lg}
          />
        </View>
      )}

      {queryRef.current.length >= 3 && isLoadMore && (
        <TouchableOpacity onPress={() => LoadMoreRandomData()} style={styles.loadMoreButton}>
          <Text style={styles.loadMoreText}>Load More</Text>
        </TouchableOpacity>
      )}

      {props.queries.length > 0 && !loading && (
        <View style={mainStyles.margin.top.md}>
          <Text style={[mainStyles.typography.h1, mainStyles.margin.bottom.sm]}>Recent searches</Text>
          {renderRecentSearches()}
        </View>
      )}
    </AppLayout>
  );
}

const styles = StyleSheet.create({
  loading: {
    marginTop: 20,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadMoreButton: {
    marginBottom: 20,
    alignSelf: 'center',
    backgroundColor: 'white',
    width: 100,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
  },
  loadMoreText: {
    fontFamily: 'Roboto-Medium',
    color: 'black',
    fontSize: 15,
    marginTop: 15,
    marginBottom: 12,
  },
});

export default connect(
  state => ({
    ...state,
    results: selectors.podcast.search.getResults(state),
    queries: selectors.podcast.search.getQueries(state),
    userId: selectors.user.getUserId(state),
  }),
  {
    addQuery: actions.podcast.search.addQuery,
  },
)(withRouter(SearchScreen));
