import React, { Component } from 'react';
import { Dimensions, Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';

import actions from '../../../store/actions';
import selectors from '../../../store/selectors';
import mainStyles from '../../../styles';
import analytics from '../../../utils/routing/analytics';
import { withRouter } from '../../../utils/routing/Routing';

class SearchVideoList extends Component {
  playVideo(id, provider, name) {
    if (this.props.tunedTo) {
      this.props.disableTuneIn();
      analytics().logEvent('stop_tunein');
    }
    this.props.setTrack({
      track: {
        name,
        id,
        provider: 'youtube',
      },
      origin: 'video',
    });

    this.props.history.push(`/video/${id}`, { provider, name, id });
  }

  render() {
    const { videoId, title, channelTitle } = this.props;

    return (
      <TouchableOpacity onPress={() => this.playVideo(videoId, 'youtube', title)} style={styles.videolistContainer}>
        <Image source={{ uri: `https://i.ytimg.com/vi/${videoId}/mqdefault.jpg` }} style={styles.thumbnail} />
        <View style={{ marginLeft: 10 }}>
          <Text numberOfLines={4} style={styles.vdoTitle}>
            {title}
          </Text>
          <Text style={styles.channelName}>{channelTitle}</Text>
        </View>
        <Image source={require('../../../assets/icons/arrow/arrow_right.png')} style={mainStyles.iconRight} />
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  videolistContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginVertical: 9,
  },
  thumbnail: {
    width: 120,
    height: 70,
  },
  vdoTitle: {
    width: Dimensions.get('screen').width / 1.5,
    fontSize: 16,
    lineHeight: 19,
    color: 'white',
  },
  channelName: {
    fontSize: 12,
    color: 'white',
  },
});

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    tunedTo: selectors.tunein.getTunedTo(state),
  }),
  {
    setTrack: actions.media.player.setTrack,
    disableTuneIn: actions.tunein.disableTuneIn,
  },
)(withRouter(SearchVideoList));
