import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import DeviceInfo from 'react-native-device-info';
import { widthPercentageToDP as wp } from 'react-native-responsive-screen';
import { connect } from 'react-redux';

import actions from '../../store/actions';
import selectors from '../../store/selectors';
import { ACTIVITY_CM_KEY } from '../../styles/consts';
import { logout, sendDetailsToCM } from '../../utils/api/api';
import analytics from '../../utils/routing/analytics';
import { withRouter } from '../../utils/routing/Routing';
import ButtonRez from '../app/ButtonRez';

const LogoutButton = ({ player, toggleProviderStatus, jwt, history, userId, ...props }) => {
  const isProviderPlaying = () => Object.keys(player.statuses).find(prov => player.statuses[prov] === 'playing');

  const logOut = async () => {
    if (isProviderPlaying()) {
      toggleProviderStatus({ provider: player.track.provider, status: 'paused' });
    }

    const cmActivity = {
      created: new Date(),
      userId,
      logout: new Date(),
    };
    sendDetailsToCM(cmActivity, ACTIVITY_CM_KEY);

    if (Platform.OS !== 'web') await analytics().resetAnalyticsData();
    const uuid = DeviceInfo.getUniqueId();
    logout(uuid, jwt);
    props.logout();
    global.xmpp.logout();
    delete global.xmpp;
    history.push('/signin');
  };

  return (
    <View style={styles.logoutButton}>
      <ButtonRez fontColor="rgba(23,23,38,1)" label="Logout" onPress={logOut} startColor="#ffffff" testID="logout-button" />
    </View>
  );
};

const styles = StyleSheet.create({
  logoutButton: {
    width: wp('60%'),
  },
});

export default connect(
  state => ({
    player: selectors.media.player.getPlayer(state),
    jwt: selectors.user.getJwt(state),
  }),
  {
    toggleProviderStatus: actions.media.player.toggleProviderStatus,
    logout: actions.user.logoutUser,
  },
)(withRouter(LogoutButton));
