export const getOS = () => {
  const { userAgent } = window.navigator;
  const { platform } = window.navigator;
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  }

  return os;
};
