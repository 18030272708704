import { Platform } from 'react-native';
import { eventChannel } from 'redux-saga';
import { call, put, select, take, takeEvery } from 'redux-saga/effects';

import types from '../../types';

export function createPlayer() {
  return eventChannel(emitter => {
    const { apple } = global;

    apple.addListener('AppleMetadataChange', payload => {
      emitter({
        type: 'event',
        event: 'metadata_change',
        payload,
      });
    });

    apple.addListener('ApplePlayerStateChange', payload => {
      emitter({
        type: 'event',
        event: 'play_state_change',
        payload,
      });
    });

    apple.addListener('ApplePlaybackError', () => {
      emitter({
        type: 'event',
        error: 'playback_error',
      });
    });

    return () => {};
  });
}

export function* initApplePlayer() {
  global.apple.init();
  yield put({ type: types.providers.apple.SET_INITIALISED });

  const playerChannel = yield call(createPlayer);

  try {
    while (true) {
      const appleEvent = yield take(playerChannel);
      // eslint-disable-next-line no-unused-vars
      const player = yield select(getStorePlayer);

      switch (appleEvent.event) {
        case 'metadata_change':
          const { status, track } = appleEvent.payload;
          track.status = status;
          yield put({
            type: types.media.music.LOAD_NEW_MUSIC_TRACK,
            payload: { provider: 'apple', track: { ...track, provider: 'apple' }, origin: 'music' },
          });
          yield put({ type: types.media.player.SET_PROVIDER_STATUS, payload: { provider: 'apple', status, position: 0 } });
          break;
        case 'play_state_change':
          yield put({
            type: types.media.player.SET_PROVIDER_STATUS,
            payload: { status: appleEvent.payload.status, position: appleEvent.payload.position, provider: 'apple' },
          });
          break;
        default:
          console.log('unknown initApplePlayer event', appleEvent.event);
      }

      switch (appleEvent.error) {
        case 'playback_error':
          yield put({ type: types.media.music.LOAD_NEW_MUSIC_TRACK, payload: { provider: 'apple', track: null } });
          yield put({ type: types.media.player.SET_PROVIDER_STATUS, payload: { status: 'stopped', position: null, provider: 'apple' } });
          yield put({
            type: types.app.SET_ALERT,
            payload: { message: 'There was a problem playing that song. Please try again later.', type: 'error' },
          });
          break;
        default:
      }
    }
  } finally {
    playerChannel.close();
  }
}

export function* watchInitApple() {
  yield takeEvery(types.providers.apple.INIT_PLAYER, initApplePlayer);
}

const getStorePlayer = state => {
  return state.media.music.player;
};

export function* playAppleTrack(action) {
  const { payload } = action;
  const { track } = payload;

  const player = yield select(getStorePlayer);

  if (player.status === 'playing') {
    yield put({ type: types.media.music.TOGGLE_PLAYER_STATE, payload: {} });
  }

  global.apple.playTrack(track);
}

function* playMusic({ id }, { track }) {
  const player = yield select(getStorePlayer);
  if (player.status === 'playing' && player.currentProvider !== 'apple') {
    yield put({ type: types.media.music.TOGGLE_PLAYER_STATE, payload: {} });
    global.apple.playMusic(id, track);
  } else {
    global.apple.playMusic(id, track);
  }
}

export function* playAppleTrackOnAndroid(action) {
  const { payload } = action;
  const { track } = payload;
  const { id } = track;

  yield call(playMusic, { id }, { track });
}

export function* watchPlayAppleTrack() {
  if (Platform.OS === 'android') {
    yield takeEvery(types.providers.apple.PLAY_TRACK, playAppleTrackOnAndroid);
  } else {
    yield takeEvery(types.providers.apple.PLAY_TRACK, playAppleTrack);
  }
}
