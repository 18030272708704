import { Dimensions, Platform } from 'react-native';

const isAndroid = Platform.OS === 'android';
const isIphone = Platform.OS === 'ios';

export const TITLE_SIZE = 23;
export const PARAGRAPH_SIZE = 12;
export const STATUS_ONLINE = 'online';
export const STATUS_OFFLINE = 'offline';
export const STATUS_LIVE = 'streaming';
export const ALL_NOTIFICATIONS = 'all';
export const LIVE_NOTIFICATION = 'live';
export const NEW_CONNECTION_NOTIFICATION = 'follow';
export const NEW_MESSAGE_NOTIFICATION = 'newMessage';
export const TUNED_IN_NOTIFICATION = 'tuneIn';
export const NAVIGATE_GOING_LIVE_NOTIFICATION = 'GOING-LIVE';
export const NAVIGATE_FOLLOW_NOTIFICATION = 'FOLLOW';
export const NAVIGATE_NEW_MESSAGE_NOTIFICATION = 'NEW-MESSAGE';
export const XMPP_MESSAGE = 'message';
export const XMPP_GROUP_TYPE = 'groupchat';
export const TUNED_IN_TYPE = 'tuneIn';
export const TUNED_OUT_TYPE = 'tuneOut';
export const TUNED_IN_EMIT = 'TuneIn';
export const TUNED_OUT_EMIT = 'TuneOut';
export const PERSONAL_DETAILS_CM_KEY = 'personal';
export const MEDIA_CONNECT_CM_KEY = 'media';
export const ACTIVITY_CM_KEY = 'activity';

export const FULL_WIDTH = Dimensions.get('window').width;
export const FULL_HEIGHT = Dimensions.get('window').height;
export const APP_PADDING = isIphone ? 12 : 15;

export const TOPBAR_HEIGHT = isIphone ? 30 : 50;
export const BOTTOM_NAVIGATOR_HEIGHT = 72;
export const MINI_PLAYER_HEIGHT = 54;

export const GRID_COLUMNS = FULL_WIDTH < 450 ? 6 : 12;
// 15% column width
export const COLUMN_WIDTH_PERCENTAGE = GRID_COLUMNS === 6 ? 15 : 7.5;
export const SIDE_MARGIN_PERCENTAGE = 5;

export const SMALL_IMAGE_SIZE = 50;
export const IMAGE_BORDER_RADIUS = 2;
export const IMAGE_PLACEHOLDER_COLOR = 'rgba(3,3,3,3)';

export const FORM_ELEMENT_HEIGHT = 50;
export const FORM_BORDER_COLOR = 'rgba(255,255,255,0.6)';
export const FORM_BORDER_WIDTH = 1;
export const FORM_BORDER_RADIUS = 25;
export const FORM_INACTIVE_OPACITY = 0.6;

export const BUTTON_GRADIENT = ['rgba(255,142,12,1)', 'rgba(254,107,12,1)'];
export const BUTTON_GRADIENT_SECONDARY = ['rgba(255,255,255,0)', 'rgba(255,255,255,0)'];

export const SQUARE_BUTTON_WIDTH = 130;
export const SQUARE_BUTTON_HEIGHT = 160;
export const CIRCLE_BUTTON_WIDTH = 100;

export const colours = {
  orange: 'rgba(254,107,12,1)',
  orangeLight: 'rgba(255,142,12,1)',
  blue: 'rgba(67,141,227,1)',
  blueLight: 'rgba(76,157,252,1)',
  purple: 'rgba(144,27,205,1)',
  purpleLight: 'rgba(183,49,254,1)',
  purpleDark: 'rgba(29,17,78,1)',
  red: 'rgba(237,43,44,1)',
  redLight: 'rgba(253,53,54,1)',
  green: 'rgba(30,182,21,1)',
  greenLight: 'rgba(36,212,26,1)',
  white: 'rgba(255,255,255,1)',
  dark: 'rgba(23,23,38,1)',
  statusBar: 'rgb(60,33,91)',
  controlBar: 'rgba(23,17,78,1)',
  navigationBar: 'rgba(23,13,67,1)',
};

export const TEXT_COLOR = colours.white;
export const TEXT_COLOR_DISABLED = 'rgba(255,255,255,0.6)';

export const distance = {
  xs: 5,
  sm: 10,
  md: 20,
  lg: 30,
  xl: 40,
};

export const margin = {
  top: {
    xs: {
      marginTop: distance.xs,
    },
    sm: {
      marginTop: distance.sm,
    },
    md: {
      marginTop: distance.md,
    },
    lg: {
      marginTop: distance.lg,
    },
    xl: {
      marginTop: distance.xl,
    },
  },
  bottom: {
    xs: {
      marginBottom: distance.xs,
    },
    sm: {
      marginBottom: distance.sm,
    },
    md: {
      marginBottom: distance.md,
    },
    lg: {
      marginBottom: distance.lg,
    },
    xl: {
      marginBottom: distance.xl,
    },
  },
  left: {
    xs: {
      marginLeft: distance.xs,
    },
    sm: {
      marginLeft: distance.sm,
    },
    md: {
      marginLeft: distance.md,
    },
    lg: {
      marginLeft: distance.lg,
    },
    xl: {
      marginLeft: distance.xl,
    },
  },
  right: {
    xs: {
      marginRight: distance.xs,
    },
    sm: {
      marginRight: distance.sm,
    },
    md: {
      marginRight: distance.md,
    },
    lg: {
      marginRight: distance.lg,
    },
    xl: {
      marginRight: distance.xl,
    },
  },
};

const fontStyle = {
  letterSpacing: 0,
  color: TEXT_COLOR,
};

const fontStyleTitleSplash = isAndroid
  ? {
      ...fontStyle,
      fontFamily: 'Cabo-Rounded-Regular',
    }
  : {
      ...fontStyle,
      fontFamily: 'Cabo Rounded',
    };
const fontStyleTitleBold = isAndroid
  ? {
      ...fontStyle,
      fontFamily: 'Cabo-Rounded-Bold',
    }
  : {
      ...fontStyle,
      fontFamily: 'CaboRoundedBold',
    };

const fontStyleBold = {
  ...fontStyle,
  fontFamily: 'Roboto-Medium',
};

const fontStyleRegular = {
  ...fontStyle,
  fontFamily: 'Roboto-Medium',
};

export const fontStyleLight = {
  ...fontStyle,
  fontFamily: 'Roboto-Light',
};

export const typography = {
  titleSplash: {
    ...fontStyleTitleSplash,
    fontSize: 26,
    lineHeight: 30,
    letterSpacing: 1,
  },
  title: {
    ...fontStyleTitleBold,
    fontSize: 22,
    lineHeight: 22,
    textTransform: 'uppercase',
    letterSpacing: 1,
  },
  h1: {
    ...fontStyleTitleBold,
    textTransform: 'uppercase',
    fontSize: 18,
    lineHeight: 19,
    letterSpacing: 1,
  },
  h2: {
    ...fontStyleRegular,
    fontSize: 16,
    lineHeight: 19,
  },
  h2Light: {
    ...fontStyleLight,
    fontSize: 16,
    lineHeight: 19,
  },
  h3: {
    ...fontStyleRegular,
    fontSize: 14,
    lineHeight: 19,
  },
  h3Light: {
    ...fontStyleLight,
    fontSize: 14,
    lineHeight: 19,
  },
  p: {
    ...fontStyleLight,
    fontSize: 14,
    lineHeight: 19,
  },
  navigation: {
    ...fontStyleTitleBold,
    textTransform: 'uppercase',
    paddingTop: 3,
    fontSize: 18,
    lineHeight: 18,
    letterSpacing: 1,
  },
  navigationSmall: {
    ...fontStyleTitleSplash,
    textTransform: 'uppercase',
    fontSize: 12,
    lineHeight: 12,
    letterSpacing: 1,
  },
  ctaUnderline: {
    ...fontStyleLight,
    fontSize: 16,
    lineHeight: 22,
    textDecorationLine: 'underline',
  },
  textCenter: {
    textAlign: 'center',
  },
  sRegular: {
    ...fontStyleRegular,
    fontSize: 16,
    lineHeight: 19,
  },
  sBold: {
    ...fontStyleBold,
    fontSize: 16,
    lineHeight: 19,
  },
  mRegular: {
    ...fontStyleRegular,
    fontSize: 18,
    lineHeight: 22,
  },
  mBold: {
    ...fontStyleBold,
    fontSize: 18,
    lineHeight: 22,
  },
  xsRegular: {
    ...fontStyleRegular,
    fontSize: 12,
    lineHeight: 16,
  },
  xsBold: {
    ...fontStyleBold,
    fontSize: 12,
    lineHeight: 16,
  },
};
