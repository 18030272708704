/* eslint-disable react/state-in-constructor */

import { Formik } from 'formik';
import React, { Component } from 'react';
import { ActivityIndicator, Platform, StyleSheet, Text, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ButtonRez from '../../components/app/ButtonRez';
import FullScreenLayout from '../../layouts/fullscreen';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import mainStyles from '../../styles';
import { PERSONAL_DETAILS_CM_KEY } from '../../styles/consts';
import { additionalInfo, getUserProfile, sendDetailsToCM } from '../../utils/api/api';
import CheckBox from '../../utils/CheckBoxTick';
import PhoneInput from '../../utils/PhoneInputField';
import { withRouter } from '../../utils/routing/Routing';
import Sentry from '../../utils/Sentry';

class ExclusiveContentPermission extends Component {
  phoneInput = React.createRef(null);

  constructor(props) {
    super(props);
    this.state = { value: null, loading: false, userId: '', isAccessMoreFeatures: true, wantExclusiveContentConsent: null };
  }

  componentDidMount() {
    const { userId } = this.props.match.params.userId ? this.props.match.params : this.props.location.state;
    this.setState({
      userId,
      wantExclusiveContentConsent: new Date(),
    });
  }

  submitData() {
    const { userId } = this.state;
    const { jwt } = this.props;
    this.setState({
      loading: true,
    });

    const additionalData = {
      userId,
      phoneNumber: this.state.value,
      isAccessMoreFeatures: this.state.isAccessMoreFeatures,
    };
    const cmData = {
      userId,
      mobile: null,
      wantExclusiveContentConsent: this.state.wantExclusiveContentConsent,
    };
    sendDetailsToCM(cmData, PERSONAL_DETAILS_CM_KEY);
    additionalInfo(additionalData)
      .then(() => {
        this.setState({
          loading: false,
        });
        getUserProfile({ jwt }).then(profile => {
          this.props.setProfile({ profile });
        });
        this.goToAudio();
      })
      .catch(err => {
        Sentry.captureMessage(err);
        this.setState({
          loading: false,
        });
      });
  }

  goToAudio() {
    const { history } = this.props;
    history.entries = [];
    history.index = -1;
    history.push('/audio');
  }

  render() {
    const { loading } = this.state;
    return (
      <FullScreenLayout backgroundExclusive>
        <View style={{ flex: 1, justifyContent: 'flex-end', paddingBottom: '10%' }}>
          <View>
            <Text style={[mainStyles.typography.p, mainStyles.typography.textCenter, mainStyles.margin.bottom.xl]}>
              If you want to have access to special features, exclusive content and live interaction with artists and labels, make sure we have your
              mobile phone number!
            </Text>
            <Formik
              initialValues={{ enterotp: '' }}
              onSubmit={values => this.submitData(values)}
              validateOnBlur
              validationSchema={this.enterOtpSchema}
            >
              {props => (
                <View style={{ zIndex: 5 }}>
                  <View
                    style={[
                      mainStyles.margin.bottom.sm,
                      {
                        zIndex: Platform.OS === 'web' ? 10 : 0,
                        position: 'relative',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginHorizontal: Platform.OS === 'web' ? '20%' : 0,
                        height: mainStyles.FORM_ELEMENT_HEIGHT,
                        borderRadius: mainStyles.FORM_BORDER_RADIUS,
                        backgroundColor: 'white',
                      },
                    ]}
                  >
                    {Platform.OS === 'web' ? (
                      <PhoneInput
                        buttonStyle={{ borderTopLeftRadius: 20, borderBottomLeftRadius: 20 }}
                        containerStyle={{ paddingLeft: '3%' }}
                        country="nl"
                        enableSearch
                        inputStyle={{
                          width: '97%',
                          borderRadius: 25,
                        }}
                        onChange={text => {
                          this.setState({ value: text });
                        }}
                        value={this.state.value}
                      />
                    ) : (
                      <PhoneInput
                        ref={this.phoneInput}
                        containerStyle={[
                          mainStyles.typography.p,
                          mainStyles.margin.left.md,
                          mainStyles.margin.right.md,
                          { backgroundColor: 'transparent' },
                        ]}
                        defaultCode="NL"
                        defaultValue={this.state.value}
                        layout="first"
                        onChangeFormattedText={text => {
                          this.setState({ value: text });
                        }}
                        textContainerStyle={styles.input}
                        textInputStyle={{
                          backgroundColor: 'transparent',
                        }}
                        value={this.state.value}
                        withDarkTheme
                        withShadow
                      />
                    )}
                  </View>
                  <View
                    style={[
                      mainStyles.margin.bottom.md,
                      { flexDirection: 'row', marginHorizontal: Platform.OS === 'web' ? '20%' : '2%', alignItems: 'center' },
                    ]}
                  >
                    {Platform.OS !== 'web' ? (
                      <CheckBox
                        boxType="square"
                        offAnimationType="bounce"
                        onAnimationType="bounce"
                        onCheckColor="black"
                        onFillColor="white"
                        onTintColor="white"
                        onValueChange={value => {
                          this.setState({ isAccessMoreFeatures: value });
                          if (!value) {
                            this.setState({ wantExclusiveContentConsent: null });
                          } else {
                            this.setState({ wantExclusiveContentConsent: new Date() });
                          }
                        }}
                        style={{ width: Platform.OS === 'android' ? 30 : 20, height: Platform.OS === 'android' ? 30 : 20 }}
                        tintColor="white"
                        tintColors={{ true: 'white', false: 'white' }}
                        value={this.state.isAccessMoreFeatures}
                      />
                    ) : (
                      <CheckBox
                        borderColor="white"
                        checked={this.state.isAccessMoreFeatures}
                        icon={<img alt="" src={require('../../assets/icons/check/check_big.png')} style={{ width: 17, height: 17 }} />}
                        onChange={val => {
                          this.setState({ isAccessMoreFeatures: val });
                          if (!val) {
                            this.setState({ wantExclusiveContentConsent: null });
                          } else {
                            this.setState({ wantExclusiveContentConsent: new Date() });
                          }
                        }}
                      />
                    )}
                    <Text style={[mainStyles.typography.p, { marginHorizontal: '2%' }]}>
                      I want to have access to special features, exclusive content and personal messages from artists and labels.
                    </Text>
                  </View>

                  {!loading && (
                    <View style={[mainStyles.margin.bottom.md]}>
                      <ButtonRez
                        fontColor="rgba(23,23,38,1)"
                        label="Send"
                        large
                        onPress={props.handleSubmit}
                        startColor="rgba(255,255,255,1)"
                        testID="send-additionaldata-button"
                      />
                    </View>
                  )}
                </View>
              )}
            </Formik>

            {!loading && (
              <View style={[mainStyles.margin.bottom.md]}>
                <ButtonRez
                  fontColor="rgba(23,23,38,1)"
                  label="Skip"
                  large
                  onPress={() => this.submitData()}
                  startColor="rgba(255,255,255,1)"
                  testID="skip-button"
                />
              </View>
            )}
            {loading && (
              <View style={mainStyles.loading}>
                <ActivityIndicator color="rgba(255,142,12,1)" size="large" />
              </View>
            )}
          </View>
        </View>
      </FullScreenLayout>
    );
  }
}

const styles = StyleSheet.create({
  logoContainer: {
    alignItems: 'center',
  },
  exclusive: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  input: {
    height: 100,
    backgroundColor: 'transparent',
  },
  text: {
    flex: 1,
    flexGrow: 2,
  },
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        setProfile: actions.user.setProfile,
      },
      dispatch,
    ),
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    jwt: selectors.user.getJwt(state),
    userId: selectors.user.getUserId(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExclusiveContentPermission));
