import { Formik } from 'formik';
import React, { useState } from 'react';
import { ActivityIndicator, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';

import ButtonRez from '../../components/app/ButtonRez';
import TextInputRez from '../../components/app/TextInputRez';
import FullScreenLayout from '../../layouts/fullscreen';
import actions from '../../store/actions';
import mainStyles from '../../styles';
import { passwordReset } from '../../utils/api/api';
import analytics from '../../utils/routing/analytics';

const ForgotPasswordScreen = ({ setAlert, history }) => {
  const [sent, setSent] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const ForgotPasswordSchema = Yup.object().shape({
    username: Yup.string().required('Required'),
  });

  const handleReset = async ({ username }) => {
    setLoading(true);
    analytics().logEvent('password_request');
    const res = await passwordReset({ username });
    if (res.status === 201) {
      console.log(`STATUS: ${res.status}`);
      setLoading(false);
      setSent(true);
      setEmail(username);
    } else {
      setLoading(false);
      setAlert({
        type: 'error',
        message: 'Something went wrong. Please try again later',
      });
    }
  };

  return (
    <FullScreenLayout title="forgot password">
      {!sent && (
        <Text style={[mainStyles.typography.p, mainStyles.margin.top.lg, mainStyles.margin.bottom.lg, mainStyles.typography.textCenter]}>
          Resetting your password is easy, just tell us the email address or username you registered with.
        </Text>
      )}
      {!loading && (
        <>
          <Formik initialValues={{ username: '' }} onSubmit={values => handleReset(values)} validateOnBlur validationSchema={ForgotPasswordSchema}>
            {props => (
              <View>
                {sent ? (
                  <>
                    <Text style={[mainStyles.typography.p, mainStyles.margin.top.lg, mainStyles.typography.textCenter]}>
                      We have sent a reset password link to: {email}. Please click it and set your new password.
                    </Text>
                    <Text style={[mainStyles.typography.p, mainStyles.margin.top.lg, mainStyles.typography.textCenter]}>
                      If you have not received the email in a few minutes please check your spam folder.
                    </Text>
                    <View style={[mainStyles.margin.top.md]}>
                      <ButtonRez disabled={!props.isValid} label="Resend Link" large onPress={props.handleSubmit} secondary />
                    </View>
                  </>
                ) : (
                  <>
                    <TextInputRez
                      autoCapitalize="none"
                      autoCorrect={false}
                      autoFocus={false}
                      enablesReturnKeyAutomatically
                      icon={require('../../assets/icons/users/users.png')}
                      label="Username"
                      name="username"
                      onBlur={props.handleBlur('username')}
                      onChangeText={props.handleChange('username')}
                      onSubmitEditing={() =>
                        props.validateForm().then(() => {
                          if (props.isValid) handleReset(props.values);
                        })
                      }
                      placeholder="Your email address or username"
                      validation={{ error: props.errors.username, touched: props.touched.username }}
                      value={props.values.username}
                    />
                    <View style={[mainStyles.margin.top.md]}>
                      <ButtonRez
                        disabled={!props.isValid}
                        fontColor="rgba(23,23,38,1)"
                        label="Send"
                        large
                        onPress={props.handleSubmit}
                        startColor="rgba(255,255,255,1)"
                      />
                    </View>
                  </>
                )}
              </View>
            )}
          </Formik>
          <TouchableOpacity onPress={() => history.push('/signin')}>
            <Text style={[mainStyles.typography.ctaUnderline, mainStyles.margin.top.lg, mainStyles.typography.textCenter]}>I remember it now</Text>
          </TouchableOpacity>
        </>
      )}
      {loading && (
        <View style={mainStyles.loading}>
          <ActivityIndicator color="rgba(255,142,12,1)" size="large" />
        </View>
      )}
    </FullScreenLayout>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        setAlert: actions.app.setAlert,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordScreen);
