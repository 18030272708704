import { format, isValid, parse, parseISO } from 'date-fns';
import React, { useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Collapsible from 'react-native-collapsible';
import { connect } from 'react-redux';

import actions from '../../../store/actions';
import mainStyles from '../../../styles';
import { durationToTime } from '../../../utils/providers/spotify/common';
import { withRouter } from '../../../utils/routing/Routing';

function attemptParseDate(advertisedIsoDate) {
  let episodeDate = parseISO(advertisedIsoDate);
  if (!isValid(episodeDate)) {
    episodeDate = parse(advertisedIsoDate.substr(5), 'dd MMM yyyy HH:mm:ss xx', new Date());
  }

  return isValid(episodeDate) ? format(episodeDate, 'dd-MM-yyyy') : advertisedIsoDate;
}

function EpisodeItem(props) {
  const [open, setOpen] = useState(false);

  const loadEpisode = () => {
    props.setEpisode({ episode: props.episode });
    props.history.push('/podcast/channel/play');
  };

  if (props.episode.enclosures) {
    const {
      episode: { enclosures },
    } = props;
    [props.episode.enclosure] = enclosures;
  }

  // Channels seem to put any kind of date format in here whether `published` or `isoDate`
  let episodeDate = props.episode.published;
  if (props.episode.isoDate) {
    episodeDate = props.episode.isoDate;
  }

  const potentionallyParsedDate = attemptParseDate(episodeDate);

  return (
    <View>
      <TouchableOpacity onPress={loadEpisode} testID={props.testID}>
        <Text style={[mainStyles.typography.h3]}>{props.episode.title}</Text>
        <Text style={[mainStyles.typography.p]}>{potentionallyParsedDate}</Text>
        {props.episode.content !== '' && props.episode.content !== undefined && (
          <TouchableOpacity onPress={() => setOpen(!open)}>
            {!open && <Text style={mainStyles.typography.h3}>More</Text>}
            {open && <Text style={mainStyles.typography.h3}>Less</Text>}
          </TouchableOpacity>
        )}
      </TouchableOpacity>

      {props.episode.content !== '' && props.episode.enclosure !== undefined && (
        <Collapsible collapsed={!open} style={[styles.collapsibleContainer, mainStyles.margin.bottom.sm]}>
          <Text style={mainStyles.typography.p}>{props.episode.content}</Text>
          {props.episode.enclosure.length && (
            <Text style={[mainStyles.typography.h3, mainStyles.margin.top.xs]}>{durationToTime(+props.episode.enclosure.length)}</Text>
          )}
        </Collapsible>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  collapsibleContainer: {
    backgroundColor: 'rgba(29,17,78,1)',
    padding: mainStyles.APP_PADDING,
  },
});

export default connect(null, {
  setEpisode: actions.podcast.search.setEpisode,
})(withRouter(EpisodeItem));
