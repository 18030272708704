import RSSParser from 'rss-parser';

import { config } from '../../utils/config';

const doCorsPasstroughRequest = (url, jwt) =>
  fetch(`${config.API_URL}/podcasts/proxy`, {
    headers: {
      'x-proxy-url': url,
      authorization: `Bearer ${jwt}`,
    },
  });

export const loadFeedData = async (feedUrl, jwt) => {
  const parser = new RSSParser();

  const res = await fetch(feedUrl, {
    headers: {
      'content-type': 'application/rss+xml',
    },
  }).catch(async () => doCorsPasstroughRequest(feedUrl, jwt));

  if (res.status === 200) {
    return parser.parseString(await res.text());
  } else {
    return [];
  }
};
