import React, { Component } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';

import SeekBar from '../../../components/media/seekbar/Seekbar';
import MediaControl from '../../../containers/media/MediaControl';
import PlayScreenHeader from '../../../containers/media/PlayScreenHeader';
import AppLayout from '../../../layouts/app';
import actions from '../../../store/actions';
import selectors from '../../../store/selectors';
import mainStyles from '../../../styles';
import { fetchPodcastsById, insertPodcast } from '../../../utils/DatabaseHelper';
import analytics from '../../../utils/routing/analytics';
import { withRouter } from '../../../utils/routing/Routing';

class PlayScreen extends Component {
  componentDidMount() {
    if (this.props.episode.enclosures) {
      const {
        episode: { enclosures },
      } = this.props;
      [this.props.episode.enclosure] = enclosures;
    }

    analytics().logEvent('play_podcast', { podcast: this.props.channel.artistName });
    this.props.removeCurrentPodcast();
    if (this.props.tunedTo) {
      this.props.disableTuneIn();
      analytics().logEvent('stop_tunein');
    }
    this.props.setTrack({
      track: {
        artistName: this.props.channel.artistName,
        duration: +this.props.episode.enclosure.length,
        name: this.props.episode.title,
        id: this.props.episode.id,
        url: this.props.episode.enclosure.url,
        album: { images: [{ url: this.props.channel.artworkUrl100 }] },
        provider: 'stream',
      },
      origin: 'podcast',
    });
    this.fetchStoredPodcastByID(this.props.userId, this.props.episode.id, this.props.episode.title);
  }

  async fetchStoredPodcastByID(userId, podcastId, title) {
    try {
      const podcastResult = await fetchPodcastsById(userId, podcastId, title);
      const temp = [];

      if (Platform.OS === 'web') {
        podcastResult.forEach(item => {
          temp.push(item);
        });
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < podcastResult.rows.length; ++i) temp.push(podcastResult.rows.item(i));
      }

      if (temp.length > 0) {
        this.props.setCurrent({
          ...temp,
        });
      } else {
        this.storePodcastData();
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async storePodcastData() {
    if (this.props.episode.enclosures) {
      const {
        episode: { enclosures },
      } = this.props;
      [this.props.episode.enclosure] = enclosures;
    }
    try {
      const dbResult = await insertPodcast(
        this.props.episode.title,
        this.props.episode.id,
        0,
        +this.props.episode.enclosure.length,
        this.props.userId,
      );
      console.log(dbResult);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  togglePlay() {
    analytics().logEvent(this.props.player.statuses.stream === 'playing' ? 'pause_podcast' : 'play_podcast');
    this.props.toggleProviderStatus({ provider: 'stream', status: this.props.player.statuses.stream === 'playing' ? 'paused' : 'playing' });
  }

  render() {
    const { episode, channel, player } = this.props;

    return (
      <AppLayout hideMiniPlayer title="podcast">
        <View style={styles.container}>
          <PlayScreenHeader
            deactiveArtistSearch
            image={{ uri: (episode.itunes && episode.itunes.image) || channel.artworkUrl600 }}
            subtitle={channel.artistName}
            title={episode.title}
          />

          <View style={mainStyles.margin.top.md}>
            <SeekBar fullSeekbar />
          </View>

          <View style={[mainStyles.margin.top.md, mainStyles.margin.bottom.md]}>
            <MediaControl isPlaying={player.statuses.stream === 'playing'} onTogglePlay={() => this.togglePlay()} />
          </View>
        </View>
      </AppLayout>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: mainStyles.APP_PADDING,
  },
});

export default connect(
  state => ({
    ...state,
    channel: selectors.podcast.search.getChannel(state),
    episode: selectors.podcast.search.getEpisode(state),
    player: selectors.media.player.getPlayer(state),
    tunedTo: selectors.tunein.getTunedTo(state),
    userId: selectors.user.getUserId(state),
  }),
  {
    setTrack: actions.media.player.setTrack,
    toggleProviderStatus: actions.media.player.toggleProviderStatus,
    disableTuneIn: actions.tunein.disableTuneIn,
    setCurrent: actions.podcast.storedPodcasts.setCurrent,
    removeCurrentPodcast: actions.podcast.storedPodcasts.removeCurrent,
  },
)(withRouter(PlayScreen));
