import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import actions from '../../../store/actions';
import selectors from '../../../store/selectors';
import { ACTIVITY_CM_KEY } from '../../../styles/consts';
import { sendDetailsToCM } from '../../../utils/api/api';
import analytics from '../../../utils/routing/analytics';
import TextInputRez from '../../app/TextInputRez';

const CreatePlaylistInput = ({ createNewPlaylist, toggleInput, userId, setNewPlaylistName }) => {
  const [playlistName, setPlaylistName] = useState('');
  const savePlaylist = () => {
    analytics().logEvent('create_playlist');
    createNewPlaylist({
      userId,
      name: playlistName,
    });
    const cmActivity = {
      created: new Date(),
      userId,
      playlistAdded: true,
    };
    sendDetailsToCM(cmActivity, ACTIVITY_CM_KEY);
    setNewPlaylistName(playlistName);
    setPlaylistName('');

    toggleInput();
  };

  return (
    <TextInputRez
      buttonLabel="Ok"
      onButtonPress={() => savePlaylist()}
      onChangeText={setPlaylistName}
      placeholder="Your playlist name..."
      testID="createplaylist-input"
      testIDButton="createplaylistconfirm-button"
      value={playlistName}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    userId: selectors.user.getUserId(state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        createNewPlaylist: actions.media.playlists.createNewPlaylist,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePlaylistInput);
