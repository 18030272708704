import React from 'react';
import { connect } from 'react-redux';

import PlaylistButton from '../../../components/media/playlist/PlaylistButton';
import Playlists from '../../../containers/media/Playlists';
import AppLayout from '../../../layouts/app';
import selectors from '../../../store/selectors';
import { withRouter } from '../../../utils/routing/Routing';

const PlaylistsScreen = ({ history }) => {
  const openPlaylist = id => history.push(`/playlists/${id}`);

  return (
    <AppLayout title="playlists">
      <Playlists onClickPlaylist={playlist => openPlaylist(playlist.shortId)} playlistButton={PlaylistButton} />
    </AppLayout>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    userId: selectors.user.getUserId(state),
  };
};

export default connect(mapStateToProps)(withRouter(PlaylistsScreen));
