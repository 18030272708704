import React, { useEffect } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import actions from '../../store/actions';
import mainStyles from '../../styles';

function AlertWindow(props) {
  const insets = useSafeArea();
  const closeAlert = () => {
    props.setAlert(null);
  };

  const { message, type } = props.alert;
  const alertStyles = [styles.alert];

  switch (type) {
    case 'error':
      alertStyles.push(styles.alertError);
      break;
    case 'warning':
      alertStyles.push(styles.alertWarning);
      break;
    case 'success':
      alertStyles.push(styles.alertSuccess);
      break;
    default:
      console.log('Alert type not supported');
  }

  useEffect(() => {
    setTimeout(() => {
      closeAlert();
    }, 5000);
  }, []);

  return (
    <View style={[alertStyles, { marginTop: insets.top }]}>
      <Text style={[mainStyles.p, mainStyles.margin.top.md, mainStyles.margin.bottom.md, styles.alertText]} testID="alert">
        {message}
      </Text>
      <TouchableOpacity onPress={closeAlert} style={styles.closeIconContainer} testID="closealert-button">
        <Image resizeMode="contain" source={require('../../assets/icons/close/close_darkpurple.png')} style={mainStyles.icon} />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  alert: {
    position: 'absolute',
    left: 0,
    right: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    minHeight: 50,
    paddingLeft: mainStyles.APP_PADDING,
    backgroundColor: 'white',
    borderBottomWidth: 2,
    zIndex: 2,
  },
  alertError: {
    borderBottomColor: mainStyles.colours.red,
  },
  alertWarning: {
    borderBottomColor: mainStyles.colours.orange,
  },
  alertSuccess: {
    borderBottomColor: mainStyles.colours.green,
  },
  alertText: {
    flexShrink: 1,
  },
  closeIconContainer: {
    flexBasis: 35,
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingRight: mainStyles.APP_PADDING,
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        setAlert: actions.app.setAlert,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertWindow);
