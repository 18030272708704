import axios from 'axios';

import {
  ALL_NOTIFICATIONS,
  LIVE_NOTIFICATION,
  NEW_CONNECTION_NOTIFICATION,
  NEW_MESSAGE_NOTIFICATION,
  TUNED_IN_NOTIFICATION,
} from '../../styles/consts';
import { config } from '../config';

export const getRecommendations = ({ services, jwt, cancelToken }) =>
  axios(
    {
      method: 'POST',
      url: `${config.API_URL}/recommendations/`,
      data: {
        services,
      },
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
    {
      cancelToken,
    },
  ).then(r => r.data);

export const getPlaylist = ({ shortId, jwt, tracksSize, tracksFrom }) =>
  axios({
    method: 'GET',
    url: `${config.API_URL}/playlist/${shortId}`,
    params: {
      tracksSize,
      tracksFrom,
    },
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(r => r.data);

export const getArtist = ({ artistId, provider, token, jwt }) => {
  return axios({
    method: 'GET',
    url: `${config.API_URL}/artist/${artistId}`,
    params: {
      provider,
    },
    headers: {
      Authorization: `Bearer ${jwt}`,
      token,
    },
  }).then(response => {
    return response.data;
  });
};

export const artistSearch = async ({ query, providers, jwt }) => {
  let data = { providers };
  data = query ? { ...data, query } : data;
  return axios({
    method: 'POST',
    url: `${config.API_URL}/search/artist`,
    data,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(response => {
    return response.data;
  });
};

export const getYoutubeList = query => {
  return axios({
    method: 'GET',
    url: 'https://youtube.googleapis.com/youtube/v3/search/',
    params: {
      part: 'snippet',
      maxResults: 30,
      q: query,
      type: 'video',
      key: config.YOUTUBE_API_KEY,
    },
  }).then(response => {
    return response.data;
  });
};

export const getSpotifyAccountInfo = token => {
  return axios({
    method: 'GET',
    url: 'https://api.spotify.com/v1/me',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(response => {
    return response;
  });
};

export const getAlbum = ({ albumId, provider, token, jwt, countryCode }) => {
  return axios({
    method: 'GET',
    url: `${config.API_URL}/album/${albumId}`,
    params: {
      provider,
      countryCode,
    },
    headers: {
      Authorization: `Bearer ${jwt}`,
      token,
    },
  }).then(response => {
    return response.data;
  });
};

export const getFavoriteStations = ({ jwt }) => {
  return axios({
    method: 'GET',
    url: `${config.API_URL}/station/favorites`,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(r => r);
};

export const addFavoriteStation = (jwt, stationKey) => {
  return axios({
    method: 'POST',
    url: `${config.API_URL}/station/favorites`,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    data: {
      stationUuid: stationKey,
    },
  }).then(r => r);
};

export const deleteFavoriteStation = (jwt, stationKey) => {
  return axios({
    method: 'DELETE',
    url: `${config.API_URL}/station/favorites/${stationKey}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(r => r);
};

export const goLive = jwt => {
  return axios({
    method: 'GET',
    url: `${config.API_URL}/user/going-live`,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(r => r);
};

export const signup = ({ email, password, username, displayName, firstName, lastName }) => {
  return axios({
    method: 'POST',
    url: `${config.API_URL}/user/`,
    data: {
      email,
      password,
      username,
      displayName,
      firstName,
      lastName,
    },
  }).then(response => {
    return response.data;
  });
};

export const additionalInfo = data => {
  return axios({
    method: 'POST',
    url: `${config.API_URL}/user/add-additional-info`,
    data,
  }).then(response => {
    return response.data;
  });
};

export const signin = ({ username, password }) => {
  return axios({
    method: 'POST',
    url: `${config.API_URL}/user/login`,
    data: {
      username,
      password,
    },
  }).then(response => {
    return response.data;
  });
};

export const logout = (uuid, jwt) => {
  return axios({
    method: 'GET',
    url: `${config.API_URL}/user/logout/${uuid}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(response => {
    return response.data;
  });
};

export const deleteAccount = jwt => {
  return axios({
    method: 'DELETE',
    url: `${config.API_URL}/user/delete`,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(response => {
    return response.data;
  });
};

export const userNameSearch = (jwt, limit, userName, pageNo) => {
  return axios({
    method: 'GET',
    url: `${config.API_URL}/user/search/`,
    params: {
      key: userName,
      page: pageNo,
      limit,
    },
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(response => {
    return response.data;
  });
};

export const getTopUsers = jwt => {
  return axios({
    method: 'GET',
    url: `${config.API_URL}/user/top-users`,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(response => {
    return response.data;
  });
};

export const joinWaitinglist = ({ email }) => {
  return axios({
    method: 'POST',
    url: `${config.API_URL}/user/join-waitinglist`,
    data: {
      email,
    },
  }).then(response => {
    return response.data;
  });
};

export const editPlaylist = ({ jwt, data, playlistId }) => {
  return axios({
    method: 'PUT',
    url: `${config.API_URL}/playlist/${playlistId}`,
    data,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(response => {
    return response.data;
  });
};

export const updateProviderImportedPlaylists = (jwt, token, provider) => {
  return axios({
    method: 'POST',
    url: `${config.API_URL}/playlist/import/${provider}`,
    data: { token },
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(response => {
    return response.data;
  });
};

export const deletePlaylist = ({ jwt, playlist }) => {
  return axios({
    method: 'DELETE',
    url: `${config.API_URL}/playlist/${playlist}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(response => {
    return response.data;
  });
};

export const deletePlaylistTrack = ({ jwt, playlistId, trackId }) => {
  return axios({
    method: 'DELETE',
    url: `${config.API_URL}/playlist/${playlistId}/${trackId}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(response => {
    return response.data;
  });
};

export const addTrackToPlaylist = ({ jwt, trackId, provider, playlistId, token }) => {
  return axios({
    method: 'PUT',
    url: `${config.API_URL}/playlist/track`,
    data: { trackId, provider, playlistId, token },
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(response => {
    return response.data;
  });
};

export const search = async ({ query, isrc, providers, jwt }) => {
  let data = { providers };
  data = query ? { ...data, query } : data;
  data = isrc ? { ...data, isrc } : data;

  return axios({
    method: 'POST',
    url: `${config.API_URL}/search/music`,
    data,
    timeout: 20000,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
    .then(response => {
      const responseData = response.data;
      responseData.code = 200;
      return responseData;
    })
    .catch(err => {
      const errorData = err;
      errorData.code = 598;
      return errorData;
    });
};

export const addSubscription = async (sub, jwt) => {
  return axios({
    method: 'POST',
    url: `${config.API_URL}/podcasts/subscription`,
    data: sub,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
};

export const deleteSubscription = async (sub, jwt) => {
  return axios({
    method: 'DELETE',
    url: `${config.API_URL}/podcasts/subscription/${sub.uuid}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
};

export const passwordReset = async ({ username }) => {
  return axios({
    method: 'POST',
    url: `${config.API_URL}/user/reset-password`,
    data: {
      username,
    },
  });
};

export const setPassword = async ({ password, requestId, userId }) => {
  return axios({
    method: 'PUT',
    url: `${config.API_URL}/user/reset-password`,
    data: {
      password,
      requestId,
      userId,
    },
  });
};

export const uploadProfilePicture = async ({ picture, jwt }) => {
  return axios({
    method: 'POST',
    url: `${config.API_URL}/user/profile-picture`,
    data: {
      picture,
    },
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(response => {
    return response.data;
  });
};
export const uploadPlaylisPicture = async ({ playlistId, picture, jwt }) => {
  return axios({
    method: 'POST',
    url: `${config.API_URL}/playlist/upload-image`,
    data: {
      playlistId,
      picture,
    },
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(response => {
    return response.data;
  });
};

export const getUserProfile = async ({ jwt, uuid, fcmToken, platform }) => {
  let header = {
    Authorization: `Bearer ${jwt}`,
  };

  if (fcmToken) {
    header = {
      Authorization: `Bearer ${jwt}`,
      'device-type': platform,
      'device-uuid': uuid,
      'device-token': fcmToken,
    };
  }
  return axios({
    method: 'GET',
    url: `${config.API_URL}/user/profile`,
    headers: header,
  }).then(response => {
    return response.data;
  });
};

export const notificationToggle = async ({ jwt, deviceUuid, toggle, type }) => {
  let param = {
    deviceUuid,
  };

  if (type === ALL_NOTIFICATIONS) {
    param = {
      deviceUuid,
      all: toggle,
    };
  }

  if (type === LIVE_NOTIFICATION) {
    param = {
      deviceUuid,
      live: toggle,
    };
  }

  if (type === NEW_CONNECTION_NOTIFICATION) {
    param = {
      deviceUuid,
      follow: toggle,
    };
  }

  if (type === NEW_MESSAGE_NOTIFICATION) {
    param = {
      deviceUuid,
      newMessage: toggle,
    };
  }

  if (type === TUNED_IN_NOTIFICATION) {
    param = {
      deviceUuid,
      tuneIn: toggle,
    };
  }

  return axios({
    method: 'PUT',
    url: `${config.API_URL}/notification/settings`,
    params: param,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(response => {
    return response.data;
  });
};

export const getToggleSettingValue = (jwt, deviceUuid) => {
  return axios({
    method: 'GET',
    url: `${config.API_URL}/notification/settings/${deviceUuid}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(response => {
    return response.data;
  });
};

export const sendMessageNotification = (jwt, room) => {
  return axios({
    method: 'POST',
    url: `${config.API_URL}/user/message-notification`,
    data: {
      room,
    },
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(response => {
    return response.data;
  });
};

export const sendTuneInNotification = (jwt, username) => {
  return axios({
    method: 'POST',
    url: `${config.API_URL}/user/tune-in`,
    data: {
      username,
    },
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(response => {
    return response.data;
  });
};

export const getPublicUserProfile = async ({ username }) => {
  return axios({
    method: 'GET',
    url: `${config.API_URL}/user/${username}/profile`,
  }).then(response => {
    return response.data;
  });
};

export const followUser = async ({ jwt, username }) => {
  return axios({
    method: 'POST',
    url: `${config.API_URL}/user/${username}/follow`,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(response => {
    return response.data;
  });
};

export const unfollowUser = async ({ jwt, username }) => {
  return axios({
    method: 'DELETE',
    url: `${config.API_URL}/user/${username}/follow`,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(response => {
    return response.data;
  });
};

export const registerMessagingToken = async ({ token, type, jwt }) => {
  return axios({
    method: 'POST',
    url: `${config.API_URL}/user/messagingToken`,
    data: {
      token,
      type,
    },
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(response => {
    return response.data;
  });
};

export const getSharePlaylist = ({ shortId, jwt }) =>
  axios({
    method: 'GET',
    url: `${config.API_URL}/playlist/shared/${shortId}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(r => r.data);

export const getShareTrackInfo = async ({ trackId, provider, token, jwt }) => {
  return axios({
    method: 'POST',
    url: `${config.API_URL}/playlist/get-track`,
    data: {
      trackId,
      provider,
      token,
    },
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(response => {
    return response.data;
  });
};

export const verifyEmail = async ({ userId, otp }) => {
  return axios({
    method: 'GET',
    url: `${config.API_URL}/user/emailVerify?userId=${userId}&otp=${otp}`,
  }).then(response => {
    return response.data;
  });
};

export const resendOtp = ({ email }) => {
  return axios({
    method: 'POST',
    url: `${config.API_URL}/user/resendOtp`,
    data: {
      email,
    },
  }).then(response => {
    return response.data;
  });
};

export const saveSharePlaylist = ({ playlistId, jwt }) => {
  return axios({
    method: 'GET',
    url: `${config.API_URL}/playlist/clone/${playlistId}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(response => {
    return response.data;
  });
};

export const getRadio = (byOption, searchValue, limit, pageNumber) => {
  return axios({
    method: 'GET',
    url: `${config.RADIO_BASE_SERVER_URL}/${byOption.toLowerCase()}/${searchValue.toLowerCase()}?limit=${limit}&offset=${pageNumber}&hidebroken=true`,
    headers: {
      'user-agent': 'My Music App',
    },
  }).then(response => {
    return response.data;
  });
};

export const sendDetailsToCM = (data, eventKey) => {
  return axios({
    method: 'POST',
    url: `${config.API_URL}/cm-analytics/${eventKey}`,
    data,
  }).then(response => {
    return response.data;
  });
};

export const getCountryOrigin = () => {
  return axios({
    method: 'GET',
    url: 'https://ipapi.co/json/',
  }).then(response => {
    return response.data;
  });
};
