import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import { WebView } from 'react-native-webview';

import FullScreenLayout from '../layouts/fullscreen';
import { withRouter } from '../utils/routing/Routing';

const PrivacyPolicyScreen = ({ history }) => {
  useEffect(() => {
    if (Platform.OS === 'web') {
      window.open('https://www.rezzonation.com/privacy-policy/');
      history.goBack();
    }
  }, []);
  return (
    <FullScreenLayout noPadding title="Privacy Policy">
      <WebView source={{ uri: 'https://www.rezzonation.com/privacy-policy/' }} />
    </FullScreenLayout>
  );
};

export default withRouter(PrivacyPolicyScreen);
