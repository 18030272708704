import types from '../../types';

const initialState = {
  searches: [],
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case types.media.music.STORE_RECENT_SEARCH:
      const existingSearchIndex = state.searches.findIndex(e => payload.query.query.includes(e.query));

      if (existingSearchIndex >= 0) {
        state.searches.splice(existingSearchIndex, 1);
      }

      return {
        ...state,
        searches: [payload.query, ...state.searches.splice(0, 4)],
      };
    case types.user.LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
