export const normalizeDeezerTrack = track => ({
  id: track.id,
  provider: 'deezer',
  name: track.title,
  artistName: track.artist.name,
  album: normalizeDeezerAlbum(track.album),
  duration: track.album.duration * 1000,
  discNumber: track.disk_number,
  trackNumber: track.track_position,
  explicit: track.explicit_lyrics,
  isrc: track.isrc,
  type: 'track',
});

export const normalizeDeezerAlbum = album => ({
  id: album.id ? album.id.toString() : undefined,
  provider: 'deezer',
  name: album.title,
  artistName: album.artist ? album.artist.name : undefined,
  images: [
    {
      url: album.cover_xl,
      width: 1000,
      height: 1000,
    },
    {
      url: album.cover_big,
      width: 500,
      height: 500,
    },
    {
      url: album.cover_medium,
      width: 250,
      height: 250,
    },
    {
      url: album.cover_small,
      width: 56,
      height: 56,
    },
  ],
  releaseDate: album.release_date,
  type: 'album',
});
