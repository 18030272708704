import types from '../../types';

const initialState = {
  auth: {
    isAuthorized: false,
  },
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case types.providers.deezer.SET_AUTH_TOKEN:
      return {
        ...state,
        auth: {
          isAuthorized: payload.token !== undefined && payload.token !== null,
          expires: payload.expires,
          token: payload.token,
          created: new Date(),
          userId: payload.userId,
        },
      };
    case types.user.LOGOUT:
      return {
        ...state,
        auth: {
          isAuthorized: false,
        },
      };
    default:
      return state;
  }
};
