import { combineReducers } from 'redux';

import search from './search';
import storedPodcasts from './storedPodcasts';
import subscriptions from './subscriptions';

export default combineReducers({
  search,
  subscriptions,
  storedPodcasts,
});
