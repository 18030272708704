import { Platform } from 'react-native';
import { eventChannel } from 'redux-saga';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { ACTIVITY_CM_KEY, TUNED_OUT_TYPE } from '../../styles/consts';
import { goLive, sendDetailsToCM } from '../../utils/api/api';
import { config } from '../../utils/config';
import { updatePodcastPosition } from '../../utils/DatabaseHelper';
import { getDurationFromProvider } from '../../utils/media/position';
import analytics from '../../utils/routing/analytics';
// eslint-disable-next-line import/named
import { getConnectedProvider } from '../../utils/version';
import { getPauseTime, getPlayer, getPlayTime } from '../selectors/media/player';
import { getHostUsersCount, getTunedTo, getUserPlayingStatus } from '../selectors/tunein';
import { getJwt, getUserId } from '../selectors/user';
import types from '../types';

// These providers don't have their own SET_STATUS mechanism/callbacks and must be set manually to 'playing' when loading a track.
const MANUAL_SET_STATUS_PROVIDERS = ['stream', 'youtube'];
let pubChannel = null;
let tuneInPayLoad = null;

function startPositionPublish() {
  return eventChannel(emitter => {
    const iv = setInterval(() => {
      emitter('publish');
    }, 5000);
    return () => {
      clearInterval(iv);
    };
  });
}

function publishToTuneIn({ payload }) {
  if (payload.track && global.xmpp) {
    global.xmpp.publishIsStreaming();
    global.xmpp.publishPlayingStatus({
      status: payload.track,
      position: payload.postion,
      duration: payload.duration,
      dateStartedTrack: payload.dateStartedTrack,
      hostUserTuneInCount: payload.hostUsersCount,
    });
  }
}

async function updatePodcastItem({ payload }, userId) {
  if (payload.track) {
    const dbResult = await updatePodcastPosition(userId, payload.track.id, payload.postion, payload.track.name);
    console.log(dbResult);
  }
}

function* publishPosition() {
  const player = yield select(state => getPlayer(state));
  const userId = yield select(state => getUserId(state));
  const getHostUserCount = yield select(state => getHostUsersCount(state));
  const { provider } = player.track;

  const duration = yield call(getDurationFromProvider, provider);
  const payload = {
    track: player.track,
    duration: duration.duration,
    postion: duration.position,
    dateStartedTrack: player.dateStartedTrack,
    hostUsersCount: getHostUserCount,
  };
  publishToTuneIn({ payload });
  if (Platform.OS === 'web' && provider === 'stream' && player.track) {
    updatePodcastItem({ payload }, userId);
  } else if (provider === 'stream' && player.track.id) updatePodcastItem({ payload }, userId);
}

function* publishTrackTuneIn() {
  if (pubChannel) pubChannel.close();
  pubChannel = yield call(startPositionPublish);
  yield takeEvery(pubChannel, publishPosition);
}

function* setPauseTime() {
  const pauseDate = Date.now();
  yield put({ type: types.media.player.SET_PAUSETIME, payload: pauseDate });
}

// get differnce between pause time and play time and if user is in paused state for 5min then call again push notification live api
function* handleGoLiveApi() {
  const playDate = Date.now();
  yield put({ type: types.media.player.SET_PLAYTIME, payload: playDate });
  const playTime = yield select(getPlayTime);
  const pauseTime = yield select(getPauseTime);
  if (playTime - pauseTime <= 300000) {
    yield call(setPauseTime);
  }
  if (playTime - pauseTime > 300000) {
    const jwt = yield select(getJwt);
    goLive(jwt);
    yield call(setPauseTime);
  }
}

function* setProviderStatus(provider, status) {
  if (status === 'playing') {
    yield call(handleGoLiveApi);
  }
  if (status !== 'playing') {
    const tunedInTo = yield select(state => getTunedTo(state));
    const tunedInNumber = yield select(state => getHostUsersCount(state));
    yield call(setPauseTime);
    analytics().logEvent('stop_playing', { provider });
    if (pubChannel) pubChannel.close();
    pubChannel = null;
    global.xmpp.publishStoppedStreaming();
    if (tunedInTo) {
      global.xmpp.sendMessage({
        to: `${tunedInTo.toLowerCase()}@conference.${config.XMPP_DOMAIN}`,
        type: TUNED_OUT_TYPE,
        message: { count: tunedInNumber + 1, hostName: tunedInTo },
      });
    } else {
      yield put({ type: types.tunein.RESET_TUNE_IN_NUMBER });
    }
  }

  if (status === 'playing' && pubChannel === null) yield fork(publishTrackTuneIn);

  switch (provider) {
    case 'apple':
      status === 'playing' ? global.apple.resume() : global.apple.pause();
      break;
    case 'deezer':
      status === 'playing' ? global.deezer.resume() : global.deezer.pause();
      break;
    case 'spotify':
      status === 'playing' ? global.spotify.resumePlayer() : global.spotify.pausePlayer();
      break;
    case 'stream':
      if (status !== 'playing') analytics().logEvent('stop_radio');
      yield put({ type: types.media.player.SET_PROVIDER_STATUS, payload: { provider, status } });
      break;
    case 'youtube':
      yield put({ type: types.media.player.SET_PROVIDER_STATUS, payload: { provider, status } });
      break;
    default:
      console.warn('toggleMusicPlayer: unknown provider');
  }
}

function* pauseOtherPlayersIfNeededExcept(provider) {
  const player = yield select(state => state.media.player);

  // eslint-disable-next-line no-restricted-syntax
  for (const statusProvider of Object.keys(player.statuses)) {
    if (statusProvider !== provider && player.statuses[statusProvider] === 'playing') {
      yield fork(setProviderStatus, statusProvider, 'paused');
    }
  }
}

function* setTrack({ payload }) {
  const playTime = yield select(getPlayTime);
  const pauseTime = yield select(getPauseTime);
  const userId = yield select(state => getUserId(state));
  if (payload.track.provider === 'stream' || payload.track.provider === 'youtube') {
    if (Platform.OS === 'web' && payload.origin === 'podcast') {
      const cmActivity = {
        created: new Date(),
        userId,
        // eslint-disable-next-line no-inline-comments
        newSongPlaying: payload.track.name, // sending name as web podcast doesn't provide id
        origin: payload.origin,
      };
      yield sendDetailsToCM(cmActivity, ACTIVITY_CM_KEY);
    } else if (payload.origin === 'radio') {
      const cmActivity = {
        created: new Date(),
        userId,
        newSongPlaying: payload.track.stationuuid,
        origin: payload.origin,
      };
      yield sendDetailsToCM(cmActivity, ACTIVITY_CM_KEY);
    } else {
      const cmActivity = {
        created: new Date(),
        userId,
        newSongPlaying: payload.track.id,
        origin: payload.origin,
      };
      yield sendDetailsToCM(cmActivity, ACTIVITY_CM_KEY);
    }
  }
  if (playTime === null) {
    const jwt = yield select(getJwt);
    goLive(jwt);
  }
  if (pauseTime === null) {
    yield call(setPauseTime);
  }
  yield call(handleGoLiveApi);
  if (Platform.OS !== 'web') yield call(getConnectedProvider, payload.track.provider);
  if (MANUAL_SET_STATUS_PROVIDERS.includes(payload.track.provider)) {
    yield put({ type: types.media.player.SET_PROVIDER_STATUS, payload: { provider: payload.track.provider, status: 'playing' } });
  }

  yield fork(pauseOtherPlayersIfNeededExcept, payload.track.provider);
}

function* setTrackMusic({ payload }) {
  if (Platform.OS !== 'web') yield call(getConnectedProvider, payload.track.provider);
  if (payload.track && (payload.track.status === 'playing' || payload.track.status === 'waiting')) {
    yield put({ type: types.media.player.SET_TRACK, payload });
  }
}

function* newTuneInTrack({ payload }) {
  const tunedTo = yield select(state => state.tunein.tunedTo);

  if (payload.track && payload.from === tunedTo) {
    if (tuneInPayLoad === null) {
      tuneInPayLoad = payload;
    }
    if (tuneInPayLoad.track.trackStartTime !== payload.track.trackStartTime) {
      tuneInPayLoad = payload;
      if (MANUAL_SET_STATUS_PROVIDERS.includes(payload.track.provider)) {
        if (payload.track.artistName === 'Radio') {
          yield put({ type: types.media.player.SET_TRACK, payload: { track: payload.track, origin: 'radio' } });
        } else {
          yield put({ type: types.media.player.SET_TRACK, payload: { track: payload.track, origin: 'podcast' } });
        }
      } else {
        yield put({ type: types.media.music.PLAY_TRACK, payload });
      }
    }
  }

  if (payload.track) {
    yield put({ type: types.tunein.UPDATE_PLAYING_STATUS, payload: { user: payload.from, track: payload.track } });
  }
}

function* startTuneIn(action) {
  const playingStatus = yield select(state => getUserPlayingStatus(state, action.payload.user));
  if (playingStatus && MANUAL_SET_STATUS_PROVIDERS.includes(playingStatus.provider)) {
    if (playingStatus.artistName === 'Radio') {
      yield put({ type: types.media.player.SET_TRACK, payload: { track: playingStatus, origin: 'radio' } });
    } else {
      yield put({ type: types.media.player.SET_TRACK, payload: { track: playingStatus, origin: 'podcast' } });
    }
  } else if (playingStatus && playingStatus.id) yield put({ type: types.media.music.PLAY_TRACK, payload: { track: playingStatus } });
}

function* stopTuneIn() {
  const player = yield select(state => getPlayer(state));
  const { provider } = player.track;
  yield put({ type: types.media.player.TOGGLE_PROVIDER_STATUS, payload: { provider, status: 'stopped' } });
}

function* rePlayDeezerTrack() {
  const player = yield select(state => getPlayer(state));
  if (player && player.track) yield put({ type: types.media.music.PLAY_TRACK, payload: { track: player.track } });
}

export function* watchPlayerActions() {
  yield takeEvery(types.media.player.SET_TRACK, setTrack);
  yield takeEvery(types.media.player.SET_TRACK, publishTrackTuneIn);
  yield takeEvery(types.tunein.NEW_TRACK_RECEIVED, newTuneInTrack);
  yield takeEvery(types.media.music.LOAD_NEW_MUSIC_TRACK, setTrackMusic);
  yield takeEvery(types.tunein.ENABLE_TUNE_IN, startTuneIn);
  yield takeEvery(types.media.player.TOGGLE_PROVIDER_STATUS, ({ payload }) => setProviderStatus(payload.provider, payload.status));
  yield takeEvery(types.tunein.DISABLE_TUNE_IN, stopTuneIn);
  yield takeEvery(types.providers.deezer.REPLAY_TRACK, rePlayDeezerTrack);
}
