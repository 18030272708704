import React from 'react';
import { Platform, ScrollView, StyleSheet } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { useSafeArea } from 'react-native-safe-area-context';
import { connect } from 'react-redux';

import AlertWindow from '../components/app/AlertWindow';
import AllPopups from '../components/app/AllPopups';
import DetectWebPopup from '../components/app/DetectWebPopup';
import NavBarRez from '../containers/app/NavBarRez';
import BottomNavigator from '../containers/footer/BottomNavigator';
import CMSuggestionModal from '../containers/media/CMSuggestionModal';
import MusicMiniPlayer from '../containers/media/MusicMiniPlayer';
import selectors from '../store/selectors';
import mainStyles from '../styles';
import { getOS } from '../utils/DetectOS';
import { withRouter } from '../utils/routing/Routing';

const AppLayout = ({ player, location, moreOptionsHandler, title, hideMiniPlayer, children, alert, popup, jwt, webPopup, backButton = true }) => {
  const PLAYER_STATES_SHOWING = ['playing', 'paused', 'waiting', 'completed', 'ended', 'loading'];
  const insets = useSafeArea();

  const shouldShowPlayer = () =>
    player.track.provider !== 'youtube' &&
    !hideMiniPlayer &&
    player &&
    player.track &&
    Object.keys(player.statuses).find(provider => PLAYER_STATES_SHOWING.includes(player.statuses[provider]));

  return (
    <LinearGradient colors={['rgba(60,33,91,1)', 'rgba(30,18,84,1)']} style={[styles.overlay, { paddingTop: insets.top }]}>
      {alert && !!alert.message && <AlertWindow alert={alert} />}
      {Platform.OS === 'web' && jwt && (getOS() === 'Android' || getOS() === 'iOS') && webPopup.validateDownloadPopup && (
        <DetectWebPopup os={getOS()} />
      )}
      <NavBarRez backButton={backButton} moreOptionsHandler={moreOptionsHandler} title={title} />
      {popup && <AllPopups />}
      <ScrollView contentContainerStyle={{ flexGrow: 1 }} style={styles.content}>
        {children}
      </ScrollView>
      {shouldShowPlayer() && <MusicMiniPlayer />}
      <BottomNavigator location={location} />
      <CMSuggestionModal />
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
    paddingTop: mainStyles.APP_PADDING,
    paddingLeft: mainStyles.APP_PADDING,
    paddingRight: mainStyles.APP_PADDING,
  },
  overlay: {
    flex: 1,
  },
});
export default connect((state, ownProps) => ({
  ...ownProps,
  player: selectors.media.player.getPlayer(state),
  alert: selectors.app.getAlert(state),
  popup: selectors.app.getPopup(state),
  jwt: selectors.user.getJwt(state),
  webPopup: selectors.app.getWebDownloadPopup(state),
}))(withRouter(AppLayout));
