import { differenceInSeconds, isBefore, parseISO, toDate } from 'date-fns';
import { Platform } from 'react-native';

import types from '../../types';

const initialState = {
  auth: {
    isAuthorized: false,
  },
  deviceId: undefined,
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case types.providers.spotify.RESET_AUTH_TOKEN:
      return {
        ...state,
        auth: {
          isAuthorized: false,
        },
      };
    case types.providers.spotify.ENABLE_AUTH:
      return {
        ...state,
        auth: {
          isAuthorized: true,
        },
      };
    case types.providers.spotify.SET_AUTH_TOKEN:
      const now = new Date();
      let { expires } = payload;
      if (Platform.OS === 'web') {
        expires = parseISO(expires);
      }
      const expired = isBefore(toDate(expires), now);
      const isAuthorized = !expired && payload.token !== undefined && payload.token !== null;
      return {
        ...state,
        auth: {
          isAuthorized,
          expires: toDate(expires),
          token: payload.token,
          refreshToken: payload.refreshToken,
          expiresIn: payload.expiresIn > 3600 && payload.expires < 10000 ? differenceInSeconds(now, toDate(payload.expiresIn)) : payload.expiresIn,
          created: now,
        },
      };
    case types.providers.spotify.SET_DEVICE_ID:
      return {
        ...state,
        deviceId: payload.deviceId,
      };
    case types.user.LOGOUT:
      return {
        ...state,
        auth: {
          isAuthorized: false,
        },
        deviceId: undefined,
      };
    default:
      return state;
  }
};
