import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Alert, StyleSheet, Text, View } from 'react-native';
import { connect } from 'react-redux';

import ButtonRez from '../../../components/app/ButtonRez';
import AppLayout from '../../../layouts/app';
import actions from '../../../store/actions';
import selectors from '../../../store/selectors';
import mainStyles from '../../../styles';
import { addSubscription, deleteSubscription } from '../../../utils/api/api';
import analytics from '../../../utils/routing/analytics';
import { withRouter } from '../../../utils/routing/Routing';
import ChannelHeader from '../components/ChannelHeader';
import { loadFeedData } from '../rss';
import EpisodeItem from './EpisodeItem';

function EpisodesScreen(props) {
  const [description, setDescription] = useState('loading...');
  const [episodes, setEpisodes] = useState([]);
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadFeed();

    const foundSubscription = props.subscriptions.find(sub => sub.feedUrl === props.channel.feedUrl);
    setSubscription(foundSubscription);
  }, []);

  const loadFeed = async () => {
    setLoading(true);
    const feedBody = await loadFeedData(props.channel.feedUrl, props.jwt).catch(e => console.warn('podcast/loadFeed: fetch catch', e));
    setLoading(false);

    if (!feedBody) {
      props.setAlert({ message: 'Unable to retrieve list of episodes.', type: 'error' });
      return;
    }
    if (Array.isArray(feedBody) && feedBody.length < 1) {
      props.setAlert({ message: 'Cannot find episodes for this podcast.', type: 'warning' });
      return;
    }

    setDescription(feedBody.description);
    setEpisodes(feedBody.items.slice(0, 25));
  };

  const playAllEpisodes = () => {
    props.storeSetEpisode({ episode: episodes[0] });
    props.history.push('/podcast/channel/play');
  };

  const subOrUnsubscribe = () => {
    if (subscription) {
      analytics().logEvent('unsubscribe_podcast');
      removeSubscriptionRemote(subscription);
    } else {
      analytics().logEvent('subscribe_podcast');
      addSubscriptionRemote({
        artistId: props.channel.artistId,
        artistName: props.channel.artistName,
        artworkUrl100: props.channel.artworkUrl100,
        artworkUrl600: props.channel.artworkUrl600,
        collectionId: props.channel.collectionId,
        feedUrl: props.channel.feedUrl,
        genres: props.channel.genres,
        trackName: props.channel.trackName,
        wrapperType: props.channel.wrapperType,
      });
    }
  };

  const removeSubscriptionRemote = async sub => {
    const { jwt } = props;

    const res = await deleteSubscription(sub, jwt);
    if (res.status === 201) {
      props.storeRemoveSubscription({ subscription: props.channel });
      setSubscription(null);
    }
  };

  const addSubscriptionRemote = async sub => {
    const { jwt } = props;

    const res = await addSubscription(sub, jwt);
    if (res.status === 201) {
      props.storeAddSubscription({ subscription: res.data });
      setSubscription(res.data);
    }
  };
  const showConfirmationAlert = () => {
    const alertTitle = subscription ? 'Unsubcription' : 'Subcription';
    const alertMessage = subscription ? 'Do you want to unsubscribe from this podcast?' : 'Do you want to subscribe to this podcast?';
    const buttonTitle = subscription ? 'Unsubscribe' : 'Subscribe';

    Alert.alert(
      alertTitle,
      alertMessage,
      [
        {
          text: 'Cancel',
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        },
        {
          text: buttonTitle,
          onPress: subOrUnsubscribe,
        },
      ],
      { cancelable: false },
    );
  };
  return (
    <AppLayout title="podcast">
      <View style={mainStyles.margin.bottom.md}>
        <ChannelHeader channel={props.channel} description={description} />
      </View>

      <View style={[styles.actionContainer, mainStyles.margin.bottom.md]}>
        <View style={mainStyles.margin.right.sm}>
          <ButtonRez inline label="Play" onPress={playAllEpisodes} />
        </View>
        <ButtonRez
          inline
          label={subscription ? 'Unsubscribe' : 'Subscribe'}
          onPress={showConfirmationAlert}
          secondary={!!subscription}
          testID="podcastsubscribe-button"
        />
      </View>

      {loading && (
        <View style={styles.loading}>
          <ActivityIndicator color="rgba(255,142,12,1)" size="large" />
        </View>
      )}

      {episodes.length > 0 && (
        <View style={styles.episodesContainer}>
          <Text style={mainStyles.typography.h1}>Episodes</Text>
          <View>
            {episodes.map((episode, index) => (
              <View key={Math.random()} style={mainStyles.margin.bottom.sm} testID={`podcastepisode_${index}`}>
                <EpisodeItem episode={episode} />
              </View>
            ))}
          </View>
        </View>
      )}
    </AppLayout>
  );
}

const styles = StyleSheet.create({
  episodesContainer: {
    flex: 2,
  },
  actionContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  actionButton: {
    width: 100,
    padding: 10,
  },
  loading: {
    marginTop: 20,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default connect(
  state => ({
    ...state,
    subscriptions: selectors.podcast.subscriptions.getSubscriptions(state),
    channel: selectors.podcast.search.getChannel(state),
    feed: selectors.podcast.search.getFeed(state),
    jwt: selectors.user.getJwt(state),
  }),
  {
    storeSetEpisode: actions.podcast.search.setEpisode,
    storeAddSubscription: actions.podcast.subscriptions.addSubscription,
    storeRemoveSubscription: actions.podcast.subscriptions.removeSubscription,
    setAlert: actions.app.setAlert,
  },
)(withRouter(EpisodesScreen));
