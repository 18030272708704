import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import mainStyles from '../../styles';
import { withRouter } from '../../utils/routing/Routing';

const CircleButton = ({ history, url, urlOpts, label, images, testID }) => {
  const openUrl = () => history.push(url, urlOpts || {});

  const image =
    images && Array.isArray(images) && images.length > 0 ? (
      <Image resizeMode="cover" source={{ uri: images[0].url }} style={styles.image} />
    ) : (
      <View style={[styles.placeholder, styles.image]} />
    );

  return (
    <TouchableOpacity onPress={openUrl} style={styles.container} testID={testID}>
      {image}
      {label && (
        <Text numberOfLines={2} style={[mainStyles.typography.textCenter, mainStyles.typography.h3, mainStyles.margin.top.sm]}>
          {label}
        </Text>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: mainStyles.CIRCLE_BUTTON_WIDTH,
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    width: mainStyles.CIRCLE_BUTTON_WIDTH,
    height: mainStyles.CIRCLE_BUTTON_WIDTH,
    borderRadius: 50,
  },
  placeholder: {
    backgroundColor: mainStyles.IMAGE_PLACEHOLDER_COLOR,
  },
});

export default withRouter(CircleButton);
