import types from '../../types';

export const createNewPlaylist = payload => ({
  type: types.media.playlists.CREATE_NEW_PLAYLIST,
  payload: {
    ...payload,
  },
});

export const addTrackToPlaylist = payload => ({
  type: types.media.playlists.ADD_TRACK,
  payload,
});

export const getPlaylists = payload => ({
  type: types.media.playlists.GET_PLAYLISTS,
  payload,
});

export const addPlaylist = payload => ({
  type: types.media.playlists.PLAYLIST_CREATED,
  payload,
});

export const updatePlaylist = payload => ({
  type: types.media.playlists.UPDATE_PLAYLIST,
  payload,
});

export const followPlaylist = payload => ({
  type: types.media.playlists.FOLLOW_PLAYLIST,
  payload,
});

export const unfollowPlaylist = payload => ({
  type: types.media.playlists.UNFOLLOW_PLAYLIST,
  payload,
});

export const deletePlaylist = payload => ({
  type: types.media.playlists.DELETE_PLAYLIST,
  payload,
});

export const setPlaylists = payload => ({
  type: types.media.playlists.SET_PLAYLISTS,
  payload,
});

export const setImagePlaylist = payload => ({
  type: types.media.playlists.SET_IMAGE_PLAYLIST,
  payload,
});

export const getImagePlaylist = payload => ({
  type: types.media.playlists.GET_IMAGE_PLAYLIST,
  payload,
});
