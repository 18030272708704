import types from '../../types';

export const setStations = payload => ({
  type: types.media.stations.SET_STATIONS,
  payload,
});
export const setFavoriteStation = (key, favorite) => ({
  type: types.media.stations.SET_FAVORITE_STATION,
  payload: {
    key,
    favorite,
  },
});
export const setRadioFav = payload => ({
  type: types.media.stations.SET_RADIOSTATION_ISFAV,
  payload,
});
