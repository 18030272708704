import React from 'react';
import { Platform, Text, View } from 'react-native';

import AppleButton from '../../components/providers/AppleButton';
import DeezerButton from '../../components/providers/DeezerButton';
import ProviderPremiumButton from '../../components/providers/ProviderPremiumButton';
import SpotifyButton from '../../components/providers/SpotifyButton';
import AppLayout from '../../layouts/app';
import mainStyles from '../../styles';

const ProviderSelection = () => {
  return (
    <AppLayout title="connect">
      <View style={[mainStyles.margin.bottom.lg, mainStyles.margin.top.lg]}>
        <Text style={[mainStyles.typography.p, mainStyles.typography.textCenter]}>
          Please select a streaming provider and get your Premium account and unlock all features of the ONE app!.
        </Text>
      </View>
      <View style={mainStyles.margin.bottom.md}>
        <AppleButton enabled />
        <ProviderPremiumButton path="/applepremium">Apple Music Premium</ProviderPremiumButton>
      </View>
      {(Platform.OS === 'web' || Platform.OS === 'ios') && (
        <View style={mainStyles.margin.bottom.md}>
          <DeezerButton enabled />
          <ProviderPremiumButton path="/deezerpremium">Deezer Music Premium</ProviderPremiumButton>
        </View>
      )}
      <View style={mainStyles.margin.bottom.md}>
        <SpotifyButton enabled />
        <ProviderPremiumButton path="/spotifypremium">Spotify Music Premium</ProviderPremiumButton>
      </View>
    </AppLayout>
  );
};

export default ProviderSelection;
