import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import { WebView } from 'react-native-webview';

import FullScreenLayout from '../layouts/fullscreen';
import { withRouter } from '../utils/routing/Routing';

const DeezerPremiumScreen = ({ history }) => {
  useEffect(() => {
    if (Platform.OS === 'web') {
      window.open('https://www.deezer.com/nl/offers');
      history.goBack();
    }
  }, []);

  return (
    <FullScreenLayout noPadding title="Deezer Premium">
      <WebView source={{ uri: 'https://www.deezer.com/nl/offers' }} />
    </FullScreenLayout>
  );
};

export default withRouter(DeezerPremiumScreen);
