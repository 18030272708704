import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import Logo from '../../components/app/Logo';
import ContentTypeButton from '../../components/media/ContentTypeButton';
import FullScreenLayout from '../../layouts/fullscreen';
import mainStyles from '../../styles';
import { withRouter } from '../../utils/routing/Routing';

const AudioSelection = ({ history }) => {
  const navigate = url => history.push(url);

  return (
    <FullScreenLayout background profileBtn>
      <View style={[styles.logoContainer, mainStyles.margin.bottom.xl]}>
        <Logo />
      </View>
      <Text style={[mainStyles.typography.titleSplash, styles.introText]}>
        Connect with your streaming provider, have live interaction with your friends and start sharing the experience!
      </Text>
      <View style={[mainStyles.margin.bottom.md, mainStyles.margin.top.lg]}>
        <ContentTypeButton handler={() => navigate('/music')} label="Music" testID="music-button" type="music" />
      </View>
      <View style={mainStyles.margin.bottom.md}>
        <ContentTypeButton handler={() => navigate('/radioCategory')} label="Radio" testID="radio-button" type="radio" />
      </View>
      <View style={mainStyles.margin.bottom.md}>
        <ContentTypeButton handler={() => navigate('/podcast/search')} label="Podcast" testID="podcast-button" type="podcast" />
      </View>
    </FullScreenLayout>
  );
};

export default withRouter(AudioSelection);

const styles = StyleSheet.create({
  appContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  addHomeScreen: {
    height: 100,
    backgroundColor: 'white',
    width: '100%',
  },
  contentButton: {
    flex: 1,
  },
  logoContainer: {
    alignItems: 'center',
  },
  introText: {
    fontSize: 24,
    lineHeight: 32,
    textAlign: 'center',
  },
});
