import { isBefore, parseISO } from 'date-fns';

import types from '../../types';

const initialState = {
  availableProviders: {
    spotify: false,
    deezer: false,
    apple: false,
    soundcloud: false,
  },
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case types.providers.spotify.RESET_AUTH_TOKEN:
      return {
        ...state,
        availableProviders: {
          ...state.availableProviders,
          spotify: false,
        },
      };
    case types.providers.spotify.SET_AUTH_TOKEN:
      const now = new Date();
      const expired = isBefore(parseISO(payload.expires), now);
      const isAuthorized = !expired && payload.token !== undefined && payload.token !== null;

      if (isAuthorized) {
        return {
          ...state,
          availableProviders: {
            ...state.availableProviders,
            spotify: true,
          },
        };
      } else {
        return {
          ...state,
          availableProviders: {
            ...state.availableProviders,
            spotify: false,
          },
        };
      }
    case types.providers.deezer.SET_AUTH_TOKEN:
      if (payload.token !== undefined && payload.token !== null) {
        return {
          ...state,
          availableProviders: {
            ...state.availableProviders,
            deezer: true,
          },
        };
      }

      return {
        ...state,
        availableProviders: {
          ...state.availableProviders,
          deezer: false,
        },
      };
    case types.providers.apple.SET_ENABLED:
      return {
        ...state,
        availableProviders: {
          ...state.availableProviders,
          apple: true,
        },
      };
    case types.user.LOGOUT:
      return {
        ...state,
        availableProviders: {
          spotify: false,
          deezer: false,
          apple: false,
          soundcloud: false,
        },
      };
    default:
      return state;
  }
};
