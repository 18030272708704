import types from '../../types';

export const initApplePlayer = () => ({
  type: types.providers.apple.INIT_PLAYER,
});

export const setEnabled = () => ({
  type: types.providers.apple.SET_ENABLED,
});

export const setToken = payload => ({
  type: types.providers.apple.SET_TOKEN,
  payload,
});

export const playAppleTrack = payload => ({
  type: types.providers.apple.PLAY_TRACK,
  payload,
});
