import React, { useEffect, useState } from 'react';
import { FlatList, Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TextInputRez from '../../components/app/TextInputRez';
import Follow from '../../components/user/Follow';
import SearchFollowList from '../../components/user/SearchFollowList';
import AppLayout from '../../layouts/app';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import mainStyles from '../../styles';
import { getTopUsers, userNameSearch } from '../../utils/api/api';
import { withRouter } from '../../utils/routing/Routing';

let pageNumber = 1;
let maxPages = 0;

const FollowingListScreen = ({ following, jwt }) => {
  const [query, setQuery] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [filterSearch, setFilterSearch] = useState([]);
  const [isFollowing, setIsFollowing] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const getUserList = () => {
    setIsLoading(true);
    userNameSearch(jwt, 20, query === '' ? null : query, pageNumber).then(response => {
      pageNumber = response.page.current;
      maxPages = response.page.total;

      if (pageNumber === 1) {
        setSearchResult(response.data);
      } else {
        const joined = searchResult.concat(response.data);
        setSearchResult(joined);
      }
      setIsLoading(false);
    });
  };

  const getTopUserList = () => {
    setIsLoading(true);
    getTopUsers(jwt).then(res => {
      setSearchResult(res.data);
      setIsLoading(false);
    });
  };

  const runSearch = () => {
    const result = following.filter(item => item.username.toLowerCase().startsWith(query.toLowerCase()));
    setFilterSearch(result);
  };

  const runUserSearch = () => {
    pageNumber = 1;
    maxPages = 0;
    getUserList();
  };

  const loadMoreData = () => {
    if (!isLoading && pageNumber < maxPages) {
      pageNumber += 1;
      getUserList();
    }
  };

  const followUnfollowUser = index => {
    const searchRes = [...searchResult];
    searchRes[index].isFollowing = !searchRes[index].isFollowing;
    setSearchResult(searchRes);
  };

  const emptyList = () => (
    <>
      {following.length === 0 && (
        <View style={[mainStyles.centerFlex, mainStyles.margin.top.lg, mainStyles.margin.bottom.md]}>
          <Text style={mainStyles.typography.p}>Your following list is empty. Start following..</Text>
        </View>
      )}
    </>
  );

  const itemSeperator = () => <View style={{ height: 0.5, width: '100%', backgroundColor: 'rgba(0,0,0,0.5)' }} />;

  useEffect(() => {
    getTopUserList();
    setFilterSearch(following);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-nested-ternary
    isFollowing ? runSearch() : query === '' ? getTopUserList() : runUserSearch();
  }, [query]);

  useEffect(() => {
    if (isFollowing) {
      setFilterSearch(following);
      setQuery('');
    } else getTopUserList();
  }, [isFollowing]);

  return (
    <AppLayout title="Follow ONE">
      <TextInputRez
        defaultValue={query}
        icon={require('../../assets/icons/search/search.png')}
        onChangeText={setQuery}
        placeholder="Search profiles"
        testID="search-input"
      />
      <View style={styles.radioBtnContainer}>
        <TouchableOpacity onPress={() => setIsFollowing(true)} style={styles.radioBtn} testID="following-radiobutton">
          <Image
            resizeMode="contain"
            source={isFollowing ? require('../../assets/icons/radiobtn/checked.png') : require('../../assets/icons/radiobtn/notChecked.png')}
            style={styles.img}
          />
          <Text style={styles.radioBtnLable}>Following</Text>
        </TouchableOpacity>

        <TouchableOpacity onPress={() => setIsFollowing(false)} style={styles.radioBtn} testID="all-users-radiobutton">
          <Image
            resizeMode="contain"
            source={!isFollowing ? require('../../assets/icons/radiobtn/checked.png') : require('../../assets/icons/radiobtn/notChecked.png')}
            style={styles.img}
          />
          <Text style={styles.radioBtnLable}>Top Users</Text>
        </TouchableOpacity>
      </View>
      {!isFollowing ? (
        <FlatList
          data={searchResult}
          extraData={item => item.isFollowing}
          ItemSeparatorComponent={itemSeperator}
          keyExtractor={item => item.username}
          renderItem={({ item, index }) => (
            <SearchFollowList
              key={`following-${item.username}`}
              following={item}
              followUnfollow={() => followUnfollowUser(index)}
              testID={`following-${item.username}`}
            />
          )}
          style={[mainStyles.margin.top.lg, mainStyles.margin.bottom.md]}
        />
      ) : (
        <FlatList
          data={filterSearch}
          ItemSeparatorComponent={itemSeperator}
          keyExtractor={item => item.username}
          ListEmptyComponent={emptyList()}
          renderItem={({ item }) => <Follow key={`following-${item.username}`} following={item} updateList={() => setFilterSearch(following)} />}
          style={mainStyles.margin.top.lg}
          vertical
        />
      )}

      {!isFollowing && pageNumber < maxPages && (
        <TouchableOpacity onPress={() => loadMoreData()} style={styles.loadMoreButton}>
          <Text style={styles.loadMoreText}>Load More</Text>
        </TouchableOpacity>
      )}
    </AppLayout>
  );
};

const styles = StyleSheet.create({
  radioBtnContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '70%',
    height: 40,
    alignSelf: 'center',
    marginTop: 10,
  },
  radioBtn: {
    width: '40%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  img: {
    height: '100%',
    width: 30,
    marginRight: 10,
  },
  radioBtnLable: {
    color: 'white',
    marginRight: 10,
  },
  loadMoreButton: {
    marginBottom: 40,
    alignSelf: 'center',
    backgroundColor: 'white',
    width: 100,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
  },
  loadMoreText: {
    fontFamily: 'Roboto-Medium',
    color: 'black',
    fontSize: 15,
    marginTop: 15,
    marginBottom: 12,
  },
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        setAlert: actions.app.setAlert,
        setProfile: actions.user.setProfile,
        dropFollowing: actions.user.dropFollowing,
      },
      dispatch,
    ),
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    jwt: selectors.user.getJwt(state),
    profile: selectors.user.getProfile(state),
    following: selectors.user.getFollowing(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FollowingListScreen));
