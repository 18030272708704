/* eslint-disable react/no-unused-state */
import { Formik } from 'formik';
import React, { Component } from 'react';
import { ActivityIndicator, Image, Linking, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import ButtonRez from '../../components/app/ButtonRez';
import TextInputRez from '../../components/app/TextInputRez';
import FullScreenLayout from '../../layouts/fullscreen';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import mainStyles from '../../styles';
import { signup } from '../../utils/api/api';
import CheckBox from '../../utils/CheckBoxTick';
import analytics from '../../utils/routing/analytics';
import { withRouter } from '../../utils/routing/Routing';

class SignupScreen extends Component {
  state = {
    loading: false,
    terms: false,
    policyCheckedTime: null,
  };

  errorTexts = {
    USER_EXISTS: 'That username or email address is already taken.',
    VALIDATION_ERROR: 'Something is off, please check your email and password',
    default: 'Ooops, something went wrong. Please try again later.',
  };

  SignupSchema = Yup.object().shape({
    password: Yup.string().required('Required').min(8, 'Password must be at least 8 characters long'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    displayname: Yup.string()
      .required('Required')
      .min(3, 'Display name must be at least 3 characters long')
      .max(25, "Display name can't be longer than 25 characters"),
    email: Yup.string().email('Invalid email').required('Required'),
    terms: Yup.boolean().oneOf([true], 'Accepting the terms of service is required'),
  });

  handleSignup({ email, password, displayname, firstName, lastName, terms }) {
    const { history, setUserCredentials, setUsername, setXmppPassword } = this.props;
    const displayName = displayname;
    this.setState({
      loading: true,
    });

    const Reqdata = {
      email,
      password,
      displayname,
      firstName,
      lastName,
      terms,
    };
    console.log(Reqdata);
    signup({
      email,
      password,
      displayName,
      terms,
      firstName,
      lastName,
    })
      .then(async data => {
        if (data && data.token && data.userId) {
          setUserCredentials({ jwt: data.token, userId: data.userId });
          setUsername({ username: data.username });
          setXmppPassword({ password: data.xmppPassword });
        }

        await analytics().setUserId(data.userId);
        analytics().logEvent('sign_up', {
          account_status: data.status,
        });

        this.props.setAlert(null);

        if (data && data.status && data.status === 'activated') {
          history.push('/audio');
        } else if (data && data.status && data.status === 'pending') {
          this.props.history.push('/validate', {
            userEmail: email,
            userId: data.userId,
            userDisplayName: displayname,
            privacyPolicyConsent: this.state.policyCheckedTime,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch(err => {
        this.setState({
          loading: false,
        });

        if (err && err.response && err.response.data) {
          this.props.setAlert({
            type: 'error',
            message: err.response.data.errorMessage || this.errorTexts[err.response.data.error],
          });
        }
      });
  }

  goToSignIn() {
    const { history } = this.props;
    history.entries = [];
    history.index = -1;
    this.props.history.push('/signin');
  }

  render() {
    const { loading } = this.state;

    return (
      <FullScreenLayout backButton={false} backgroundSignup scrollEnabled>
        <Text style={[mainStyles.typography.title, mainStyles.typography.textCenter, mainStyles.margin.bottom.xl]}>
          Let&apos;s get you signed up!
        </Text>
        <Formik
          initialValues={{ email: '', password: '', username: '', displayname: '', terms: false }}
          onSubmit={values => this.handleSignup(values)}
          validateOnBlur
          validationSchema={this.SignupSchema}
        >
          {props => (
            <View>
              <View style={mainStyles.margin.bottom.md}>
                <TextInputRez
                  autoCapitalize="none"
                  autoFocus={false}
                  disabled={loading}
                  icon={require('../../assets/icons/profile/profile.png')}
                  keyboardType="default"
                  label="Display name"
                  onBlur={props.handleBlur('displayname')}
                  onChangeText={props.handleChange('displayname')}
                  placeholder="Pick a display name"
                  testID="displayname-input"
                  validation={{ error: props.errors.displayname, touched: props.touched.displayname }}
                  value={props.values.displayname}
                />
              </View>

              <View style={mainStyles.margin.bottom.md}>
                <TextInputRez
                  autoCapitalize="none"
                  autoFocus={false}
                  disabled={loading}
                  icon={require('../../assets/icons/profile/profile.png')}
                  keyboardType="default"
                  label="First name"
                  onBlur={props.handleBlur('firstName')}
                  onChangeText={props.handleChange('firstName')}
                  placeholder="Your first name"
                  testID="firstName-input"
                  validation={{ error: props.errors.firstName, touched: props.touched.firstName }}
                  value={props.values.firstName}
                />
              </View>

              <View style={mainStyles.margin.bottom.md}>
                <TextInputRez
                  autoCapitalize="none"
                  autoFocus={false}
                  disabled={loading}
                  icon={require('../../assets/icons/profile/profile.png')}
                  keyboardType="default"
                  label="Last name"
                  onBlur={props.handleBlur('lastName')}
                  onChangeText={props.handleChange('lastName')}
                  placeholder="Your last name"
                  testID="lastName-input"
                  validation={{ error: props.errors.lastName, touched: props.touched.lastName }}
                  value={props.values.lastName}
                />
              </View>

              <View style={mainStyles.margin.bottom.md}>
                <TextInputRez
                  autoCapitalize="none"
                  autoFocus={false}
                  disabled={loading}
                  enablesReturnKeyAutomatically
                  icon={require('../../assets/icons/mail/mail.png')}
                  keyboardType="email-address"
                  label="Email address"
                  name="email"
                  onBlur={props.handleBlur('email')}
                  onChangeText={props.handleChange('email')}
                  placeholder="Your e-mail address"
                  testID="email-input"
                  validation={{ error: props.errors.email, touched: props.touched.email }}
                  value={props.values.email}
                />
              </View>

              <View style={mainStyles.margin.bottom.md}>
                <TextInputRez
                  autoCapitalize="none"
                  autoCorrect={false}
                  disabled={loading}
                  enablesReturnKeyAutomatically
                  icon={require('../../assets/icons/lock/lock.png')}
                  label="Password"
                  name="password"
                  onBlur={props.handleBlur('password')}
                  onChangeText={props.handleChange('password')}
                  placeholder="Your password"
                  secureTextEntry
                  testID="password-input"
                  validation={{ error: props.errors.password, touched: props.touched.password }}
                  value={props.values.password}
                />
              </View>

              <View style={[mainStyles.margin.bottom.md, styles.terms]}>
                {Platform.OS !== 'web' ? (
                  <CheckBox
                    boxType="square"
                    offAnimationType="bounce"
                    onAnimationType="bounce"
                    onCheckColor="black"
                    onFillColor="white"
                    onTintColor="white"
                    onValueChange={newValue => {
                      props.setFieldValue('terms', newValue);
                      this.setState({ policyCheckedTime: new Date() });
                    }}
                    style={{ width: Platform.OS === 'android' ? 30 : 20, height: Platform.OS === 'android' ? 30 : 20 }}
                    tintColors={{ true: 'white', false: 'white' }}
                    value={props.values.terms}
                  />
                ) : (
                  <CheckBox
                    borderColor="white"
                    checked={props.values.terms}
                    icon={<img alt="" src={require('../../assets/icons/check/check_big.png')} style={{ width: 17, height: 17 }} />}
                    onChange={val => {
                      props.setFieldValue('terms', val);
                      this.setState({ policyCheckedTime: new Date() });
                    }}
                  />
                )}
                <Text style={[mainStyles.typography.p, styles.text, { marginLeft: '2%' }]}>
                  I have taken notice, understand and agree with the{' '}
                  <Text
                    onPress={() => Linking.openURL('https://rezzonation.com/privacy')}
                    style={[mainStyles.typography.p, styles.text, styles.link]}
                  >
                    privacy policy.
                  </Text>
                </Text>
                {props.errors && props.errors.terms && props.touched.terms && (
                  <View style={[styles.validationResultContainer, mainStyles.margin.top.xs]}>
                    <Image source={require('../../assets/icons/alert/alert_red.png')} style={[styles.iconSm, mainStyles.margin.right.xs]} />
                    <Text style={[mainStyles.typography.p]}>{props.errors && props.errors.terms}</Text>
                  </View>
                )}
              </View>

              {!loading && (
                <View style={mainStyles.margin.bottom.md}>
                  <ButtonRez
                    disabled={!props.isValid}
                    fontColor="rgba(23,23,38,1)"
                    label="Sign up"
                    large
                    onPress={props.handleSubmit}
                    startColor="rgba(255,255,255,1)"
                    testID="signup-button"
                  />
                </View>
              )}
            </View>
          )}
        </Formik>

        {loading && (
          <View style={mainStyles.loading}>
            <ActivityIndicator color="rgba(255,142,12,1)" size="large" />
          </View>
        )}

        {!loading && (
          <View style={styles.CTA}>
            <Text style={[mainStyles.typography.p, mainStyles.typography.textCenter, mainStyles.margin.bottom.lg]}>Already have a One account? </Text>
            <TouchableOpacity onPress={() => this.goToSignIn()}>
              <Text style={[mainStyles.typography.ctaUnderline, mainStyles.typography.textCenter, { fontSize: 14 }]}>Login</Text>
            </TouchableOpacity>
          </View>
        )}
      </FullScreenLayout>
    );
  }
}

const styles = StyleSheet.create({
  validationResultContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconSm: {
    width: 10,
    height: 10,
  },
  CTA: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'baseline',
  },
  link: {
    textDecorationLine: 'underline',
  },
  terms: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  text: {
    flex: 1,
    flexGrow: 2,
  },
  switch: {
    flex: 1,
    flexGrow: 1,
    minWidth: 35,
    maxWidth: 35,
    marginLeft: 5,
    marginRight: Platform.OS === 'web' ? 0 : 17,
  },
});

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    hasValidInvite: selectors.user.validInvite(state),
  }),
  {
    setAlert: actions.app.setAlert,
    setUserCredentials: actions.user.setUserCredentials,
    setUsername: actions.user.setUsername,
    setXmppPassword: actions.user.setXmppPassword,
  },
)(withRouter(SignupScreen));
