import { createBrowserHistory, createMemoryHistory } from 'history';
import { Platform } from 'react-native';

import analytics from './analytics';

const history = Platform.OS === 'web' ? createBrowserHistory() : createMemoryHistory();

history.listen(location => {
  analytics().logEvent('page_view', {
    path: location.pathname,
  });
  if (Platform.OS === 'web') {
    analytics().setCurrentScreen(location.pathname, location.pathname);
  } else {
    analytics().logScreenView({ screen_name: location.pathname, screen_class: location.pathname });
  }
});

export default history;
