import types from '../../types';

const initialState = {
  groupchats: {},
  presence: {},
  playingStatus: {},
  tunedTo: '',
  addHostUsersCount: 0,
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case types.tunein.ADD_NEW_GROUPCHAT_MESSAGE:
      const { message } = payload;

      const msg = {
        from: message.from,
        message: message.message,
        id: message.id,
        date: message.date,
      };

      const newState = { ...state };
      if (newState.groupchats[message.chat] && newState.groupchats[message.chat].messages) {
        const has = newState.groupchats[message.chat].messages.find(i => i.id === message.id);
        if (!has) newState.groupchats[message.chat].messages.unshift(msg);
      } else {
        newState.groupchats[message.chat] = {
          messages: [msg],
        };
      }

      return newState;
    case types.tunein.CLEAR_GROUPCHAT_HISTORY:
      if (state.groupchats[payload.chat]) {
        state.groupchats[payload.chat] = {};
      }
      return state;

    case types.tunein.SET_USER_ONLINE:
      const onlinePresence = { ...state.presence };
      onlinePresence[payload.user] = 'online';

      return {
        ...state,
        presence: {
          ...onlinePresence,
        },
      };

    case types.tunein.SET_USER_OFFLINE:
      const { user } = payload;
      const offlinePresence = { ...state.presence };
      offlinePresence[user] = 'offline';

      return {
        ...state,
        presence: {
          ...offlinePresence,
        },
      };

    case types.tunein.SET_USER_STREAMING:
      const streamingPresence = { ...state.presence };
      streamingPresence[payload.user] = 'streaming';

      return {
        ...state,
        presence: {
          ...streamingPresence,
        },
      };

    case types.tunein.UPDATE_PLAYING_STATUS:
      const newState2 = { ...state };
      newState2.playingStatus[payload.user] = payload.track;
      return newState2;

    case types.tunein.ENABLE_TUNE_IN:
      return {
        ...state,
        tunedTo: payload.user,
      };

    case types.tunein.DISABLE_TUNE_IN:
      return {
        ...state,
        tunedTo: '',
      };
    case types.tunein.CLEAR_PLAYING_STATUS:
      const clearState = { ...state };
      delete clearState.playingStatus[payload.user];
      return clearState;
    case types.tunein.SET_TUNE_IN_NUMBER:
      return {
        ...state,
        addHostUsersCount: state.addHostUsersCount + payload.count,
      };
    case types.tunein.SET_TUNE_OUT_NUMBER:
      return {
        ...state,
        addHostUsersCount: state.addHostUsersCount - payload.count,
      };
    case types.tunein.RESET_TUNE_IN_NUMBER:
      return {
        ...state,
        addHostUsersCount: 0,
      };
    case types.user.LOGOUT:
      return {
        ...state,
        groupchats: {},
        presence: {},
        playingStatus: {},
        tunedTo: '',
      };
    default:
      return state;
  }
};
