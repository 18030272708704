import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';

import mainStyles from '../../styles';

const ListScreenHeader = ({ image, title, subtitle, circle }) => {
  const imageStyles = [styles.image, mainStyles.margin.bottom.md];
  if (circle) imageStyles.push(styles.imageCircle);

  return (
    <View style={[mainStyles.centerFlex, mainStyles.margin.bottom.md]}>
      {image ? (
        <Image resizeMode="cover" source={{ uri: image }} style={imageStyles} />
      ) : (
        <Image resizeMode="cover" source={require('../../assets/logos/one/logo.png')} style={styles.image} />
      )}
      {title && (
        <Text style={[mainStyles.typography.h3, mainStyles.typography.textCenter, mainStyles.margin.bottom.xs]} testID={`listscreentitle_${title}`}>
          {title}
        </Text>
      )}
      {subtitle && <Text style={[mainStyles.typography.p, mainStyles.typography.textCenter]}>{subtitle}</Text>}
    </View>
  );
};

const styles = StyleSheet.create({
  image: {
    backgroundColor: 'rgba(3,3,3,3)',
    width: 140,
    height: 140,
    borderRadius: mainStyles.IMAGE_BORDER_RADIUS,
  },
  imageCircle: {
    borderRadius: 70,
  },
});

export default ListScreenHeader;
