import React, { useState } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import mainStyles from '../../../styles';
import ChannelDetailsExpando from './ChannelDetailsExpando';

export default function ChannelHeader(props) {
  const [open, setOpen] = useState(false);

  return (
    <View style={[styles.channelContainer]}>
      <Image resizeMode="contain" source={{ uri: props.channel.artworkUrl100 }} style={[styles.logo, mainStyles.margin.right.sm]} />
      <View style={[styles.detailsContainer]}>
        <View>
          <Text numberOfLines={1} style={[mainStyles.typography.h2]} testID="podcastname-text">
            {props.channel.trackName}
          </Text>
          <Text numberOfLines={1} style={[mainStyles.typography.h2Light, mainStyles.margin.top.xs]}>
            {props.channel.artistName}
          </Text>
          <Text numberOfLines={open ? 999 : 2} style={[mainStyles.typography.p]}>
            {props.description}
          </Text>
          <ChannelDetailsExpando meta={{ genres: props.channel.genres }} open={open} style={mainStyles.margin.top.xs} />
        </View>
        <View>
          <View>
            <TouchableOpacity onPress={() => setOpen(!open)}>
              {!open && <Text style={mainStyles.typography.h3}>More</Text>}
              {open && <Text style={mainStyles.typography.h3}>Less</Text>}
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  channelContainer: {
    flex: 2,
    flexDirection: 'row',
  },
  flex: {
    flex: 1,
    alignItems: 'center',
  },
  logo: {
    width: 100,
    height: 100,
    backgroundColor: 'white',
  },
  detailsContainer: {
    flex: 2,
  },
});
