/* eslint-disable no-inline-comments */
export const SET_ORIGIN_URL = 'SET_ORIGIN_URL';
export const APP_STARTED = 'APP_STARTED';
export const SET_ALERT = 'APP_SET_ALERT';
export const SET_POPUP = 'APP_SET_POPUP';
export const SHOW_OVERLAY = 'APP_SHOW_OVERLAY';
export const HIDE_OVERLAY = 'APP_HIDE_OVERLAY';
export const SET_PUSH_NOTIFICATIONS_REQUESTED = 'APP_SET_PUSH_NOTIFICATIONS_REQUESTED';
export const SET_WEB_DOWNLOAD_POPUP = 'WEB_SET_DOWNLOAD_POPUP';
export const SET_TUNEIN_STATUS = 'APP_SET_TUNEIN_STATUS'; // action name to set when xmpp is ready for tune in
