export const normalizeSpotifyTrack = track => {
  const trackId = () => {
    if (track.id) return track.id.toString();
    if (track.uri) {
      const uriSplit = track.uri.split('spotify:track:');
      if (Array.isArray(uriSplit) && uriSplit.length === 2) return uriSplit[1];
    }
  };
  return {
    id: trackId(),
    provider: 'spotify',
    name: track.name,
    artistName: track.artistName || track.artists.map(a => a.name).join(', '),
    album: normalizeSpotifyAlbum(
      track.album
        ? { ...track.album, artistName: track.artists.map(a => a.name).join(', ') }
        : {
            id: track.albumUri.split('spotify:album:')[1],
            name: track.albumName,
            artistName: track.artistName,
            images: [{ url: track.albumCoverArtURL, width: 640, height: 640 }],
          },
    ),
    duration: track.duration_ms || track.duration * 1000,
    discNumber: track.disc_number,
    trackNumber: track.track_number,
    explicit: track.explicit,
    type: 'track',
    uri: track.uri,
    isrc: track.external_ids && track.external_ids.isrc ? track.external_ids.isrc.replace(/-/g, '') : undefined,
    media_type: 'audio',
  };
};

export const normalizeSpotifyAlbum = album => ({
  id: album.id,
  provider: 'spotify',
  name: album.name,
  artistName: album.artistName,
  images: album.images.map(i => ({
    url: i.url,
    height: i.height,
    width: i.width,
  })),
  releaseDate: album.release_date,
  type: 'album',
});
