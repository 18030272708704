import React, { Component } from 'react';
import { StyleSheet, Text, TextInput } from 'react-native';

import ModalRez from '../../components/app/ModalRez';
import mainStyles from '../../styles';

class ShareButton extends Component {
  state = {
    linkCopied: false,
  };

  copyUrl() {
    this.urlTextBox.focus();
    document.execCommand('copy');
    this.setState({
      linkCopied: true,
    });
  }

  render() {
    const { title, url, message, onBackdropPress, isVisible } = this.props;
    const { linkCopied } = this.state;

    return (
      <ModalRez
        confirmButtonAction={() => this.copyUrl()}
        confirmButtonLabel="Copy"
        isVisible={isVisible}
        onBackdropPress={onBackdropPress}
        testID="shareplaylist"
        title={url ? 'Your sharing link' : title}
      >
        {url && (
          <TextInput
            ref={urlTextBox => {
              this.urlTextBox = urlTextBox;
            }}
            editable={false}
            selection={{ start: 0, end: url.length }}
            selectTextOnFocus
            style={styles.copyText}
            value={url}
          />
        )}
        {linkCopied && (
          <Text style={[mainStyles.typography.h3, styles.copiedText, mainStyles.margin.top.sm]}>The link has been copied to your clipboard</Text>
        )}
        {!url && message && <Text>{message}</Text>}
      </ModalRez>
    );
  }
}

const styles = StyleSheet.create({
  shareButtonCircle: {
    backgroundColor: 'white',
    width: 50,
    height: 50,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
  },
  shareButton: {
    width: 25,
    height: 25,
  },
  copiedText: {
    color: 'black',
  },
  copyText: {
    backgroundColor: 'white',
    paddingLeft: mainStyles.distance.sm,
    paddingRight: mainStyles.distance.sm,
    height: 30,
  },
});

export default ShareButton;
