import types from '../../types';

const initialState = {
  player: {
    currentProvider: null,
    trackLoaded: false,
    track: {},
  },
  origin: null,
  queue: [],
  recommendations: {
    artists: [],
    tracks: [],
  },
  searches: [],
  crossMatch: {
    track: null,
    suggestions: [],
  },
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case types.media.music.SET_CURRENT_PLAYER:
      return {
        ...state,
        player: {
          ...state.player,
          ...payload,
        },
      };
    case types.media.music.CLEAR_CROSS_MATCH:
      return {
        ...state,
        crossMatch: {
          track: null,
          suggestions: [],
        },
      };
    case types.media.music.SET_CROSS_MATCH:
      return {
        ...state,
        crossMatch: {
          track: payload.track,
          suggestions: payload.suggestions,
        },
      };
    case types.media.music.LOAD_NEW_MUSIC_TRACK:
      return {
        ...state,
        player: {
          ...state.player,
          currentProvider: payload.provider,
          trackLoaded: true,
          track: payload.track,
          origin: payload.origin,
        },
      };
    case types.media.music.SET_ARTIST_RECOMMENDATIONS:
      return {
        ...state,
        recommendations: {
          ...state.recommendations,
          artists: Array.isArray(payload.artists) ? payload.artists : state.recommendations.artists,
        },
      };
    case types.user.LOGOUT:
      return {
        ...state,
        player: {
          currentProvider: null,
          trackLoaded: false,
          track: {},
        },
        origin: null,
        queue: [],
        recommendations: {
          artists: [],
          tracks: [],
        },
        searches: [],
        crossMatch: {
          track: null,
          suggestions: [],
        },
      };
    default:
      return state;
  }
};
