import types from '../../types';

const initialState = {
  token: '',
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case types.providers.apple.SET_TOKEN:
      return {
        ...state,
        token: payload.token,
      };
    case types.user.LOGOUT:
      return {
        ...state,
        token: '',
      };
    default:
      return state;
  }
};
