import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import ListButton from '../components/app/ListButton';
import AppLayout from '../layouts/app';
import mainStyles from '../styles';
import { withRouter } from '../utils/routing/Routing';
import { getVersionName } from '../utils/version';

const AboutScreen = () => {
  const [version, setVersion] = useState('');

  useEffect(() => {
    getVersionName().then(setVersion);
  }, []);

  return (
    <AppLayout title="about">
      <ListButton path="/conditions">General conditions of use</ListButton>
      <ListButton path="/Privacy Policy">Privacy policy</ListButton>
      <View style={[mainStyles.margin.top.md, styles.version]}>
        <Text style={[mainStyles.typography.h3]}>Version: </Text>
        <Text style={[mainStyles.typography.h3Light, mainStyles.margin.right.sm]}>{version}</Text>
      </View>
    </AppLayout>
  );
};

const styles = StyleSheet.create({
  version: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
});

export default withRouter(AboutScreen);
