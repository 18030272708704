import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import NavBarLeftButton from '../../components/app/navbar/NavBarLeftButton';
import NavBarRightButton from '../../components/app/navbar/NavBarRightButton';
import PublicProfilePicture from '../../components/user/PublicProfilePicture';
import selectors from '../../store/selectors';
import mainStyles from '../../styles';

const NavBarRez = ({ history, title, moreOptionsHandler, profileBtn, backButton, profile }) => {
  const goToProfile = () => {
    history.push('/profile');
  };

  const renderTitle = titleToRender => {
    switch (titleToRender) {
      case 'music':
        return <Image resizeMode="contain" source={require('../../assets/icons/top-menu/top-music.png')} style={styles.titleImage} />;
      case 'podcast':
        return <Image resizeMode="contain" source={require('../../assets/icons/top-menu/top-podcast.png')} style={styles.titleImage} />;
      case 'radio':
        return <Image resizeMode="contain" source={require('../../assets/icons/top-menu/top-radio.png')} style={styles.titleImage} />;
      default:
        return (
          <Text numberOfLines={1} style={mainStyles.typography.navigation}>
            {titleToRender?.length > 25 ? `${titleToRender.substring(0, 25)}...` : titleToRender}
          </Text>
        );
    }
  };

  return (
    <View style={styles.navBar}>
      <NavBarLeftButton backButton={backButton} />
      {title ? renderTitle(title) : <View />}
      {moreOptionsHandler && <NavBarRightButton onPress={moreOptionsHandler} />}
      {profileBtn && (
        <TouchableOpacity onPress={() => goToProfile()} style={styles.profilePicture} testID="profile-picture">
          <PublicProfilePicture small url={profile.profilePictureUrl} />
        </TouchableOpacity>
      )}
      {!moreOptionsHandler && !profileBtn && <View style={{ flexBasis: 50 }} />}
    </View>
  );
};

const styles = StyleSheet.create({
  navBar: {
    height: mainStyles.TOPBAR_HEIGHT,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  profilePicture: {
    marginRight: 10,
    marginTop: 10,
  },
  titleImage: {
    height: 18,
    width: 112,
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    profile: selectors.user.getProfile(state),
  };
};

export default connect(mapStateToProps, null)(withRouter(NavBarRez));
