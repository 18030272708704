import { StationSearchType } from 'radio-browser-api';
import React, { Component } from 'react';
import { FlatList, Image, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';

import TextInputRez from '../../components/app/TextInputRez';
import AppLayout from '../../layouts/app';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import mainStyles from '../../styles';
import * as consts from '../../styles/consts';
import { getRadio, sendDetailsToCM } from '../../utils/api/api';
import analytics from '../../utils/routing/analytics';
import { withRouter } from '../../utils/routing/Routing';

let pageNumber = 0;

class StationCategories extends Component {
  // these are the 'tune hunter' categories: https://apps.apple.com/nl/app/tune-hunter/id1440690657
  state = {
    radioStation: [
      '80S',
      '90S',
      'Alternative',
      'Classic Hits',
      'Classic Rock',
      'Classical',
      'Clus',
      'Drum And Bass',
      'Dubstep',
      'Electronic',
      'Funk',
      'Hard Rock',
      'Hardcore',
      'Heavy Metal',
      'Hiphop',
      'Hits',
      'House',
      'Indie',
      'Metal',
      'News',
      'Pop',
      'Punk',
      'Rap',
      'Reggae',
      'Relax',
      'Talk',
      'Techno',
    ],
    searchQuery: '',
    filterStation: [],
    isLoadMore: true,
  };

  componentDidMount() {
    pageNumber = 0;
  }

  updateSearchQuery(searchQuery) {
    pageNumber = 0;
    this.setState({
      searchQuery,
    });
    if (searchQuery !== '') {
      this.searchStation(searchQuery);
    }
  }

  clickCategory(key) {
    this.props.setCategory(key);
    this.props.history.push('/radio');
  }

  groupBy(array, key) {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);

      return result;
    }, {});
  }

  async searchStation(searchValue) {
    this.setState({ isLoadMore: true });
    const cmActivity = {
      created: new Date(),
      userId: this.props.userId,
      searchRadio: searchValue,
    };
    sendDetailsToCM(cmActivity, consts.ACTIVITY_CM_KEY);

    const stations = await getRadio(StationSearchType.byName, searchValue, 50, pageNumber);

    if (pageNumber === 0) {
      this.setState({ filterStation: [] });
      this.setState({ filterStation: stations });
    } else {
      // eslint-disable-next-line react/no-access-state-in-setstate
      const joined = this.state.filterStation.concat(stations);
      this.setState({ filterStation: joined });
    }
    if (stations.length < 1) {
      this.setState({ isLoadMore: false });
    }
  }

  LoadMoreRandomData() {
    if (this.state.isLoadMore) {
      pageNumber += 1;
      this.searchStation(this.state.searchQuery);
    }
  }

  clickStation(station) {
    analytics().logEvent('play_radio', { station: station.name });
    if (this.props.tunedTo) {
      this.props.disableTuneIn();
      analytics().logEvent('stop_tunein');
    }
    this.props.setTrack({
      track: { ...station, artistName: 'Radio', provider: 'stream', album: { images: [{ url: station.favicon }] } },
      origin: 'radio',
    });
  }

  clickFavouriteList() {
    this.props.history.push('/favouriteRadio');
  }

  renderStations() {
    const { searchQuery, filterStation, isLoadMore } = this.state;

    const stationsToShow = filterStation.filter(radio => radio.name.match(new RegExp(searchQuery, 'i')));
    const regularStations = stationsToShow.filter(station => !station.favorite);

    return (
      <View style={{ flex: 1, marginTop: 20 }}>
        {regularStations.length > 0 && <Text style={mainStyles.typography.h1}>Stations</Text>}
        <View style={[styles.stationsContainer, mainStyles.margin.bottom.md]}>
          <FlatList
            data={regularStations}
            keyExtractor={(item, index) => item.key + index.toString()}
            numColumns={3}
            renderItem={({ item }) => (
              <TouchableOpacity
                key={item.key}
                onPress={() => this.clickStation(item)}
                style={[styles.stations, mainStyles.margin.top.md]}
                testID={`radio_${item.key}`}
              >
                <Image
                  defaultSource={require('../../assets/icons/radio/radio_mini.png')}
                  resizeMode="contain"
                  source={item.favicon === '' ? require('../../assets/icons/radio/radio_mini.png') : { uri: item.favicon }}
                  style={styles.stationLogos}
                />
                <Text
                  numberOfLines={1}
                  style={[
                    mainStyles.typography.h3,
                    mainStyles.margin.top.xs,
                    { textAlign: 'center', fontFamily: Platform.OS === 'android' ? 'Cabo-Rounded-Regular' : 'Cabo Rounded' },
                  ]}
                >
                  {item.name}
                </Text>
              </TouchableOpacity>
            )}
          />
        </View>

        {isLoadMore && regularStations.length > 0 && (
          <TouchableOpacity onPress={() => this.LoadMoreRandomData()} style={styles.loadMoreButton}>
            <Text style={styles.loadMoreText}>Load More</Text>
          </TouchableOpacity>
        )}
      </View>
    );
  }

  render() {
    const { searchQuery, radioStation } = this.state;
    const stationsToShow = radioStation.filter(key => key.match(new RegExp(searchQuery, 'i')));
    return (
      <AppLayout title="radio">
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <View style={{ width: '90%' }}>
            <TextInputRez
              icon={require('../../assets/icons/search/search.png')}
              onChangeText={e => this.updateSearchQuery(e)}
              placeholder="Search radio station..."
              testID="radiosearch-input"
            />
          </View>
          <TouchableOpacity onPress={() => this.clickFavouriteList()} style={{ height: 35, width: 35, marginLeft: 5 }}>
            <Image resizeMode="contain" source={require('../../assets/icons/radio/favourite.png')} style={{ height: '100%', width: '100%' }} />
          </TouchableOpacity>
        </View>

        <View style={[styles.stationsContainer, mainStyles.margin.bottom.md]}>
          {this.state.searchQuery === '' ? (
            <View>
              {stationsToShow.map(key => {
                return (
                  <View key={key} style={{ width: '100%' }}>
                    <TouchableOpacity onPress={() => this.clickCategory(key)} style={[styles.category]} testID={`radio_${key}`}>
                      <Image resizeMode="contain" source={require('../../assets/icons/radio/radio.png')} style={styles.categoryLogo} />
                      <Text
                        numberOfLines={2}
                        style={[
                          mainStyles.typography.h3,
                          {
                            width: '95%',
                            marginLeft: 10,
                            fontFamily: Platform.OS === 'android' ? 'Cabo-Rounded-Regular' : 'Cabo Rounded',
                          },
                        ]}
                      >
                        {key}
                      </Text>
                    </TouchableOpacity>
                  </View>
                );
              })}
            </View>
          ) : (
            this.renderStations()
          )}
        </View>
      </AppLayout>
    );
  }
}

const styles = StyleSheet.create({
  stationsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  category: {
    paddingLeft: mainStyles.distance.xs,
    paddingRight: mainStyles.distance.xs,
    flexBasis: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,
    marginTop: 20,
  },
  categoryLogo: {
    height: 50,
    width: 50,
    borderRadius: 25,
  },
  loadingContainer: {
    marginTop: consts.FULL_HEIGHT / 3,
  },
  stations: {
    paddingLeft: mainStyles.distance.xs,
    paddingRight: mainStyles.distance.xs,
    flexBasis: '33%',
  },
  stationLogos: {
    flex: 1,
    height: 120,
    width: undefined,
    borderRadius: mainStyles.IMAGE_BORDER_RADIUS,
  },
  loadMoreButton: {
    marginBottom: 20,
    alignSelf: 'center',
    backgroundColor: 'white',
    width: 100,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
  },
  loadMoreText: {
    fontFamily: 'Roboto-Medium',
    color: 'black',
    fontSize: 15,
    marginTop: 15,
    marginBottom: 12,
  },
});

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    jwt: selectors.user.getJwt(state),
    userId: selectors.user.getUserId(state),
    stations: selectors.media.stations.getStations(state),
    tunedTo: selectors.tunein.getTunedTo(state),
    isFav: selectors.media.stations.getIsFav(state),
  }),
  {
    setAlert: actions.app.setAlert,
    setRadioFav: actions.media.stations.setRadioFav,
    setStations: actions.media.stations.setStations,
    disableTuneIn: actions.tunein.disableTuneIn,
    setTrack: actions.media.player.setTrack,
    setCategory: actions.media.player.setCategory,
  },
)(withRouter(StationCategories));
