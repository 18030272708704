import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { withRouter } from 'react-router-dom';

import mainStyles from '../../styles';

const ListButton = ({ path, history, onPress, children, icon, disabled, testID }) => {
  const onClick = () => {
    path ? history.push(path) : onPress();
  };

  const textStyles = [mainStyles.typography.h2];
  if (disabled) textStyles.push(styles.textDisabled);

  return (
    <TouchableOpacity disabled={disabled} onPress={onClick} style={styles.button} testID={testID}>
      {icon && <Image source={icon} style={[styles.iconLeft, mainStyles.margin.right.md]} />}
      <Text style={textStyles}>{children}</Text>
      {path && <Image source={require('../../assets/icons/arrow/arrow_right.png')} style={mainStyles.iconRight} />}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    paddingTop: mainStyles.distance.md,
    paddingBottom: mainStyles.distance.md,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  iconLeft: {
    width: 20,
    height: 20,
  },
  textDisabled: {
    color: 'rgba(255,255,255,0.6)',
  },
});

export default withRouter(ListButton);
