import axios from 'axios';
import React, { Component } from 'react';
import { ActivityIndicator, FlatList, Platform, StyleSheet, Text, View } from 'react-native';
import { connect } from 'react-redux';

import CircleButton from '../../components/app/CircleButton';
import SquareButton from '../../components/app/SquareButton';
import { VerticalList } from '../../components/media/controls/VerticalList';
import PopularVideoComponent from '../../components/media/videos/PopularVideoComponent';
import TuneInUser from '../../components/tunein/TuneInUser';
import AppLayout from '../../layouts/app';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import mainStyles from '../../styles';
import { getRecommendations, getYoutubeList } from '../../utils/api/api';
import { fetchTitleById, updatePopupState } from '../../utils/DatabaseHelper';
import analytics from '../../utils/routing/analytics';
import { withRouter } from '../../utils/routing/Routing';
import Sentry from '../../utils/Sentry';

class MainMusicScreen extends Component {
  state = {
    recommendations: {
      albums: [],
      artists: [],
      playlists: [],
      tracks: [],
      popVideos: [],
      newVideos: [],
    },
    loading: true,
  };

  CancelToken = axios.CancelToken;

  source = this.CancelToken.source();

  componentDidMount() {
    const { spotifyCredentials, deezerCredentials, history, userId, getPlaylists } = this.props;

    if (Platform.OS === 'web') {
      this.onGetRecommendations();
      getPlaylists({ userId });
      if (spotifyCredentials.isAuthorized === true || deezerCredentials.isAuthorized === true || this.props.availableProviders.apple === true) {
        this.fetchPopupData();
      }
    } else {
      const { availableProviders } = this.props;
      if (spotifyCredentials.isAuthorized === false && deezerCredentials.isAuthorized === false && availableProviders.apple === false) {
        history.push('/providers');
      } else {
        this.onGetRecommendations();
        getPlaylists({ userId });
      }
    }
  }

  componentDidUpdate() {
    const { spotifyCredentials, deezerCredentials, availableProviders } = this.props;
    if (spotifyCredentials.isAuthorized === false && deezerCredentials.isAuthorized === false && availableProviders.apple === false) {
      console.log('not update');
    } else if (this.state.loading === true) {
      this.onGetRecommendations();
    }
  }

  componentWillUnmount() {
    this.source.cancel('Component unmounted');
  }

  onGetRecommendations() {
    const { appleToken, deezerCredentials, jwt, spotifyCredentials } = this.props;
    const services = [];

    if (appleToken) services.push({ name: 'apple', token: appleToken });
    if (Object.hasOwnProperty.call(deezerCredentials, 'token') && deezerCredentials.token !== null)
      services.push({ name: 'deezer', token: deezerCredentials.token });
    if (Object.hasOwnProperty.call(spotifyCredentials, 'token') && spotifyCredentials.token !== null)
      services.push({ name: 'spotify', token: spotifyCredentials.token });

    getRecommendations({ services, jwt, cancelToken: this.source.token })
      .then(recs => {
        const playlists = recs.filter(rec => rec.type === 'playlist');
        const albums = recs.filter(rec => rec.type === 'album');
        const artists = recs.filter(rec => rec.type === 'artist');
        const tracks = recs.filter(rec => rec.type === 'track');
        let dataArtist;
        let dataTracks;
        let dataAlbum;
        let query;
        let queryTwo;

        if ((spotifyCredentials.isAuthorized === true || deezerCredentials.isAuthorized === true) && artists.length !== 0) {
          dataArtist = artists.map(artist => {
            return {
              name: artist.name,
            };
          });

          dataTracks = tracks.map(track => {
            return {
              name: track.album.artistName,
            };
          });

          query = `popular music videos of ${dataArtist[0].name}, ${dataArtist[dataArtist.length - 1].name}`;
          queryTwo = `New music videos of ${dataTracks[0].name}, ${dataTracks[dataTracks.length - 1].name}`;
        }

        if (this.props.availableProviders.apple === true && albums.length > 0) {
          dataAlbum = albums.map(album => {
            return {
              name: album.artistName,
            };
          });

          query = `popular music videos of ${dataAlbum[0].name}, ${dataAlbum[dataAlbum.length - 1].name}`;
          queryTwo = `New music videos of ${dataAlbum[2].name}, ${dataAlbum[dataAlbum.length - 2].name}`;
        }

        if (artists.length > 0 && tracks.length > 0 && albums.length > 0) {
          query = `popular music videos of ${dataArtist[0].name}, ${dataArtist[dataArtist.length - 1].name}`;
          queryTwo = `New music videos of ${dataTracks[0].name}, ${dataTracks[dataTracks.length - 1].name}`;
        }

        if (artists.length === 0 && tracks.length === 0 && albums.length === 0) {
          query = 'popular music video';
          queryTwo = 'new music videos';
        }

        if (artists.length > 0 || tracks.length > 0 || albums.length > 0 || Platform.OS === 'web') {
          this.setState({
            recommendations: {
              artists,
              albums,
              playlists,
              tracks,
            },
            loading: false,
          });
        }

        getYoutubeList(query).then(response => {
          getYoutubeList(queryTwo).then(newResponse => {
            this.setState({
              recommendations: {
                // eslint-disable-next-line react/no-access-state-in-setstate
                ...this.state.recommendations,
                newVideos: newResponse.items,
                popVideos: response.items,
              },
            });
          });
        });
      })
      .catch(err => {
        Sentry.captureException(err);
      });
  }

  fetchItemImage(item) {
    let imageSrc = '';
    if (this.props?.playlistImageData?.data?.url && this.props.playlistImageData.data.playlistId === item.playlistId) {
      imageSrc = this.props.playlistImageData.data.url;
    } else if (item && item.customImage) {
      imageSrc = item.customImage;
    } else {
      imageSrc = item && item.source && item.source.image ? item.source.image : undefined;
    }
    return imageSrc;
  }

  async fetchPopupData() {
    try {
      const dbResult = await fetchTitleById('1');
      dbResult.forEach(item => {
        if (item.isPopupshowing === 'true') {
          this.props.setPopup({ connected: true });
          this.updatePopupData();
        }
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async updatePopupData() {
    try {
      await updatePopupState('1', 'false');
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  fetchPlaylistImage(item) {
    let imageSrc = '';
    const { playlistImageData } = this.props;
    if (playlistImageData && playlistImageData.data && playlistImageData.data.url && playlistImageData.data.playlistId === item.playlistId) {
      imageSrc = this.props.playlistImageData.data.url;
    } else if (item && item.customImage) {
      imageSrc = item.customImage;
    } else {
      imageSrc = item && item.source && item.source.image ? item.source.image : undefined;
    }
    return imageSrc;
  }

  playTrack(track) {
    if (this.props.tunedTo) {
      this.props.disableTuneIn();
      analytics().logEvent('stop_tunein');
    }
    this.props.emptyQueue();
    this.props.playTrack(track);
  }

  render() {
    const following = [{ ...this.props.profile }, ...this.props.following];
    const itemSeperator = () => <View style={{ minWidth: mainStyles.distance.sm }} />;

    return (
      <AppLayout title="music">
        {this.props.tuneStatus && (
          <View style={styles.tuneInLoader}>
            <ActivityIndicator color="rgba(255,142,12,1)" size={Platform.OS === 'android' ? 55 : 'large'} />
          </View>
        )}
        {following && following.length > 0 && (
          <View style={[mainStyles.margin.bottom.lg]}>
            <Text style={[mainStyles.typography.h1, mainStyles.margin.bottom.sm, styles.title]}>Tune in one</Text>
            <View style={styles.tuneInContainer} testID="tune-in-button">
              <FlatList
                data={following}
                getItemLayout={(data, index) => ({ length: 108, offset: 108 * index + 10, index })}
                horizontal
                initialNumToRender={5}
                ItemSeparatorComponent={itemSeperator}
                keyExtractor={item => item.username}
                maxToRenderPerBatch={5}
                removeClippedSubview
                renderItem={({ item }) => <TuneInUser key={`user-${item.username}`} user={item} />}
              />
            </View>
          </View>
        )}

        {this.props.playlists && this.props.playlists.length > 0 && (
          <VerticalList
            items={this.props.playlists.slice(0, 15)}
            itemSeparator={itemSeperator}
            keyExtractor={item => item.playlistId}
            listTitle="Playlists"
            renderItem={({ item }) => (
              <SquareButton
                imageUri={this.fetchItemImage(item)}
                path={`/playlists/${item.shortId}/`}
                provider={item.source ? item.source.provider : undefined}
                testID={`hotplaylist_${item.name}-button`}
                title={item.name}
              />
            )}
          />
        )}

        {this.state.loading && (
          <View style={styles.loadingContainer}>
            <ActivityIndicator color="rgba(255,142,12,1)" size="large" />
          </View>
        )}

        {this.state.recommendations.artists && this.state.recommendations.artists.length > 0 && (
          <VerticalList
            circle
            items={this.state.recommendations.artists.slice(0, 10)}
            itemSeparator={itemSeperator}
            keyExtractor={artist => artist.id}
            listTitle="Hot Artists"
            renderItem={({ item }) => (
              <CircleButton
                id={item.id}
                images={item.images}
                label={item.name}
                testID="hot-artist"
                url={`/artist/${item.provider}/${item.id}`}
                urlOpts={{ title: item.name, id: item.id, provider: item.provider }}
              />
            )}
          />
        )}

        {this.state.recommendations.tracks && this.state.recommendations.tracks.length > 0 && (
          <VerticalList
            items={this.state.recommendations.tracks.slice(0, 10)}
            itemSeparator={itemSeperator}
            keyExtractor={track => track.id}
            listTitle="Hot Tracks"
            renderItem={({ item }) => (
              <SquareButton
                active={this.props.currentPlayingTrack ? this.props.currentPlayingTrack.id === item.id : false}
                imageUri={item.album && item.album.images && item.album.images[0] && item.album.images[0].url}
                onPress={() => this.playTrack(item)}
                provider={item.provider}
                subtitle={item.artistName}
                testID={`hot-track-${item.artistName}-${item.name}`}
                title={item.name}
              />
            )}
          />
        )}

        {this.state.recommendations.albums && this.state.recommendations.albums.length > 0 && (
          <VerticalList
            items={this.state.recommendations.albums.slice(0, 10)}
            itemSeparator={itemSeperator}
            keyExtractor={album => album.id}
            listTitle="Hot Albums"
            renderItem={({ item }) => (
              <SquareButton
                imageUri={item.images && item.images.length > 0 && item.images[0].url}
                path={`/album/${item.provider}/${item.id}`}
                provider={item.provider}
                subtitle={item.artistName}
                testID="hot-album"
                title={item.name}
              />
            )}
          />
        )}

        {this.state.recommendations.popVideos && this.state.recommendations.popVideos.length > 0 && (
          <VerticalList
            items={this.state.recommendations.popVideos}
            itemSeparator={itemSeperator}
            keyExtractor={video => video.id.videoId}
            listTitle="Popular Videos"
            renderItem={({ item }) => (
              <PopularVideoComponent channelTitle={item.snippet.channelTitle} title={item.snippet.title} videoId={item.id.videoId} />
            )}
          />
        )}

        {this.state.recommendations.newVideos && this.state.recommendations.newVideos.length > 0 && (
          <VerticalList
            items={this.state.recommendations.newVideos}
            itemSeparator={itemSeperator}
            keyExtractor={video => video.id.videoId}
            listTitle="New Videos"
            renderItem={({ item }) => (
              <PopularVideoComponent channelTitle={item.snippet.channelTitle} title={item.snippet.title} videoId={item.id.videoId} />
            )}
          />
        )}
      </AppLayout>
    );
  }
}

const styles = StyleSheet.create({
  loadingContainer: {
    marginTop: 75,
  },
  horizontalScrollContainer: {
    marginRight: -mainStyles.APP_PADDING,
  },
  listItem: {
    flex: 1,
  },
  listItemCircleButton: {
    maxWidth: 120,
  },
  logo: {
    width: 124,
    height: 20,
    maxHeight: 20,
  },
  tuneInContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  tuneInLoader: {
    position: 'absolute',
    zIndex: 1,
    top: '30%',
    left: Platform.OS === 'android' ? '43%' : '45%',
    alignItems: 'center',
  },
});

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    appleToken: selectors.providers.apple.getToken(state),
    availableProviders: selectors.providers.getAvailableProviders(state),
    jwt: selectors.user.getJwt(state),
    deezerCredentials: selectors.providers.deezer.getDeezerCredentials(state),
    playlists: selectors.media.playlists.getPlaylists(state),
    spotifyCredentials: selectors.providers.spotify.getSpotifyCredentials(state),
    spotifyDeviceId: selectors.providers.spotify.getSpotifyDeviceId(state),
    userId: selectors.user.getUserId(state),
    currentPlayingTrack: selectors.media.player.getCurrentPlayingTrack(state),
    profilePicture: selectors.user.getProfilePicture(state),
    profile: selectors.user.getProfile(state),
    following: selectors.user.getFollowing(state),
    tunedTo: selectors.tunein.getTunedTo(state),
    playlistImageData: selectors.media.playlists.getImagePlaylist(state),
    tuneStatus: selectors.app.getTuneInStatus(state),
  }),
  {
    setSpotifyAuthToken: actions.providers.spotify.setAuthToken,
    setAlert: actions.app.setAlert,
    setPopup: actions.app.setPopup,
    getPlaylists: actions.media.playlists.getPlaylists,
    playTrack: actions.media.music.playTrack,
    emptyQueue: actions.media.queue.emptyPlayQueue,
    disableTuneIn: actions.tunein.disableTuneIn,
  },
)(withRouter(MainMusicScreen));
