/* eslint-disable no-nested-ternary */
import React from 'react';
import { ImageBackground, Platform, ScrollView, StyleSheet } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { connect } from 'react-redux';

import emailOtpBackground from '../assets/emailOtpBackgroundWeb.jpg';
import exclusiveBackground from '../assets/exclusiveBackgroundWeb.jpg';
import backgroundImage from '../assets/Rezzonation.jpg';
import signupBackground from '../assets/signupBackgroundWeb.jpg';
import AlertWindow from '../components/app/AlertWindow';
import AllPopups from '../components/app/AllPopups';
import DetectWebPopup from '../components/app/DetectWebPopup';
import NavBarRez from '../containers/app/NavBarRez';
import CMSuggestionModal from '../containers/media/CMSuggestionModal';
import selectors from '../store/selectors';
import mainStyles from '../styles';
import { getOS } from '../utils/DetectOS';
import { withRouter } from '../utils/routing/Routing';

const FullScreenLayout = ({
  children,
  moreOptionsHandler,
  profileBtn,
  title,
  alert,
  noPadding,
  popup,
  jwt,
  webPopup,
  background,
  backButton = true,
  backgroundSignup,
  backgroundEmailOtp,
  backgroundExclusive,
}) => {
  const containerStyles = [styles.container];
  if (!noPadding) containerStyles.push(styles.containerPadding);

  const colors =
    background || backgroundSignup || backgroundEmailOtp || backgroundExclusive
      ? ['rgba(60,33,91,0.8)', 'rgba(30,18,84,0.8)']
      : ['rgba(60,33,91,1)', 'rgba(30,18,84,1)'];

  return (
    <ImageBackground
      source={
        background
          ? backgroundImage
          : backgroundSignup
          ? signupBackground
          : backgroundEmailOtp
          ? emailOtpBackground
          : backgroundExclusive
          ? exclusiveBackground
          : backgroundImage
      }
      style={styles.overlay}
    >
      <LinearGradient colors={colors} style={styles.overlay}>
        {alert && !!alert.message && <AlertWindow alert={alert} />}
        {Platform.OS === 'web' && jwt && (getOS() === 'Android' || getOS() === 'iOS') && webPopup.validateDownloadPopup && (
          <DetectWebPopup os={getOS()} />
        )}
        <NavBarRez backButton={backButton} moreOptionsHandler={moreOptionsHandler} profileBtn={profileBtn} title={title} />
        {popup && <AllPopups />}
        <ScrollView contentContainerStyle={containerStyles}>{children}</ScrollView>
        <CMSuggestionModal />
      </LinearGradient>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  containerPadding: {
    paddingTop: mainStyles.APP_PADDING * 2,
    paddingLeft: mainStyles.APP_PADDING,
    paddingRight: mainStyles.APP_PADDING,
  },
  overlay: {
    flexGrow: 1,
    flex: 1,
  },
});

export default connect((state, ownProps) => ({
  ...ownProps,
  alert: selectors.app.getAlert(state),
  popup: selectors.app.getPopup(state),
  jwt: selectors.user.getJwt(state),
  webPopup: selectors.app.getWebDownloadPopup(state),
}))(withRouter(FullScreenLayout));
