import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import mainStyles from '../../../styles';
import { withRouter } from '../../../utils/routing/Routing';

const SearchAlbum = ({ artistName, images, name, history, id, provider }) => {
  const goToAlbum = () => history.push(`/album/${provider}/${id}`);

  const image =
    images && Array.isArray(images) && images.length > 0 ? (
      <Image resizeMode="cover" source={{ uri: images[0].url }} style={styles.img} />
    ) : (
      <View style={styles.placeholder} />
    );

  return (
    <TouchableOpacity onPress={goToAlbum} style={[styles.album, mainStyles.margin.bottom.sm, mainStyles.margin.top.sm]}>
      <View style={mainStyles.margin.right.sm}>{image}</View>
      <View style={styles.infoContainer}>
        <Text numberOfLines={1} style={mainStyles.typography.h2}>
          {name}
        </Text>
        <Text numberOfLines={1} style={mainStyles.typography.h2Light}>
          {artistName}
        </Text>
      </View>
      <Image source={require('../../../assets/icons/arrow/arrow_right.png')} style={mainStyles.iconRight} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  album: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  img: {
    width: mainStyles.SMALL_IMAGE_SIZE,
    height: mainStyles.SMALL_IMAGE_SIZE,
    borderRadius: mainStyles.IMAGE_BORDER_RADIUS,
  },
  infoContainer: {
    flex: 1,
  },
  placeholder: {
    width: mainStyles.SMALL_IMAGE_SIZE,
    height: mainStyles.SMALL_IMAGE_SIZE,
    backgroundColor: mainStyles.IMAGE_PLACEHOLDER_COLOR,
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({}, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchAlbum));
