import { StationSearchType } from 'radio-browser-api';
import React, { Component } from 'react';
import { ActivityIndicator, FlatList, Image, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { connect } from 'react-redux';

import TextInputRez from '../../components/app/TextInputRez';
import AppLayout from '../../layouts/app';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import mainStyles from '../../styles';
import * as consts from '../../styles/consts';
import { getRadio, sendDetailsToCM } from '../../utils/api/api';
import analytics from '../../utils/routing/analytics';
import { withRouter } from '../../utils/routing/Routing';

let pageNumber = 0;

class StationsScreen extends Component {
  state = {
    searchQuery: '',
    filterStation: [],
    loading: true,
    isLoadMore: true,
  };

  componentDidMount() {
    pageNumber = 0;
    this.searchStation(false, this.props.getCategory.radioCategory == null ? 'All' : this.props.getCategory.radioCategory);
  }

  groupBy(array, key) {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
      return result;
    }, {});
  }

  clickStation(station) {
    analytics().logEvent('play_radio', { station: station.name });
    if (this.props.tunedTo) {
      this.props.disableTuneIn();
      analytics().logEvent('stop_tunein');
    }
    this.props.setTrack({
      track: { ...station, artistName: 'Radio', provider: 'stream', album: { images: [{ url: station.favicon }] } },
      origin: 'radio',
    });
  }

  updateSearchQuery(searchQuery) {
    pageNumber = 0;
    analytics().logEvent('search_radio', { query: searchQuery });
    this.setState({
      searchQuery,
    });
    if (searchQuery === '') {
      this.searchStation(false, this.props.getCategory.radioCategory == null ? 'All' : this.props.getCategory.radioCategory);
    } else {
      this.searchStation(true, searchQuery);
    }
  }

  async searchStation(byName, searchValue) {
    this.setState({ isLoadMore: true });
    const cmActivity = {
      created: new Date(),
      userId: this.props.userId,
      searchRadio: searchValue,
    };
    sendDetailsToCM(cmActivity, consts.ACTIVITY_CM_KEY);
    const stations = await getRadio(byName ? StationSearchType.byName : StationSearchType.byTag, searchValue, 48, pageNumber);

    if (!byName) {
      stations.filter(obj => obj.votes > 50);
    }
    this.setState({ loading: false });

    if (pageNumber === 0) {
      this.setState({ filterStation: [] });
      this.setState({ filterStation: stations });
    } else {
      // eslint-disable-next-line react/no-access-state-in-setstate
      const joined = this.state.filterStation.concat(stations);
      this.setState({ filterStation: joined });
    }

    if (stations.length < 1) {
      this.setState({ isLoadMore: false });
    }
  }

  LoadMoreRandomData() {
    if (this.state.isLoadMore) {
      pageNumber += 1;
      this.searchStation(
        // eslint-disable-next-line no-unneeded-ternary
        this.state.searchQuery === '' ? false : true,
        // eslint-disable-next-line no-nested-ternary
        this.state.searchQuery === ''
          ? this.props.getCategory.radioCategory === null
            ? 'All'
            : this.props.getCategory.radioCategory
          : this.state.searchQuery,
      );
    }
  }

  render() {
    const { searchQuery, filterStation, isLoadMore } = this.state;

    const stationsToShow = filterStation.filter(radio => radio.name.match(new RegExp(searchQuery, 'i')));
    const regularStations = stationsToShow.filter(station => !station.favorite);
    return (
      <AppLayout title="radio">
        <View style={mainStyles.margin.bottom.md}>
          <TextInputRez
            icon={require('../../assets/icons/search/search.png')}
            onChangeText={e => this.updateSearchQuery(e)}
            placeholder="Search radio station..."
            testID="radiosearch-input"
          />
        </View>

        {this.state.loading && (
          <View style={styles.loadingContainer}>
            <ActivityIndicator color="rgba(255,142,12,1)" size="large" />
          </View>
        )}

        {regularStations.length > 0 && <Text style={mainStyles.typography.h1}>Stations</Text>}
        <View style={[styles.stationsContainer, mainStyles.margin.bottom.md]}>
          <FlatList
            columnWrapperStyle={{ justifyContent: 'space-between' }}
            data={regularStations}
            keyExtractor={(item, index) => item.id + index.toString()}
            numColumns={3}
            renderItem={({ item }) => (
              <TouchableOpacity
                key={item.stationuuid}
                onPress={() => this.clickStation(item)}
                style={[styles.station, mainStyles.margin.top.md]}
                testID={`radio_${item.name.toLowerCase().replace(' ', '_')}`}
              >
                <Image
                  defaultSource={require('../../assets/icons/radio/radio_mini.png')}
                  resizeMode="contain"
                  source={item.favicon === '' ? require('../../assets/icons/radio/radio_mini.png') : { uri: item.favicon }}
                  style={styles.stationLogo}
                />
                <Text
                  numberOfLines={1}
                  style={[
                    mainStyles.typography.h3,
                    mainStyles.margin.top.xs,
                    { textAlign: 'center', fontFamily: Platform.OS === 'android' ? 'Cabo-Rounded-Regular' : 'Cabo Rounded' },
                  ]}
                >
                  {item.name}
                </Text>
              </TouchableOpacity>
            )}
          />
        </View>

        {isLoadMore && regularStations.length > 0 && (
          <TouchableOpacity onPress={() => this.LoadMoreRandomData()}>
            <LinearGradient colors={mainStyles.BUTTON_GRADIENT} end={{ x: 0, y: 0.5 }} start={{ x: 1, y: 1 }} style={styles.loadMoreButton}>
              <Text style={styles.loadMoreText}>Load More</Text>
            </LinearGradient>
          </TouchableOpacity>
        )}
      </AppLayout>
    );
  }
}

const styles = StyleSheet.create({
  stationsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  loadingContainer: {
    marginTop: consts.FULL_HEIGHT / 3,
  },
  station: {
    paddingLeft: mainStyles.distance.xs,
    paddingRight: mainStyles.distance.xs,
    flexBasis: '30%',
  },
  stationLogo: {
    flex: 1,
    height: 120,
    width: 110,
    alignSelf: 'center',
    borderRadius: mainStyles.IMAGE_BORDER_RADIUS,
  },
  loadMoreButton: {
    marginBottom: 20,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: mainStyles.FORM_BORDER_RADIUS,
    paddingLeft: Platform.OS === 'web' ? 75 : 40,
    paddingRight: Platform.OS === 'web' ? 75 : 40,
  },
  loadMoreText: {
    fontFamily: 'Roboto-Medium',
    color: 'white',
    fontSize: 15,
    marginTop: 15,
    marginBottom: 12,
  },
});

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    jwt: selectors.user.getJwt(state),
    userId: selectors.user.getUserId(state),
    stations: selectors.media.stations.getStations(state),
    tunedTo: selectors.tunein.getTunedTo(state),
    isFav: selectors.media.stations.getIsFav(state),
    getCategory: selectors.media.player.getCategory(state),
  }),
  {
    setAlert: actions.app.setAlert,
    setRadioFav: actions.media.stations.setRadioFav,
    setStations: actions.media.stations.setStations,
    setTrack: actions.media.player.setTrack,
    disableTuneIn: actions.tunein.disableTuneIn,
  },
)(withRouter(StationsScreen));
