import React, { useState } from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import { widthPercentageToDP as wp } from 'react-native-responsive-screen';
import { connect } from 'react-redux';

import actions from '../../store/actions';
import selectors from '../../store/selectors';
import { deleteAccount } from '../../utils/api/api';
import analytics from '../../utils/routing/analytics';
import { withRouter } from '../../utils/routing/Routing';
import ButtonRez from '../app/ButtonRez';
import ModalRez from '../app/ModalRez';

const DeleteButton = ({ player, toggleProviderStatus, jwt, history, ...props }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const isProviderPlaying = () => Object.keys(player.statuses).find(prov => player.statuses[prov] === 'playing');

  const deleteUser = async () => {
    if (isProviderPlaying()) {
      toggleProviderStatus({ provider: player.track.provider, status: 'paused' });
    }

    if (Platform.OS !== 'web') await analytics().resetAnalyticsData();
    deleteAccount(jwt)
      .then(() => {
        props.setDeleteCMdata({ deleted: new Date() });
        props.logout();
        global.xmpp.logout();
        delete global.xmpp;
        history.push('/signin');
        props.setAlert({ message: 'Your account is deleted successfully', type: 'success' });
      })
      .catch(() => props.setAlert({ message: 'Unable to delete your account, Please try again later', type: 'error' }));
  };

  return (
    <View style={styles.deleteButton}>
      <ButtonRez
        fontColor="rgba(23,23,38,1)"
        label="Delete account"
        onPress={() => setShowDeleteModal(true)}
        startColor="#ffffff"
        testID="deleteAccount-button"
      />
      <ModalRez
        cancelButtonLabel="No"
        confirmButtonAction={() => {
          deleteUser();
        }}
        confirmButtonLabel="Yes"
        isVisible={showDeleteModal}
        onBackdropPress={() => setShowDeleteModal(false)}
        title="Confirmation"
      >
        <Text>Are you sure, you want to delete your account?</Text>
      </ModalRez>
    </View>
  );
};

const styles = StyleSheet.create({
  deleteButton: {
    width: wp('60%'),
  },
});

export default connect(
  state => ({
    player: selectors.media.player.getPlayer(state),
    jwt: selectors.user.getJwt(state),
  }),
  {
    toggleProviderStatus: actions.media.player.toggleProviderStatus,
    setAlert: actions.app.setAlert,
    logout: actions.user.logoutUser,
    setDeleteCMdata: actions.user.deletePersonalDetailsCMdata,
  },
)(withRouter(DeleteButton));
