import React, { useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import Logo from '../components/app/Logo';
import ContentTypeButton from '../components/media/ContentTypeButton';
import FullScreenLayout from '../layouts/fullscreen';
import mainStyles from '../styles';
import { withRouter } from '../utils/routing/Routing';

const ContentSelection = ({ history }) => {
  // eslint-disable-next-line no-unused-vars
  const [showAddToHomeScreen, setShowAddToHomeScreen] = useState(false);

  const clickAudio = () => history.push('/audio');

  return (
    <FullScreenLayout>
      <View style={StyleSheet.flatten([styles.addHomeScreen, { display: showAddToHomeScreen ? 'flex' : 'none' }])}>
        <Text>Add Resonation to your homescreen</Text>
        <TouchableOpacity style={{ backgroundColor: 'blue', height: 25 }}>
          <Text>Add to home screen</Text>
        </TouchableOpacity>
      </View>
      <View style={[styles.logoContainer, mainStyles.margin.bottom.xl]}>
        <Logo />
      </View>
      <View style={[mainStyles.margin.bottom.md, mainStyles.margin.top.lg]}>
        <ContentTypeButton handler={clickAudio} label="Audio" testID="audio-button" type="music" />
      </View>
      <View style={mainStyles.margin.bottom.md}>
        <ContentTypeButton disabled handler={clickAudio} label="Video" type="video" />
      </View>
      <View style={mainStyles.margin.bottom.md}>
        <ContentTypeButton disabled handler={clickAudio} label="Games" type="games" />
      </View>
      <View style={mainStyles.margin.bottom.md}>
        <ContentTypeButton disabled handler={clickAudio} label="Shopping" type="shopping" />
      </View>
      <View style={mainStyles.margin.bottom.md}>
        <ContentTypeButton disabled handler={clickAudio} label="Tickets" type="tickets" />
      </View>
    </FullScreenLayout>
  );
};

const styles = StyleSheet.create({
  addHomeScreen: {
    height: 100,
    backgroundColor: 'white',
    width: '100%',
  },
  logoContainer: {
    alignItems: 'center',
  },
});

export default withRouter(ContentSelection);
