import axios from 'axios';
import React, { useEffect } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';

import actions from '../../../store/actions';
import selectors from '../../../store/selectors';
import mainStyles from '../../../styles';
import { config } from '../../../utils/config';
import { withRouter } from '../../../utils/routing/Routing';
import ChannelHeaders from './ChannelHeader';

function SubscriptionsList(props) {
  useEffect(() => {
    getSubscriptionsFromBackEnd();
  }, []);

  const getSubscriptionsFromBackEnd = async () => {
    const { jwt } = props;

    const res = await axios({
      method: 'GET',
      url: `${config.API_URL}/podcasts/subscriptions`,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    if (res.status === 200) {
      props.storeSetSubscriptions({ subscriptions: res.data });
    }
  };

  const navigateToPodcastChannel = channel => {
    props.setChannel({ channel });
    props.history.push('/podcast/channel/episodes');
  };

  return (
    <View>
      {props.subscriptions.length > 0 && <Text style={[mainStyles.typography.h1, mainStyles.margin.bottom.sm]}>Subscriptions</Text>}
      {props.subscriptions.map(channel => (
        <TouchableOpacity
          key={channel.collectionId}
          onPress={() => navigateToPodcastChannel(channel)}
          style={mainStyles.margin.bottom.sm}
          testID={`podcastfavourite_${channel.collectionId}`}
        >
          <ChannelHeaders channel={channel} description="" />
        </TouchableOpacity>
      ))}
    </View>
  );
}

export default connect(
  state => ({
    ...state,
    subscriptions: selectors.podcast.subscriptions.getSubscriptions(state),
    jwt: selectors.user.getJwt(state),
  }),
  {
    setChannel: actions.podcast.search.setChannel,
    storeSetSubscriptions: actions.podcast.subscriptions.setSubscriptions,
  },
)(withRouter(SubscriptionsList));
