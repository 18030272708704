/* eslint-disable react/state-in-constructor */
// import * as Sentry from '@sentry/react-native';
import { Formik } from 'formik';
import React, { Component } from 'react';
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';

import ButtonRez from '../../components/app/ButtonRez';
import TextInputRez from '../../components/app/TextInputRez';
import FullScreenLayout from '../../layouts/fullscreen';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import mainStyles from '../../styles';
import { PERSONAL_DETAILS_CM_KEY } from '../../styles/consts';
import { getCountryOrigin, getUserProfile, resendOtp, sendDetailsToCM, verifyEmail } from '../../utils/api/api';
import analytics from '../../utils/routing/analytics';
import { withRouter } from '../../utils/routing/Routing';

class ValidateOtpScreen extends Component {
  enterOtpSchema = Yup.object().shape({
    enterotp: Yup.string()
      .required('Required')
      .matches(/^[0-9]+$/, 'code must consist of digits only')
      .test('len', 'code must be exactly 6 digits', val => val.length === 6),
  });

  constructor(props) {
    super(props);
    this.state = { timer: 60, loading: false, email: '', userId: '', displayName: '', policyCheckedTime: null, countryOrigin: '' };
  }

  componentDidMount() {
    const { userEmail } = this.props.match.params.userEmail ? this.props.match.params : this.props.location.state;
    const { userId } = this.props.match.params.userId ? this.props.match.params : this.props.location.state;
    const { userDisplayName } = this.props.match.params.userDisplayName ? this.props.match.params : this.props.location.state;
    const { privacyPolicyConsent } = this.props.match.params.privacyPolicyConsent ? this.props.match.params : this.props.location.state;
    this.setState({
      email: userEmail,
      userId,
      displayName: userDisplayName,
      policyCheckedTime: privacyPolicyConsent,
    });
    this.interval = setInterval(() => {
      this.decrementClock();
    }, 1000);
    getCountryOrigin().then(res => {
      this.setState({ countryOrigin: res.country });
    });
  }

  componentDidUpdate() {
    if (this.state.timer === 0) {
      clearInterval(this.interval);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleOtp({ enterotp }) {
    const { setUserCredentials, setUsername, setDisplayname, setXmppPassword, setPersonalDetailsCMdata } = this.props;
    const { userId } = this.state;
    const otp = enterotp;
    this.setState({
      loading: true,
    });

    verifyEmail({ userId, otp })
      .then(async data => {
        if (data && data.token && data.userId) {
          setUserCredentials({ jwt: data.token, userId: data.userId });
          setUsername({ username: data.username });
          setXmppPassword({ password: data.xmppPassword });
          await analytics().setUserId(data.userId);
          analytics().logEvent('verify_email');
        }
        this.props.setAlert(null);
        return data;
      })
      .then(data => {
        const jwt = data.token;
        const cmData = {
          userId: data.userId,
          createdAt: new Date(),
          email: this.state.email,
          username: data.username,
          firstName: data.firstName,
          lastName: data.lastName,
          displayName: this.state.displayName,
          privacyPolicyConsent: this.state.policyCheckedTime,
          countryOrigin: this.state.countryOrigin,
          role: 'user',
        };
        setPersonalDetailsCMdata(cmData);
        sendDetailsToCM(cmData, PERSONAL_DETAILS_CM_KEY);
        getUserProfile({ jwt })
          .then(profile => {
            this.props.setProfile({ profile });
            if (profile && profile.displayName) {
              setDisplayname({ displayName: profile.displayName });
            }
            this.goToAdditionalInfo();
          })
          .catch(err => {
            console.log('Error verifying email: ', err);
            // eslint-disable-next-line no-undef
            Sentry.captureMessage(err);
            this.goToAdditionalInfo();
          });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loading: false,
        });

        if (err && err.response && err.response.data) {
          this.props.setAlert({
            type: 'error',
            message: this.errorTexts[err.response.data.error] || this.errorTexts.default,
          });
        }
      });
  }

  handleResendOtp() {
    const { email } = this.state;
    this.setState({
      loading: true,
    });
    analytics().logEvent('resend_otp');

    resendOtp({ email })
      // eslint-disable-next-line no-unused-vars
      .then(res => {
        this.props.setAlert({ message: 'Otp sent successfully..', type: 'success' });
        this.setState({ timer: 60, loading: false });
        this.interval = setInterval(() => {
          this.decrementClock();
        }, 1000);
      })
      .catch(() => {
        this.setState({ loading: false });
        this.props.setAlert({
          type: 'error',
          message: 'Something went wrong. Please try again later',
        });
      });
  }

  decrementClock = () => {
    this.setState(prevstate => ({ timer: prevstate.timer - 1 }));
  };

  goToAdditionalInfo() {
    const { history } = this.props;
    history.entries = [];
    history.index = -1;
    history.push('/exclusive-content', {
      userId: this.state.userId,
    });
  }

  render() {
    const { loading } = this.state;
    return (
      <FullScreenLayout backgroundEmailOtp>
        <View style={styles.container}>
          <View>
            <Text style={[mainStyles.typography.p, mainStyles.typography.textCenter, mainStyles.margin.bottom.xl]}>
              Please enter the security code we send to {this.state.email}
            </Text>
            <Formik
              initialValues={{ enterotp: '' }}
              onSubmit={values => this.handleOtp(values)}
              validateOnBlur
              validationSchema={this.enterOtpSchema}
            >
              {props => (
                <View>
                  <View style={mainStyles.margin.bottom.md}>
                    <TextInputRez
                      autoCapitalize="none"
                      autoCorrect={false}
                      autoFocus={false}
                      enablesReturnKeyAutomatically
                      keyboardType="numeric"
                      label="Enter OTP"
                      maxLength={4}
                      name="enterotp"
                      onBlur={props.handleBlur('enterotp')}
                      onChangeText={props.handleChange('enterotp')}
                      onSubmitEditing={() =>
                        props.validateForm().then(() => {
                          if (props.isValid) this.handleOtp(props.values);
                        })
                      }
                      placeholder="Please enter code..."
                      testID="enterotp-input"
                      validation={{ error: props.errors.enterotp, touched: props.touched.enterotp }}
                      value={props.values.enterotp}
                    />
                  </View>

                  {!loading && (
                    <View style={[mainStyles.margin.bottom.md]}>
                      <ButtonRez
                        disabled={!props.isValid}
                        fontColor="rgba(23,23,38,1)"
                        label="Continue"
                        large
                        onPress={props.handleSubmit}
                        startColor="rgba(255,255,255,1)"
                        testID="otp-button"
                      />
                    </View>
                  )}
                </View>
              )}
            </Formik>

            {!loading && (
              <View style={[mainStyles.margin.bottom.md]}>
                <ButtonRez
                  disabled={this.state.timer !== 0}
                  fontColor="rgba(23,23,38,1)"
                  label={this.state.timer !== 0 ? `resend otp (${this.state.timer})` : 'resend otp'}
                  large
                  onPress={() => this.handleResendOtp()}
                  startColor="rgba(255,142,12,1)"
                  testID="resend-otp-button"
                />
              </View>
            )}
            {loading && (
              <View style={mainStyles.loading}>
                <ActivityIndicator color="rgba(255,142,12,1)" size="large" />
              </View>
            )}
          </View>
        </View>
      </FullScreenLayout>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-end',
    paddingBottom: '10%',
  },
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        setUserCredentials: actions.user.setUserCredentials,
        setAlert: actions.app.setAlert,
        setProfile: actions.user.setProfile,
        setJWT: actions.user.setJWT,
        setUserId: actions.user.setUserId,
        setUsername: actions.user.setUsername,
        setDisplayname: actions.user.setDisplayname,
        setXmppPassword: actions.user.setXmppPassword,
        setPersonalDetailsCMdata: actions.user.setPersonalDetailsCMdata,
      },
      dispatch,
    ),
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    jwt: selectors.user.getJwt(state),
    userId: selectors.user.getUserId(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ValidateOtpScreen));
