import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import mainStyles from '../../../styles';

const CreatePlaylistButton = ({ onCreateClick }) => {
  return (
    <TouchableOpacity onPress={onCreateClick} style={styles.createButton} testID="createplaylist-button">
      <View style={styles.createButtonIconContainer}>
        <Image source={require('../../../assets/icons/plus/plus.png')} style={styles.createButtonIcon} />
      </View>
      <Text style={[mainStyles.typography.p, mainStyles.margin.left.sm]}>Create new playlist</Text>
    </TouchableOpacity>
  );
};
const styles = StyleSheet.create({
  createButton: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexGrow: 1,
    maxHeight: mainStyles.SMALL_IMAGE_SIZE,
  },
  createButtonIconContainer: {
    borderWidth: 1,
    borderColor: 'rgba(255,255,255,1)',
    borderRadius: mainStyles.IMAGE_BORDER_RADIUS,
    maxWidth: mainStyles.SMALL_IMAGE_SIZE,
    height: mainStyles.SMALL_IMAGE_SIZE,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  createButtonIcon: {
    width: 24,
    height: 24,
  },
});

export default CreatePlaylistButton;
