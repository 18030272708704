import { Platform } from 'react-native';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { ACTIVITY_CM_KEY } from '../../styles/consts';
import { search, sendDetailsToCM } from '../../utils/api/api';
import { getUserId } from '../selectors/user';
import types from '../types';

const BLACKLISTED_WORDS = ['single', 'spotify', 'recorded'];
const DURATION_SPREAD_TOLERANCE = 1000;

const removeDupesFromString = str =>
  Array.from(new Set(str.split(' ')))
    .toString()
    .replace(/,/g, ' ');

const removeBlacklistedWordsFromQuery = query =>
  query
    .split(' ')
    .filter(word => !BLACKLISTED_WORDS.includes(word.toLowerCase().trim()))
    .join(' ');

const prepareQueryForProviderSearch = query => {
  query = removeDupesFromString(query);
  query = removeBlacklistedWordsFromQuery(query);
  return query.replace(/\s\s/g, ' ');
};

const formatCMName = str =>
  str
    .toLowerCase()
    .normalize()
    .replace(/[^a-z\d\s:]/g, '')
    .replace(/\s+/g, ' ')
    .trim();

const isSameCMName = (a, b) => formatCMName(a) === formatCMName(b);

const isSameCMDuration = (a, b) => a < b + DURATION_SPREAD_TOLERANCE && a > b - DURATION_SPREAD_TOLERANCE;

const hasCrossMatchAttributes = track => track.name && track.album && track.album.name && track.duration;

const isUniqueCMTrack = (originalTrack, compareTrack) =>
  hasCrossMatchAttributes(originalTrack) &&
  hasCrossMatchAttributes(compareTrack) &&
  (!isSameCMName(originalTrack.name, compareTrack.name) || !isSameCMName(originalTrack.album.name, compareTrack.album.name)) &&
  !isSameCMDuration(originalTrack.duration, compareTrack.duration);

const filterNonUniqueCrossMatchTracks = (originalTrack, cmTracks) => cmTracks.filter(cmTrack => isUniqueCMTrack(originalTrack, cmTrack));

const filterAlternativeDuplicates = alternativeTracks =>
  [alternativeTracks[0]].concat(alternativeTracks.reduce((accum, altTrack) => filterNonUniqueCrossMatchTracks(altTrack, accum), []));

function* checkForAlternatives(track, potentionalTrackAlternatives) {
  let alternatives = filterNonUniqueCrossMatchTracks(track, potentionalTrackAlternatives);
  if (alternatives.length > 0) {
    alternatives = filterAlternativeDuplicates(alternatives);
    alternatives = alternatives.map(alt => ({
      ...alt,
      crossMatched: true,
    }));

    yield put({
      type: types.media.music.SET_CROSS_MATCH,
      payload: {
        track,
        suggestions: alternatives.slice(0, 5),
      },
    });
  }
}

function* matchTrackOnProperties(track, providers, jwt, searchMode) {
  let args = { providers, jwt };

  if (searchMode === 'isrc') {
    args = track.isrc ? { ...args, isrc: track.isrc } : args;
  } else if (searchMode === 'query') {
    let query = track.name;
    query = track.album && track.album.name ? `${query} ${track.album.name}` : query;
    query = track.artistName ? `${query} ${track.artistName}` : query;
    args.query = prepareQueryForProviderSearch(query);
  }

  const res = yield call(search, args);
  if (!res || res.results.tracks.length === 0 || res.results.tracks[0] === null) {
    if (searchMode === 'isrc') {
      yield fork(matchTrackOnProperties, track, providers, jwt, 'query');
      return;
    }

    yield put({
      type: types.app.SET_ALERT,
      payload: { message: "Sorry, we couldn't find that track.", type: 'error' },
    });
    return;
  }

  const searchTrackResults = res.results.tracks;
  if (searchTrackResults.length > 0) {
    yield call(checkForAlternatives, track, searchTrackResults);
  }

  const foundTrack = searchTrackResults[0];
  foundTrack.crossMatched = true;

  yield put({ type: types.media.player.SET_CROSS_MATCHED, payload: { provider: track.provider, originalId: track.id } });
  yield put({ type: types.media.music.PLAY_TRACK, payload: { track: foundTrack } });
}

function getProviders(store) {
  return store.providers.providers.availableProviders;
}

function* playTrack(action) {
  console.log('Play track saga');
  console.log(action);
  const { track } = action.payload;
  const userId = yield select(state => getUserId(state));
  const cmActivity = {
    created: new Date(),
    userId,
    newSongPlaying: track.id,
    origin: 'playlist',
  };
  yield sendDetailsToCM(cmActivity, ACTIVITY_CM_KEY);
  const spoitifyPosition = !('trackPosition' in track) ? 0 : track.trackPosition / 1000;
  const providers = yield select(getProviders);
  if (providers[track.provider]) {
    if (!track.crossMatched) {
      yield put({ type: types.media.player.SET_CROSS_MATCHED, payload: { provider: undefined, originalId: undefined } });
    }
    switch (track.provider) {
      case 'apple':
        if (Platform.OS === 'android') {
          yield put({ type: types.providers.apple.PLAY_TRACK, payload: { track } });
          yield put({
            type: types.media.music.LOAD_NEW_MUSIC_TRACK,
            payload: { provider: 'apple', track: { ...track, provider: 'apple' }, origin: 'music' },
          });
        } else {
          yield put({ type: types.providers.apple.PLAY_TRACK, payload: { track: track.id } });
        }
        break;
      case 'deezer':
        yield put({ type: types.providers.deezer.PLAY_TRACK, payload: { track } });
        yield put({
          type: types.media.music.LOAD_NEW_MUSIC_TRACK,
          payload: { provider: 'deezer', track: { ...track, status: 'playing' }, origin: 'music' },
        });
        break;
      case 'spotify':
        yield put({
          type: types.providers.spotify.PLAY_TRACK,
          payload: {
            track: track.uri,
            startIndex: 0,
            startPosition: spoitifyPosition,
          },
        });
        break;
      default:
        console.log('playNewQueue: unknown provider');
    }
  } else if (!track.crossMatched) {
    const searchProviders = [];
    const jwt = yield select(store => store.user.jwt);
    if (providers.spotify === true) searchProviders.push({ name: 'spotify', token: yield select(store => store.providers.spotify.auth.token) });
    if (providers.apple === true) searchProviders.push({ name: 'apple', token: yield select(store => store.providers.apple.token) });
    if (providers.deezer === true) searchProviders.push({ name: 'deezer', token: yield select(store => store.providers.deezer.auth.token) });

    if (searchProviders.length > 0) {
      const searchMode = track.isrc ? 'isrc' : 'query';
      yield fork(matchTrackOnProperties, track, searchProviders, jwt, searchMode);
    }
  }
}

export function* watchPlayMusic() {
  yield takeEvery(types.media.music.PLAY_TRACK, playTrack);
}
