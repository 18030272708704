import { StyleSheet } from 'react-native';

import * as consts from './consts';

export default StyleSheet.create({
  app: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: consts.FULL_WIDTH,
    height: consts.FULL_HEIGHT,
  },
  button: {
    height: 50,
    borderRadius: 25,
    backgroundColor: 'rgba(255,255,255,1)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonFooterLabel: {
    fontSize: 12,
    fontWeight: '100',
    marginTop: 8,
  },
  buttonLabel: {
    color: 'rgba(23,23,38,1)',
  },
  centerFlex: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    marginTop: 16,
    textAlign: 'center',
  },
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  errorContainer: {
    borderWidth: 2,
    borderRadius: 25,
    borderColor: 'red',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  title: {
    marginTop: 30,
  },
  icon: {
    width: 18,
    height: 18,
  },
  iconRight: {
    width: 16,
    height: 16,
    marginLeft: 'auto',
  },
});
