import { call, select, takeEvery } from 'redux-saga/effects';

import { seekTo } from '../../utils/player';
import types from '../types';

function* seekPosition({ payload }) {
  const player = yield select(state => state.media.player);
  yield call(seekTo, payload.position, player.track);
}

export function* watchSeekActions() {
  yield takeEvery(types.media.seekbar.SEEK_POSITION, seekPosition);
}
