import axios from 'axios';
import { addSeconds, isBefore, parseISO } from 'date-fns';

export const durationToTime = duration => {
  const secs = duration / 1000;
  const sec_num = parseInt(secs, 10);
  const hours = Math.floor(sec_num / 3600);
  const minutes = Math.floor(sec_num / 60) % 60;
  const seconds = sec_num % 60;

  return [hours, minutes, seconds]
    .map(v => (v < 10 ? `0${v}` : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':');
};

export const getRecommendedTracksBasedOnTracks = (token, tracks) => {
  const seedTracks = tracks.length > 5 ? tracks.sort(() => Math.random() - 0.5).slice(0, 5) : tracks;

  return axios({
    url: 'https://api.spotify.com/v1/recommendations',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    params: {
      seed_tracks: seedTracks.join(','),
      market: 'NL',
    },
  });
};

export const isAuthorized = ({ token, expires, created }) => {
  if (token === undefined || token === null) return false;

  const expiryDate = addSeconds(parseISO(created), expires);
  return isBefore(expiryDate, new Date());
};
