import types from '../../types';

const initialState = {
  originUrl: null,
  alert: {
    message: '',
    type: '',
  },
  popup: {
    connected: false,
    moreFun: false,
    showSendText: false,
    sendMessage: false,
    showSuccessfullyInvited: false,
    notNow: false,
  },
  showDownloadPopup: {
    validateDownloadPopup: false,
    visibleDownloadPopup: false,
  },
  showOverlay: false,
  pushNotificationsRequested: false,
  loadingTuneIn: true,
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case types.app.SET_ORIGIN_URL:
      return {
        ...state,
        originUrl: payload.origin,
      };
    case types.app.SET_ALERT:
      return {
        ...state,
        alert: {
          ...payload,
        },
      };
    case types.app.SET_POPUP:
      return {
        ...state,
        popup: {
          ...payload,
        },
      };
    case types.app.SET_WEB_DOWNLOAD_POPUP:
      return {
        ...state,
        showDownloadPopup: {
          ...payload,
        },
      };
    case types.app.SHOW_OVERLAY:
      return {
        ...state,
        showOverlay: true,
      };
    case types.app.HIDE_OVERLAY:
      return {
        ...state,
        showOverlay: false,
      };
    case types.app.SET_PUSH_NOTIFICATIONS_REQUESTED:
      return {
        ...state,
        pushNotificationsRequested: true,
      };
    case types.app.SET_TUNEIN_STATUS:
      return {
        ...state,
        loadingTuneIn: payload,
      };
    case types.user.LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
