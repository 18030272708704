import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import mainStyles from '../../styles';

const ButtonRez = ({
  btnStyle,
  children,
  disabled,
  endColor,
  fontColor,
  icon,
  inline,
  large,
  onPress,
  label,
  small,
  secondary,
  startColor,
  testID,
}) => {
  let gradientColors = [];
  if (!startColor && !endColor) {
    gradientColors = secondary ? mainStyles.BUTTON_GRADIENT_SECONDARY : mainStyles.BUTTON_GRADIENT;
  } else {
    gradientColors = endColor ? [startColor, endColor] : [startColor, startColor];
  }

  const buttonStyles = [styles.button, btnStyle];
  const gradientStyles = [styles.gradient];
  const labelStyles = small ? [mainStyles.typography.navigationSmall] : [mainStyles.typography.navigation];

  if (secondary) gradientStyles.push(styles.gradientSecondary);
  if (disabled) gradientStyles.push(styles.gradientDisabled);

  if (inline) buttonStyles.push(styles.buttonInline);
  if (small) {
    gradientStyles.push(styles.gradientSm);
    buttonStyles.push(styles.buttonSm);
  }
  if (large) {
    gradientStyles.push(styles.gradientLg);
    buttonStyles.push(styles.buttonLg);
  }

  if (icon) labelStyles.push(mainStyles.margin.left.md);
  if (fontColor) labelStyles.push({ color: fontColor });

  return (
    <TouchableOpacity disabled={disabled} onPress={onPress} style={buttonStyles} testID={testID}>
      <LinearGradient colors={gradientColors} end={{ x: 0, y: 0.5 }} start={{ x: 1, y: 1 }} style={gradientStyles}>
        {icon && <Image resizeMode="contain" source={icon} style={styles.icon} />}
        {label === 'Share Profile' || label === 'Invite Friends' ? (
          <Text style={{ fontFamily: 'Roboto-Medium', color: 'white', fontSize: 15 }}>{label}</Text>
        ) : (
          <Text style={labelStyles}>{label}</Text>
        )}
        {children}
      </LinearGradient>
    </TouchableOpacity>
  );
};

export default ButtonRez;

const styles = StyleSheet.create({
  button: {
    height: 40,
    width: '100%',
  },
  buttonInline: {
    width: 'auto',
  },
  buttonSm: {
    height: 30,
  },
  buttonLg: {
    height: 50,
  },
  icon: {
    width: 20,
    height: 20,
  },
  gradient: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: mainStyles.FORM_BORDER_RADIUS,
  },
  gradientSm: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  gradientLg: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  gradientSecondary: {
    borderWidth: mainStyles.FORM_BORDER_WIDTH,
    borderColor: mainStyles.FORM_BORDER_COLOR,
    borderRadius: mainStyles.FORM_BORDER_RADIUS,
  },
  gradientDisabled: {
    opacity: mainStyles.FORM_INACTIVE_OPACITY,
  },
});
