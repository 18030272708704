import React, { Component } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';

import ButtonRez from '../../components/app/ButtonRez';
import Track from '../../components/media/tracks/Track';
import ListScreenHeader from '../../containers/app/ListScreenHeader';
import AppLayout from '../../layouts/app';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import mainStyles from '../../styles';
import { getAlbum, getSpotifyAccountInfo } from '../../utils/api/api';
import analytics from '../../utils/routing/analytics';
import { withRouter } from '../../utils/routing/Routing';

class AlbumScreen extends Component {
  state = {
    album: null,
    loading: true,
  };

  componentDidMount() {
    this.getAlbum(this.props);
  }

  async getAlbum(props) {
    let countryCode = null;
    const { jwt, location, tokens } = props;
    const {
      params: { id, provider },
    } = matchPath(location.pathname, {
      path: '/album/:provider/:id',
    });

    if (!tokens[provider]) return false;

    if (this.props.spotifyCredentials.token) {
      const response = await getSpotifyAccountInfo(this.props.spotifyCredentials.token);
      countryCode = response.data.country;
    }
    const album = await getAlbum({ albumId: id, token: tokens[provider], provider, jwt, countryCode });
    this.setState({
      album,
      loading: false,
    });
  }

  playAlbum() {
    const { album } = this.state;
    const { startQueue, emptyQueue } = this.props;

    analytics().logEvent('play_album', { name: album.name });

    emptyQueue();

    const { tracks } = album;

    startQueue({ tracks, index: 0 });
  }

  render() {
    const { album } = this.state;

    const imageSrc = album && album.images && Array.isArray(album.images) && album.images.length > 0 ? album.images[0].url : null;

    if (album && album !== null) {
      let tracks = [];
      let isVisible;
      let provider;
      if (album && album.tracks) {
        tracks = album.tracks.map((track, index) => {
          isVisible = track.isVisible;
          provider = track.provider;
          return (
            <Track
              key={`track-${track.id}`}
              disabled={!track.isVisible && track.provider === 'spotify'}
              queue={album.tracks}
              queueIndex={index}
              track={track}
            />
          );
        });
      }

      return (
        <AppLayout title={album && album.name}>
          {this.state.loading && (
            <View style={styles.loadingContainer}>
              <ActivityIndicator color="rgba(255,142,12,1)" size="large" />
            </View>
          )}
          {!this.state.loading && album && Object.keys(album).length > 0 && (
            <View>
              <ListScreenHeader image={imageSrc} subtitle={album && album.artistName} title={album && album.name} />

              <View style={[styles.controlContainer, mainStyles.margin.bottom.sm]}>
                <ButtonRez disabled={!isVisible && provider === 'spotify'} inline label="Play" onPress={() => this.playAlbum()} />
              </View>
              {tracks.length > 0 && (
                <View style={mainStyles.margin.bottom.md} testID="tracklist">
                  {tracks}
                </View>
              )}
            </View>
          )}
        </AppLayout>
      );
    }
    return <AppLayout />;
  }
}

const styles = StyleSheet.create({
  loadingContainer: {
    marginTop: 75,
  },
  controlContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    jwt: selectors.user.getJwt(state),
    spotifyCredentials: selectors.providers.spotify.getSpotifyCredentials(state),
    tokens: selectors.providers.getTokens(state),
  }),
  {
    emptyQueue: actions.media.queue.emptyPlayQueue,
    startQueue: actions.media.queue.startQueue,
  },
)(withRouter(AlbumScreen));
