import React from 'react';
import { connect } from 'react-redux';

import selectors from '../../../store/selectors';
import { getDurationFromProvider } from '../../../utils/media/position';
import SeekbarInner from './SeekbarInner';

class Seekbar extends React.Component {
  render() {
    return <SeekbarInner {...this.props} getDurationFromProvider={() => getDurationFromProvider(this.props.player.track.provider)} />;
  }
}

export default connect(state => {
  return {
    ...state,
    player: selectors.media.player.getPlayer(state),
    tunedTo: selectors.tunein.getTunedTo(state),
  };
})(Seekbar);
