import React, { Component } from 'react';
import { Image, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';

import mainStyles from '../../styles';
import ButtonRez from './ButtonRez';

export default class TextInputRez extends Component {
  state = {
    isHighlighted: false,
    isSecureInput: this.props.secureTextEntry,
  };

  onFocus() {
    this.setState({ isHighlighted: true });
  }

  onBlur() {
    const { value, onBlur } = this.props;
    if (!onBlur) {
      return;
    }
    if (!value) this.setState({ isHighlighted: false });
    if (onBlur) onBlur();
  }

  onRightIconPress() {
    this.setState(prevState => ({ isSecureInput: !prevState.isSecureInput }));
  }

  render() {
    const {
      icon,
      disabled,
      enablesReturnKeyAutomatically,
      autoCorrect,
      buttonLabel,
      defaultValue,
      onChangeText,
      name,
      keyboardType,
      placeholder,
      secureTextEntry,
      value,
      label,
      validation,
      onButtonPress,
      onSubmitEditing,
      testID,
      testIDButton,
    } = this.props;
    const { isHighlighted } = this.state;

    const textInputStyles = [mainStyles.typography.p, mainStyles.margin.left.md, mainStyles.margin.right.md, styles.input];
    const inputContainerStyles = [styles.inputContainer];
    if (icon) textInputStyles.push(styles.inputWithIcon);
    if (isHighlighted && !disabled) inputContainerStyles.push(styles.inputContainerActive);

    if (validation && validation.touched) {
      if (validation.error) {
        inputContainerStyles.push(styles.inputValidationError);
      } else {
        inputContainerStyles.push(styles.inputValidationSuccess);
      }
    }

    return (
      <View>
        {label && <Text style={[mainStyles.typography.h3, mainStyles.margin.bottom.xs]}>{label}</Text>}
        <View style={inputContainerStyles}>
          {icon && <Image pointerEvents="none" source={icon} style={[mainStyles.icon, styles.iconLeft, mainStyles.margin.left.md]} />}
          <TextInput
            autoCapitalize="none"
            autoCorrect={autoCorrect}
            autoFocus={false}
            defaultValue={defaultValue}
            disabled={disabled}
            enablesReturnKeyAutomatically={enablesReturnKeyAutomatically}
            keyboardType={keyboardType}
            name={name}
            onBlur={() => this.onBlur()}
            onChangeText={onChangeText}
            onFocus={() => this.onFocus()}
            onSubmitEditing={onSubmitEditing}
            placeholder={placeholder}
            placeholderTextColor={mainStyles.TEXT_COLOR}
            secureTextEntry={this.state.isSecureInput}
            style={textInputStyles}
            testID={testID}
            value={value}
          />
          {onButtonPress && buttonLabel && (
            <View style={mainStyles.margin.right.sm}>
              <ButtonRez inline label="Ok" onPress={onButtonPress} small testID={testIDButton} />
            </View>
          )}
          {secureTextEntry && (
            <TouchableOpacity onPress={() => this.onRightIconPress()}>
              {this.state.isSecureInput ? (
                <Image source={require('../../assets/icons/eye/eye_off.png')} style={[mainStyles.icon, mainStyles.margin.right.md]} />
              ) : (
                <Image source={require('../../assets/icons/eye/eye.png')} style={[mainStyles.icon, mainStyles.margin.right.md]} />
              )}
            </TouchableOpacity>
          )}
          {validation && validation.error && validation.touched && (
            <Image source={require('../../assets/icons/alert/alert_red.png')} style={[mainStyles.icon, mainStyles.margin.right.md]} />
          )}
          {validation && !validation.error && validation.touched && (
            <Image source={require('../../assets/icons/check/check_small.png')} style={[mainStyles.icon, mainStyles.margin.right.md]} />
          )}
        </View>
        {validation && validation.error && validation.touched && (
          <View style={[styles.validationResultContainer, mainStyles.margin.top.xs]}>
            <Image source={require('../../assets/icons/alert/alert_red.png')} style={[styles.iconSm, mainStyles.margin.right.xs]} />
            <Text style={[mainStyles.typography.p]}>{validation && validation.error}</Text>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  validationResultContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputContainer: {
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: mainStyles.FORM_ELEMENT_HEIGHT,
    opacity: mainStyles.FORM_INACTIVE_OPACITY,
    borderWidth: mainStyles.FORM_BORDER_WIDTH,
    borderColor: mainStyles.FORM_BORDER_COLOR,
    borderRadius: mainStyles.FORM_BORDER_RADIUS,
  },
  inputContainerActive: {
    opacity: 1,
  },
  iconLeft: {
    position: 'absolute',
    left: 0,
  },
  iconSm: {
    width: 10,
    height: 10,
  },
  input: {
    height: 50,
    flex: 1,
  },
  inputWithIcon: {
    paddingLeft: 38,
  },
  inputValidationError: {
    borderColor: mainStyles.colours.red,
  },
  inputValidationSuccess: {
    borderColor: mainStyles.colours.green,
  },
});
