import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity } from 'react-native';

import mainStyles from '../../styles';
import { withRouter } from '../../utils/routing/Routing';

const SquareButton = ({ onPress, path, urlOpts, history, title, subtitle, imageUri, active, testID }) => {
  const handlePress = () => {
    if (onPress) onPress();
    if (path) history.push(path, urlOpts || {});
  };
  const titleStyles = [mainStyles.typography.h3, mainStyles.margin.top.sm];
  const subtitleStyles = [mainStyles.typography.p];
  if (active) titleStyles.push(styles.titleActive);

  return (
    <TouchableOpacity onPress={handlePress} style={styles.container} testID={testID}>
      {imageUri ? (
        <Image resizeMode="cover" source={{ uri: imageUri }} style={styles.image} />
      ) : (
        <Image resizeMode="cover" source={require('../../assets/logos/one/logo.png')} style={[styles.placeholder, styles.image]} />
      )}
      {title && (
        <Text numberOfLines={1} style={titleStyles}>
          {title}
        </Text>
      )}
      {subtitle && (
        <Text numberOfLines={1} style={subtitleStyles}>
          {subtitle}
        </Text>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: mainStyles.SQUARE_BUTTON_WIDTH,
  },
  image: {
    width: mainStyles.SQUARE_BUTTON_WIDTH,
    height: mainStyles.SQUARE_BUTTON_WIDTH,
    borderRadius: mainStyles.IMAGE_BORDER_RADIUS,
  },
  placeholder: {
    backgroundColor: mainStyles.IMAGE_PLACEHOLDER_COLOR,
  },
  titleActive: {
    color: mainStyles.colours.orange,
  },
});

export default withRouter(SquareButton);
