import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import mainStyles from '../../../styles';
import { withRouter } from '../../../utils/routing/Routing';

const SearchArtist = ({ images, name, history, id, provider }) => {
  const goToArtist = () => history.push(`/artist/${provider}/${id}`);

  const image =
    images && Array.isArray(images) && images.length > 0 ? (
      <Image resizeMode="cover" source={{ uri: images[0].url }} style={styles.image} />
    ) : (
      <View style={styles.placeholder} />
    );

  return (
    <TouchableOpacity onPress={goToArtist} style={[styles.artist, mainStyles.margin.bottom.sm, mainStyles.margin.top.sm]}>
      <View style={mainStyles.margin.right.sm}>{image}</View>
      <View style={styles.infoContainer}>
        <Text numberOfLines={1} style={mainStyles.typography.h2}>
          {name}
        </Text>
        <Text numberOfLines={1} style={mainStyles.typography.h2Light}>
          Artist
        </Text>
      </View>
      <Image source={require('../../../assets/icons/arrow/arrow_right.png')} style={mainStyles.iconRight} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  artist: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  image: {
    width: mainStyles.SMALL_IMAGE_SIZE,
    height: mainStyles.SMALL_IMAGE_SIZE,
    borderRadius: mainStyles.SMALL_IMAGE_SIZE / 2,
  },
  infoContainer: {
    flex: 1,
  },
  placeholder: {
    width: mainStyles.SMALL_IMAGE_SIZE,
    height: mainStyles.SMALL_IMAGE_SIZE,
    borderRadius: mainStyles.SMALL_IMAGE_SIZE / 2,
    backgroundColor: mainStyles.IMAGE_PLACEHOLDER_COLOR,
  },
  goIcon: {
    width: 15,
    height: 15,
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({}, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchArtist));
