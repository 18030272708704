import qs from 'qs';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import AppLayout from '../../layouts/app';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import providers from '../../store/selectors/providers';
import { updateProviderImportedPlaylists } from '../../utils/api/api';
import { Redirect, withRouter } from '../../utils/routing/Routing';

class SpotifyAuth extends Component {
  componentDidMount() {
    const { location, setAuthToken, jwt } = this.props;
    const { token, expiresIn, expires, refreshToken } = qs.parse(location.search.substr(1));

    if (token) {
      setAuthToken({ token, expires, expiresIn, refreshToken });
      updateProviderImportedPlaylists(jwt, token, 'spotify');
    }
  }

  render() {
    const { originUrl } = this.props;
    const redirectTo = originUrl && originUrl !== null ? originUrl : '/music';

    return (
      <AppLayout>
        <Redirect to={redirectTo} />
      </AppLayout>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    originUrl: selectors.app.getOriginUrl(state),
    spotify: providers.spotify.getSpotifyCredentials(state),
    jwt: selectors.user.getJwt(state),
  }),
  {
    setOriginUrl: actions.app.setOriginUrl,
    setAuthToken: actions.providers.spotify.setAuthToken,
  },
)(withRouter(SpotifyAuth));
