import React, { Component } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';

import actions from '../../../store/actions';
import selectors from '../../../store/selectors';
import mainStyles from '../../../styles';
import { deletePodcastById } from '../../../utils/DatabaseHelper';
import { durationToTime } from '../../../utils/providers/spotify/common';
import SeekbarInnerLine from './SeekbarInnerLine';

class SeekbarInner extends Component {
  TICK_RATE = 500;

  PROVIDER_CHECK_RATE = 5000 / this.TICK_RATE;

  state = {
    ticksSinceLastCheck: 0,
    barWidthPixels: 100,
    barWidth: 0,
    duration: 0,
    position: 0,
    tickerInterval: null,
    tuneInTrackNotStarted: true,
    isPodcastDeleted: false,
    isPodcastSeekPosition: false,
  };

  componentDidMount() {
    if (this.props.player.track && this.props.player.track.provider) {
      this.forceTickUpdate();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.player.dateStartedTrack !== this.props.player.dateStartedTrack && this.didTrackSwitchRecently()) {
      this.resetSeekbarPosition();
    }
    if (this.props.trackStatusEnd) {
      this.setRenderPositions({ position: 0, ticksSinceLastCheck: 1 });
      this.props.setTrackEnded(false);
    }
    // Deezer SDK doesn't provide track end event so replay the same song can not be played. After track reaches end position
    // and press on play button play track again.
    const providerName = this.props.player.track.provider;
    if (this.state.barWidth === 100 && providerName === 'deezer' && this.isAProviderPlaying() && this.state.tickerInterval === null) {
      this.props.replayTrack();
    }
    this.checkStatusSeekbarInterval();
  }

  componentWillUnmount() {
    if (this.state.tickerInterval) {
      this.stopTickerInterval();
    }
  }

  didTrackSwitchRecently = () => new Date() - this.props.player.dateStartedTrack < 1000;

  isAProviderPlaying = () =>
    this.props.player.statuses && Object.keys(this.props.player.statuses).find(prov => this.props.player.statuses[prov] === 'playing');

  checkStatusSeekbarInterval = () => {
    if (this.state.tickerInterval === null && this.isAProviderPlaying()) {
      this.tickerTick();
      this.startTickerInterval();
    } else if (this.state.tickerInterval !== null && !this.isAProviderPlaying()) {
      this.stopTickerInterval();
    }
  };

  tickerTick = async () => {
    let newProgress = {
      position: this.state.position + this.TICK_RATE,
      duration: this.state.duration,
      ticksSinceLastCheck: this.state.ticksSinceLastCheck + 1,
    };

    if (
      this.state.ticksSinceLastCheck === 0 ||
      (!this.didTrackSwitchRecently() && this.state.duration === 0) ||
      this.state.ticksSinceLastCheck > this.PROVIDER_CHECK_RATE
    ) {
      try {
        newProgress = await this.props.getDurationFromProvider();
        newProgress.ticksSinceLastCheck = 1;
      } catch (e) {
        return;
      }
    }

    if (newProgress.position > newProgress.duration) {
      newProgress.position = newProgress.duration;
    }

    const { trackPosition } = this.props.playingStatus;
    const calculateBarWidth = this.getCurrentBarWidthPerc(newProgress);
    const providerName = this.props.player.track.provider;

    // Only Spotify provide to set particular position option, so check other providers for the first time
    // playing track after tune in and set broadcast position.
    if (this.state.tuneInTrackNotStarted === true && this.isAProviderPlaying() && !(providerName === 'spotify') && calculateBarWidth > 0) {
      if (this.props.tunedTo) {
        if (trackPosition) {
          const position = trackPosition;
          this.props.seekPosition({
            position,
          });
          newProgress.position = position;
          this.setState({
            tuneInTrackNotStarted: false,
          });
        }
      }
    }

    if (
      this.props.podcastCurrent &&
      this.props.podcastCurrent[0] &&
      this.props.podcastCurrent[0].position &&
      this.props.podcastCurrent[0].position > 0 &&
      this.state.isPodcastSeekPosition === false &&
      calculateBarWidth >= 0
    ) {
      const { position } = this.props.podcastCurrent[0];
      this.props.seekPosition({
        position,
      });
      newProgress.position = position;
      this.setState({
        isPodcastSeekPosition: true,
      });
      this.props.removeCurrentPodcast();
    }

    this.setRenderPositions(newProgress);
  };

  startTickerInterval = () => {
    if (this.state.tickerInterval) {
      this.stopTickerInterval();
    }

    this.state.tickerInterval = setInterval(this.tickerTick, this.TICK_RATE);
  };

  stopTickerInterval = () => {
    clearInterval(this.state.tickerInterval);
    this.setState({
      tickerInterval: null,
      ticksSinceLastCheck: 0,
    });
  };

  touchEventToBarPercent = ev => Math.max(0, Math.min(ev.nativeEvent.locationX / this.state.barWidthPixels, 1));

  currentWidthStyle = () => ({ width: `${this.state.barWidth}%` });

  getCurrentBarWidthPerc = newProgress => (newProgress.position / newProgress.duration) * 100;

  onPress = ev => {
    const position = this.touchEventToBarPercent(ev) * this.state.duration;
    this.props.seekPosition({
      position,
    });
    this.setRenderPositions({ position, duration: this.state.duration });
  };

  setRenderPositions = newProgress => {
    let barWidth = this.getCurrentBarWidthPerc(newProgress);
    if (!newProgress.duration) {
      barWidth = 0;
    }
    this.setState({ ...newProgress, barWidth });
    const providerName = this.props.player.track.provider;
    if (barWidth === 100 && providerName === 'stream' && this.state.isPodcastDeleted === false) {
      this.deleteCompletedPodcastData();
    }
  };

  onLayout = event => {
    if (this.state.barWidthPixels === 100) {
      this.setState({
        barWidthPixels: event.nativeEvent.layout.width,
      });
    }
  };

  async deleteCompletedPodcastData() {
    await deletePodcastById(this.props.userId, this.props.player.track.id, this.props.player.track.name);
    this.setState({
      isPodcastDeleted: true,
    });
  }

  async forceTickUpdate() {
    try {
      const newProgress = await this.props.getDurationFromProvider();

      newProgress.ticksSinceLastCheck = 1;
      this.setRenderPositions(newProgress);
    } catch (e) {
      // keep an eye out if this happens on more devices.
    }
  }

  async resetSeekbarPosition() {
    this.setRenderPositions({ duration: 0, position: 0, ticksSinceLastCheck: 1 });
  }

  render() {
    const { fullSeekbar } = this.props;
    const seekBar = <SeekbarInnerLine currentWidthStyle={this.currentWidthStyle} onLayout={this.onLayout} />;

    if (fullSeekbar) {
      return (
        <View style={styles.seekBarContainer}>
          {fullSeekbar && (
            <View style={[styles.seekTextContainer, mainStyles.margin.right.sm]}>
              <Text style={styles.seekText}>{durationToTime(this.state.position)}</Text>
            </View>
          )}

          <TouchableOpacity onPress={this.onPress} style={[styles.flexBar, styles.seekbarTouchArea]}>
            {seekBar}
          </TouchableOpacity>

          {fullSeekbar && (
            <View style={[styles.seekTextContainer, mainStyles.margin.left.sm]}>
              <Text style={styles.seekText}>{durationToTime(this.state.duration)}</Text>
            </View>
          )}
        </View>
      );
    } else {
      return seekBar;
    }
  }
}

const styles = StyleSheet.create({
  seekbarTouchArea: {
    height: 40,
    justifyContent: 'center',
  },
  seekTextContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  seekText: {
    color: 'white',
  },
  seekBarContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  flexBar: {
    flex: 1,
    alignItems: 'center',
  },
  bar: {
    height: 3,
  },
  outerBar: {
    width: '100%',
    backgroundColor: 'grey',
  },
  innerBar: {
    backgroundColor: 'orange',
  },
});

export default connect(
  (state, ownProps) => {
    return {
      ...state,
      ...ownProps,
      player: selectors.media.player.getPlayer(state),
      trackStatusEnd: selectors.media.player.getTrackEnded(state),
      playingStatus: selectors.tunein.getUserPlayingStatus(state, ownProps.tunedTo),
      queueStore: selectors.media.queue.getQueue(state),
      podcastCurrent: selectors.podcast.storedPodcasts.getCurrent(state),
      userId: selectors.user.getUserId(state),
    };
  },
  {
    seekPosition: actions.media.seekbar.seekPosition,
    setTrackEnded: actions.media.player.setTrackEnded,
    setProviderStatus: actions.media.player.setProviderStatus,
    replayTrack: actions.providers.deezer.replayTrack,
    toggleProviderStatus: actions.media.player.toggleProviderStatus,
    removeCurrentPodcast: actions.podcast.storedPodcasts.removeCurrent,
  },
)(SeekbarInner);
