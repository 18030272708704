import React, { useState } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';

import actions from '../../store/actions';
import selectors from '../../store/selectors';
import mainStyles from '../../styles';
import { unfollowUser } from '../../utils/api/api';
import { withRouter } from '../../utils/routing/Routing';
import Sentry from '../../utils/Sentry';

const Follow = ({ jwt, history, dropFollowing, following, updateList }) => {
  const [profile, setProfile] = useState({});

  const pictureStyle = [styles.picture, styles.small];
  const titleStyles = [mainStyles.typography.h2, mainStyles.margin.bottom.xs];
  const imageSrc = following?.profilePictureUrl ? following.profilePictureUrl : null;
  const userProfileName = following.displayName ? following.displayName : following.username;

  const splitData = following.profilePictureUrl.split('/');

  const userContainerStyles = [styles.userContainer, mainStyles.margin.bottom.sm, mainStyles.margin.top.sm];

  const submitUnfollow = username => {
    if (jwt == null) {
      history.push('/signin');
      return;
    }

    unfollowUser({
      jwt,
      username,
    })
      .then(() => {
        dropFollowing({ username });
        if (global.xmpp && global.xmpp.getStatus() !== 'offline') {
          global.xmpp.sendPresence(username, 'unavailable');
          global.xmpp.unsubscribePubSubUser({ username });
        }
        const newProfile = { profile: { ...profile, followerCount: profile.followerCount - 1 } };
        setProfile(newProfile);
        updateList();
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  return (
    <View style={userContainerStyles} testID={`following-${userProfileName}`}>
      <Image
        source={splitData[splitData.length - 1] === 'undefined' ? require('../../assets/icons/userProfile/user.png') : { uri: imageSrc }}
        style={[pictureStyle, mainStyles.margin.right.sm]}
      />

      <View style={styles.userDetailsContainer}>
        <Text numberOfLines={1} style={titleStyles}>
          {userProfileName}
        </Text>
      </View>
      <TouchableOpacity onPress={() => submitUnfollow(following.username)} style={styles.unfollowButton} underlayColor="#fff">
        <Text style={styles.unfollowText}>Unfollow</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  userContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  picture: {
    borderWidth: 0,
    backgroundColor: mainStyles.colours.purpleDark,
  },
  small: {
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  userDetailsContainer: {
    flex: 1,
    paddingRight: mainStyles.distance.sm,
  },
  unfollowButton: {
    marginLeft: 40,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: '#1E6738',
    borderRadius: 5,
    borderWidth: 0.5,
    borderColor: '#fff',
  },
  unfollowText: {
    color: '#fff',
    textAlign: 'center',
    paddingLeft: 10,
    paddingRight: 10,
  },
});

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    jwt: selectors.user.getJwt(state),
    profile: selectors.user.getProfile(state),
  }),
  {
    setAlert: actions.app.setAlert,
    setProfile: actions.user.setProfile,
    dropFollowing: actions.user.dropFollowing,
  },
)(withRouter(Follow));
