export function seekTo(position, track) {
  switch (track.provider) {
    case 'spotify':
      global.spotify.playerSeekTo(position);
      break;
    case 'deezer':
      global.deezer.seekTo(position);
      break;
    case 'apple':
      global.apple.seekTo(position);
      break;
    case 'stream':
      window.StreamPlayer.seekTo(position / 1000, 'seconds');
      break;
    case 'youtube':
      global.youtubePlayer.seekTo(position / 1000, 'seconds');
      break;
    default:
      console.warn('utils/player/seekTo: unknown provider type', track.provider);
  }
}
