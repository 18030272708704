import React, { Component } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';

import selectors from '../../store/selectors';
import mainStyles from '../../styles';
import { artistSearch } from '../../utils/api/api';
import history from '../../utils/routing/history';

let artistId;
class PlayScreenHeader extends Component {
  state = {
    artist: '',
  };

  componentDidMount() {
    const { availableProviders, spotifyCredentials, appleToken, deezerCredentials, jwt, deactiveArtistSearch } = this.props;
    // Artist search API supports only one artist name at a time.
    const query = this.props.player.track.artistName?.split(',')[0];
    const providers = [];
    if (availableProviders.spotify === true) providers.push({ name: 'spotify', token: spotifyCredentials.token });
    if (availableProviders.apple === true) providers.push({ name: 'apple', token: appleToken });
    if (availableProviders.deezer === true) providers.push({ name: 'deezer', token: deezerCredentials.token });
    if (!deactiveArtistSearch) {
      artistSearch({ query, providers, jwt }).then(response => {
        this.setState({
          artist: response,
        });
        artistId = this.state.artist.artists[0].id;
      });
    }
  }

  openMoreTracks() {
    const { moreTracks } = this.props;
    if (this.props.player.track.provider !== 'stream') {
      history.push(`/artist/${moreTracks.provider}/${artistId}`, { title: moreTracks.artistName, id: artistId, provider: moreTracks.provider } || {});
    }
  }

  render() {
    const { image, title, subtitle } = this.props;
    const imageStyles = [styles.image, mainStyles.margin.bottom.sm];
    if (image && !image.uri) imageStyles.push(styles.imagePlaceholder);
    const underLineText = [];
    if (this.props.player.track.provider !== 'stream') underLineText.push(styles.underlineArtist);
    return (
      <View style={[mainStyles.centerFlex, styles.container]}>
        <Image resizeMode="contain" source={image} style={imageStyles} />
        {title && <Text style={[mainStyles.typography.h2, mainStyles.typography.textCenter, mainStyles.margin.bottom.xs]}>{title}</Text>}
        <TouchableOpacity disabled={this.props.player.track.provider === 'stream'} onPress={() => this.openMoreTracks()}>
          {subtitle && <Text style={[mainStyles.typography.h2Light, mainStyles.typography.textCenter, underLineText]}>{subtitle}</Text>}
        </TouchableOpacity>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 2,
  },
  image: {
    flexGrow: 1,
    width: '100%',
    height: undefined,
    borderRadius: mainStyles.IMAGE_BORDER_RADIUS,
  },
  imagePlaceholder: {
    backgroundColor: 'rgba(255,255,255,0.4)',
  },
  underlineArtist: {
    textDecorationLine: 'underline',
  },
});

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    jwt: selectors.user.getJwt(state),
    player: selectors.media.player.getPlayer(state),
    availableProviders: selectors.providers.getAvailableProviders(state),
    appleToken: selectors.providers.apple.getToken(state),
    spotifyCredentials: selectors.providers.spotify.getSpotifyCredentials(state),
    deezerCredentials: selectors.providers.deezer.getDeezerCredentials(state),
  }),
  null,
)(PlayScreenHeader);
