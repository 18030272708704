import EventEmitter from 'events';

import { config } from '../../config';

export default class Deezer extends EventEmitter {
  initialize() {
    if (DZ) {
      DZ.init({
        appId: config.DEEZER_APP_ID,
        channelUrl: `${config.DEEZER_CHANNEL_URL}/deezerchannel`,
        player: {
          onload() {},
        },
      });
      this.registerListeners();
    }
  }

  login(userId) {
    window.location.href = `${config.API_URL}/deezer/authorise?userid=${userId}&origin=${config.DEEZER_REDIRECT_URL}`;
  }

  play(id) {
    if (DZ) {
      DZ.player.playTracks([id]);
    }
  }

  pause() {
    DZ.player.pause();
  }

  resume() {
    DZ.player.play();
  }

  seekTo() {
    // TODO: IMPLEMENT WHEN DEEZER WEB IS WORKING AGAIN
  }

  getPlayerPositions() {
    // TODO: IMPLEMENT WHEN DEEZER WEB IS WORKING AGAIN
  }

  isPlaying() {
    return DZ.player.isPlaying();
  }

  registerListeners() {
    DZ.Event.subscribe('player_play', () => {
      this.emit('DeezerPlay');
    });

    DZ.Event.subscribe('current_track', t => this.emit('DeezerCurrentTrack', t));

    DZ.Event.subscribe('player_paused', () => {
      this.emit('DeezerPaused');
    });

    DZ.Event.subscribe('track_end', () => {
      this.emit('DeezerEnding');
    });

    DZ.Event.subscribe('player_position', () => {});
  }
}
