import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import actions from '../../store/actions';
import selectors from '../../store/selectors';
import { MEDIA_CONNECT_CM_KEY } from '../../styles/consts';
import { sendDetailsToCM } from '../../utils/api/api';
import { savePopupDataLocally } from '../../utils/DatabaseHelper';
import { withRouter } from '../../utils/routing/Routing';
import ProviderButton from './ProviderButton';

class DeezerButtonWeb extends Component {
  clickHandler() {
    savePopupDataLocally();
    const { origin, setOriginUrl, userId } = this.props;
    const cmMedia = {
      userId: this.props.userId,
      deezer: new Date(),
    };
    sendDetailsToCM(cmMedia, MEDIA_CONNECT_CM_KEY);
    setOriginUrl({ origin });
    global.deezer.login(userId);
  }

  render() {
    const { enabled } = this.props;
    return <ProviderButton enabled={enabled} handler={() => this.clickHandler()} icon="deezer" name="Deezer" testID="deezer-provider-button" />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    userId: selectors.user.getUserId(state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        setOriginUrl: actions.app.setOriginUrl,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeezerButtonWeb));
