import { combineReducers } from 'redux';

import apple from './apple';
import deezer from './deezer';
import players from './players';
import providers from './providers';
import spotify from './spotify';

export default combineReducers({
  apple,
  deezer,
  players,
  providers,
  spotify,
});
