import { Formik } from 'formik';
import React, { Component } from 'react';
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import ButtonRez from '../../components/app/ButtonRez';
import Logo from '../../components/app/Logo';
import TextInputRez from '../../components/app/TextInputRez';
import FullScreenLayout from '../../layouts/fullscreen';
import actions from '../../store/actions';
import mainStyles from '../../styles';
import { joinWaitinglist } from '../../utils/api/api';
import analytics from '../../utils/routing/analytics';
import { withRouter } from '../../utils/routing/Routing';

class JoinWaitinglistScreen extends Component {
  state = {
    loading: false,
  };

  errorTexts = {
    VALIDATION_ERROR: 'Something is off, please check your email address and try again.',
    default: 'Ooops, something went wrong. Please try again later.',
  };

  WaitinglistSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
  });

  handleJoin({ email }) {
    this.setState({
      loading: true,
    });

    analytics().logEvent('join_waitinglist');

    joinWaitinglist({ email })
      .then(() => {
        this.props.setAlert(null);
        this.props.history.push('/waiting');
      })
      .catch(err => {
        this.setState({
          loading: false,
        });
        if (err && err.response && err.response.data) {
          this.props.setAlert({
            type: 'error',
            message: err.response.data.errorMessage || this.errorTexts[err.response.data.error],
          });
        }
      });
  }

  render() {
    const { loading } = this.state;

    return (
      <FullScreenLayout>
        <View style={[styles.logoContainer, mainStyles.margin.bottom.xl]}>
          <Logo />
        </View>

        <Text style={[mainStyles.typography.p, mainStyles.typography.textCenter, mainStyles.margin.bottom.xl]} testID="waitinglist-text">
          Bummer, that code isn&apos;t correct. No worries, you can join our waitinglist and we&apos;ll send you an invite as soon as possible. Enter
          your email address below to join our list.
        </Text>
        <Formik initialValues={{ email: '' }} onSubmit={values => this.handleJoin(values)} validateOnBlur validationSchema={this.WaitinglistSchema}>
          {props => (
            <View>
              <View style={mainStyles.margin.bottom.md}>
                <TextInputRez
                  autoCapitalize="none"
                  autoCorrect={false}
                  autoFocus={false}
                  disabled={loading}
                  enablesReturnKeyAutomatically
                  icon={require('../../assets/icons/mail/mail.png')}
                  keyboardType="email-address"
                  label="E-mail address"
                  name="email"
                  onBlur={props.handleBlur('email')}
                  onChangeText={props.handleChange('email')}
                  onSubmitEditing={() =>
                    props.validateForm().then(() => {
                      if (props.isValid) this.handleJoin(props.values);
                    })
                  }
                  placeholder="Your e-mail address"
                  validation={{ error: props.errors.email, touched: props.touched.email }}
                  value={props.values.email}
                />
              </View>

              {!loading && (
                <ButtonRez
                  disabled={!props.isValid}
                  fontColor="rgba(23,23,38,1)"
                  label="Join"
                  large
                  onPress={props.handleSubmit}
                  startColor="rgba(255,255,255,1)"
                />
              )}
            </View>
          )}
        </Formik>
        {loading && (
          <View style={styles.loading}>
            <ActivityIndicator color="rgba(255,142,12,1)" size="large" />
          </View>
        )}
      </FullScreenLayout>
    );
  }
}

const styles = StyleSheet.create({
  logoContainer: {
    alignItems: 'center',
  },
});

export default connect(null, {
  setAlert: actions.app.setAlert,
})(withRouter(JoinWaitinglistScreen));
