export const SET_TYPE = 'PLAYER_SET_TYPE';
export const SET_STATUS = 'PLAYER_SET_PLAYING';
export const SET_TRACK = 'PLAYER_SET_TRACK';
export const SET_CATEGORY = 'PLAYER_SET_CATEGORY';
export const SET_PROGRESS = 'PLAYER_SET_PROGRESS';
export const TOGGLE_PROVIDER_STATUS = 'PLAYER_TOGGLE_PROVIDER_STATUS';
export const SET_PROVIDER_STATUS = 'PLAYER_SET_PROVIDER_STATUS';
export const SET_CROSS_MATCHED = 'PLAYER_SET_CROSS_MATCHED';
export const SET_VIDEO = 'PLAYER_SET_VIDEO';
export const SET_TRACKEND = 'PLAYER_SET_TRACKEND';
export const SET_PLAYTIME = 'PLAYER_SET_PLAYTIME';
export const SET_PAUSETIME = 'PLAYER_SET_PAUSETIME';
