import axios from 'axios';
import { addMinutes, isBefore, parseISO, toDate } from 'date-fns';
import EventEmitter from 'events';

import { config } from '../../config';
import { normalizeSpotifyTrack } from './normalize';

export default class Spotify extends EventEmitter {
  constructor() {
    super();
    this.player = null;
    this.token = null;
    this.refreshToken = null;
    this.tokenExpires = null;
    this.deviceId = null;
  }

  setDeviceId(deviceId) {
    this.deviceId = deviceId;
  }

  setToken(token) {
    this.token = token;
  }

  setRefreshtoken(token) {
    this.refreshToken = token;
  }

  setExpires(expires) {
    this.tokenExpires = expires;
  }

  async initialize() {
    this.player = new window.Spotify.Player({
      name: 'Rezzonation',
      getOAuthToken: cb => {
        cb(this.token);
      },
    });

    const connected = await this.player.connect();
    if (connected) this.registerListeners();
    this.startRefreshInterval();
  }

  login(userId) {
    window.location.href = `${config.API_URL}/spotify/authorise?userid=${userId}&origin=${config.SPOTIFY_REDIRECT_URL}`;
  }

  getDeviceId() {
    return this.deviceId;
  }

  getCredentials = () => {
    return normalizeCredentials({
      token: this.token,
      expires: this.tokenExpires,
      expiresIn: this.tokenExpires,
      refreshToken: this.refreshToken,
    });
  };

  isAuthorised = async () => {
    const now = new Date();
    const expired = isBefore(toDate(parseISO(this.tokenExpires)), now);
    return !expired && this.token !== undefined && this.token !== null;
  };

  renewSession = async () => {
    if (!this.token || !this.refreshToken) return false;

    return axios({
      url: `${config.API_URL}/spotify/refresh`,
      method: 'POST',
      data: {
        access_token: this.token,
        refresh_token: this.refreshToken,
      },
    }).then(response => {
      this.setToken(response.data.access_token);
      this.setRefreshtoken(response.data.refresh_token);
      this.setExpires(response.data.expires);
      this.emit('SpotifySessionRenewed', {
        ...response.data,
      });
    });
  };

  async play(uri, index, position) {
    if (!(await this.isAuthorised())) {
      await this.renewSession();
    }

    axios({
      method: 'PUT',
      url: `https://api.spotify.com/v1/me/player/play?device_id=${this.getDeviceId()}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.getCredentials().token}`,
      },
      data: JSON.stringify({
        uris: [uri],
        offset: {
          position,
        },
      }),
    });
  }

  pausePlayer() {
    this.player.pause();
  }

  resumePlayer() {
    this.player.resume();
  }

  async getSeekbarPositions() {
    return this.player.getCurrentState();
  }

  playerSeekTo(newPosition) {
    this.player.seek(newPosition);
  }

  isPlaying() {
    return this.player.getCurrentState().then(state => !state.paused);
  }

  registerListeners() {
    this.player.addListener('player_state_changed', state => {
      if (!state) return this.emit('SpotifyPlaybackError');

      const { paused, track_window, position } = state;
      const { current_track } = track_window;

      const event = paused ? 'SpotifyPause' : 'SpotifyPlay';
      this.emit(event, {
        status: paused ? 'paused' : 'playing',
        position,
      });

      this.emit('SpotifyMetadataChange', {
        track: normalizeSpotifyTrack(current_track),
        state,
      });
    });

    this.player.addListener('playback_error', () => {
      this.emit('SpotifyPlaybackError');
    });

    this.player.addListener('account_error', () => {
      this.emit('SpotifyAccountError');
    });

    this.player.addListener('authentication_error', () => {
      this.emit('SpotifyAuthenticationError');
    });

    this.player.addListener('initialization_error', () => {
      this.emit('SpotifyInitError');
    });

    this.player.addListener('ready', event => {
      const deviceId = event.device_id;
      this.setDeviceId(deviceId);
      this.emit('SpotifyReady', {
        deviceId,
      });
    });

    this.player.addListener('not_ready', ({ deviceId }) => {
      this.emit('SpotifyNotReady', {
        deviceId,
      });
    });
  }

  startRefreshInterval() {
    setInterval(() => {
      const expireDate = toDate(parseISO(this.tokenExpires));
      const nextInterval = addMinutes(new Date(), 10);
      if (isBefore(expireDate, nextInterval)) {
        this.renewSession();
      }
    }, 600000);
  }
}

export const normalizeCredentials = credentials => {
  return {
    token: credentials.token,
    expires: credentials.expires,
    expiresIn: credentials.expiresIn,
    refreshToken: credentials.refreshToken,
  };
};
