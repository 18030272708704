import _ from 'lodash';
import React, { Component } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from 'react-native-responsive-screen';
import { connect } from 'react-redux';

import actions from '../../../store/actions';
import selectors from '../../../store/selectors';
import mainStyles from '../../../styles';
import { withRouter } from '../../../utils/routing/Routing';
import VideoPlayer from './VideoPlayer';

class Video extends Component {
  state = {
    shouldDisplay: false,
    size: {
      width: 0,
      height: 0,
    },
    position: {
      left: 0,
      bottom: 0,
    },
  };

  componentDidMount() {
    this.runPositionChecks();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState);
  }

  componentDidUpdate() {
    this.runPositionChecks();
  }

  runPositionChecks = () => {
    this.size();
    this.shouldDisplay();
    this.position();
  };

  setDuration = () => {};

  setProgress = () => {};

  inVideoScreen = pathname => pathname.match('^/video/.+');

  shouldDisplay() {
    const { location, player } = this.props;
    const playing = player && player.track.provider === 'youtube' && player.statuses.youtube !== 'stopped';
    const screen = location.pathname === `/video/${player.track.id}`;
    this.setState({
      shouldDisplay: playing && screen,
    });
  }

  size() {
    const inVideoScreen = this.inVideoScreen(this.props.location.pathname);
    this.setState({
      size: {
        width: inVideoScreen ? wp('100%') - 32 : 50,
        height: inVideoScreen ? ((wp('100%') - 16) / 16) * 9 : (50 / 16) * 9,
      },
    });
  }

  position() {
    const inVideoScreen = this.inVideoScreen(this.props.location.pathname);
    this.setState({
      position: {
        top: inVideoScreen ? 16 : hp('100%') - mainStyles.BOTTOM_NAVIGATOR_HEIGHT - 50,
        left: 0,
      },
    });
  }

  goToVideo() {
    this.props.history.push(`/video/${this.props.player.track.id}`);
  }

  showDetails() {
    const { location } = this.props;
    return !location.pathname.match('^/video/.+');
  }

  togglePlayer() {
    this.props.setProviderStatus({ status: this.props.player.statuses.youtube === 'playing' ? 'paused' : 'playing', provider: 'youtube' });
  }

  render() {
    const { player } = this.props;
    const { position, shouldDisplay, size } = this.state;
    const { width, height } = size;
    const { top, left } = position;
    const { statuses, track } = player;

    const showDetails = this.showDetails();

    // This is here as display: none does not work on native and conditional rendering doesn't hide the container;
    const containerWidth = shouldDisplay ? width : 0;
    let containerHeight = 0;
    if (shouldDisplay) {
      containerHeight = height > 50 ? height : 50;
    }

    const containerStyles = [
      styles.container,
      { minWidth: containerWidth, minHeight: containerHeight, top, left, display: shouldDisplay ? 'flex' : 'none' },
    ];
    if (showDetails) {
      containerStyles.push(styles.miniContainer);
    }

    return (
      <View style={containerStyles}>
        {shouldDisplay && (
          <TouchableOpacity onPress={() => this.goToVideo()} style={styles.row}>
            <VideoPlayer
              height={height}
              id={track.id}
              onEnded={() => this.props.toggleProviderStatus({ provider: 'youtube', status: 'paused' })}
              onPause={() => this.props.toggleProviderStatus({ provider: 'youtube', status: 'paused' })}
              onPlay={() => this.props.toggleProviderStatus({ provider: 'youtube', status: 'playing' })}
              provider={track.provider}
              setDuration={() => this.setDuration()}
              setProgress={() => this.setProgress()}
              status={statuses.youtube}
              width={width}
            />
            {showDetails && (
              <View style={[styles.detailsContainer, { display: showDetails ? 'flex' : 'none' }, mainStyles.margin.left.sm]}>
                <Text numberOfLines={2} style={[mainStyles.typography.h3, styles.title]}>
                  {player.track.name}
                </Text>
                <TouchableOpacity onPress={() => this.togglePlayer()}>
                  {statuses.youtube === 'playing' && <Image source={require('../../../assets/icons/pause/pause.png')} style={styles.controlIcon} />}
                  {(statuses.youtube === 'paused' || statuses.youtube === 'stopped') && (
                    <Image source={require('../../../assets/icons/play/play.png')} style={styles.controlIcon} />
                  )}
                </TouchableOpacity>
              </View>
            )}
          </TouchableOpacity>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    zIndex: 100,
    top: 0,
    left: 0,
    minHeight: 50,
    maxHeight: 50,
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: 'transparent',
  },
  controlIcon: {
    width: 24,
    height: 24,
  },
  detailsContainer: {
    flex: 1,
    flexDirection: 'row',
    flexGrow: 2,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  miniContainer: {
    backgroundColor: mainStyles.colours.controlBar,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    marginRight: 16,
  },
});

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    player: selectors.media.player.getPlayer(state),
  }),
  {
    setProviderStatus: actions.media.player.setProviderStatus,
    toggleProviderStatus: actions.media.player.toggleProviderStatus,
  },
)(withRouter(Video));
