import { combineReducers } from 'redux';

import music from './music';
import player from './player';
import playlists from './playlists';
import queue from './queue';
import search from './search';
import stations from './stations';

export default combineReducers({
  music,
  playlists,
  stations,
  search,
  player,
  queue,
});
