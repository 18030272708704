import React, { Component } from 'react';
import { Platform, Share, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';

import ListButton from '../../../components/app/ListButton';
import ModalRez from '../../../components/app/ModalRez';
import ListScreenHeader from '../../../containers/app/ListScreenHeader';
import ShareModal from '../../../containers/app/ShareModal';
import FullScreenLayout from '../../../layouts/fullscreen';
import actions from '../../../store/actions';
import selectors from '../../../store/selectors';
import mainStyles from '../../../styles';
import { deletePlaylistTrack } from '../../../utils/api/api';
import { config } from '../../../utils/config';
import analytics from '../../../utils/routing/analytics';
import { withRouter } from '../../../utils/routing/Routing';

class TrackOptionsScreen extends Component {
  state = {
    showModal: null,
    isUserPlaylist: false,
    shareTexts: {
      message: `Open this track in Rezzonation ${config.APP_URL}/track/${this.props.match.params.title}/${this.props.match.params.id}`,
      title: 'Share track',
      url: '',
    },
  };

  componentDidMount() {
    const { location } = this.props;
    const {
      params: { title, id, shortId },
    } = matchPath(location.pathname, {
      path: '/track/:title/:id/:shortId',
    });
    if (!id) {
      this.goBack();
    }
    if (shortId && shortId !== 'other') {
      this.setState({ isUserPlaylist: true });
    }

    this.setState(prevState => ({
      ...prevState,
      shareTexts: {
        ...prevState.shareTexts,
        message: `Open this track in Rezzonation ${config.APP_URL}/track/${title}/${id}`,
        url: `${config.APP_URL}/track/${title}/${id}`,
      },
    }));
  }

  onDeleteTrack() {
    const { jwt, playlist } = this.props;
    const track = this.props.location.state || {};
    deletePlaylistTrack({ jwt, playlistId: playlist.playlistId, trackId: track.id })
      .then(() => {
        this.setState({ showModal: null });
        this.props.history.push(`/playlists/${playlist.shortId}`);
        this.props.history.index = 2;
      })
      .catch(err => {
        console.log('Error on deleting track', err);
        // eslint-disable-next-line no-undef
        Sentry.captureException(err);
        this.setState({ showModal: null });
        this.props.setAlert({ message: `Couldn't delete Track ${track.title || track.name}`, type: 'error' });
      });
  }

  shareTrack() {
    const { url, title, message } = this.state.shareTexts;
    const { provider } = this.props.location.state;
    const track = this.props.location.state || {};

    analytics().logEvent('share_track', { name: track.title || track.name, artist: track.artistName, provider });
    if (Platform.OS === 'web') {
      this.showModal('share');
    } else {
      Share.share(
        {
          title,
          url,
          message: Platform.OS === 'android' ? `${message} ${url}` : message,
        },
        {
          dialogTitle: title,
          subject: title,
        },
      );
    }
  }

  addToPlaylist() {
    const { location } = this.props;
    const { id, provider } = location.state;
    this.props.history.push(`/track/${id}/addToPlaylist/${provider}`);
  }

  goBack() {
    this.props.setAlert(null);
    this.props.history.goBack();
  }

  showModal(modalName) {
    this.setState({ showModal: modalName });
  }

  render() {
    const track = this.props.location.state || {};
    const imageSrc =
      track && track.album && track.album.images && Array.isArray(track.album.images) && track.album.images.length > 0
        ? track.album.images[0].url
        : undefined;

    return (
      <FullScreenLayout>
        <ListScreenHeader image={imageSrc} subtitle={track.artistName} title={track.title || track.name} />

        <View style={styles.listButtonContainer}>
          <ListButton
            icon={require('../../../assets/icons/addplaylist/addtoplaylist.png')}
            onPress={() => this.addToPlaylist()}
            testID="trackaddtoplaylist-button"
          >
            Add to playlist
          </ListButton>

          {this.state.isUserPlaylist && (
            <View>
              <ListButton
                icon={require('../../../assets/icons/trash/trash.png')}
                onPress={() => this.showModal('delete')}
                testID="trackdelete-button"
              >
                Delete Track
              </ListButton>
            </View>
          )}
          <TouchableOpacity onPress={() => this.goBack()} style={mainStyles.centerFlex}>
            <Text style={[mainStyles.typography.ctaUnderline, mainStyles.margin.bottom.lg, mainStyles.margin.top.md]}>Cancel</Text>
          </TouchableOpacity>
        </View>
        <ModalRez
          confirmButtonAction={() => this.onDeleteTrack()}
          confirmButtonLabel="Delete"
          isVisible={this.state.showModal === 'delete'}
          onBackdropPress={() => this.setState({ showModal: null })}
          testID="trackdelete-modal"
          title="Delete Track"
        >
          <Text>Are you sure you want to delete the Track?</Text>
        </ModalRez>
        <ShareModal
          isVisible={this.state.showModal === 'share'}
          message={this.state.shareTexts.message}
          onBackdropPress={() => this.setState({ showModal: null })}
          title={this.state.shareTexts.title}
          url={this.state.shareTexts.url}
        />
      </FullScreenLayout>
    );
  }
}

const styles = StyleSheet.create({
  listButtonContainer: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  renameTextInput: {
    backgroundColor: 'white',
    padding: mainStyles.distance.sm,
    height: 40,
  },
});

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    jwt: selectors.user.getJwt(state),
    userId: selectors.user.getUserId(state),
    playlist: selectors.media.playlists.getPlaylist(state, ownProps.match.params.shortId),
  }),
  {
    updatePlaylist: actions.media.playlists.updatePlaylist,
    setAlert: actions.app.setAlert,
    storeDeletePlaylist: actions.media.playlists.deletePlaylist,
    followPlaylist: actions.media.playlists.followPlaylist,
    unfollowPlaylist: actions.media.playlists.unfollowPlaylist,
  },
)(withRouter(TrackOptionsScreen));
