import EventEmitter from 'events';

import { config } from '../../config';
import { normalizeAppleTrack } from './normalize';

export default class Apple extends EventEmitter {
  init() {
    MusicKit.configure({
      developerToken: config.APPLE_DEV_TOKEN,
      app: {
        name: 'Rezzonation',
        build: '0.0.1',
      },
    });
    this.apple = MusicKit.getInstance();

    this.registerListeners();
  }

  login() {
    return this.apple.authorize();
  }

  async playTrack(trackId) {
    await this.apple.setQueue({
      song: trackId,
    });

    this.apple.play();
  }

  pause = () => this.apple.player.pause();

  resume = () => this.apple.player.play();

  seekTo = position => this.apple.seekToTime(position / 1000);

  isPlaying = async () => this.apple.isPlaying();

  registerListeners() {
    const { player } = this.apple;

    player.addEventListener('metadataDidChange', () => {
      const { nowPlayingItem } = player;

      if (nowPlayingItem && player.nowPlayingItem.attributes) {
        this.emit('AppleMetadataChange', {
          track: normalizeAppleTrack(nowPlayingItem),
          status: MusicKit.PlaybackStates[player.playbackState],
          position: 0,
          provider: 'apple',
        });
      }
    });

    player.addEventListener('playbackStateDidChange', () => {
      this.emit('ApplePlayerStateChange', {
        status: MusicKit.PlaybackStates[player.playbackState],
        isPlaying: player.isPlaying,
        position: player.currentPlaybackTime,
      });
    });

    player.addEventListener('mediaPlaybackError', () => {
      this.emit('ApplePlaybackError');
    });
  }
}
