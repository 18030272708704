import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity } from 'react-native';

import mainStyles from '../../styles';
import { withRouter } from '../../utils/routing/Routing';

const NavigatorButton = ({ history, path, label, icon, active, testID }) => {
  const navigate = () => history.push(path);

  const textStyles = [mainStyles.typography.p, mainStyles.margin.top.xs];
  if (active) textStyles.push(styles.textActive);

  return (
    <TouchableOpacity onPress={navigate} style={styles.footerButton} testID={testID}>
      {icon && <Image resizeMode="contain" source={icon} style={styles.footerIcon} />}
      {label && <Text style={textStyles}>{label}</Text>}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  textActive: {
    color: mainStyles.colours.orange,
  },
  footerButton: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerIcon: {
    flex: 1,
    width: 24,
    height: 24,
  },
});

export default withRouter(NavigatorButton);
