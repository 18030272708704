/* eslint-disable no-sequences */
/* eslint-disable babel/no-unused-expressions */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { ActivityIndicator, FlatList, StyleSheet, Text, View } from 'react-native';
import { connect } from 'react-redux';

import ButtonRez from '../../../components/app/ButtonRez';
import Track from '../../../components/media/tracks/Track';
import PlayScreenHeader from '../../../containers/media/PlayScreenHeader';
import AppLayout from '../../../layouts/app';
import actions from '../../../store/actions';
import selectors from '../../../store/selectors';
import mainStyles from '../../../styles';
import { getShareTrackInfo, search } from '../../../utils/api/api';
import { withRouter } from '../../../utils/routing/Routing';

class ShareTrackScreen extends Component {
  state = {
    trackTitle: '',
    artistTitle: '',
    track: [],
    loading: true,
    isSameProvider: true,
    trackUnavailable: false,
    connectedProvider: '',
    sections: [],
    searchTrackList: [],
    isMatched: true,
  };

  componentDidMount() {
    try {
      const { location } = this.props;
      const pathName = location.pathname;
      const props = pathName.split('/');
      const title = props[2];
      const artist = props[3];
      const trackID = props[4];
      const trackProvider = props[5];

      if (title && artist && trackProvider && trackID) {
        const checkSameProvider = this.isConnectedToSameProvider(trackProvider);
        const trackName = decodeURIComponent(title);
        const artistName = decodeURIComponent(artist);
        this.setState({ artistTitle: artistName, trackTitle: trackName });
        this.getTrackInfo(trackID, trackProvider, checkSameProvider, trackName);
      }
    } catch (err) {
      console.log('Issue when mounting the Share Track Screen: ', err);
      // eslint-disable-next-line no-undef
      Sentry.captureException(err);
    }
  }

  getTrackInfo(trackId, provider, checkSameProvider, trackName) {
    const { appleToken, availableProviders, deezerCredentials, jwt, spotifyCredentials } = this.props;
    let token = '';
    let connectProvider = '';
    if (checkSameProvider === true) {
      if (provider === 'spotify') (token = spotifyCredentials.token), (connectProvider = 'spotify');
      if (provider === 'apple') (token = appleToken), (connectProvider = 'apple');
      if (provider === 'deezer') (token = deezerCredentials.token), (connectProvider = 'deezer');
    } else {
      if (availableProviders.spotify === true) (token = spotifyCredentials.token), (connectProvider = 'spotify');
      if (availableProviders.apple === true) (token = appleToken), (connectProvider = 'apple');
      if (availableProviders.deezer === true) (token = deezerCredentials.token), (connectProvider = 'deezer');
    }

    provider = connectProvider;
    getShareTrackInfo({ trackId, provider, token, jwt })
      .then(response => {
        if (response) {
          this.setState({ track: response, isMatched: true });
        } else {
          console.log('ERROR');
          this.setState({
            trackUnavailable: true,
          });
        }
        this.setState({ trackTitle: trackName, isSameProvider: checkSameProvider, loading: false, connectedProvider: connectProvider });
      })
      .catch(err => {
        console.log('RESPONSE ERROR: ', err);
        // eslint-disable-next-line no-undef
        Sentry.captureException(err);
        this.runSearch(trackName, connectProvider);
      });
  }

  runSearch(query, connectProvider) {
    const { appleToken, availableProviders, deezerCredentials, jwt, spotifyCredentials } = this.props;
    const providers = [];
    if (query.length < 3) return;
    if (availableProviders.spotify === true) providers.push({ name: 'spotify', token: spotifyCredentials.token });
    if (availableProviders.apple === true) providers.push({ name: 'apple', token: appleToken });
    if (availableProviders.deezer === true) providers.push({ name: 'deezer', token: deezerCredentials.token });

    search({ query, providers, jwt })
      .then(response => {
        const sections = Object.keys(response.results).map(key => {
          return {
            title: `${key.substr(0, 1).toUpperCase()}${key.substr(1)}`,
            key,
            data: response.results[key],
          };
        });
        const searchTracks = sections.filter(item => item.key === 'tracks');
        const searchItems = searchTracks[0].data.slice(0, 5);

        this.setState({
          sections,
          loading: false,
          trackUnavailable: false,
          isMatched: false,
          searchTrackList: searchItems,
          connectedProvider: connectProvider,
        });
      })
      .catch(err => {
        console.log('SEARCH RESPONSE ERROR: ', err);
        // eslint-disable-next-line no-undef
        Sentry.captureException(err);
        this.setState({
          trackUnavailable: true,
          loading: false,
        });
      });
  }

  isConnectedToSameProvider(providerName) {
    let checkSameProvider = false;
    if (providerName === 'deezer' && this.props.deezerCredentials.isAuthorized === true) {
      checkSameProvider = true;
    } else if (providerName === 'spotify' && this.props.spotifyCredentials.isAuthorized === true) {
      checkSameProvider = true;
    } else if (providerName === 'apple' && this.props.availableProviders.apple === true) {
      checkSameProvider = true;
    }
    return checkSameProvider;
  }

  isAnyProviderLinked() {
    return !(
      this.props.spotifyCredentials.isAuthorized === false &&
      this.props.deezerCredentials.isAuthorized === false &&
      this.props.availableProviders.apple === false
    );
  }

  playTrack(track) {
    this.props.emptyQueue();
    this.props.playTrack(track);
  }

  goBack() {
    this.props.history.goBack();
  }

  addToPlaylist() {
    const { track } = this.state;
    const { id, provider } = track;
    this.props.history.push(`/track/${id}/addToPlaylist/${provider}`);
  }

  emptyList() {
    return (
      <>
        {!this.state.loading && (
          <View style={styles.viewcontainer}>
            <Text style={[mainStyles.typography.titleSplash, styles.introText]}>Sorry, unable to find the track</Text>
          </View>
        )}
      </>
    );
  }

  render() {
    const { loading, isMatched, track, trackUnavailable, connectedProvider, trackTitle, artistTitle } = this.state;
    const { artistName, name, album } = track;
    const imageSrc = album && album.images && Array.isArray(album.images) && album.images.length > 0 ? album.images[0].url : null;
    const providerLinked = this.isAnyProviderLinked();
    const currentProvider = connectedProvider.toUpperCase();

    if (!providerLinked) {
      return (
        <AppLayout title="Track Info">
          {!loading && (
            <View style={styles.viewcontainer}>
              <Text style={[mainStyles.typography.h2, styles.title]}>No music providers linked</Text>
            </View>
          )}
          {loading && (
            <View style={styles.loading}>
              <ActivityIndicator color="rgba(255,142,12,1)" size="large" />
            </View>
          )}
        </AppLayout>
      );
    }
    if (trackUnavailable === true) {
      return (
        <AppLayout title="Track Info">
          {!loading && (
            <View style={styles.viewcontainer}>
              <Text style={[mainStyles.typography.titleSplash, styles.introText]}>Sorry, unable to find the track</Text>
            </View>
          )}
          {loading && (
            <View style={styles.loading}>
              <ActivityIndicator color="rgba(255,142,12,1)" size="large" />
            </View>
          )}
        </AppLayout>
      );
    }
    return (
      <AppLayout title="Track Info">
        {!loading && isMatched && track && (
          <View style={styles.container}>
            <PlayScreenHeader image={{ uri: imageSrc }} subtitle={artistName} title={name} />
            <View style={styles.buttonContainer}>
              <ButtonRez inline label="Play" onPress={() => this.playTrack(track)} testID="playlistplay-button" />
            </View>
            <View style={styles.buttonContainer}>
              <ButtonRez inline label="Add To Playlist" onPress={() => this.addToPlaylist()} />
            </View>
          </View>
        )}

        {!loading && !isMatched && this.state.searchTrackList && (
          <View style={styles.container}>
            <Text style={[mainStyles.typography.titleSplash, styles.introText]}>
              Unfortunately, there’s no 100% match at {currentProvider} for the track "{trackTitle.toUpperCase()}" by "{artistTitle.toUpperCase()}"
              catalogue but we’ve managed to give you some alternatives!
            </Text>

            <FlatList
              data={this.state.searchTrackList}
              keyExtractor={(item, index) => item._id + index.toString()}
              ListEmptyComponent={this.emptyList()}
              renderItem={({ item, index }) => (
                <Track disabled={!providerLinked} id={item.id} queue={this.state.tracks} queueIndex={index} track={item} />
              )}
            />

            <View style={styles.buttonContainer}>
              <ButtonRez inline label="Skip" onPress={() => this.goBack()} testID="playlistskip-button" />
            </View>
          </View>
        )}

        {loading && (
          <View style={styles.loading}>
            <ActivityIndicator color={mainStyles.colours.orangeLight} size="large" />
          </View>
        )}
      </AppLayout>
    );
  }
}

const styles = StyleSheet.create({
  viewcontainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    flex: 1,
    marginTop: 20,
    padding: mainStyles.APP_PADDING,
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  introText: {
    fontSize: 24,
    lineHeight: 32,
    textAlign: 'center',
    marginBottom: 10,
  },
  img: {
    width: mainStyles.SMALL_IMAGE_SIZE,
    height: mainStyles.SMALL_IMAGE_SIZE,
    borderRadius: mainStyles.IMAGE_BORDER_RADIUS,
  },
  trackConatiner: {
    flex: 1,
    marginTop: 20,
    padding: mainStyles.APP_PADDING,
  },
  trackDetailsContainer: {
    flex: 1,
    paddingRight: mainStyles.distance.sm,
  },
  trackContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  trackTitleActive: {
    color: mainStyles.colours.orange,
  },
  leftSwipeItem: {
    alignItems: 'flex-end',
    flexDirection: 'row',
    marginRight: 10,
    marginLeft: 10,
    justifyContent: 'center',
  },
  buttonContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
});

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    availableProviders: selectors.providers.getAvailableProviders(state),
    appleToken: selectors.providers.apple.getToken(state),
    deezerCredentials: selectors.providers.deezer.getDeezerCredentials(state),
    spotifyCredentials: selectors.providers.spotify.getSpotifyCredentials(state),
    player: selectors.media.player.getPlayer(state),
    getCrossMatch: selectors.media.music.getCrossMatch(state),
    jwt: selectors.user.getJwt(state),
  }),
  {
    setAlert: actions.app.setAlert,
    playTrack: actions.media.music.playTrack,
    emptyQueue: actions.media.queue.emptyPlayQueue,
  },
)(withRouter(ShareTrackScreen));
