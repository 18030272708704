import axios from 'axios';
import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';
import { fork, put, select, takeEvery } from 'redux-saga/effects';

import { getCountryOrigin, getUserProfile } from '../../utils/api/api';
import { config } from '../../utils/config';
import { getFcmToken, getJwt, getUserId } from '../selectors/user';
import types from '../types';
import { watchPlayMusic } from './music';
import { watchPlayerActions } from './player';
import { watchInitApple, watchPlayAppleTrack } from './providers/apple';
import { watchPlayDeezerTrack, watchStartDeezer } from './providers/deezer';
import { watchPlaySpotifyTrack, watchSeekPosition, watchSpotifyTokenChange, watchStartSpotify } from './providers/spotify';
import { watchQueueActions } from './queue';
import { watchSeekActions } from './seekbar';
import { watchCreateUser, watchDeleteUser, watchSendUserToApi, watchUserLogin } from './user';

export function* createPlaylist(action) {
  const { payload } = action;
  const jwt = yield select(getJwt);

  const playlist = yield axios({
    method: 'POST',
    url: `${config.API_URL}/playlist`,
    data: payload,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).catch(err => {
    console.log('Error creating playlist', err);
  });

  if (playlist.status === 201) {
    yield put({ type: types.media.playlists.PLAYLIST_CREATED, payload: playlist.data });
  }
}

export function* watchCreatePlaylist() {
  yield takeEvery(types.media.playlists.CREATE_NEW_PLAYLIST, createPlaylist);
}

export function* addTrackToPlaylist(action) {
  const { payload } = action;
  const { userId, playlistId, track, token } = payload;
  delete payload.push;

  const jwt = yield select(getJwt);
  const playlist = yield axios({
    method: 'PUT',
    url: `${config.API_URL}/playlist/track`,
    data: { playlistId, token, trackId: track.id, provider: track.provider },
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });

  if (playlist.status === 201) {
    yield put({ type: types.media.playlists.TRACK_ADDED, payload: { playlistId: playlist.playlistId, track } });
    yield put({ type: types.media.playlists.GET_PLAYLISTS, payload: { userId } });
    yield put({ type: types.media.queue.ADD_TRACK_TO_QUEUE, payload: { track } });
  }
}

export function* watchAddTrackToPlugin() {
  yield takeEvery(types.media.playlists.ADD_TRACK, addTrackToPlaylist);
}

export function* loadPlaylists() {
  const jwt = yield select(getJwt);
  const userId = yield select(getUserId);

  const playlists = yield axios({
    method: 'GET',
    url: `${config.API_URL}/playlist?userId=${userId}`,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).catch(err => {
    console.error(err);
  });

  if (playlists.status === 200) {
    yield put({ type: types.media.playlists.PLAYLISTS_RETRIEVED, payload: playlists.data });
  }
}

export function* watchGetPlaylists() {
  yield takeEvery(types.media.playlists.GET_PLAYLISTS, loadPlaylists);
}

export function* followPlaylist(action) {
  const { payload } = action;
  const { playlistId, shortId, isOwner } = payload;

  if (isOwner) return false;

  const jwt = yield select(getJwt);

  const follow = yield axios({
    method: 'POST',
    url: `${config.API_URL}/playlist/${shortId}/follow?`,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });

  if (follow.status === 201) {
    yield put({ type: types.media.playlists.FOLLOWING_PLAYLIST, payload: { playlistId } });
  }
}

export function* watchfollowPlaylist() {
  yield takeEvery(types.media.playlists.FOLLOW_PLAYLIST, followPlaylist);
}

export function* unfollowPlaylist(action) {
  const { payload } = action;
  const { playlistId, shortId } = payload;
  const jwt = yield select(getJwt);

  const unfollow = yield axios({
    method: 'DELETE',
    url: `${config.API_URL}/playlist/${shortId}/follow?`,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });

  if (unfollow.status === 204) {
    yield put({ type: types.media.playlists.UNFOLLOWED_PLAYLIST, payload: { playlistId } });
  }
}

export function* watchUnfollowPlaylist() {
  yield takeEvery(types.media.playlists.UNFOLLOW_PLAYLIST, unfollowPlaylist);
}

export function* storeToken() {
  const jwt = yield select(getJwt);
  const uuid = DeviceInfo.getUniqueId();
  const fcmToken = yield select(getFcmToken);
  const platform = Platform.OS.toUpperCase();
  if (jwt) {
    yield getUserProfile({ jwt, uuid, fcmToken, platform });
  }
}

export function* getProfile() {
  const jwt = yield select(getJwt);
  const countryOrigin = yield getCountryOrigin();
  if (jwt) {
    const profile = yield getUserProfile({ jwt });
    if (profile) {
      yield put({ type: types.user.SET_PROFILE, payload: { profile } });
      yield put({ type: types.user.SET_PERSONAL_DETAILS_CM_DATA, payload: { profile, countryOrigin: countryOrigin.country, role: 'user' } });
    }
  }
}

export function* watchGetProfileOnStart() {
  yield takeEvery(types.app.APP_STARTED, getProfile);
  yield takeEvery(types.user.SET_FCM_TOKEN, storeToken);
}

export default function* rootSaga() {
  yield fork(watchCreatePlaylist);
  yield fork(watchAddTrackToPlugin);
  yield fork(watchGetPlaylists);
  yield fork(watchfollowPlaylist);
  yield fork(watchUnfollowPlaylist);
  yield fork(watchSendUserToApi);
  yield fork(watchCreateUser);
  yield fork(watchUserLogin);
  yield fork(watchDeleteUser);

  // yield fork(watchInitSpotifyPlayer);
  yield fork(watchPlaySpotifyTrack);
  yield fork(watchSeekPosition);

  // yield fork(watchInitDeezer);
  yield fork(watchStartDeezer);
  yield fork(watchInitApple);
  yield fork(watchPlayAppleTrack);

  yield fork(watchSpotifyTokenChange);
  yield fork(watchPlayMusic);

  yield fork(watchPlayDeezerTrack);
  yield fork(watchStartSpotify);

  yield fork(watchPlayerActions);
  yield fork(watchSeekActions);
  yield fork(watchQueueActions);

  yield fork(watchGetProfileOnStart);
}
