import '../../node_modules/video-react/dist/video-react.css';

import React, { Component } from 'react';
import { ActivityIndicator, Image, StyleSheet, View } from 'react-native';
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from 'react-native-responsive-screen';
import ReactPlayer from 'react-player';

import splashImage from '../assets/splash.png';
import { getOS } from '../utils/DetectOS';
import { withRouter } from '../utils/routing/Routing';

class LoadingScreen extends Component {
  player = React.createRef();

  state = {
    loading: true,
  };

  // it will show splash screen with app logo for 5 seconds only and after that it will load the app(only for iphone devices running web version)
  componentDidMount() {
    if (getOS() === 'iOS') {
      this.ready();
      setTimeout(() => {
        this.finished();
      }, 5000);
    }
  }

  handleStateChange(state) {
    if (state.ended === true) {
      this.finished();
    }
  }

  ready() {
    this.setState({
      loading: false,
    });
  }

  finished() {
    const { history } = this.props;
    history.push('/audio');
  }

  render() {
    return (
      <View style={styles.video}>
        {this.state.loading && (
          <View style={styles.loadingContainer}>
            <ActivityIndicator color="rgba(255,142,12,1)" size="large" />
          </View>
        )}
        {getOS() === 'iOS' && this.state.loading === false ? (
          <View style={{ alignItems: 'center' }}>
            <Image resizeMode="contain" source={splashImage} style={{ width: 400, height: 600 }} />
          </View>
        ) : (
          <ReactPlayer
            background="1"
            controls={false}
            height="100%"
            muted
            onEnded={() => this.finished()}
            onReady={() => this.ready()}
            playing
            url="https://vimeo.com/360534455?background=1"
            volume={0}
            width="100%"
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  video: {
    backgroundColor: '#000000',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: hp('100%'),
    width: wp('100%'),
  },
  loadingContainer: {
    position: 'absolute',
  },
});

export default withRouter(LoadingScreen);
