import React from 'react';
import { Share, Text } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import partyPic from '../../assets/party.png';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import history from '../../utils/routing/history';
import { message } from '../../utils/shareMessageText';
import PopupRez from './PopupRez';

function AllPopups(props) {
  const shareTexts = {
    title: "Let's connect",
    url: 'download',
  };
  const easySharing = () => {
    const { url, title } = shareTexts;

    Share.share(
      {
        title,
        url,
        message,
      },
      {
        dialogTitle: title,
        subject: title,
      },
    );
  };
  const faqAction = () => {
    props.setPopup({ showSuccessfullyInvited: false });
    history.push('/FaqScreen');
  };
  if (props.popup.connected === true) {
    return (
      <PopupRez
        cancelButtonAction={() => {
          props.setPopup({ connected: false, moreFun: false });
        }}
        cancelButtonLabel="Sure, but not now"
        childrenTwo="Invite your friends and TUNE IN to each other and your favourite
        artists!"
        confirmButtonAction={() => {
          props.setPopup({ connected: false, moreFun: true });
        }}
        confirmButtonLabel="More fun with friends!"
        fontSize={10}
        iconSrc={partyPic}
        isVisible={props.popup.connected}
        onBackdropPress={() => {
          props.setPopup({ connected: false, moreFun: false });
        }}
        title="CONGRATULATIONS"
      >
        You’re all set to start your interactive entertainment experience.
      </PopupRez>
    );
  }

  if (props.popup.moreFun === true) {
    return (
      <PopupRez
        confirmButtonAction={() => {
          props.setPopup({ connected: false, moreFun: false });
          history.push('/contactlist');
        }}
        confirmButtonLabelForFriends="Select your Friends"
        easySharing="Easy Sharing"
        easySharingAction={() => {
          easySharing();
        }}
        fontSize={10}
        isVisible={props.popup.moreFun}
        onBackdropPress={() => {
          props.setPopup({ connected: false, moreFun: false });
        }}
      >
        Let’s get your{'\n'}friends on board !
      </PopupRez>
    );
  }

  if (props.popup.showSuccessfullyInvited === true) {
    return (
      <PopupRez
        isVisible={props.popup.showSuccessfullyInvited}
        onBackdropPress={() => {
          props.setPopup({ showSuccessfullyInvited: false });
        }}
      >
        AWESOME!{'\n\n'} Your Friends are invited… {'\n\n'}Don’t forget to share your profile to connect with your Friends!{'\n\n'}We got your back to
        make sure{'\n'} you’ll use the full potential{'\n'} of the ONE app!{'\n\n'}Just check the{' '}
        <Text
          onPress={() => {
            faqAction();
          }}
          style={{ textDecorationLine: 'underline' }}
        >
          FAQ
        </Text>{' '}
        {'\n'} to keep you going 😃
      </PopupRez>
    );
  }
  return null;
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    popup: selectors.app.getPopup(state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        setPopup: actions.app.setPopup,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AllPopups);
