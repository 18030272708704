import React, { Component } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';

import actions from '../../../store/actions';
import selectors from '../../../store/selectors';
import mainStyles from '../../../styles';
import { durationToTime } from '../../../utils/providers/spotify/common';
import analytics from '../../../utils/routing/analytics';
import { withRouter } from '../../../utils/routing/Routing';
import AddToQueueSwiper from './AddToQueueSwiper';

class Track extends Component {
  state = {
    active: true,
    userPlaylistId: 'other',
  };

  componentDidMount() {
    const { location } = this.props;
    const { pathname } = location;
    const playlist = RegExp('playlists/.*');
    if (pathname.match(playlist)) {
      const {
        params: { shortId },
      } = matchPath(location.pathname, {
        path: '/playlists/:shortId',
      });
      if (shortId) {
        this.setState({
          userPlaylistId: shortId,
        });
      }
    }
  }

  addToPlaylist() {
    const { addTrackToPlaylist, playlistId, track, userId, jwt, tokens } = this.props;

    if (!tokens[track.provider]) return false;

    addTrackToPlaylist({
      playlistId,
      track,
      token: tokens[track.provider],
      userId,
      jwt,
    });

    this.setState({
      active: false,
    });
  }

  playTrack() {
    if (this.props.tunedTo) {
      this.props.disableTuneIn();
      analytics().logEvent('stop_tunein');
    }

    const { track, queueIndex, queue, onTrackChosen } = this.props;

    if (onTrackChosen) {
      onTrackChosen(this.props);
    }

    if (queue && queueIndex >= 0) {
      this.props.startQueue({ tracks: queue, index: queueIndex });
    } else {
      this.props.emptyPlayQueue();
      this.props.playTrack(track);
    }
  }

  goToTrackOptions() {
    const { track } = this.props;
    const { userPlaylistId } = this.state;
    this.props.history.push(`/track/${this.props.track.title || this.props.track.name}/${this.props.track.id}/${userPlaylistId}/options`, track);
  }

  render() {
    const { playlistId, showDuration, showMenu = true, track, alt, disabled, player } = this.props;
    const { id, artistName, duration, album, name } = track;
    const { active } = this.state;

    const imageSrc = album && album.images && Array.isArray(album.images) && album.images.length > 0 ? album.images[0].url : null;
    const titleStyles = [mainStyles.typography.h2, mainStyles.margin.bottom.xs];
    const artistStyles = [mainStyles.typography.h2Light];
    const trackContainerStyles = [styles.trackContainer, mainStyles.margin.bottom.sm, mainStyles.margin.top.sm];
    const durationStyles = [mainStyles.typography.p, styles.trackDuration, mainStyles.margin.right.xs];
    if (alt) {
      titleStyles.push(styles.trackTitleAlt);
      artistStyles.push(styles.trackTitleAlt);
      durationStyles.push(styles.trackTitleAlt);
    }
    if (disabled) {
      trackContainerStyles.push(styles.trackContainerDisabled);
    }

    if (player.track.id) {
      // eslint-disable-next-line eqeqeq
      if (player.track.id == id || player.crossMatched.originalId == track.id) {
        titleStyles.push(styles.trackTitleActive);
      }
    }

    if (!active) return <View />;

    return (
      <AddToQueueSwiper track={track}>
        <TouchableOpacity disabled={disabled} onPress={() => this.playTrack()} style={trackContainerStyles} testID={`track-${artistName}-${name}`}>
          {imageSrc && <Image source={{ uri: imageSrc }} style={[styles.img, mainStyles.margin.right.sm]} />}
          <View style={styles.trackDetailsContainer}>
            <Text numberOfLines={1} style={titleStyles}>
              {name}
            </Text>
            {artistName && (
              <Text numberOfLines={1} style={artistStyles}>
                {artistName}
              </Text>
            )}
          </View>

          {showDuration && <Text style={durationStyles}>{durationToTime(duration)}</Text>}

          {playlistId && (
            <TouchableOpacity
              hitSlop={{ top: 10, bottom: 20, left: 20, right: 20 }}
              onPress={() => this.addToPlaylist()}
              style={[styles.addButton, mainStyles.margin.left.sm]}
            >
              <Image source={require('../../../assets/icons/addplaylist/addtoplaylist.png')} style={mainStyles.icon} />
            </TouchableOpacity>
          )}
          {!playlistId && showMenu && (
            <TouchableOpacity
              hitSlop={{ top: 10, bottom: 20, left: 20, right: 20 }}
              onPress={() => this.goToTrackOptions()}
              style={styles.menuButton}
              testID={`trackoptions-${artistName}-${name}`}
            >
              <Image
                resizeMode="contain"
                source={require('../../../assets/icons/more/more_vertical.png')}
                style={[styles.menuButtonIcon, mainStyles.icon]}
              />
            </TouchableOpacity>
          )}
        </TouchableOpacity>
      </AddToQueueSwiper>
    );
  }
}

const styles = StyleSheet.create({
  trackContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  trackContainerDisabled: {
    opacity: 0.6,
  },
  trackTitleActive: {
    color: mainStyles.colours.orange,
  },
  trackTitleAlt: {
    color: '#000000',
  },
  img: {
    width: mainStyles.SMALL_IMAGE_SIZE,
    height: mainStyles.SMALL_IMAGE_SIZE,
    borderRadius: mainStyles.IMAGE_BORDER_RADIUS,
  },
  trackDetailsContainer: {
    flex: 1,
    paddingRight: mainStyles.distance.sm,
  },
  trackDuration: {
    marginLeft: 'auto',
  },
  addButton: {
    marginTop: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    userId: selectors.user.getUserId(state),
    jwt: selectors.user.getJwt(state),
    player: selectors.media.player.getPlayer(state),
    getCrossMatch: selectors.media.music.getCrossMatch(state),
    tokens: selectors.providers.getTokens(state),
    tunedTo: selectors.tunein.getTunedTo(state),
  }),
  {
    addTrackToPlaylist: actions.media.playlists.addTrackToPlaylist,
    playTrack: actions.media.music.playTrack,
    clearCrossMatch: actions.media.music.clearCrossMatch,
    startQueue: actions.media.queue.startQueue,
    emptyPlayQueue: actions.media.queue.emptyPlayQueue,
    disableTuneIn: actions.tunein.disableTuneIn,
  },
)(withRouter(Track));
