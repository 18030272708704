import { Formik } from 'formik';
import React, { Component } from 'react';
import { ActivityIndicator, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';

import ButtonRez from '../../components/app/ButtonRez';
import TextInputRez from '../../components/app/TextInputRez';
import FullScreenLayout from '../../layouts/fullscreen';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import mainStyles from '../../styles';
import { ACTIVITY_CM_KEY } from '../../styles/consts';
import { getCountryOrigin, getUserProfile, sendDetailsToCM, signin } from '../../utils/api/api';
import analytics from '../../utils/routing/analytics';
import { withRouter } from '../../utils/routing/Routing';
import Sentry from '../../utils/Sentry';

class SigninScreen extends Component {
  state = {
    loading: false,
    countryOrigin: '',
  };

  errorTexts = {
    ACTIVATION_PENDING: 'Your account is not active yet. When we have open slots we will let you know.',
    VALIDATION_ERROR: 'Something is off, please check your email and password.',
    default: 'Ooops, something went wrong. Check your username and password and try again.',
  };

  SigninSchema = Yup.object().shape({
    password: Yup.string().required('Required').min(8, 'Password must be 8 characters long'),
    username: Yup.string().required('Required'),
  });

  componentDidMount() {
    getCountryOrigin().then(res => {
      this.setState({ countryOrigin: res.country });
    });
  }

  handleSigninErr(err) {
    this.setState({
      loading: false,
    });

    if (err && err.response && err.response.data) {
      this.props.setAlert({
        type: 'error',
        message: this.errorTexts[err.response.data.error] || this.errorTexts.default,
      });
    }
  }

  handleSignin({ username, password }) {
    const { setUserCredentials, setUsername, setDisplayname, setXmppPassword } = this.props;
    this.setState({
      loading: true,
    });

    signin({ username, password })
      .then(async data => {
        if (data && data.token && data.userId) {
          setUserCredentials({ jwt: data.token, userId: data.userId });
          setUsername({ username: data.username });
          setXmppPassword({ password: data.xmppPassword });
          await analytics().setUserId(data.userId);
          analytics().logEvent('login');
        }
        const cmActivity = {
          created: new Date(),
          userId: this.props.userId,
          appActivated: Platform.OS,
        };
        sendDetailsToCM(cmActivity, ACTIVITY_CM_KEY);
        this.props.setAlert(null);
        return data;
      })
      .then(data => {
        const jwt = data.token;
        if (data && data.status && data.status === 'pending') {
          this.props.history.push('/validate', { userEmail: data.email, userId: data.userId });
        } else {
          getUserProfile({ jwt })
            .then(profile => {
              this.props.setProfile({ profile });
              this.props.setPersonalDetailsCMdata({ profile, countryOrigin: this.state.countryOrigin, role: 'user' });
              if (profile && profile.displayName) {
                setDisplayname({ displayName: profile.displayName });
              }
              this.checkProviderAuth();
            })
            .catch(err => {
              console.log('Error while signing in! ', err);
              Sentry.captureMessage(err);
              this.checkProviderAuth();
            });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loading: false,
        });

        if (err && err.response && err.response.data) {
          this.props.setAlert({
            type: 'error',
            message: this.errorTexts[err.response.data.error] || this.errorTexts.default,
          });
        }
      });
  }

  checkProviderAuth() {
    const { spotifyCredentials, deezerCredentials, availableProviders, profile } = this.props;
    if (
      spotifyCredentials.isAuthorized === false &&
      deezerCredentials.isAuthorized === false &&
      availableProviders.apple === false &&
      !profile.showAdditionalInfoPage
    ) {
      this.goToHome();
    } else if (
      (spotifyCredentials.isAuthorized === false &&
        deezerCredentials.isAuthorized === false &&
        availableProviders.apple === false &&
        profile.showAdditionalInfoPage) ||
      ((spotifyCredentials.isAuthorized || deezerCredentials.isAuthorized || availableProviders.apple) && profile.showAdditionalInfoPage)
    ) {
      this.goToAdditionalInput();
    } else {
      this.goToMusic();
    }
  }

  goToAdditionalInput() {
    const { history } = this.props;
    history.entries = [];
    history.index = -1;
    history.push('/exclusive-content', {
      userId: this.props.userId,
    });
  }

  goToHome() {
    const { history } = this.props;
    history.entries = [];
    history.index = -1;
    history.push('/audio');
  }

  goToMusic() {
    const { history } = this.props;
    history.entries = [];
    history.index = -1;
    history.push('/music');
  }

  goToSignup() {
    const { history } = this.props;
    history.entries = [];
    history.index = -1;
    this.props.history.push('/signup');
  }

  render() {
    const { loading } = this.state;

    return (
      <FullScreenLayout backButton={false}>
        <Text style={[mainStyles.typography.title, mainStyles.typography.textCenter, mainStyles.margin.bottom.xl]} testID="signin-text">
          Welcome
        </Text>
        <Formik
          initialValues={{ username: '', password: '' }}
          onSubmit={values => this.handleSignin(values)}
          validateOnBlur
          validationSchema={this.SigninSchema}
        >
          {props => (
            <View>
              <View style={mainStyles.margin.bottom.md}>
                <TextInputRez
                  autoCapitalize="none"
                  autoCorrect
                  autoFocus={false}
                  disabled={loading}
                  enablesReturnKeyAutomatically
                  icon={require('../../assets/icons/profile/profile.png')}
                  label="E-mail address"
                  name="username"
                  onBlur={props.handleBlur('username')}
                  onChangeText={props.handleChange('username')}
                  onSubmitEditing={() =>
                    props.validateForm().then(() => {
                      if (props.isValid) this.handleSignin(props.values);
                    })
                  }
                  placeholder="Your e-mail address"
                  testID="username-input"
                  validation={{ error: props.errors.username, touched: props.touched.username }}
                  value={props.values.username}
                />
              </View>
              <View style={mainStyles.margin.bottom.md}>
                <TextInputRez
                  autoCapitalize="none"
                  autoCorrect={false}
                  disabled={loading}
                  enablesReturnKeyAutomatically
                  icon={require('../../assets/icons/lock/lock.png')}
                  label="Password"
                  name="password"
                  onBlur={props.handleBlur('password')}
                  onChangeText={props.handleChange('password')}
                  onSubmitEditing={() =>
                    props.validateForm().then(() => {
                      if (props.isValid) this.handleSignin(props.values);
                    })
                  }
                  placeholder="Your password"
                  secureTextEntry
                  testID="password-input"
                  validation={{ error: props.errors.password, touched: props.touched.password }}
                  value={props.values.password}
                />
              </View>

              <TouchableOpacity onPress={() => this.props.history.push('/forgot')} style={styles.forgotPassword}>
                <Text style={[mainStyles.typography.p, mainStyles.margin.right.sm]}>Forgot password?</Text>
              </TouchableOpacity>

              {!loading && (
                <View style={[mainStyles.margin.top.xl, mainStyles.margin.bottom.xl]}>
                  <ButtonRez
                    disabled={!props.isValid}
                    fontColor="rgba(23,23,38,1)"
                    label="Log in"
                    large
                    onPress={props.handleSubmit}
                    startColor="rgba(255,255,255,1)"
                    testID="login-button"
                  />
                </View>
              )}
            </View>
          )}
        </Formik>

        {loading && (
          <View style={mainStyles.loading}>
            <ActivityIndicator color="rgba(255,142,12,1)" size="large" />
          </View>
        )}

        {!loading && (
          <View style={styles.CTA}>
            <Text style={[mainStyles.typography.p, mainStyles.margin.bottom.md, mainStyles.typography.textCenter]}>Don't have an account? </Text>
            <TouchableOpacity onPress={() => this.goToSignup()} testID="gotosignup-button">
              <Text style={[mainStyles.typography.ctaUnderline, { fontSize: 14 }]}>Sign up</Text>
            </TouchableOpacity>
          </View>
        )}
      </FullScreenLayout>
    );
  }
}

const styles = StyleSheet.create({
  CTA: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'baseline',
  },
  forgotPassword: {
    alignSelf: 'flex-end',
  },
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        setUserCredentials: actions.user.setUserCredentials,
        setAlert: actions.app.setAlert,
        setProfile: actions.user.setProfile,
        setJWT: actions.user.setJWT,
        setUserId: actions.user.setUserId,
        setUsername: actions.user.setUsername,
        setDisplayname: actions.user.setDisplayname,
        setXmppPassword: actions.user.setXmppPassword,
        setPersonalDetailsCMdata: actions.user.setPersonalDetailsCMdata,
      },
      dispatch,
    ),
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    jwt: selectors.user.getJwt(state),
    spotifyCredentials: selectors.providers.spotify.getSpotifyCredentials(state),
    deezerCredentials: selectors.providers.deezer.getDeezerCredentials(state),
    availableProviders: selectors.providers.getAvailableProviders(state),
    profile: selectors.user.getProfile(state),
    userId: selectors.user.getUserId(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SigninScreen));
