import React from 'react';
import { Image, StyleSheet } from 'react-native';

const Logo = () => <Image resizeMode="contain" source={require('../../assets/one-logo.png')} style={styles.logo} />;

const styles = StyleSheet.create({
  logo: {
    width: 128,
    height: 52,
  },
});

export default Logo;
