export const searchItunesAPI = async (searchQuery, page) => {
  const res = await fetch(
    `https://itunes.apple.com/search?&term=${encodeURIComponent(searchQuery)}&country=gb&entity=podcast&offset=${page * 20}&limit=20`,
  );
  if (!res || res.status !== 200) {
    return;
  }
  return res.json();
};

export const formatSearchedData = body => {
  const formatted = [];
  body.results.forEach(result => {
    const formatKind = formatted.find(form => form.wrapperType === result.wrapperType);
    if (!formatKind) {
      let typeContainer;
      switch (result.wrapperType) {
        case 'track':
          typeContainer = formatTypeContainer(result);
          typeContainer.title = 'Podcast';
          break;
        case 'artist':
          typeContainer = formatTypeContainer(result);
          typeContainer.title = 'Channel';
          break;
        default:
          console.log('uknown search result', result.wrapperType);
      }

      formatted.push(typeContainer);
    } else {
      formatKind.data.push(result);
    }
  });

  return formatted;
};

const formatTypeContainer = result => ({
  wrapperType: result.wrapperType,
  data: [result],
});
