import React, { Component } from 'react';
import { FlatList, SectionList, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TextInputRez from '../../components/app/TextInputRez';
import SearchAlbum from '../../components/media/albums/SearchAlbum';
import SearchArtist from '../../components/media/artists/SearchArtist';
import RecentSearchQuery from '../../components/media/search/RecentSearchQuery';
import Track from '../../components/media/tracks/Track';
import SearchVideoList from '../../components/media/videos/SearchVideoList';
import AppLayout from '../../layouts/app';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import mainStyles from '../../styles';
import { ACTIVITY_CM_KEY } from '../../styles/consts';
import { getYoutubeList, search, sendDetailsToCM } from '../../utils/api/api';
import analytics from '../../utils/routing/analytics';
import Sentry from '../../utils/Sentry';
import { debounce } from '../../utils/utils';

class SearchScreen extends Component {
  state = {
    query: '',
    sections: [],
    focusSectionType: 'all',
    videos: [],
    val: 2,
  };

  setFocusSectionType(sectionType) {
    this.setState({
      focusSectionType: sectionType.substr(0, sectionType.length - 1),
    });
  }

  setSearchQuery = debounce(query => {
    analytics().logEvent('search_music', { query });
    this.setState({ query }, this.runSearch);
  }, 500);

  runSearch() {
    const { appleToken, availableProviders, deezerCredentials, jwt, spotifyCredentials, storeSearch } = this.props;
    const { query } = this.state;

    const providers = [];

    if (query.length >= 3) {
      if (availableProviders.spotify === true) providers.push({ name: 'spotify', token: spotifyCredentials.token });
      if (availableProviders.apple === true) providers.push({ name: 'apple', token: appleToken });
      if (availableProviders.deezer === true) providers.push({ name: 'deezer', token: deezerCredentials.token });

      const cmActivity = {
        created: new Date(),
        userId: this.props.userId,
        searchMusic: query,
      };
      sendDetailsToCM(cmActivity, ACTIVITY_CM_KEY);

      getYoutubeList(query).then(response => {
        this.setState({
          videos: response.items,
        });
      });

      search({ query, providers, jwt }).then(response => {
        if (response.code === 598) {
          this.props.setAlert({ message: 'Sorry, something failed, please check your internet connection.', type: 'error' });
          Sentry.captureException(response);
          return;
        }
        const sections = Object.keys(response.results).map(key => {
          return {
            title: `${key.substr(0, 1).toUpperCase()}${key.substr(1)}`,
            key,
            data: response.results[key],
          };
        });

        const countResults = sResults => {
          let count = 0;
          sResults.forEach(res => {
            count += res.data.length;
          });
          return count;
        };

        const recentImage =
          sections &&
          sections !== null &&
          sections[0].data &&
          Array.isArray(sections[0].data) &&
          sections[0].data.length > 0 &&
          sections[0].data[0].images
            ? sections[0].data[0].images
            : [];

        this.setState({
          sections,
        });

        storeSearch({
          query,
          results: countResults(sections),
          images: recentImage,
        });
      });
    }
  }

  renderEmptyList = () => (
    <View style={styles.noResultsContainer}>
      <Text key={Math.random()} style={[mainStyles.typography.p, styles.noResultsText]}>
        Searching...
      </Text>
    </View>
  );

  renderRecentSearches = searches => {
    return searches.map(recentSearch => {
      return (
        <RecentSearchQuery
          key={Math.random()}
          images={recentSearch.images}
          query={recentSearch.query}
          results={recentSearch.results}
          run={() => this.startRecentSearch(recentSearch.query)}
        />
      );
    });
  };

  startRecentSearch(query) {
    this.setState({ query }, this.runSearch);
  }

  renderSectionFooter(section) {
    return (
      <TouchableOpacity onPress={() => this.setFocusSectionType(section.key)} style={[mainStyles.margin.top.sm, mainStyles.margin.bottom.xl]}>
        <Text style={[mainStyles.typography.ctaUnderline, styles.renderSectionFooter]}>See all {section.title}</Text>
      </TouchableOpacity>
    );
  }

  renderSectionTitle({ section: { title } }) {
    return (
      <Text key={`section-${title}`} style={[mainStyles.typography.h1, styles.sectionTitle]}>
        {title}
      </Text>
    );
  }

  renderSearchResult({ item, index }) {
    if (this.state.focusSectionType === 'all') {
      if (index > 1) {
        return <View key={Math.random()} />;
      }
    } else if (this.state.focusSectionType !== item.type) {
      return <View key={Math.random()} />;
    }

    switch (item.type) {
      case 'album':
        return (
          <SearchAlbum
            key={`search-album-${item.id}`}
            artistName={item.artistName}
            id={item.id}
            images={item.images}
            name={item.name}
            provider={item.provider}
          />
        );
      case 'artist':
        return <SearchArtist key={`search-artist-${item.id}`} id={item.id} images={item.images} name={item.name} provider={item.provider} />;
      case 'track':
        return <Track key={`search-track-${item.id}`} showDuration={false} testID={`track-${item.name}`} track={item} />;
      default:
        return <View key={`empty-search-${Math.random()}`} />;
    }
  }

  render() {
    const { recentSearches } = this.props;
    const { query } = this.state;
    const hasQuery = query.length > 0;
    const recent = this.renderRecentSearches(recentSearches);

    return (
      <AppLayout title="search">
        <View style={mainStyles.margin.bottom.md}>
          <TextInputRez
            defaultValue={this.state.query}
            icon={require('../../assets/icons/search/search.png')}
            onChangeText={t => this.setSearchQuery(t)}
            placeholder="Searching for..."
            testID="search-input"
          />
        </View>

        {hasQuery && (
          <View>
            <SectionList
              initialNumToRender={10}
              keyExtractor={(item, index) => index.toString()}
              ListEmptyComponent={this.renderEmptyList}
              nestedScrollEnabled
              renderItem={item => this.renderSearchResult(item)}
              renderSectionFooter={({ section }) => this.renderSectionFooter(section)}
              renderSectionHeader={this.renderSectionTitle}
              sections={this.state.sections}
            />
            {this.state.sections.length > 1 ? <Text style={[mainStyles.typography.h1, mainStyles.margin.bottom.sm]}>Videos</Text> : null}
            <FlatList
              contentContainerStyle={{ marginBottom: 5 }}
              data={this.state.videos}
              keyExtractor={item => item.id.videoId}
              renderItem={({ item, index }) => {
                return index < this.state.val ? (
                  <SearchVideoList channelTitle={item.snippet.channelTitle} title={item.snippet.title} videoId={item.id.videoId} />
                ) : null;
              }}
            />
            {this.state.sections.length > 1 ? (
              <TouchableOpacity onPress={() => this.setState({ val: 29 })} style={[mainStyles.margin.top.sm, mainStyles.margin.bottom.xl]}>
                <Text style={[mainStyles.typography.ctaUnderline, styles.renderSectionFooter]}>See all Videos</Text>
              </TouchableOpacity>
            ) : null}
          </View>
        )}

        {!hasQuery && recentSearches.length > 0 && (
          <View>
            <Text style={[mainStyles.typography.h1, mainStyles.margin.bottom.sm]}>Recent searches</Text>
            {recent}
          </View>
        )}

        {!hasQuery && recentSearches.length === 0 && (
          <View style={[mainStyles.centerFlex, styles.noResultsContainer]}>
            <Text style={[mainStyles.typography.p, styles.noResultsText]}>No results, yet...</Text>
          </View>
        )}

        {hasQuery && this.state.sections.length === 0 && (
          <View style={{ flex: 1 }}>
            <Text key="section-TRACKS" style={[mainStyles.typography.h1, styles.sectionTitle]}>
              fetching TRACKS...
            </Text>
            <Text key="section-ALBUMS" style={[mainStyles.typography.h1, styles.sectionTitle, { marginTop: 50 }]}>
              fetching ALBUMS...
            </Text>
            <Text key="section-ARTISTS" style={[mainStyles.typography.h1, styles.sectionTitle, { marginTop: 50 }]}>
              fetching ARTISTS...
            </Text>
            <Text key="section-VIDEOS" style={[mainStyles.typography.h1, styles.sectionTitle, { marginTop: 50, marginBottom: 20 }]}>
              fetching VIDEOS...
            </Text>
          </View>
        )}
      </AppLayout>
    );
  }
}

const styles = StyleSheet.create({
  noResultsContainer: {
    flexGrow: 2,
  },
  noResultsText: {
    width: '100%',
    textAlign: 'center',
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    appleToken: selectors.providers.apple.getToken(state),
    availableProviders: selectors.providers.getAvailableProviders(state),
    jwt: selectors.user.getJwt(state),
    userId: selectors.user.getUserId(state),
    deezerCredentials: selectors.providers.deezer.getDeezerCredentials(state),
    spotifyCredentials: selectors.providers.spotify.getSpotifyCredentials(state),
    spotifyDeviceId: selectors.providers.spotify.getSpotifyDeviceId(state),
    recentSearches: selectors.media.music.getRecentSearches(state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        storeSearch: actions.media.music.storeSearch,
        setAlert: actions.app.setAlert,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchScreen);
