import React from 'react';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';

import actions from '../../../store/actions';
import selectors from '../../../store/selectors';

function StreamPlayer(props) {
  const onRef = reactPlayer => {
    if (reactPlayer) {
      window.StreamPlayer = reactPlayer;

      if (!global.streamPlayer || global.streamPlayer !== reactPlayer) {
        global.streamPlayer = reactPlayer;
      }
    }
  };

  const onStatusChange = status => props.setProviderStatus({ status, provider: 'stream' });

  return (
    <ReactPlayer
      ref={onRef}
      controls={false}
      height="0%"
      onPause={() => onStatusChange('paused')}
      onStart={() => onStatusChange('playing')}
      playing={props.player.statuses.stream === 'playing'}
      url={props.player.track.url}
      volume={1}
      width="0%"
    />
  );
}

export default connect(
  state => ({
    ...state,
    player: selectors.media.player.getPlayer(state),
  }),
  {
    setProviderStatus: actions.media.player.setProviderStatus,
  },
)(StreamPlayer);
