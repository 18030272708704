import * as music from './music';
import * as player from './player';
import * as playlists from './playlists';
import * as queue from './queue';
import * as seekbar from './seekbar';
import * as stations from './stations';

export default {
  music,
  playlists,
  stations,
  seekbar,
  player,
  queue,
};
