import React, { Component } from 'react';
import { ActivityIndicator, FlatList, Image, Keyboard, Platform, Share, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import GroupChatMessage from '../../components/tunein/GroupChatMessage';
import PublicProfilePicture from '../../components/user/PublicProfilePicture';
import ShareModal from '../../containers/app/ShareModal';
import ChatLayout from '../../layouts/chat';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import mainStyles from '../../styles';
import { ACTIVITY_CM_KEY, STATUS_LIVE, STATUS_OFFLINE, STATUS_ONLINE, TUNED_IN_TYPE, TUNED_OUT_TYPE } from '../../styles/consts';
import { getPublicUserProfile, sendDetailsToCM, sendMessageNotification, sendTuneInNotification } from '../../utils/api/api';
import { config } from '../../utils/config';
import analytics from '../../utils/routing/analytics';
import { enablePushNotifications } from '../../utils/user';

class TuneInUserScreen extends Component {
  state = {
    chat: '',
    profile: {},
    message: '',
    inputHeight: 0,
    showModal: null,
    checkProfileStatus: false,
    clickedTuneIn: false,
    setMessageNotification: '',
  };

  componentDidMount() {
    const { username } = this.props.match.params;

    this.setState({
      chat: `${username.toLowerCase()}@conference.${config.XMPP_DOMAIN}`,
    });
    this.setState({ setMessageNotification: username.toLowerCase() });
    global.xmpp.sendPresence(username);

    if (username === this.props.profile.username) {
      this.setState({
        profile: this.props.profile,
      });
    } else if (username) {
      getPublicUserProfile({ username }).then(profile => {
        this.setState({ profile });
      });
    }

    // check own chat screen status after 2 seconds beacuse passing xmpp global value take some time
    if (this.props.username === username) {
      setTimeout(() => {
        if (global.xmpp.status === STATUS_ONLINE || global.xmpp.status === STATUS_LIVE) {
          this.setState({ checkProfileStatus: false });
        }
      }, 2000);
    }

    // check other user profile connection status after 30 seconds beacuse to established the xmpp connection maximum time limit is 30 seconds
    // ########### after 30 seconds statusChecker function #######
    if (!this.props?.tuneStatus && (this.props?.presence === STATUS_OFFLINE || this.props?.presence === undefined)) {
      this.setState({ checkProfileStatus: true });
      setTimeout(() => {
        if (
          this.props.presence === STATUS_OFFLINE ||
          this.props.presence === STATUS_ONLINE ||
          this.props.presence === STATUS_LIVE ||
          this.props?.presence === undefined
        ) {
          this.setState({ checkProfileStatus: false });
        }
      }, 30000);
    }
    // ######### end of statusChecker function ##########

    if (!this.props.pushNotificationsRequested) {
      try {
        enablePushNotifications({ jwt: this.props.jwt }).then(requested => {
          if (requested) this.props.setPushNotificationsRequested();
        });
      } catch (err) {
        console.log('Error getting push notifications');
      }
    }
  }

  componentDidUpdate(prevState) {
    const { username } = this.props.match.params;
    // if before 30 seconds xmpp connected and changes it status then it will interrupt the above statusChecker function
    if ((prevState.presence === STATUS_OFFLINE && prevState.presence !== this.props.presence) || prevState.presence === undefined) {
      if (this.props.presence === STATUS_ONLINE || this.props.presence === STATUS_LIVE) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ checkProfileStatus: false });
      }
    }
    if (Object.keys(this.props.player.statuses).find(prov => this.props.player.statuses[prov] === 'playing') && this.state.clickedTuneIn) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ clickedTuneIn: false });
    }
    if (this.props.presence === STATUS_ONLINE || this.props.presence === STATUS_OFFLINE) {
      if (this.props.tunedTo === username) {
        this.props.disableTuneIn();
      }
    }
  }

  sendMessage = () => {
    try {
      analytics().logEvent('send_tunein_chat_message');
      global.xmpp.sendMessage({
        to: this.state.chat,
        type: 'groupchat',
        message: this.state.message,
      });
      this.setState({
        message: '',
        inputHeight: 0,
      });
      const cmActivity = {
        created: new Date(),
        userId: this.props.userId,
        chatSentToUserId: this.state.profile.userId,
      };
      sendMessageNotification(this.props.jwt, this.state.setMessageNotification);
      sendDetailsToCM(cmActivity, ACTIVITY_CM_KEY);
    } catch (err) {
      this.props.setAlert({
        type: 'error',
        message: 'There was an error sending your message.',
      });
    }
  };

  messageInputSize = event => {
    const { height } = event.nativeEvent.contentSize;
    // eslint-disable-next-line radix
    if (parseInt(this.state.inputHeight) !== parseInt(height)) {
      this.setState({
        inputHeight: height + 5,
      });
    }
  };

  tuneIn = async () => {
    const { username } = this.props.match.params;
    if (this.props.tunedTo && this.props.tunedTo !== username) {
      global.xmpp.sendMessage({
        to: `${this.props.tunedTo.toLowerCase()}@conference.${config.XMPP_DOMAIN}`,
        type: TUNED_OUT_TYPE,
        message: { fromUser: this.props.username, count: this.props.getTunedInNumbers + 1, hostName: this.props.tunedTo },
      });
    }
    if (this.props.tunedTo !== username) {
      this.props.enableTuneIn({ user: username });
      analytics().logEvent('start_tunein');
      this.setState({ clickedTuneIn: true });
      global.xmpp.sendMessage({
        to: this.state.chat,
        type: TUNED_IN_TYPE,
        message: { fromUser: this.props.username, count: 1, hostName: username },
      });
      const cmActivity = {
        created: new Date(),
        userId: this.props.userId,
        tunedInToUserId: this.state.profile.userId,
      };
      sendTuneInNotification(this.props.jwt, username);
      sendDetailsToCM(cmActivity, ACTIVITY_CM_KEY);
    }
    if (this.props.tunedTo === username) {
      this.props.disableTuneIn();
      global.xmpp.sendMessage({
        to: this.state.chat,
        type: TUNED_OUT_TYPE,
        message: { fromUser: this.props.username, count: this.props.getTunedInNumbers + 1, hostName: username },
      });
      analytics().logEvent('stop_tunein');
    }
  };

  addToPlaylist() {
    analytics().logEvent('add_song_to_playlist_tunein');
    const { provider, id } = this.props.playingStatus;
    this.props.history.push(`/track/${id}/addToPlaylist/${provider}`);
  }

  shareTrack() {
    const { provider, id, name, artistName } = this.props.playingStatus;
    const message = `Listen to ${name} on Rezzonation`;
    const trackName = encodeURIComponent(name);
    const artistTitle = encodeURIComponent(artistName);
    const url = `${config.APP_URL}/track-share/${trackName}/${artistTitle}/${id}/${provider}`;

    analytics().logEvent('share_track_tunein');
    if (Platform.OS === 'web') {
      this.setState({ showModal: 'share' });
    } else {
      Share.share(
        {
          title: 'Share track',
          url,
          message: Platform.OS === 'android' ? `${message} ${url}` : message,
        },
        {
          dialogTitle: 'Share track',
          subject: 'Share track',
        },
      );
    }
  }

  render() {
    const { username } = this.props.match.params;
    const userProfileName = this.state.profile.displayName ? this.state.profile.displayName : this.state.profile.username;
    const messages = this.props.groupChatMessages ? this.props.groupChatMessages : [];
    const inputContainerHeight = this.state.inputHeight + 20 < 48 ? 48 : this.state.inputHeight + 20;
    const followersCount = this.state.profile?.followerCount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    const presenceStyle = [styles.presenceIndicator, mainStyles.margin.right.xs];
    let presenceText = '';
    const presenceStatus = this.props.username !== username ? this.props.presence : global.xmpp.status;
    switch (presenceStatus) {
      case 'online':
        presenceStyle.push(styles.online);
        presenceText = 'Online';
        break;
      case 'offline':
        presenceStyle.push(styles.offline);
        presenceText = 'Offline';
        break;
      case 'streaming':
        presenceStyle.push(styles.streaming);
        presenceText = 'Live';
        break;
      default:
        presenceStyle.push(styles.offline);
        presenceText = 'Offline';
        break;
    }

    return (
      <ChatLayout title="Tune in">
        <View style={[styles.header, styles.padding]}>
          <View>
            <PublicProfilePicture small url={this.state.profile.profilePictureUrl} />
          </View>
          <View style={styles.userDetails}>
            <View>
              <Text style={mainStyles.typography.h2}>{userProfileName}</Text>
            </View>
            <View style={[mainStyles.margin.top.xs, styles.presence]}>
              <View style={presenceStyle} />
              <Text style={mainStyles.typography.xsRegular}>{presenceText}</Text>
            </View>
          </View>
          {this.props.username !== username && presenceStatus === STATUS_LIVE && (
            <View style={styles.tuneInBtnContainer}>
              <TouchableOpacity onPress={() => this.tuneIn()}>
                {this.props.tunedTo === username && (
                  <View style={[styles.tuneInBtn, styles.tuneOutBtn]}>
                    <Text style={[mainStyles.typography.h1, styles.tuneOutText]}>Tune out</Text>
                  </View>
                )}

                {this.props.tunedTo !== username && (
                  <LinearGradient colors={mainStyles.BUTTON_GRADIENT} style={styles.tuneInBtn}>
                    <Text style={mainStyles.typography.h1}>Tune in</Text>
                  </LinearGradient>
                )}
              </TouchableOpacity>
              <View style={mainStyles.margin.top.xs}>
                <Text style={mainStyles.typography.h3}>Followers: {followersCount} </Text>
              </View>
              <View style={mainStyles.margin.top.xs}>
                <Text style={mainStyles.typography.h3}>Tuned In: {this.props.playingStatus.tuneInNumber}</Text>
              </View>
            </View>
          )}
          {(this.props.username === username || presenceStatus !== STATUS_LIVE) && (
            <View style={[mainStyles.margin.top.xs, styles.alignText]}>
              <Text style={mainStyles.typography.h3}>Followers: {followersCount} </Text>
            </View>
          )}
          {this.props.username === username && presenceStatus === STATUS_LIVE && (
            <View style={[mainStyles.margin.top.xs, styles.alignText]}>
              <Text style={mainStyles.typography.h3}>Tuned In: {this.props.getTunedInNumbers}</Text>
            </View>
          )}
        </View>

        {this.props.presence && this.props.presence === STATUS_LIVE && this.props.playingStatus && this.props.playingStatus.name && (
          <View style={styles.playingStatusContainer}>
            <View style={styles.playingStatus}>
              <Text style={styles.nowPlaying}>now playing</Text>
              <Text style={mainStyles.typography.sBold}>{this.props.playingStatus.name}</Text>
              <Text style={styles.artist}>{this.props.playingStatus.artistName}</Text>
            </View>
            <View style={styles.trackActionButtons}>
              <TouchableOpacity onPress={() => this.addToPlaylist()} testID="addtracktoplaylist-button">
                <Image source={require('../../assets/icons/addplaylist/addtoplaylist.png')} style={styles.actionButton} />
              </TouchableOpacity>

              <TouchableOpacity onPress={() => this.shareTrack()}>
                <Image source={require('../../assets/icons/share/share.png')} style={styles.actionButton} />
              </TouchableOpacity>
            </View>
          </View>
        )}

        <View style={[styles.chat, styles.padding]}>
          {(this.props?.tuneStatus || this.state.checkProfileStatus) && (
            <View style={{ marginTop: 30 }}>
              <ActivityIndicator color="rgba(255,142,12,1)" size="large" />
            </View>
          )}
          {!Object.keys(this.props.player.statuses).find(prov => this.props.player.statuses[prov] === 'playing') && this.state.clickedTuneIn && (
            <View style={styles.tuneInLoader}>
              <ActivityIndicator color="rgba(255,142,12,1)" size={Platform.OS === 'android' ? 55 : 'large'} />
            </View>
          )}
          {this.props.username && messages && (
            <FlatList
              data={messages}
              inverted
              ItemSeparatorComponent={() => <View style={{ minHeight: 10 }} />}
              renderItem={({ item }) => {
                return <GroupChatMessage key={item.id} message={item.message} sender={item.from} username={this.props.username} />;
              }}
            />
          )}

          {!this.props.username || (!messages && <ActivityIndicator color="rgba(255,142,12,1)" size="large" />)}
        </View>

        {presenceStatus !== STATUS_OFFLINE && (
          <View style={[styles.inputWindow, { minHeight: inputContainerHeight, maxHeight: inputContainerHeight }]}>
            <View style={[styles.chatInputContainer, styles.padding, { minHeight: inputContainerHeight, maxHeight: inputContainerHeight }]}>
              <View style={styles.chatInputWrapper}>
                <TextInput
                  autoCapitalize="sentences"
                  multiline
                  onChangeText={i => this.setState({ message: i })}
                  onContentSizeChange={e => this.messageInputSize(e)}
                  placeholder="Your message..."
                  scrollEnabled={false}
                  style={[styles.chatInput, { height: this.state.inputHeight }]}
                  value={this.state.message}
                />

                <View style={[styles.btnContainer, { minHeight: inputContainerHeight }]}>
                  <TouchableOpacity
                    onPress={() => {
                      this.sendMessage();
                      Keyboard.dismiss();
                    }}
                  >
                    <LinearGradient colors={mainStyles.BUTTON_GRADIENT} style={styles.sendButton}>
                      <Text style={[mainStyles.typography.navigation, { fontSize: 14, lineHeight: 14, textAlign: 'center' }]}>Send</Text>
                    </LinearGradient>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        )}

        {this.props.playingStatus && (
          <ShareModal
            isVisible={this.state.showModal === 'share'}
            message={`Listen to ${this.props.playingStatus.name} on Rezzonation`}
            onBackdropPress={() => this.setState({ showModal: null })}
            title="Share track"
            url={`${config.APP_URL}/track-share/${encodeURIComponent(this.props.playingStatus.name)}/${this.props.playingStatus.artistName}/${
              this.props.playingStatus.id
            }/${this.props.playingStatus.provider}`}
          />
        )}
      </ChatLayout>
    );
  }
}

const styles = StyleSheet.create({
  actionButton: {
    width: 20,
    height: 20,
  },
  actionButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  artist: {
    ...mainStyles.fontStyleLight,
    fontSize: 16,
    lineHeight: 19,
    color: 'rgba(255,255,255,1)',
  },
  btnContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    // marginBottom: 10,
    marginRight: 15,
    maxWidth: 65,
  },
  chat: {
    backgroundColor: '#F8F6FD',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingBottom: 20,
  },
  chatInputContainer: {
    minHeight: 48,
    backgroundColor: '#F8F6FD',
    flex: 1,
    marginBottom: 20,
  },
  chatInputWrapper: {
    alignItems: 'center',
    borderColor: '#171726',
    borderRadius: 25,
    borderWidth: 1,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  chatInput: {
    flex: 1,
    flexGrow: 2,
    marginLeft: 27,
    textAlignVertical: 'top',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingBottom: 10,
  },
  inputWindow: {
    flex: 1,
    backgroundColor: '#F8F6FD',
    justifyContent: 'center',
  },
  nowPlaying: {
    fontSize: 12,
    lineHeight: 19,
    color: 'rgba(161,161,161,1)',
    ...mainStyles.fontStyleLight,
  },
  online: {
    backgroundColor: mainStyles.colours.green,
  },
  offline: {
    backgroundColor: mainStyles.colours.dark,
  },
  padding: {
    paddingLeft: mainStyles.APP_PADDING,
    paddingRight: mainStyles.APP_PADDING,
  },
  playingStatus: {
    flex: 1,
    flexGrow: 2,
  },
  playingStatusContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: mainStyles.colours.dark,
    paddingTop: 10,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 10,
    maxHeight: 80,
  },
  presence: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  presenceIndicator: {
    width: 10,
    height: 10,
    borderRadius: 5,
  },
  sendButton: {
    maxHeight: 38,
    minWidth: 55,
    borderRadius: 25,
    paddingLeft: 14,
    paddingRight: 14,
    paddingTop: 10,
    paddingBottom: 10,
  },
  streaming: {
    backgroundColor: mainStyles.colours.orange,
  },
  trackActionButtons: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: 70,
  },
  tuneInBtnContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 85,
    marginTop: -10,
  },
  tuneInBtn: {
    maxHeight: 40,
    minHeight: 40,
    borderRadius: 25,
    paddingLeft: 14,
    paddingRight: 14,
    paddingTop: 10,
    paddingBottom: 10,
  },
  tuneOutBtn: {
    borderWidth: 2,
    borderColor: mainStyles.colours.orange,
    minWidth: 105,
    alignItems: 'center',
  },
  tuneOutText: {
    color: mainStyles.colours.orange,
    textAlign: 'center',
  },
  tuneInLoader: {
    position: 'absolute',
    zIndex: 1,
    top: '35%',
    left: '47%',
  },
  userDetails: {
    marginLeft: 10,
    flexGrow: 2,
  },
  alignText: {
    marginRight: 10,
    marginTop: 31,
  },
});

const mapStateToProps = (state, ownProps) => {
  const { username } = ownProps.match.params;
  const chat = `${username.toLowerCase()}@conference.${config.XMPP_DOMAIN}`;

  return {
    ...state,
    groupChatMessages: selectors.tunein.getGroupchatMessages(state, chat),
    jwt: selectors.user.getJwt(state),
    profile: selectors.user.getProfile(state),
    pushNotificationsRequested: selectors.app.pushNotificationsRequested(state),
    username: selectors.user.getUsername(state),
    userId: selectors.user.getUserId(state),
    presence: selectors.tunein.getUserPresence(state, username),
    playingStatus: selectors.tunein.getUserPlayingStatus(state, username),
    tunedTo: selectors.tunein.getTunedTo(state),
    tuneStatus: selectors.app.getTuneInStatus(state),
    player: selectors.media.player.getPlayer(state),
    getTunedInNumbers: selectors.tunein.getHostUsersCount(state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        disableTuneIn: actions.tunein.disableTuneIn,
        enableTuneIn: actions.tunein.enableTuneIn,
        setAlert: actions.app.setAlert,
        setPushNotificationsRequested: actions.app.pushNotificationsRequested,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TuneInUserScreen);
