import React from 'react';
import { StyleSheet, View } from 'react-native';

import ControlButton from '../../components/media/controls/ControlButton';
import mainStyles from '../../styles';

const MediaControl = ({
  onNextPress,
  onPrevPress,
  onTogglePlay,
  hasNextTrack,
  hasPrevTrack,
  isPlaying,
  isRepeatActive,
  isShuffleActive,
  onShufflePress,
  onRepeatPress,
}) => (
  <View style={styles.container}>
    <ControlButton active={isShuffleActive} icon="shuffle" onPress={onShufflePress} />
    <ControlButton disabled={!hasPrevTrack} icon="previous" onPress={onPrevPress} />
    {isPlaying ? <ControlButton icon="pause" onPress={onTogglePlay} /> : <ControlButton icon="play" onPress={onTogglePlay} />}
    <ControlButton disabled={!hasNextTrack} icon="next" onPress={onNextPress} />
    <ControlButton active={isRepeatActive} icon="repeat" onPress={onRepeatPress} />
  </View>
);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: mainStyles.distance.md,
    paddingRight: mainStyles.distance.md,
  },
});

export default MediaControl;
