import React, { Component } from 'react';
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';

import CircleButton from '../../components/app/CircleButton';
import SquareButton from '../../components/app/SquareButton';
import Track from '../../components/media/tracks/Track';
import ListScreenHeader from '../../containers/app/ListScreenHeader';
import AppLayout from '../../layouts/app';
import selectors from '../../store/selectors';
import mainStyles from '../../styles';
import { getArtist } from '../../utils/api/api';
import { withRouter } from '../../utils/routing/Routing';

class ArtistScreen extends Component {
  state = {
    artist: {},
    albums: [],
    tracks: [],
    related: [],
    loading: true,
  };

  componentDidMount() {
    this.getArtistDetails();
  }

  async getArtistDetails() {
    const { jwt, location, tokens } = this.props;
    const {
      params: { id, provider },
    } = matchPath(location.pathname, {
      path: '/artist/:provider/:id',
    });

    if (!tokens[provider]) return false;

    const details = await getArtist({ artistId: id, token: tokens[provider], provider, jwt });

    // If there is any duplicate album exists in the source array, skip that item and prepare a new album array
    const filterAlbums = [];
    details.albums.forEach(obj => {
      if (!filterAlbums.some(o => o.name === obj.name)) {
        filterAlbums.push({ ...obj });
      }
    });

    this.setState({
      artist: details,
      albums: filterAlbums,
      tracks: details.tracks,
      related: details.related,
      loading: false,
    });
  }

  artistOptions() {
    const { artist } = this.state;
    console.log(artist);
    const image = artist && artist.images && Array.isArray(artist.images) && artist.images.length > 0 ? artist.images[0].url : null;
    this.props.history.push('/artist/options', { artistId: artist.id, provider: artist.provider, image, name: artist.name });
  }

  render() {
    const { tracks } = this.state;

    const albums = this.state.albums
      ? this.state.albums.map(album => {
          return (
            <View key={`album-${album.id}`} style={[mainStyles.margin.top.sm, styles.squareContainer]}>
              <SquareButton
                id={album.id}
                imageUri={album.images && album.images[0] && album.images[0].url}
                path={`/album/${album.provider}/${album.id}`}
                provider={album.provider}
                title={album.name}
                urlOpts={{ title: album.name, provider: album.provider, albumId: album.id }}
              />
            </View>
          );
        })
      : [];

    const related = this.state.related
      ? this.state.related.map(artist => {
          return (
            <View key={`artist-${artist.id}`} style={[mainStyles.margin.top.sm, styles.circleContainer]}>
              <CircleButton
                id={artist.id}
                images={artist.images}
                label={artist.name}
                url={`/artist/${artist.provider}/${artist.id}`}
                urlOpts={{ title: artist.name, id: artist.id, provider: artist.provider }}
              />
            </View>
          );
        })
      : [];

    const { artist } = this.state;
    const imageSrc = artist && artist.images && Array.isArray(artist.images) && artist.images.length > 0 ? artist.images[0].url : null;

    return (
      <AppLayout moreOptionsHandler={() => this.artistOptions()} title={artist && artist.name ? artist.name : undefined}>
        {this.state.loading && (
          <View style={styles.loadingContainer}>
            <ActivityIndicator color="rgba(255,142,12,1)" size="large" />
          </View>
        )}
        {!this.state.loading && artist && Object.keys(artist).length > 0 && (
          <View>
            <ListScreenHeader circle image={imageSrc} title={artist && artist.name ? artist.name : undefined} />

            {this.state.tracks && this.state.tracks.length > 0 && (
              <View style={mainStyles.margin.bottom.md} testID="tracklist">
                <Text style={[mainStyles.typography.h1, mainStyles.margin.bottom.xs]}>Popular tracks</Text>
                {tracks.map((track, index) => {
                  return <Track key={`track-${track.id}`} queue={tracks} queueIndex={index} track={track} />;
                })}
              </View>
            )}

            {this.state.albums && this.state.albums.length > 0 && (
              <View style={mainStyles.margin.bottom.md}>
                <Text style={[mainStyles.typography.h1, mainStyles.margin.bottom.xs]}>Popular albums</Text>
                <View style={styles.circlesList}>{albums}</View>
              </View>
            )}

            {this.state.related && this.state.related.length > 0 && (
              <View style={mainStyles.margin.bottom.md}>
                <Text style={[mainStyles.typography.h1, mainStyles.margin.bottom.xs]}>Similar artists</Text>
                <View style={styles.circlesList}>{related}</View>
              </View>
            )}
          </View>
        )}
      </AppLayout>
    );
  }
}

const styles = StyleSheet.create({
  circlesList: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: '100%',
  },
  circleContainer: {
    flexBasis: '33%',
  },
  squareContainer: {
    // TODO: Add responsivity here to change to three columns on bigger screens
    flexBasis: '50%',
    alignItems: 'center',
  },
  loadingContainer: {
    marginTop: 75,
  },
});

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    jwt: selectors.user.getJwt(state),
    tokens: selectors.providers.getTokens(state),
  }),
  {},
)(withRouter(ArtistScreen));
