import React, { Component } from 'react';
import { FlatList, Image, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';

import AppLayout from '../../layouts/app';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import mainStyles from '../../styles';
import * as consts from '../../styles/consts';
import { getFavoriteStations } from '../../utils/api/api';
import analytics from '../../utils/routing/analytics';
import { withRouter } from '../../utils/routing/Routing';
import Sentry from '../../utils/Sentry';

class FavouriteRadio extends Component {
  // these are the 'tune hunter' categories: https://apps.apple.com/nl/app/tune-hunter/id1440690657
  state = {
    favList: [],
  };

  componentDidMount() {
    this.getFavList();
  }

  componentDidUpdate() {
    if (this.props.isFav) {
      this.getFavList();
      this.props.setRadioFav(false);
    }
  }

  getFavList() {
    const { jwt } = this.props;
    getFavoriteStations({ jwt })
      .then(response => {
        if (response.status === 200) {
          // eslint-disable-next-line array-callback-return
          response.data.map(object => {
            object.stationDetails.favorite = true;
            object.stationDetails.stationuuid = object.stationUuid;
          });

          this.setState({ favList: response.data });
          console.log(response.data);
        } else {
          throw new Error();
        }
      })
      .catch(err => {
        this.props.setAlert({ message: 'Sorry, something failed, please try again.', type: 'error' });
        Sentry.captureException(err);
      });
  }

  clickStation(station) {
    analytics().logEvent('play_radio', { station: station.name });
    if (this.props.tunedTo) {
      this.props.disableTuneIn();
      analytics().logEvent('stop_tunein');
    }
    this.props.setTrack({
      track: { ...station, artistName: 'Radio', provider: 'stream', album: { images: [{ url: station.favicon }] } },
      origin: 'radio',
    });
  }

  renderStations() {
    return (
      <View style={{ flex: 1, marginTop: 20 }}>
        <View style={mainStyles.margin.bottom.md}>
          <Text style={mainStyles.typography.h1}>Favorites</Text>
          <View style={styles.stationsContainer}>
            <FlatList
              data={this.state.favList}
              keyExtractor={(item, index) => item._id + index.toString()}
              numColumns={3}
              renderItem={({ item }) => (
                <TouchableOpacity
                  key={item._id}
                  onPress={() => this.clickStation(item.stationDetails)}
                  style={[styles.stations, mainStyles.margin.top.sm]}
                  testID={`radiofavourite_${item._id}`}
                >
                  <Image
                    defaultSource={require('../../assets/icons/radio/radio_mini.png')}
                    resizeMode="contain"
                    source={
                      item.stationDetails.favicon === '' ? require('../../assets/icons/radio/radio_mini.png') : { uri: item.stationDetails.favicon }
                    }
                    style={styles.stationLogos}
                  />
                  <Text
                    numberOfLines={1}
                    style={[
                      mainStyles.typography.h3,
                      mainStyles.margin.top.xs,
                      { textAlign: 'center', fontFamily: Platform.OS === 'android' ? 'Cabo-Rounded-Regular' : 'Cabo Rounded' },
                    ]}
                  >
                    {item.stationDetails.name}
                  </Text>
                </TouchableOpacity>
              )}
            />
          </View>
        </View>
      </View>
    );
  }

  render() {
    return (
      <AppLayout title="radio">
        <View style={[styles.stationsContainer, mainStyles.margin.bottom.md]}>{this.renderStations()}</View>
      </AppLayout>
    );
  }
}

const styles = StyleSheet.create({
  stationsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  category: {
    paddingLeft: mainStyles.distance.xs,
    paddingRight: mainStyles.distance.xs,
    flexBasis: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,
    marginTop: 20,
  },
  categoryLogo: {
    height: 50,
    width: 50,
    borderRadius: 25,
  },
  loadingContainer: {
    marginTop: consts.FULL_HEIGHT / 3,
  },
  stations: {
    paddingLeft: mainStyles.distance.xs,
    paddingRight: mainStyles.distance.xs,
    flexBasis: '33%',
  },
  stationLogos: {
    flex: 1,
    height: 120,
    width: undefined,
    borderRadius: mainStyles.IMAGE_BORDER_RADIUS,
  },
  loadMoreButton: {
    marginBottom: 20,
    alignSelf: 'center',
    backgroundColor: 'white',
    width: 100,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
  },
  loadMoreText: {
    fontFamily: 'Roboto-Medium',
    color: 'black',
    fontSize: 15,
    marginTop: 15,
    marginBottom: 12,
  },
});

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    jwt: selectors.user.getJwt(state),
    stations: selectors.media.stations.getStations(state),
    tunedTo: selectors.tunein.getTunedTo(state),
    isFav: selectors.media.stations.getIsFav(state),
  }),
  {
    setAlert: actions.app.setAlert,
    setRadioFav: actions.media.stations.setRadioFav,
    setStations: actions.media.stations.setStations,
    disableTuneIn: actions.tunein.disableTuneIn,
    setTrack: actions.media.player.setTrack,
    setCategory: actions.media.player.setCategory,
  },
)(withRouter(FavouriteRadio));
