import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import Logo from '../../components/app/Logo';
import FullScreenLayout from '../../layouts/fullscreen';
import mainStyles from '../../styles';

const WaitinglistScreen = () => {
  return (
    <FullScreenLayout>
      <View style={[styles.logoContainer, mainStyles.margin.bottom.xl]}>
        <Logo />
      </View>

      <Text style={[mainStyles.typography.h2Light, mainStyles.typography.textCenter, mainStyles.margin.bottom.sm]}>You&apos;re on the list!</Text>
      <Text style={[mainStyles.typography.p, mainStyles.typography.textCenter, mainStyles.margin.bottom.xl]}>
        At the moment we&apos;re completely full. We&apos;ve placed you on the waitinglist. When there&apos;s a spot available we&apos;ll let you know
        via email.
      </Text>
    </FullScreenLayout>
  );
};
export default WaitinglistScreen;

const styles = StyleSheet.create({
  logoContainer: {
    alignItems: 'center',
  },
});
