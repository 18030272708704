import React, { Component } from 'react';
import { ActivityIndicator, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';

import selectors from '../../store/selectors';
import mainStyles from '../../styles';
import { getPublicUserProfile } from '../../utils/api/api';
import { withRouter } from '../../utils/routing/Routing';
import PublicProfilePicture from '../user/PublicProfilePicture';

class TuneInUser extends Component {
  state = {
    loading: true,
    user: {},
  };

  componentDidMount() {
    try {
      if (this.props.user.username) {
        getPublicUserProfile({ username: this.props.user.username })
          .then(profile => {
            this.setState({ user: profile, loading: false });
          })
          .catch(() => {
            this.setState({ loading: false });
          });
      }
    } catch (err) {
      this.setState({ loading: false });
    }
  }

  render() {
    const { loading, user } = this.state;
    const userProfileName = this.props.user.displayName ? this.props.user.displayName : this.props.user.username;
    return (
      <View>
        {!loading && (
          <TouchableOpacity
            onPress={() => {
              this.props.history.push(`/tuneIn/${this.props.user.username}`);
            }}
          >
            <PublicProfilePicture presence={this.props.presence} url={user.profilePictureUrl} />
          </TouchableOpacity>
        )}

        {!loading && <Text style={[mainStyles.typography.h3, mainStyles.margin.top.sm, styles.username]}>{userProfileName}</Text>}

        {loading && (
          <View style={styles.loading}>
            <ActivityIndicator color="rgba(255,142,12,1)" size="large" />
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: 108,
    height: 108,
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  username: {
    textAlign: 'center',
  },
});

export default connect((state, ownProps) => ({
  ...ownProps,
  presence: selectors.tunein.getUserPresence(state, ownProps.user.username),
}))(withRouter(TuneInUser));
