import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';

import AddToPlaylistButton from '../../../components/media/playlist/AddToPlaylistButton';
import Playlists from '../../../containers/media/Playlists';
import AppLayout from '../../../layouts/app';
import actions from '../../../store/actions';
import selectors from '../../../store/selectors';
import { addTrackToPlaylist } from '../../../utils/api/api';
import { withRouter } from '../../../utils/routing/Routing';

class AddToPlaylistScreen extends Component {
  async addToPlaylist(playlistId) {
    const { addToQueue, history, userId, jwt, tokens, location } = this.props;
    const {
      params: { id, provider },
    } = matchPath(location.pathname, {
      path: '/track/:id/addToPlaylist/:provider',
    });

    if (!tokens[provider]) return false;

    const updatedPlaylist = await addTrackToPlaylist({
      playlistId,
      userId,
      jwt,
      trackId: id,
      provider,
      token: tokens[provider],
    }).catch(() => console.warn('could not add track to playlist'));
    if (!updatedPlaylist) return;

    addToQueue({ track: updatedPlaylist.tracks[updatedPlaylist.tracks.length - 1] });
    history.goBack();
  }

  render() {
    return (
      <AppLayout>
        <Playlists onClickPlaylist={playlist => this.addToPlaylist(playlist.playlistId)} playlistButton={AddToPlaylistButton} />
      </AppLayout>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    userId: selectors.user.getUserId(state),
    jwt: selectors.user.getJwt(state),
    tokens: selectors.providers.getTokens(state),
  }),
  {
    addToQueue: actions.media.queue.addToQueue,
  },
)(withRouter(AddToPlaylistScreen));
