import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';

import ButtonRez from '../../components/app/ButtonRez';
import ProfilePicture from '../../components/user/ProfilePicture';
import mainStyles from '../../styles';
import { withRouter } from '../../utils/routing/Routing';

const ProfileHeader = ({ profile, history, children, shareProfile, inviteFriends }) => {
  const userProfileName = profile.displayName ? profile.displayName : profile.username;

  return (
    <View style={{ flexDirection: 'column' }}>
      <View style={styles.headerContainer}>
        <ProfilePicture />

        <View style={[styles.user, mainStyles.margin.left.sm, mainStyles.margin.top.sm]}>
          <View style={styles.userDetails}>
            <View style={styles.userNameContainer}>
              <Text style={[mainStyles.typography.h2]}>{userProfileName}</Text>
            </View>

            <View style={styles.countContainer}>
              <View style={[styles.count, mainStyles.margin.top.sm]}>
                <Text style={mainStyles.typography.h3}>{profile.followerCount}</Text>
                <Text style={mainStyles.typography.h3Light}> Followers</Text>
              </View>
              <TouchableOpacity onPress={() => history.push('/follower')}>
                <View style={[styles.count, { justifyContent: 'flex-end' }]}>
                  <Text style={mainStyles.typography.h3}>{profile.followingCount}</Text>
                  <Text style={mainStyles.typography.h3Light}> Following</Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>

          <View style={[styles.profileButtons, mainStyles.margin.top.md]}>{children}</View>
        </View>
      </View>
      <View style={[styles.btnContainer, mainStyles.margin.top.md, mainStyles.margin.bottom.xl]}>
        <View style={{ backgroundColor: '#e06e17', borderRadius: 20 }}>
          <ButtonRez inline label="Share Profile" onPress={shareProfile} secondary small testID="share-profile-button" />
        </View>
        <View style={{ backgroundColor: '#e06e17', borderRadius: 20 }}>
          <ButtonRez inline label="Invite Friends" onPress={inviteFriends} secondary small testID="invite-friends-button" />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  btnContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    // eslint-disable-next-line no-dupe-keys
    justifyContent: 'space-around',
  },
  count: {
    flex: 1,
    flexDirection: 'row',
  },
  countContainer: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  headerContainer: {
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  profileButtons: {
    flexDirection: 'row',
  },
  user: {
    flex: 1,
  },
  userDetails: {
    display: 'flex',
  },
  ePointsContainer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  ePointsIndicator: {
    flex: 1,
    flexDirection: 'row',
  },
});

export default connect(null, null)(withRouter(ProfileHeader));
