import { Platform } from 'react-native';
import { eventChannel } from 'redux-saga';
import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects';

import types from '../../types';

function spotifyChannel() {
  return eventChannel(emitter => {
    const { spotify } = global;

    spotify.addListener('SpotifyMetadataChange', async change => {
      emitter({
        type: 'event',
        event: 'metadata_change',
        payload: change,
      });
    });

    spotify.addListener('SpotifyPlay', event => {
      emitter({
        type: 'event',
        event: 'play',
        payload: {
          ...event,
        },
      });
    });

    spotify.addListener('SpotifyPause', event => {
      emitter({
        type: 'event',
        event: 'pause',
        payload: {
          ...event,
        },
      });
    });

    spotify.addListener('SpotifyPreviousTrack', event => {
      emitter({
        type: 'event',
        event: 'previousTrack',
        payload: {
          ...event,
        },
      });
    });

    spotify.addListener('SpotifyReady', event => {
      emitter({
        type: 'event',
        event: 'ready',
        payload: {
          deviceId: event.deviceId,
        },
      });
    });

    spotify.addListener('SpotifyNotReady', event => {
      emitter({
        type: 'event',
        event: 'not_ready',
        payload: {
          deviceId: event.deviceId,
        },
      });
    });

    spotify.addListener('SpotifyPlaybackError', () => {
      emitter({
        type: 'error',
        error: 'playback_error',
      });
    });

    spotify.addListener('SpotifyAccountError', () => {
      emitter({
        type: 'error',
        error: 'account_error',
      });
    });

    spotify.addListener('SpotifyAuthenticationError', () => {
      emitter({
        type: 'error',
        error: 'auth_error',
      });
    });

    spotify.addListener('SpotifyInitError', () => {
      emitter({
        type: 'error',
        error: 'init_error',
      });
    });

    spotify.addListener('SpotifySessionRenewed', event => {
      console.log('Spotify session renewed');
      emitter({
        type: 'event',
        event: 'session_renewed',
        payload: {
          ...event,
        },
      });
    });
    // --> add disconnect
    return () => {};
  });
}

export const getSpotifyCredentials = store => {
  return store.providers.spotify.auth;
};

function* startSpotify() {
  const spotify = yield call(spotifyChannel);
  try {
    while (true) {
      const spotifyEvent = yield take(spotify);
      switch (spotifyEvent.event) {
        case 'metadata_change':
          const { track, state } = spotifyEvent.payload;
          yield put({
            type: types.media.music.LOAD_NEW_MUSIC_TRACK,
            payload: { provider: 'spotify', track: { ...track, provider: 'spotify', status: state.paused ? 'paused' : 'playing' }, origin: 'music' },
          });
          yield put({ type: types.media.player.SET_PROGRESS, payload: { duration: track.duration } });
          break;
        case 'play':
          yield put({
            type: types.media.player.SET_PROVIDER_STATUS,
            payload: { status: 'playing', position: spotifyEvent.payload.position, provider: 'spotify' },
          });
          break;
        case 'pause':
          yield put({
            type: types.media.player.SET_PROVIDER_STATUS,
            payload: { status: 'paused', position: spotifyEvent.payload.position, provider: 'spotify' },
          });
          break;
        case 'previousTrack':
          yield put({
            type: types.media.queue.PLAY_PREVIOUS_TRACK,
          });
          break;
        case 'ready':
          yield put({ type: types.providers.spotify.SET_DEVICE_ID, payload: { deviceId: spotifyEvent.payload.deviceId } });
          break;
        case 'not_ready':
          yield put({ type: types.providers.spotify.SET_DEVICE_ID, payload: { deviceId: null } });
          yield put({ type: types.providers.spotify.SET_PLAYER, payload: { player: null } });
          break;
        case 'session_renewed':
          yield put({
            type: types.providers.spotify.SET_AUTH_TOKEN,
            payload: {
              token: spotifyEvent.payload.access_token,
              expires: spotifyEvent.payload.expires,
              expiresIn: spotifyEvent.payload.expires_in,
              refreshToken: spotifyEvent.payload.refresh_token,
            },
          });
          break;
        default:
          if (spotifyEvent.event) {
            console.warn('saga spotify: unknown spotify event', spotifyEvent);
          }
      }

      switch (spotifyEvent.error) {
        case 'account_error':
          yield put({
            type: types.app.SET_ALERT,
            payload: { message: 'There is a problem with your Spotify account. You need an active premium subscription.', type: 'error' },
          });
          break;
        case 'auth_error':
          const spotifyCredentials = yield select(getSpotifyCredentials);
          if (spotifyCredentials && spotifyCredentials.token) {
            yield call(global.spotify.renewSession, {});
          } else {
            yield put({ type: types.providers.spotify.SET_DEVICE_ID, payload: { deviceId: null } });
            yield put({ type: types.providers.spotify.SET_PLAYER, payload: { player: null } });
            yield put({ type: types.providers.spotify.SET_AUTH_TOKEN, payload: { token: null } });
            yield put({
              type: types.app.SET_ALERT,
              payload: { message: "Your Spotify credentials aren't valid. Please login again.", type: 'error' },
            });
          }
          break;
        case 'init_error':
          // eslint-disable-next-line no-undef
          Sentry.captureMessage('Unable to initialise Spotify player.');
          yield put({ type: types.providers.spotify.SET_DEVICE_ID, payload: { deviceId: null } });
          yield put({ type: types.providers.spotify.SET_PLAYER, payload: { player: null } });
          yield put({ type: types.providers.spotify.SET_AUTH_TOKEN, payload: { token: null } });
          yield put({
            type: types.app.SET_ALERT,
            payload: { message: "The Spotify player couldn't be loaded. Probably your device is not supported.", type: 'error' },
          });
          break;
        case 'playback_error':
          yield put({
            type: types.app.SET_ALERT,
            payload: { message: 'Sorry, something went wrong playing that song. Please try again.', type: 'error' },
          });
          yield put({ type: types.media.player.SET_PROVIDER_STATUS, payload: { status: 'stopped', position: 0, provider: 'spotify' } });
          break;
        default:
          if (spotifyEvent.error) {
            console.warn('saga spotify: unknown spotify error event', spotifyEvent.error);
          }
      }
    }
  } finally {
    yield put({ type: types.providers.spotify.SET_DEVICE_ID, payload: { deviceId: null } });
    yield put({ type: types.providers.spotify.SET_PLAYER, payload: { player: null } });
    spotify.close();
  }
}

// Afspelen
export function* watchStartSpotify() {
  yield takeEvery(types.app.APP_STARTED, startSpotify);
}

export function* playSpotifyTrack(action) {
  try {
    global.spotify.play(action.payload.track, action.payload.startIndex, action.payload.startPosition);
  } catch (err) {
    console.warn('playSpotifyTrack:', err);
  }
}

export function* watchPlaySpotifyTrack() {
  yield takeEvery(types.providers.spotify.PLAY_TRACK, playSpotifyTrack);
}

function* seekPosition({ payload }) {
  global.spotify.playerSeekTo(payload.newPosition);
}

export function* watchSeekPosition() {
  yield takeEvery(types.providers.spotify.SEEK_POSITION, seekPosition);
}

function* updateSpotifyPlayerTokens(action) {
  const { payload } = action;
  const { token, refreshToken, expires } = payload;

  if (Platform.OS === 'web') {
    yield fork(global.spotify.setToken, token);
    yield fork(global.spotify.setRefreshtoken, refreshToken);
    yield fork(global.spotify.setExpires, expires);
  }
}

export function* watchSpotifyTokenChange() {
  yield takeEvery(types.providers.spotify.SET_AUTH_TOKEN, updateSpotifyPlayerTokens);
}
