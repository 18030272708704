import React from 'react';
import { StyleSheet, View } from 'react-native';

const SeekbarInnerLine = ({ onLayout, currentWidthStyle }) => (
  <View id="seekbar-outer" onLayout={onLayout} pointerEvents="box-none" style={[styles.bar, styles.outerBar]}>
    <View id="seekbar-inner" pointerEvents="box-none" style={[styles.bar, styles.innerBar, currentWidthStyle()]} />
  </View>
);

const styles = StyleSheet.create({
  seekbarTouchArea: {
    height: 40,
    justifyContent: 'center',
  },
  seekTextContainer: {
    minWidth: 45,
    paddingLeft: 8,
    paddingRight: 8,
    alignItems: 'center',
    minHeight: 20,
    justifyContent: 'center',
  },
  seekText: {
    color: 'white',
  },
  seekBarContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 5,
  },
  flexBar: {
    flex: 1,
    alignItems: 'center',
  },
  bar: {
    height: 3,
  },
  outerBar: {
    width: '100%',
    backgroundColor: 'grey',
  },
  innerBar: {
    backgroundColor: 'orange',
  },
});

export default SeekbarInnerLine;
