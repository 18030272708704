import * as Sentry from '@sentry/browser';
import React from 'react';
import Script from 'react-load-script';
import { StyleSheet, View } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { connect } from 'react-redux';

import StreamPlayer from './components/media/stream/StreamPlayer';
import RezzonationRoutes from './Router';
import actions from './store/actions';
import selectors from './store/selectors';
import providers from './store/selectors/providers';
import { ACTIVITY_CM_KEY } from './styles/consts';
import { sendDetailsToCM } from './utils/api/api';
import { config } from './utils/config';
import { databaseInit } from './utils/DatabaseHelper';
import { supportsEncryptedMediaExtension } from './utils/drm';
import apple from './utils/providers/apple';
import deezer from './utils/providers/deezer';
import spotify from './utils/providers/spotify';
import Xmpp from './utils/xmpp';

class App extends React.Component {
  constructor(props) {
    super(props);
    // From: https://sentry.io/settings/d-centralize/projects/rezzonation-app
    // eslint-disable-next-line no-undef
    if (!__DEV__) {
      Sentry.init({
        dsn: config.SENTRY_DSN_KEY,
        environment: config.SENTRY_ENV,
      });
    }

    global.spotify = new spotify.Spotify();
    global.deezer = new deezer.Deezer();
    global.apple = new apple.Apple();
  }

  componentDidMount() {
    this.props.appStarted();
    supportsEncryptedMediaExtension();
    this.props.setWebDownloadPopup({ validateDownloadPopup: true, visibleDownloadPopup: false });
    databaseInit()
      .then(() => {
        console.log('Initialized Database');
      })
      .catch(err => {
        console.log('Initialized Database Failed');
        console.log(err);
      });
    if (this.props.userId !== null) {
      const cmActivity = {
        created: new Date(),
        userId: this.props.userId,
        appActivated: 'web',
      };
      sendDetailsToCM(cmActivity, ACTIVITY_CM_KEY);
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  async waitForSpotifyWebPlaybackSDKToLoad() {
    return new Promise(resolve => {
      if (window.Spotify) {
        resolve(window.Spotify);
      } else {
        window.onSpotifyWebPlaybackSDKReady = () => {
          resolve(window.Spotify);
        };
      }
    });
  }

  async spotifyLoaded() {
    await this.waitForSpotifyWebPlaybackSDKToLoad().then(async () => {
      if (this.props.spotifyCredentials.expires) {
        global.spotify.setToken(this.props.spotifyCredentials.token);
        global.spotify.setRefreshtoken(this.props.spotifyCredentials.refreshToken);
        global.spotify.setExpires(this.props.spotifyCredentials.expires);

        if (await global.spotify.isAuthorised()) {
          global.spotify.initialize();
        } else {
          this.props.resetSpotifyAuthToken();
          global.spotify.renewSession().then(() => {
            global.spotify.initialize();
          });
        }
      } else {
        this.props.resetSpotifyAuthToken();
        await global.spotify.renewSession();
      }
    });
  }

  spotifyError() {
    Sentry.captureMessage('Unable to load Spotify Javascript SDK from Spotify.');
  }

  deezerLoaded() {
    global.deezer.initialize();
    this.props.initDeezer();
  }

  deezerError() {
    Sentry.captureMessage('Unable to load Deezer Javascript SDK from Deezer');
  }

  appleLoaded() {
    this.props.initApple();
  }

  appleError() {
    Sentry.captureMessage('Unable to download Apple MusicKit JS SDK from Apple');
  }

  render() {
    const { availableProviders, deezerCredentials, spotifyCredentials } = this.props;

    if (this.props.xmppPassword && this.props.profile.username) {
      try {
        if (!global.xmpp) {
          global.xmpp = new Xmpp({
            username: this.props.profile.username,
            password: this.props.xmppPassword,
            following: this.props.following.map(u => u.username),
          });

          this.props.clearGroupchat({
            chat: `${this.props.profile.username}@conference.${config.XMPP_DOMAIN}`,
          });

          global.xmpp.on('NewGroupChatMessage', payload => {
            this.props.addGroupchatMessage({ message: payload.message });
          });

          global.xmpp.on('ClearGroupChat', payload => {
            this.props.clearGroupchat({ chat: payload.chat });
          });

          global.xmpp.on('TuneInUserOffline', payload => {
            const { user } = payload;
            this.props.setUserOffline({ user });
          });

          global.xmpp.on('TuneInUserOnline', payload => {
            const { user } = payload;
            this.props.setUserOnline({ user });
          });

          global.xmpp.on('NewTuneInTrackReceived', payload => {
            this.props.setNewTuneInTrackReceived({ payload });
          });

          global.xmpp.on('TuneInUserStartedStreaming', payload => {
            this.props.setUserStreaming({ user: payload.from });
          });

          global.xmpp.on('TuneInUserStoppedStreaming', payload => {
            this.props.setUserOnline({ user: payload.from });
            if (payload.from === this.props.tunedTo) {
              this.props.toggleProviderStatus({ provider: this.props.player.track.provider, status: 'paused' });
            }
            this.props.clearPlayingStatus({ user: payload.from });
          });

          global.xmpp.on('TuneInSubscribersReceived', () => {
            this.props.following.forEach(user => {
              if (!global.xmpp.subscribedTo.includes(user.username)) global.xmpp.subscribePubSubUser({ username: user.username });
            });
          });

          global.xmpp.start();
        }
      } catch (err) {
        console.log('Error on render of App: ', err);
        Sentry.captureException(err);
      }
    }

    return (
      <SafeAreaProvider>
        <View style={styles.wrapper}>
          {availableProviders.spotify && spotifyCredentials.isAuthorized && (
            <Script onError={this.spotifyError} onLoad={() => this.spotifyLoaded()} url="https://sdk.scdn.co/spotify-player.js" />
          )}
          {availableProviders.deezer && deezerCredentials.isAuthorized && (
            <Script onError={() => this.deezerError()} onLoad={() => this.deezerLoaded()} url="https://e-cdns-files.dzcdn.net/js/min/dz.js" />
          )}
          <Script onError={() => this.appleError()} onLoad={() => this.appleLoaded()} url="https://js-cdn.music.apple.com/musickit/v1/musickit.js" />

          <StreamPlayer />
          <RezzonationRoutes />
        </View>
      </SafeAreaProvider>
    );
  }
}

const styles = StyleSheet.create({
  wrapper: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
});

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    availableProviders: selectors.providers.getAvailableProviders(state),
    deezerCredentials: selectors.providers.deezer.getDeezerCredentials(state),
    spotifyCredentials: providers.spotify.getSpotifyCredentials(state),
    player: selectors.media.player.getPlayer(state),
    alert: selectors.app.getAlert(state),
    xmppPassword: selectors.user.getXmppPassword(state),
    following: selectors.user.getFollowing(state),
    profile: selectors.user.getProfile(state),
    jwt: selectors.user.getJwt(state),
    userId: selectors.user.getUserId(state),
    tunedTo: selectors.tunein.getTunedTo(state),
  }),
  {
    initApple: actions.providers.apple.initApplePlayer,
    initDeezer: actions.providers.deezer.initDeezerPlayer,
    appStarted: actions.app.appStarted,
    resetSpotifyAuthToken: actions.providers.spotify.resetAuthToken,
    addGroupchatMessage: actions.tunein.addGroupchatMessage,
    clearGroupchat: actions.tunein.clearGroupchatHistory,
    setUserOnline: actions.tunein.setUserOnline,
    setUserOffline: actions.tunein.setUserOffline,
    setUserStreaming: actions.tunein.setUserStreaming,
    setNewTuneInTrackReceived: actions.tunein.newTuneInTrackReceived,
    toggleProviderStatus: actions.media.player.toggleProviderStatus,
    clearPlayingStatus: actions.tunein.clearPlayingStatus,
    setWebDownloadPopup: actions.app.showWebDownloadPopup,
  },
)(App);
