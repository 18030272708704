import React from 'react';
import { Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { withRouter } from 'react-router-dom';

import mainStyles from '../../styles';

const ProviderPremiumButton = ({ children, path, history }) => {
  const navigate = url => history.push(url);
  return (
    <View style={[mainStyles.margin.bottom.md, styles.underlineTextContainer]}>
      <TouchableOpacity onPress={() => navigate(path)} style={styles.textUnderline}>
        <Text style={[styles.premiumText, mainStyles.margin.top.md]}>{children}</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  premiumText: {
    fontSize: 19,
    color: mainStyles.TEXT_COLOR,
    fontFamily: Platform.OS === 'android' ? 'Cabo-Rounded-Bold' : 'CaboRoundedBold',
    letterSpacing: 1,
    lineHeight: 24,
  },
  underlineTextContainer: {
    alignItems: 'center',
  },
  textUnderline: {
    borderBottomWidth: 2,
    borderBottomColor: mainStyles.TEXT_COLOR,
  },
});

export default withRouter(ProviderPremiumButton);
