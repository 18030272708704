import React, { Component } from 'react';
import { connect } from 'react-redux';

import actions from '../../store/actions';
import selectors from '../../store/selectors';
import { MEDIA_CONNECT_CM_KEY } from '../../styles/consts';
import { sendDetailsToCM, updateProviderImportedPlaylists } from '../../utils/api/api';
import { withRouter } from '../../utils/routing/Routing';
import ProviderButton from './ProviderButton';

class AppleButton extends Component {
  async clickHandler() {
    const { setEnabled, setOriginUrl, setToken } = this.props;

    const response = await global.apple.login();
    if (!response) return;

    setEnabled();
    this.props.setPopup({ connected: true, moreFun: false });
    setToken({ token: response });
    updateProviderImportedPlaylists(this.props.jwt, response, 'apple');
    const cmMedia = {
      userId: this.props.userId,
      appleMusic: new Date(),
    };
    sendDetailsToCM(cmMedia, MEDIA_CONNECT_CM_KEY);

    setOriginUrl({ origin: null });
  }

  render() {
    const { enabled } = this.props;
    return (
      <ProviderButton
        color="#1DB954"
        enabled={enabled}
        handler={() => this.clickHandler()}
        icon="apple"
        name="Apple Music"
        testID="apple-provider-button"
      />
    );
  }
}

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    originUrl: selectors.app.getOriginUrl(state),
    jwt: selectors.user.getJwt(state),
    userId: selectors.user.getUserId(state),
  }),
  {
    setOriginUrl: actions.app.setOriginUrl,
    setPopup: actions.app.setPopup,
    setEnabled: actions.providers.apple.setEnabled,
    setToken: actions.providers.apple.setToken,
  },
)(withRouter(AppleButton));
