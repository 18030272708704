import { isBefore } from 'date-fns';
import { Platform } from 'react-native';
import { put, select, takeEvery } from 'redux-saga/effects';

import types from '../types';

export function* playNewQueue(action) {
  const { payload } = action;
  if (payload.tracks && payload.tracks[payload.index]) {
    yield put({ type: types.media.music.PLAY_TRACK, payload: { track: payload.tracks[payload.index] } });
  }
}

export function* checkIfQueuePlaysNextTrack(action) {
  const { payload } = action;
  const { status, position, provider } = payload;

  const queueStore = yield select(state => state.media.queue);
  const player = yield select(state => state.media.player);
  let trackEnded = false;

  if (provider === 'apple') {
    if (Platform.OS === 'ios') {
      trackEnded = status === 'paused' && position === 0;
      if (status === 'stopped' && position * 1000 >= player.track.duration) {
        trackEnded = true;
      }
    } else if (Platform.OS === 'android') {
      trackEnded = status === 'paused' && position === -1;
      if (status === 'stopped' && position >= player.track.duration) {
        trackEnded = true;
      }
    } else {
      trackEnded = status === 'ended';
    }
  }

  if (provider === 'deezer') {
    trackEnded = status === 'paused' && position === 0;
  }

  if (provider === 'spotify') trackEnded = status === 'paused' && position === 0;

  // nasty hack to ensure among providers whether a track has ended.
  if (trackEnded && (!player.dateStartedTrack || isBefore(player.dateStartedTrack, new Date() - 2000))) {
    if (queueStore.queueIndexHistory.length < queueStore.queue.length) {
      // play next track, the reducer chooses which track
      const pauseDate = Date.now();
      yield put({ type: types.media.player.SET_PAUSETIME, payload: pauseDate });
      yield put({ type: types.media.queue.MANUAL_NEXT_TRACK, payload: false });
      yield put({ type: types.media.queue.PLAY_NEXT_TRACK });
    } else if (queueStore.shuffle && queueStore.queueIndexHistory.length >= queueStore.queue.length) {
      // the queue has ended and in shuffle, restart the queue
      yield put({ type: types.media.queue.RESTART_QUEUE });
    } else if (queueStore.repeat) {
      yield put({ type: types.media.music.PLAY_TRACK, payload: player });
    } else {
      const pauseDate = Date.now();
      yield put({ type: types.media.player.SET_PAUSETIME, payload: pauseDate });
      yield put({ type: types.media.player.SET_TRACKEND, payload: trackEnded });
    }
  }
}

export function* playNextOrPreviousTrack() {
  const queueStore = yield select(state => state.media.queue);
  if (!queueStore.queue[queueStore.index]) {
    return;
  }
  yield put({ type: types.media.music.PLAY_TRACK, payload: { track: queueStore.queue[queueStore.index] } });
}

export function* watchQueueActions() {
  yield takeEvery(types.media.player.SET_PROVIDER_STATUS, checkIfQueuePlaysNextTrack);
  yield takeEvery(types.media.queue.PLAY_NEXT_TRACK, playNextOrPreviousTrack);
  yield takeEvery(types.media.queue.RESTART_QUEUE, playNextOrPreviousTrack);
  yield takeEvery(types.media.queue.PLAY_PREVIOUS_TRACK, playNextOrPreviousTrack);
  yield takeEvery(types.media.queue.START_QUEUE, playNewQueue);
}
