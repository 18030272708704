import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';

import NavigatorButton from '../../components/footer/NavigatorButton';
import mainStyles from '../../styles';

export default class BottomNavigator extends Component {
  state = {
    buttons: [
      {
        label: 'Home',
        path: '/audio',
        icon: require('../../assets/icons/home/home.png'),
        activeIcon: require('../../assets/icons/home/home_orange.png'),
      },
    ],
  };

  componentDidMount() {
    const { pathname } = this.props.location;

    let newButtons = this.state.buttons;

    const musicButtons = [
      {
        label: 'Music',
        path: '/music',
        icon: require('../../assets/icons/music/music.png'),
        activeIcon: require('../../assets/icons/music/music_orange.png'),
      },
      {
        label: 'Search',
        path: '/music/search',
        icon: require('../../assets/icons/search/search.png'),
        activeIcon: require('../../assets/icons/search/search_orange.png'),
      },
      {
        label: 'Playlists',
        path: '/playlists',
        icon: require('../../assets/icons/myplaylists/myplaylist.png'),
        activeIcon: require('../../assets/icons/myplaylists/myplaylist_orange.png'),
      },
    ];

    const radioButtons = [
      {
        label: 'Radio',
        path: '/radio',
        icon: require('../../assets/icons/radio/radio.png'),
        activeIcon: require('../../assets/icons/radio/radio_orange.png'),
      },
    ];

    const podcastButtons = [
      {
        label: 'Podcast',
        path: '/podcast/search',
        icon: require('../../assets/icons/podcast/podcast.png'),
        activeIcon: require('../../assets/icons/podcast/podcast_orange.png'),
      },
    ];

    const musicRegex = RegExp('^/music');
    const radioRegex = RegExp('^/radio');
    const podcastRegex = RegExp('^/podcast');

    switch (true) {
      case musicRegex.test(pathname):
        newButtons = [...newButtons, ...musicButtons];
        break;
      case radioRegex.test(pathname):
        newButtons = [...newButtons, ...radioButtons];
        break;
      case podcastRegex.test(pathname):
        newButtons = [...newButtons, ...podcastButtons];
        break;
      default:
        newButtons = [...newButtons, ...musicButtons];
        break;
    }

    newButtons.push({
      label: 'Profile',
      path: '/profile',
      icon: require('../../assets/icons/profile/profile.png'),
      activeIcon: require('../../assets/icons/profile/profile_orange.png'),
    });

    this.setState({
      buttons: [...newButtons],
    });
  }

  render() {
    const { location } = this.props;
    const { buttons } = this.state;

    const navigatorButtonsElements = buttons.map(navigatorButton => {
      let locationPathName = location.pathname;

      if (locationPathName.startsWith('/playlist')) {
        locationPathName = '/playlists';
      }

      const active = locationPathName === navigatorButton.path;
      return (
        <NavigatorButton
          key={navigatorButton.label}
          active={active}
          icon={active ? navigatorButton.activeIcon : navigatorButton.icon}
          label={navigatorButton.label}
          path={navigatorButton.path}
          testID={`${navigatorButton.label.toLowerCase()}-nav-button`}
        />
      );
    });

    return <View style={styles.navigator}>{navigatorButtonsElements}</View>;
  }
}

const styles = StyleSheet.create({
  navigator: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: mainStyles.BOTTOM_NAVIGATOR_HEIGHT,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: mainStyles.colours.navigationBar,
    paddingTop: 15,
    paddingBottom: 15,
  },
});
