import React, { PureComponent } from 'react';
import { StyleSheet, Text, View } from 'react-native';

export default class GroupChatMessage extends PureComponent {
  render() {
    const isSender = this.props.sender === this.props.username;

    return (
      <View style={[styles.message, isSender ? styles.right : styles.left]}>
        <Text style={[isSender ? styles.user : styles.sender]}>{this.props.sender}</Text>
        <Text style={[styles.content]}>{this.props.message}</Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    flexWrap: 'wrap',
    flex: 1,
  },
  left: {
    alignItems: 'flex-start',
  },
  message: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    maxWidth: '65%',
    minWidth: '65%',
    backgroundColor: '#E5E5E5',
    borderRadius: 5,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight: 15,
  },
  right: {
    marginRight: 0,
    marginLeft: 'auto',
  },
  sender: {
    color: '#15B678',
  },
  user: {
    color: '#A730E4',
  },
});
