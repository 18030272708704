/* eslint-disable no-unused-vars */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import { ActivityIndicator, FlatList, Platform, Share, StyleSheet, Text, View } from 'react-native';
import { connect } from 'react-redux';

import ButtonRez from '../../../components/app/ButtonRez';
import ModalButton from '../../../components/app/ModalButton';
import ModalRez from '../../../components/app/ModalRez';
import Track from '../../../components/media/tracks/Track';
import ListScreenHeader from '../../../containers/app/ListScreenHeader';
import ShareModal from '../../../containers/app/ShareModal';
import AppLayout from '../../../layouts/app';
import actions from '../../../store/actions';
import selectors from '../../../store/selectors';
import mainStyles from '../../../styles';
import { ACTIVITY_CM_KEY } from '../../../styles/consts';
import { getPlaylist, getSharePlaylist, saveSharePlaylist, sendDetailsToCM } from '../../../utils/api/api';
import { config } from '../../../utils/config';
import analytics from '../../../utils/routing/analytics';
import { withRouter } from '../../../utils/routing/Routing';

class PlaylistScreen extends Component {
  itemsPerPage = 25;

  state = {
    from: 0,
    tracks: [],
    playlistInfo: [],
    trackEnd: false,
    loading: true,
    showSharePlaylist: false,
    showProviderWarning: false,
    playlistSaved: false,
  };

  errorTexts = {
    VALIDATION_ERROR: 'Something is off, please check your input and try again',
    default: 'Ooops, something went wrong. Please try again later.',
  };

  shareTexts = {
    message: `Open this playlist in Rezzonation ${config.APP_URL}/playlists/${this.props.shortId}`,
    title: 'Share playlist',
    url: `${config.APP_URL}/playlists/${this.props.shortId}`,
  };

  componentDidMount() {
    this.getSharePlayListInfo();
    this.getTracks();
  }

  getSharePlayListInfo() {
    const { jwt, shortId } = this.props;
    getSharePlaylist({ shortId, jwt }).then(data => {
      if (data) {
        this.setState({
          playlistInfo: data,
        });
      }
    });
  }

  getTracks() {
    const { jwt, shortId } = this.props;
    const { from, trackEnd } = this.state;
    if (trackEnd) return;

    getPlaylist({ shortId, jwt, trackSize: this.itemsPerPage, tracksFrom: from }).then(data => {
      if (data.tracks) {
        const newTracks = [...this.state.tracks, ...data.tracks];
        if (data.tracks.length === 0) {
          this.setState({
            trackEnd: true,
          });
        } else {
          this.setState({
            tracks: newTracks,
            from: this.state.from + this.itemsPerPage,
          });
        }
        this.setState({
          loading: false,
        });
      }
    });
  }

  isAnyProviderLinked() {
    return !(
      this.props.spotifyCredentials.isAuthorized === false &&
      this.props.deezerCredentials.isAuthorized === false &&
      this.props.availableProviders.apple === false
    );
  }

  playPlaylist() {
    const { queueStore, startQueue, emptyQueue } = this.props;

    if (!this.isAnyProviderLinked()) {
      this.toggleProviderWarningModal();
    } else {
      emptyQueue();
      if (this.props.tunedTo) {
        this.props.disableTuneIn();
        analytics().logEvent('stop_tunein');
      }
      analytics().logEvent('play_playlist');
      startQueue({ tracks: this.state.tracks, index: queueStore.shuffle ? Math.floor(Math.random() * this.state.tracks.length) : 0 });
    }
  }

  followPlaylist() {
    const { followPlaylist, unfollowPlaylist, userId } = this.props;
    const { playlistInfo } = this.state;
    if (!playlistInfo.isFollower) {
      analytics().logEvent('follow_playlist');
      followPlaylist({
        playlistId: playlistInfo.playlistId,
        userId,
        shortId: playlistInfo.shortId,
        isOwner: playlistInfo.isOwner,
      });
    } else {
      analytics().logEvent('unfollow_playlist');
      unfollowPlaylist({
        playlistId: playlistInfo.playlistId,
        shortId: playlistInfo.shortId,
      });
    }
  }

  sharePlaylist() {
    const { url, title, message } = this.shareTexts;
    analytics().logEvent('share_playlist');
    const cmActivity = {
      created: new Date(),
      userId: this.props.userId,
      playlistShare: this.state.playlistInfo.playlistId,
    };
    sendDetailsToCM(cmActivity, ACTIVITY_CM_KEY);
    if (Platform.OS === 'web') {
      this.toggleSharePlaylistModal();
    } else {
      Share.share(
        {
          title,
          url,
          message,
        },
        {
          dialogTitle: title,
          subject: title,
        },
      );
    }
  }

  savePlaylist() {
    const { jwt } = this.props;
    const playlistDetail = this.state.playlistInfo;
    analytics().logEvent('save_playlist');
    const cmActivity = {
      created: new Date(),
      userId: this.props.userId,
      playlistSaved: true,
    };
    sendDetailsToCM(cmActivity, ACTIVITY_CM_KEY);
    saveSharePlaylist({ playlistId: playlistDetail.playlistId, jwt })
      .then(data => {
        if (data) {
          this.setState({
            playlistInfo: data,
            playlistSaved: true,
          });
          this.props.addPlaylist(data);
          this.props.setAlert({ message: 'Your playlist was saved successfully.', type: 'success' });
        }
      })
      .catch(err => {
        this.props.setAlert({ message: `Couldn't save playlist ${playlistDetail.name}`, type: 'error' });
      });
  }

  goToSearch() {
    this.props.history.push('/music/search');
  }

  goToPlaylistOptions() {
    this.props.history.push(`/playlists/${this.props.shortId}/options`, { shortId: this.props.shortId });
  }

  goToProviders() {
    this.props.setOriginUrl({ origin: `/playlist/${this.props.shortId}` });
    this.props.history.push('/providers');
  }

  toggleSharePlaylistModal() {
    this.setState(prevState => ({ showSharePlaylist: !prevState.showSharePlaylist }));
  }

  toggleProviderWarningModal() {
    this.setState(prevState => ({ showProviderWarning: !prevState.showProviderWarning }));
  }

  emptyList() {
    return (
      <>
        {this.state.loading && <ActivityIndicator color="rgba(255,142,12,1)" size="large" />}
        {!this.state.loading && this.state.playlistInfo.isOwner && (
          <View style={[mainStyles.centerFlex, mainStyles.margin.top.lg, mainStyles.margin.bottom.md]}>
            <Text style={mainStyles.typography.p}>Your playlist is empty. Go add some tracks.</Text>
            <View style={mainStyles.margin.top.md}>
              <ButtonRez label="Search tracks" onPress={() => this.goToSearch()} testID="searchtracks-button" />
            </View>
          </View>
        )}
      </>
    );
  }

  render() {
    const { playlistInfo } = this.state;
    const providerLinked = this.isAnyProviderLinked();
    const { playlistImageData } = this.props;
    let imageSrc = '';
    if (playlistImageData && playlistImageData.data && playlistImageData.data.url && playlistImageData.data.playlistId === playlistInfo.playlistId) {
      imageSrc = this.props.playlistImageData.data.url;
    } else if (playlistInfo && playlistInfo.customImage) {
      imageSrc = playlistInfo.customImage;
    } else {
      imageSrc = playlistInfo && playlistInfo.source && playlistInfo.source.image ? playlistInfo.source.image : undefined;
    }
    return (
      <AppLayout
        moreOptionsHandler={playlistInfo.isOwner || playlistInfo.isPublic ? () => this.goToPlaylistOptions() : null}
        title={playlistInfo && playlistInfo.name}
      >
        <ListScreenHeader image={imageSrc} title={playlistInfo && playlistInfo.name} />

        <View style={[styles.listControlContainer, mainStyles.margin.bottom.md]}>
          {this.state.tracks.length > 0 && (
            <View style={mainStyles.margin.right.md}>
              <ButtonRez inline label="Play" onPress={() => this.playPlaylist()} testID="playlistplay-button" />
            </View>
          )}

          {playlistInfo.isOwner && this.state.playlistSaved === false && (
            <View>
              <ButtonRez inline label="Share" onPress={() => this.sharePlaylist()} testID="playlistshare-button" />
            </View>
          )}

          {!playlistInfo.isOwner && (
            <View style={mainStyles.margin.right.md}>
              <ButtonRez inline label="Save" onPress={() => this.savePlaylist()} testID="playlistsave-button" />
            </View>
          )}
        </View>

        <FlatList
          data={this.state.tracks}
          initialNumToRender={10}
          keyExtractor={(item, index) => item._id + index.toString()}
          ListEmptyComponent={this.emptyList()}
          onEndReached={() => this.getTracks()}
          onEndReachedThreshold={0.5}
          renderItem={({ item, index }) => (
            <Track disabled={!providerLinked} id={item.id} queue={this.state.tracks} queueIndex={index} track={item} />
          )}
        />

        <ShareModal
          isVisible={this.state.showSharePlaylist}
          message={this.shareTexts.message}
          onBackdropPress={() => this.toggleSharePlaylistModal()}
          title={this.shareTexts.title}
          url={this.shareTexts.url}
        />

        <ModalRez
          isVisible={this.state.showProviderWarning}
          onBackdropPress={() => this.toggleProviderWarningModal()}
          title="No music providers linked"
        >
          <ModalButton
            iconSrc={require('../../../assets/icons/connect/connect.png')}
            label="Connect to a provider"
            onPress={() => this.goToProviders()}
          />
        </ModalRez>
      </AppLayout>
    );
  }
}

const styles = StyleSheet.create({
  errorText: {
    color: '#000000',
  },
  listControlContainer: {
    // position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default connect(
  (state, ownProps) => ({
    ...ownProps,
    availableProviders: selectors.providers.getAvailableProviders(state),
    jwt: selectors.user.getJwt(state),
    deezerCredentials: selectors.providers.deezer.getDeezerCredentials(state),
    spotifyCredentials: selectors.providers.spotify.getSpotifyCredentials(state),
    userId: selectors.user.getUserId(state),
    shortId: ownProps.match.params.shortId,
    queueStore: selectors.media.queue.getQueue(state),
    tunedTo: selectors.tunein.getTunedTo(state),
    playlistImageData: selectors.media.playlists.getImagePlaylist(state),
  }),
  {
    updatePlaylist: actions.media.playlists.updatePlaylist,
    addPlaylist: actions.media.playlists.addPlaylist,
    emptyQueue: actions.media.queue.emptyPlayQueue,
    startQueue: actions.media.queue.startQueue,
    followPlaylist: actions.media.playlists.followPlaylist,
    unfollowPlaylist: actions.media.playlists.unfollowPlaylist,
    setAlert: actions.app.setAlert,
    setOriginUrl: actions.app.setOriginUrl,
    disableTuneIn: actions.tunein.disableTuneIn,
  },
)(withRouter(PlaylistScreen));
