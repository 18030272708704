import types from '../../types';

const initialState = {
  // contains all tracks
  queue: [],
  // contains indexes(to state.queue) of manually queued tracks, this array has priority over the regular queue.
  queueImmediate: [],
  // contains indexes(to state.queue) of already played tracks.
  queueIndexHistory: [],
  index: 0,
  trackEnding: false,
  repeat: false,
  shuffle: false,
  manualNextTrack: false,
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case types.media.queue.EMPTY_QUEUE:
      return {
        ...state,
        index: 0,
        queue: [],
        queueIndexHistory: [],
      };
    case types.media.queue.ADD_TRACK_TO_QUEUE: {
      const { queueImmediate } = state;
      if (payload.opts && payload.opts.next) {
        queueImmediate.push(state.queue.length);
      }

      return {
        ...state,
        queueImmediate,
        queue: [...state.queue, payload.track],
      };
    }
    case types.media.queue.START_QUEUE: {
      let queueIndexHistory = [0];
      if (payload.index > 0 && !state.shuffle) {
        // pre-fill the index history, these indexes won't be played unless the user navigates backwards(but not when shuffling).
        queueIndexHistory = [...Array(payload.index + 1).keys()];
      }

      return {
        ...state,
        queue: payload.tracks,
        index: payload.index,
        queueIndexHistory,
      };
    }
    case types.media.queue.MANUAL_NEXT_TRACK: {
      return {
        ...state,
        manualNextTrack: payload,
      };
    }
    case types.media.queue.PLAY_NEXT_TRACK: {
      let nextIndex = state.index;
      const { queueImmediate } = state;

      const notPlayedIndexes = [...Array(state.queue.length).keys()].filter(ind => !state.queueIndexHistory.includes(ind));
      if (notPlayedIndexes.length > 0) {
        if (state.queueImmediate.length > 0) {
          // the user has manually queued songs and those have priority
          [nextIndex] = queueImmediate.splice(0, 1);
        } else if (state.shuffle) {
          // take a random index from non played indexes
          nextIndex = notPlayedIndexes[Math.floor(Math.random() * notPlayedIndexes.length)];
        } else if (state.manualNextTrack === false && state.repeat) {
          nextIndex = state.index;
        } else {
          // play the next non played song
          [nextIndex] = notPlayedIndexes;
        }
      } else {
        // all songs have been played, non-existant index will stop the queue from progressing to the next song.
        nextIndex = state.queue.length;
      }

      return {
        ...state,
        queueIndexHistory: [...state.queueIndexHistory, nextIndex],
        queueImmediate,
        index: nextIndex,
      };
    }
    case types.media.queue.PLAY_PREVIOUS_TRACK:
      const { queueIndexHistory } = state;
      let prevIndex = state.queueIndexHistory[state.queueIndexHistory.indexOf(state.index) - 1];
      if (prevIndex === -1) {
        prevIndex = 0;
      }

      queueIndexHistory.splice(state.queueIndexHistory.length - 1, 1);

      return {
        ...state,
        index: prevIndex,
        queueIndexHistory,
      };
    case types.media.queue.TOGGLE_REPEAT:
      return {
        ...state,
        repeat: !state.repeat,
      };
    case types.media.queue.TOGGLE_SHUFFLE:
      return {
        ...state,
        shuffle: !state.shuffle,
      };
    case types.media.queue.RESTART_QUEUE:
      return {
        ...state,
        queueIndexHistory: [],
        index: 0,
      };
    case types.user.LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
