import * as apple from './apple';
import * as deezer from './deezer';
import * as spotify from './spotify';

const getAvailableProviders = store => {
  return store.providers.providers.availableProviders;
};

const getTokens = store => {
  return {
    spotify: store.providers.spotify.auth.token,
    deezer: store.providers.deezer.auth.token,
    apple: store.providers.apple.token,
  };
};

export default {
  apple,
  deezer,
  spotify,
  getAvailableProviders,
  getTokens,
};
