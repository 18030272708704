import React from 'react';
import { Image, Text } from 'react-native';

import mainStyles from '../../styles';
import ButtonRez from '../app/ButtonRez';

const ContentTypeButton = ({ handler, type, label, disabled, testID }) => {
  let iconImage;
  let startColor;
  let endColor;

  switch (type) {
    case 'audio-books':
      iconImage = require('../../assets/icons/audiobooks/audiobooks.png');
      startColor = 'rgba(255,142,12,1)';
      endColor = 'rgba(254,107,12,1)';
      break;
    case 'games':
      iconImage = require('../../assets/icons/game/games.png');
      startColor = 'rgba(183,49,254,1)';
      endColor = 'rgba(144,27,205,1)';
      break;
    case 'music':
      iconImage = require('../../assets/icons/music/music.png');
      startColor = 'rgba(255,142,12,1)';
      endColor = 'rgba(254,107,12,1)';
      break;
    case 'podcast':
      iconImage = require('../../assets/icons/podcast/podcast.png');
      startColor = 'rgba(255,142,12,1)';
      endColor = 'rgba(254,107,12,1)';
      break;
    case 'radio':
      iconImage = require('../../assets/icons/radio/radio.png');
      startColor = 'rgba(255,142,12,1)';
      endColor = 'rgba(254,107,12,1)';
      break;
    case 'shopping':
      iconImage = require('../../assets/icons/shopping/shopping.png');
      startColor = 'rgba(76,157,252,1)';
      endColor = 'rgba(67,141,227,1)';
      break;
    case 'tickets':
      iconImage = require('../../assets/icons/ticket/ticket.png');
      startColor = 'rgba(36,212,26,1)';
      endColor = 'rgba(30,182,21,1)';
      break;
    case 'video':
      iconImage = require('../../assets/icons/video/video.png');
      startColor = 'rgba(253,53,54,1)';
      endColor = 'rgba(247,45,46,1)';
      break;
    default:
      console.log('unkown contenttypebutton type', type);
  }

  return (
    <ButtonRez disabled={disabled} endColor={endColor} icon={iconImage} large onPress={handler} startColor={startColor} testID={testID}>
      <Text style={[mainStyles.typography.navigation, mainStyles.margin.left.md]}>{label}</Text>
      <Image source={require('../../assets/icons/arrow/arrow_right.png')} style={mainStyles.iconRight} />
    </ButtonRez>
  );
};
export default ContentTypeButton;
